import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { BlockedPansContainer } from './blockedPans/list/BlockedPansContainer';
import { CreateBlockedPan } from './blockedPans/components/createBlockedPan/CreateBlockedPan';
import { BlockedPanDetails } from './blockedPans/components/details/BlockedPanDetails';
import { useEffect } from 'react';
import { resetAllItems } from './blockedPans/blockedPansSlice';
import { useAppDispatch } from 'hooks/redux';

export const FraudPrevention = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route exact={true} path={`${match.path}/blocked-pans/details/:id`}>
        <BlockedPanDetails />
      </Route>
      <Route path={`${match.path}/blocked-pans/create`}>
        <CreateBlockedPan />
      </Route>
      <Route path={`${match.path}/blocked-pans`}>
        <BlockedPansContainer />
      </Route>
    </Switch>
  );
};

import ContentLoader from 'react-content-loader';

export const RectLoader = ({
  width,
  height,
  className,
  backgroundColor,
}: {
  width: number;
  height: number;
  className?: string;
  backgroundColor?: string;
}) => (
  <ContentLoader
    className={className}
    backgroundColor={backgroundColor}
    height={height}
    width={width}
  >
    <rect width={width} height={height} />
  </ContentLoader>
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { merchantsResource } from './api';
import { RootState } from 'store/store';

import { getRoot } from './selectors';
import { listRequestPayload } from './utils';
import { LoadItems } from 'entities';
import { listCountryCode } from 'api/merchants';
import { errorMessage } from '../../reports/utils';

export const loadItems: any = createAsyncThunk(
  'sysMerchants/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { rowsPerPage, appliedFilters, page, sort } = getRoot(
        getState() as RootState
      );

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await merchantsResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'sysMerchants/fetchDetails',
  async (data: { id: string }) => {
    try {
      const response = await merchantsResource.fetchDetails(data);
      return {
        merchantDetails: response.data,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateMerchantToggleStatus: any = createAsyncThunk(
  'sysMerchants/updateMerchantToggleStatus',
  async (data: any) => {
    try {
      await merchantsResource.updateMerchantToggleStatus(data);

      toast.success('Merchant status updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e: any) {
      toast.error(errorMessage(e.error?.message.replace('_', ' ')), {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const createMerchant: any = createAsyncThunk(
  'sysMerchants/createMerchant',
  async (data) => {
    try {
      const response = await merchantsResource.createMerchant(data);

      toast.success('Merchant created', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return response.data;
    } catch (e) {
      toast.error('Merchant could not be created', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const updateMerchantDetails: any = createAsyncThunk(
  'sysMerchants/updateMerchantDetails',
  async (data: any) => {
    try {
      await merchantsResource.updateMerchantDetails(data);

      toast.success('Merchant details updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error('Merchant details could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const updateAccountHolderDetails: any = createAsyncThunk(
  'sysMerchants/updateAccountHolderDetails',
  async (data: any) => {
    try {
      await merchantsResource.updateAccountHolderDetails(data);

      toast.success('Account holder updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error('Account holder details details could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const updateReportsConfigDetails: any = createAsyncThunk(
  'sysMerchants/updateReportsConfigDetails',
  async (data: any) => {
    try {
      await merchantsResource.updateReportsConfigDetails(data);

      toast.success('Report configuration updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error('Report configuration details could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const updateRapidConnectDetails: any = createAsyncThunk(
  'sysMerchants/updateRapidConnectDetails',
  async (data: any) => {
    try {
      await merchantsResource.updateRapidConnectDetailsPost(data);

      toast.success('Rapid Connect updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error('Rapid Connect could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const createRapidConnectDetails: any = createAsyncThunk(
  'sysMerchants/createRapidConnectDetails',
  async (data: any) => {
    try {
      await merchantsResource.createRapidConnectDetailsPost(data);

      toast.success('Rapid Connect created', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error('Rapid Connect could not be created', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const updateMerchantConfigDetails: any = createAsyncThunk(
  'sysMerchants/updateMerchantConfigDetails',
  async (data: any) => {
    try {
      await merchantsResource.updateMerchantsConfigDetails(data);

      toast.success('Merchants configuration updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error(
        'Merchants general configuration details could not be updated',
        {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        }
      );
      return Promise.reject(e);
    }
  }
);

export const updateMerchantConfigLimitDetails: any = createAsyncThunk(
  'sysMerchants/updateMerchantConfigLimitDetails',
  async (option: { merchantId: string; body: any }) => {
    try {
      const body = option.body;
      await merchantsResource.updateMerchantsConfigLimitDetails(
        body,
        option.merchantId
      );

      toast.success('Merchants limit configuration updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return option;
    } catch (e) {
      toast.error(
        'Merchants limit configuration details could not be updated',
        {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        }
      );
      return Promise.reject(e);
    }
  }
);

export const fetchListCountryCode: any = createAsyncThunk(
  'sysMerchants/listCountryCode',
  async (options: any) => {
    try {
      const { data } = await listCountryCode(options);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

import {
  AnyAction,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { ACCESS_TOKEN_HEADER_KEY } from 'constants/accessTokenHeaderKey';
import { setProfile } from 'domain/account/accountSlice';
import { logout } from 'domain/account/thunks';
import { ErrorCodes } from 'enums/errorCodes';

import { appReducer } from './reducers';
import api from '../api';

export const store = configureStore({
  reducer: appReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    thunk,
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

api.interceptors.response.use(
  ({ headers, data }) => {
    if (['application/json', 'text/csv'].includes(headers['content-type'])) {
      return data;
    } else {
      return {
        blob: new Blob([data], { type: headers['content-type'] }),
        fileName: headers['content-disposition']
          ? headers['content-disposition'].split('filename=')[1]
          : null,
      };
    }
  },
  (error) => {
    if (error) {
      if (
        [
          ErrorCodes.UNAUTHORIZED,
          ErrorCodes.ACCESS_DENIED,
          ErrorCodes.CREDENTIALS_INVALID,
          ErrorCodes.TOKEN_EXPIRED,
        ].includes(error.response?.data.code) ||
        [
          ErrorCodes.UNAUTHORIZED,
          ErrorCodes.ACCESS_DENIED,
          ErrorCodes.CREDENTIALS_INVALID,
          ErrorCodes.TOKEN_EXPIRED,
        ].includes(error.response?.data.error.code)
      ) {
        const token = sessionStorage.getItem(ACCESS_TOKEN_HEADER_KEY);

        if (token) {
          store.dispatch(logout({ sigOutReason: 'SIGN_OUT_SYSTEM' }));
        } else {
          store.dispatch(setProfile(null));
        }
      }

      return Promise.reject(error.response?.data || error.response);
    } else {
      return Promise.reject(error);
    }
  }
);

export const persistor = persistStore(store);

import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as yup from 'yup';

import { RectLoader } from 'components/RectLoader';
import { emailPattern } from 'validation/patterns';
import { LoggingStatus } from '../utils';
import { ItemAttributes } from 'components/itemAttributeType';

export interface GridItemsProps {
  label?: string;
  value?: any | null;
  withBorderRight?: boolean;
  withBorderBottom?: boolean;
  loading?: boolean;
  update?: boolean;
  Component?: any;
  className?: string;
  children?: ReactNode;
}

export const GridItem: React.FC<GridItemsProps> = ({
  label,
  value,
  withBorderRight = true,
  withBorderBottom = true,
  loading = false,
  update = false,
  Component,
  className,
  children,
}) => (
  <div
    className={classNames('px-2 py-2 border-gray-200 h-16', className, {
      'border-b': withBorderBottom,
      'border-r': withBorderRight,
    })}
  >
    <div className="flex items-center content-between h-full">
      {label && (
        <div className="w-1/4 h-auto">
          <p className="text-xs font-medium text-gray-700">
            {loading ? <RectLoader width={150} height={18} /> : label}
          </p>
        </div>
      )}

      <div className="w-3/4 h-auto">
        {!update ? (
          <p className="text-xs font-medium text-gray-900 break-all">
            {loading ? <RectLoader width={150} height={18} /> : value}
          </p>
        ) : (
          children || (Component && Component)
        )}
      </div>
    </div>
  </div>
);

export const GridItemStatus: React.FC<GridItemsProps> = ({
  label,
  withBorderRight = true,
  withBorderBottom = true,
  loading = false,
  Component,
}) => (
  <div
    className={classNames('px-2 py-2 border-gray-200 h-16', {
      'border-b': withBorderBottom,
      'border-r': withBorderRight,
    })}
  >
    <div className="flex items-center content-between h-full">
      <div className="w-1/4 h-auto">
        <p className="text-xs font-medium text-gray-700">
          {loading ? <RectLoader width={150} height={18} /> : label}
        </p>
      </div>
      <div className="w-3/4 h-auto">{Component}</div>
    </div>
  </div>
);

export const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return yup
      .object()
      .shape({
        merchants: yup.array(),
        firstName: yup.string().required(t('validations.required')),
        lastName: yup.string().required(t('validations.required')),
        email: yup
          .string()
          .required(t('validations.required'))
          .matches(emailPattern, t('validations.email')),
        role: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .default(undefined)
          .nullable()
          .required(t('validations.required')),
      })
      .required();
  }, [t]);
};

export const getUserDetailsIconColor = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'box-bg-green-300 box-shadow-green';
    case 'DISABLED':
      return 'box-bg-red-300 box-shadow-red';
    case 'PENDING':
      return 'box-bg-orange-300 box-shadow-orange';
    default:
      return 'box-bg-grey-300 box-shadow-grey';
  }
};

export enum LoggingStatusMessage {
  SIGN_IN = 'Sign in',
  SIGN_OUT = 'Sign out',
  SIGN_OUT_USER = 'Sign out by user',
  SIGN_OUT_SYSTEM = 'Sign out by system',
  MAXIMUM_LOGIN_ATTEMPTS = 'Maximum login attempts',
  INVALID_PASSWORD_ENTRIES = 'Invalid password entries',
  INVALID_OTP_ENTRIES = 'Invalid OTP entries',
}

export const getUserLoggingDescriptions = (logg: string) => {
  switch (logg) {
    case LoggingStatus.SIGN_IN:
      return LoggingStatusMessage.SIGN_IN;
    case LoggingStatus.SIGN_OUT:
      return LoggingStatusMessage.SIGN_OUT;
    case LoggingStatus.SIGN_OUT_USER:
      return LoggingStatusMessage.SIGN_OUT_USER;
    case LoggingStatus.SIGN_OUT_SYSTEM:
      return LoggingStatusMessage.SIGN_OUT_SYSTEM;
    case LoggingStatus.MAXIMUM_LOGIN_ATTEMPTS:
      return LoggingStatusMessage.MAXIMUM_LOGIN_ATTEMPTS;
    case LoggingStatus.INVALID_PASSWORD_ENTRIES:
      return LoggingStatusMessage.INVALID_PASSWORD_ENTRIES;
    case LoggingStatus.INVALID_OTP_ENTRIES:
      return LoggingStatusMessage.INVALID_OTP_ENTRIES;
    default:
      return '';
  }
};

export const getUserLoggingDescriptionsText = (logg: string) => {
  switch (logg) {
    case LoggingStatus.SIGN_IN:
      return 'Sign in';
    case LoggingStatus.SIGN_OUT:
      return 'Sign out';
    case LoggingStatus.SIGN_OUT_USER:
      return 'User Sign out';
    case LoggingStatus.SIGN_OUT_SYSTEM:
      return 'System Sign out';
    case LoggingStatus.MAXIMUM_LOGIN_ATTEMPTS:
      return 'Maxi attempts';
    case LoggingStatus.INVALID_PASSWORD_ENTRIES:
      return 'Invalid passwords';
    case LoggingStatus.INVALID_OTP_ENTRIES:
      return 'Invalid OTP';
    default:
      return '';
  }
};

export const logTypesItems: ItemAttributes[] = [
  { label: LoggingStatusMessage.SIGN_IN, value: LoggingStatus.SIGN_IN },
  { label: LoggingStatusMessage.SIGN_OUT, value: LoggingStatus.SIGN_OUT },
  {
    label: LoggingStatusMessage.SIGN_OUT_USER,
    value: LoggingStatus.SIGN_OUT_USER,
  },
  {
    label: LoggingStatusMessage.SIGN_OUT_SYSTEM,
    value: LoggingStatus.SIGN_OUT_SYSTEM,
  },
  {
    label: LoggingStatusMessage.MAXIMUM_LOGIN_ATTEMPTS,
    value: LoggingStatus.MAXIMUM_LOGIN_ATTEMPTS,
  },
  {
    label: LoggingStatusMessage.INVALID_PASSWORD_ENTRIES,
    value: LoggingStatus.INVALID_PASSWORD_ENTRIES,
  },
  {
    label: LoggingStatusMessage.INVALID_OTP_ENTRIES,
    value: LoggingStatus.INVALID_OTP_ENTRIES,
  },
];

import { createAsyncThunk } from '@reduxjs/toolkit';

import { authResource } from './api';
import { toast } from 'react-toastify';

export const login: any = createAsyncThunk(
  'account/login',
  async (dataParam: { data: any; header: any }) => {
    try {
      const response = await authResource.login(
        dataParam.data,
        dataParam.header
      );
      return {
        profile: response.data.account,
        authenticated: response.data.authenticated,
        accessToken: response.data.accessToken,
      };
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const verifyCodeAuth: any = createAsyncThunk(
  'account/verifyCodeAuth',
  async (data: any) => {
    try {
      const response = await authResource.authorizationVerify(data);
      return {
        profile: response.data.account,
        authenticated: response.data.authenticated,
        accessToken: response.data.accessToken,
      };
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const logout: any = createAsyncThunk(
  'account/logout',
  async (data: { sigOutReason: string }) => {
    try {
      await authResource.logout(data);
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const resetPassword: any = createAsyncThunk(
  'account/confirmationCode',
  async (dataParam: { data: any; token: any; header: any }) => {
    try {
      await authResource.resetPassword(dataParam.data, dataParam.header);

      toast.success('Password changed successfully ', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return true;
    } catch (e: any) {
      toast.error(e.error.errors[0].arguments.newPassword, {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return Promise.reject(e);
    }
  }
);

export const changePassword: any = createAsyncThunk(
  'account/changePassword',
  async (dataParam: { data: any; header: any }) => {
    try {
      if (dataParam.data.token) {
        await authResource.changePasswordTokenUrl(
          dataParam.data,
          dataParam.header
        );
      } else {
        await authResource.changePassword(dataParam.data);
      }

      toast.success('Password changed successfully ', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return true;
    } catch (e: any) {
      toast.error(e.error.errors[0].arguments.newPassword, {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return Promise.reject(e);
    }
  }
);

export const checkResetPasswordToken: any = createAsyncThunk(
  'account/checkResetPasswordToken',
  async (data: any) => {
    try {
      const response = await authResource.checkResetPasswordToken(data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const forgotPassword: any = createAsyncThunk(
  'account/forgotPassword',
  async (data: any) => {
    try {
      const response = await authResource.forgotPassword(data.data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchMenuItems: any = createAsyncThunk(
  'account/menuItems',
  async (data: any) => {
    try {
      const response = await authResource.menuItems(data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchProfile: any = createAsyncThunk(
  'account/profile',
  async (data: any, thunkApi) => {
    try {
      const { dispatch } = thunkApi;
      const response = await authResource.profile(data);
      dispatch(fetchMenuItems({}));
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

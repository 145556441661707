import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

import { ItemAttributes } from 'components/itemAttributeType';
import { useAppDispatch } from 'hooks/redux';
import {
  SelectorInput,
  SelectorOption,
  stylesStyles,
} from 'components/inputs/SelectHelper';

import { RequiredFieldComponent } from '../RequiredFiledComponent';

interface SelectProps {
  name: string;
  value: ItemAttributes | null;
  label?: any;
  placeholder?: string;
  loadOptions?: any;
  loadOptionsParam?: any;
  isLoading?: boolean;
  error?: any;
  options?: ItemAttributes[];
  onChange?: (options: any) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  required?: boolean;
  isDisabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  name,
  value,
  label = '',
  placeholder = ' ',
  loadOptions,
  loadOptionsParam,
  isLoading,
  error,
  options = [],
  onChange,
  isMulti = false,
  isClearable = true,
  closeMenuOnSelect = false,
  required = false,
  isDisabled = false,
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<ItemAttributes[]>(options);

  useEffect(() => {
    if (isLoading !== undefined && isLoading !== loading) {
      setLoading(isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (loadOptions) {
      const fetchOptions = async () => {
        setLoading(true);
        const data = await loadOptions();
        setList(data);
        setLoading(false);
      };

      fetchOptions();
    }

    if (loadOptionsParam) {
      const fetchOptions = async () => {
        setLoading(true);
        const data = await loadOptionsParam;
        setList(data);
        setLoading(false);
      };

      fetchOptions();
    }
  }, [dispatch, loadOptions, loadOptionsParam]);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={name} className="relative block">
        <span className="font-medium tracking-wide text-gray-700 text-xxs">
          <span className="text-sm font-normal tracking-wide text-gray-900">
            <RequiredFieldComponent isRequired={required} labelText={label} />
          </span>
        </span>
      </label>

      <ReactSelect
        inputId={`select-controller-${name}`}
        components={{ Option: SelectorOption, Input: SelectorInput }}
        placeholder={
          <span className="text-gray-600 cursor-pointer text-xs font-bold">
            {placeholder}
          </span>
        }
        isDisabled={isDisabled}
        onChange={onChange}
        styles={stylesStyles({ error })}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPortalTarget={document.body}
        hideSelectedOptions={false}
        isClearable={isClearable}
        isLoading={loading}
        menuPosition="fixed"
        menuPlacement="auto"
        isMulti={isMulti}
        options={list}
        value={value}
      />
    </div>
  );
};

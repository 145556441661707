import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container as ProgramsList } from './programs/list/Container';
import { ProgramCreateComponent } from './programs/create/ProgramCreateComponent';
import { MerchantRoleManagement } from './MerchantRoleManagement';

export const System = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/merchants`}>
        <MerchantRoleManagement />
      </Route>
      <Route path={`${match.path}/programs/create`}>
        <ProgramCreateComponent />
      </Route>
      <Route path={`${match.path}/programs`}>
        <ProgramsList />
      </Route>
    </Switch>
  );
};

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { sortBy } from 'lodash';

import { FilterSelect } from 'components/filters/FilterSelect';
import { CancelButton } from 'components/buttons/CancelButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SaveButton } from 'components/buttons/SaveButton';
import { RoleDetails } from 'entities/user-management';
import { Input, TextArea } from 'components/inputs';
import { Card } from 'components/Card';

import { getDetails, getisDetailsLoading, getRoleSidebar } from '../selectors';
import { createRole, updateRole } from '../thunks';
import { clearDetails } from '../rolesSlice';
import SelectUiTabs from './SelectUiTabs';
import { userResource } from '../api';
import { roleSchema } from '../utils';

export const RoleForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const details: RoleDetails = useAppSelector(getDetails);
  const isLoading: boolean = useAppSelector(getisDetailsLoading);
  const sidebar = useAppSelector(getRoleSidebar);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(roleSchema(t)),
    defaultValues: details,
  });

  useEffect(() => {
    if (!isLoading) {
      reset(details);
    }
  }, [reset, details, isLoading]);

  useEffect(
    () => () => {
      dispatch(clearDetails());
    },
    [dispatch]
  );

  const handleBack = () => {
    history.push('/user-management/role-list/');
  };

  const onSubmit = async (data: Partial<RoleDetails>) => {
    let result: any = {};

    if (details.id) {
      result = await dispatch(updateRole({ ...data, id: details.id }));
    } else {
      result = await dispatch(createRole(data));
    }

    if (!result.error) {
      handleBack();
    }
  };

  const fetchPermissions = useCallback(async () => {
    const result = await userResource.fetchPermisions();
    return sortBy(result.data, (item) => item.label);
  }, []);

  return (
    <div>
      <Card
        title={t('userManagement.roleDetails.details')}
        icon={<ManageAccountsIcon fontSize="large" sx={{ color: '#fff' }} />}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-5 grid grid-cols-2 gap-4 my-4 sm:m-4 sm:p-3"
          id="create-role-form"
        >
          <Input
            label={t('userManagement.roleDetails.name')}
            error={errors.name}
            inputProps={register('name')}
            labelFontClassName="font-medium tracking-wide text-xs"
            labelColorClassName="text-gray-700"
            className="h-8"
          />
          <FilterSelect
            labelClassName="font-medium tracking-wide text-xs text-gray-700"
            label={t('userManagement.roleDetails.permissions')}
            name="permissions"
            control={control}
            loadOptions={fetchPermissions}
          />
          <SelectUiTabs
            items={sidebar}
            defaultItems={details.tabs}
            control={control}
            error={errors.tabs}
          />
          <TextArea
            label={t('userManagement.roleDetails.description')}
            error={errors.description}
            inputProps={register('description')}
            className="font-bold"
            labelFontClassName="font-medium tracking-wide text-xs"
            labelColorClassName="text-gray-700"
          />
        </form>
      </Card>

      <Card className="flex items-center justify-center mt-4">
        <div className="grid grid-cols-2 gap-4">
          <SaveButton
            form="create-role-form"
            loading={isSubmitting || isLoading}
            disabled={isSubmitting || isLoading}
          />
          <CancelButton onClick={handleBack} />
        </div>
      </Card>
    </div>
  );
};

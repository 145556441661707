import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getItemsLoadingComputed,
  getTotalSalesRollingPerMonthLoading,
} from '../selectors';
import { yearRunningInitRange } from './ComplianceAuditIndexComponent';
import { useSelector } from 'react-redux';
import { dateTimeZone } from 'domain/account/selectors';
import Divider from '@mui/joy/Divider';
import moment from 'moment-timezone';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { loadTotalSalesRollingPerMonthItems } from '../thunks';
import {
  dateMonthFormatUtil,
  dateTimeFormatUtil,
  generationMonthYearFormat,
} from 'components/dateTimeFormatUtil';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { Total12MonthRunningChartComponent } from './charts-components/Total12MonthRunningChartComponent';

type TotalDailySalesProps = {
  control: any;
  watch: any;
  setValue: any;
  merchantRef: any;
};

export const Running12MonthsRangeComponent: React.FC<TotalDailySalesProps> = ({
  control,
  watch,
  setValue,
  merchantRef,
}) => {
  const dispatch = useAppDispatch();
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const dateZone = useSelector(dateTimeZone);
  const dateMonthFormatVal = (value: any) =>
    dateMonthFormatUtil(value, dateZone);

  const totalSalesRollingPerMonthLoading = useAppSelector(
    getTotalSalesRollingPerMonthLoading
  );

  const monthFormatVal = (value: any) =>
    dateTimeFormatUtil(value, generationMonthYearFormat, dateZone);
  const { running12Range } = watch();
  const running12RangeRef = useRef(yearRunningInitRange(dateZone));

  const click12MonthInit = () => {
    setValue('running12Range', yearRunningInitRange(dateZone));
  };

  const onDate12MonthsSaleRunningRangeApply = () => {
    dispatch(
      loadTotalSalesRollingPerMonthItems({
        merchantId: merchantRef.current?.value,
        date: monthFormatVal(running12Range.to),
      })
    );
    running12RangeRef.current = running12Range;
  };

  const handleChanges = (callback: any, dates: any) => {
    const start = moment(dates)
      .tz(dateZone)
      .endOf('month')
      .subtract(11, 'month')
      .toDate();
    const end = moment(dates).tz(dateZone).endOf('month').toDate();

    if (dates) {
      setDateFilter(callback, { from: start, to: end });
    }
  };

  const showRangeValue = (value: any) => {
    return value?.to && value?.from
      ? `${dateMonthFormatVal(value.from)} : ${dateMonthFormatVal(value.to)}`
      : '';
  };

  return (
    <div className="col-span-8 l1250g:col-span-4 4xl:col-span-3 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      <Total12MonthRunningChartComponent
        dateRange={running12RangeRef.current}
      />
      <div className="m-3">
        <Divider />
      </div>
      {merchantRef.current && !itemsLoading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 sm:col-span-2">
            <DatePickerControl
              control={control}
              handleChange={handleChanges}
              datePickerType={DatePickerType.MONTH_START_END_RANGE}
              showValueChange={showRangeValue}
              popoverWidth={320}
              name="running12Range"
              maxDate={moment().subtract(1, 'd').toDate()}
              title="Date Range"
              footer={
                <div className="grid justify-center justify-items-center m-4">
                  <ButtonGroup
                    variant="outlined"
                    size="small"
                    aria-label="outlined button group"
                  >
                    <Button
                      style={{ width: '140px' }}
                      onClick={click12MonthInit}
                    >
                      Last 12 months
                    </Button>
                  </ButtonGroup>
                </div>
              }
            />
          </div>
          <div className="items-end justify-stretch justify-items-end mt-1 w-full col-span-3 sm:col-span-1">
            <LoadingButton
              size="small"
              variant="contained"
              style={{ width: '100%' }}
              loading={totalSalesRollingPerMonthLoading}
              onClick={onDate12MonthsSaleRunningRangeApply}
            >
              View Data
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';

import {
  CardPanGetDetails,
  CardPanItem,
  details,
  FiltersParam,
  itemFilters,
} from './utils';
import {
  createBlockedPan,
  deleteBlockedPan,
  fetchDetails,
  loadItems,
} from './thunks';
import { ItemSort } from 'entities';

export interface BlockedPansState {
  items: CardPanItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  details: CardPanGetDetails;
  isDetailsLoading: boolean;
  isCreationLoading: boolean;
  itemId: string | null;
  itemNotFound: boolean;
  itemRemoveLoading: boolean;
  sort: ItemSort | null;
  filters: FiltersParam;
  detailsFilters: FiltersParam;
  appliedFilters: FiltersParam;
}

export const storeName = 'blockedPans';

const initialState: BlockedPansState = {
  items: [] as CardPanItem[],
  itemId: null as string | null,
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  details: details,
  isDetailsLoading: false,
  isCreationLoading: false,
  sort: null,
  itemNotFound: false,
  itemRemoveLoading: false,
  filters: itemFilters,
  detailsFilters: itemFilters,
  appliedFilters: itemFilters,
};

const blockedPansSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setItemId(state, action) {
      state.itemId = action.payload;
    },
    setCardNumberFilter(state, action) {
      state.filters = {
        ...state.filters,
        cardNumber: action.payload,
      };
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
      state.detailsFilters = {
        ...state.filters,
      };
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.totalNumber = initialState.totalNumber;
      state.page = initialState.page;
      state.appliedFilters = initialState.appliedFilters;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    resetAllItems(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.itemNotFound = false;
      state.details = action.payload;
    });
    builder.addCase(fetchDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(fetchDetails.rejected, (state) => {
      state.isDetailsLoading = false;
      state.itemNotFound = true;
      state.details = initialState.details;
    });

    builder.addCase(createBlockedPan.fulfilled, (state) => {
      state.isCreationLoading = initialState.isCreationLoading;
    });
    builder.addCase(createBlockedPan.pending, (state) => {
      state.isCreationLoading = true;
    });
    builder.addCase(createBlockedPan.rejected, (state) => {
      state.isCreationLoading = initialState.isCreationLoading;
    });

    builder.addCase(deleteBlockedPan.fulfilled, (state) => {
      state.itemRemoveLoading = initialState.itemRemoveLoading;
    });
    builder.addCase(deleteBlockedPan.pending, (state) => {
      state.itemRemoveLoading = true;
    });
    builder.addCase(deleteBlockedPan.rejected, (state) => {
      state.itemRemoveLoading = initialState.itemRemoveLoading;
    });

    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: any[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  resetAllItems,
  setSort,
  setCardNumberFilter,
  setFilters,
} = blockedPansSlice.actions;

export default blockedPansSlice.reducer;

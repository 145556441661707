import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { SecretKeyItem } from '../utils';
import { KeyStatusBadge } from '../components/KeyStatusBadge';

export const useColumns = () => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'companyName',
        value: (item: SecretKeyItem) => (
          <CellLight>{item.companyName}</CellLight>
        ),
        label: 'Company Name',
        width: 150,
        isSorted: true,
        sortField: 'COMPANY_NAME',
      },
      {
        id: 'Key Prefix',
        value: (item: SecretKeyItem) => <CellLight>{item.keyPrefix}</CellLight>,
        label: 'Key Prefix',
        width: 250,
      },
      {
        id: 'businessName',
        value: (item: SecretKeyItem) => (
          <CellLight>{item.businessName}</CellLight>
        ),
        label: 'Business Name',
        width: 150,
        isSorted: true,
        sortField: 'BUSINESS_NAME',
      },
      {
        id: 'status',
        value: (item: SecretKeyItem) => (
          <CellLight>
            <KeyStatusBadge enabled={item.isEnabled} />
          </CellLight>
        ),
        label: 'Status',
        width: 150,
      },
      {
        id: 'lastUsed',
        value: (item: SecretKeyItem) => (
          <CellLight>{item.lastUsed && timeFormat(item.lastUsed)}</CellLight>
        ),
        label: 'Last Used',
        width: 210,
        isSorted: true,
        sortField: 'LAST_USED',
      },
      {
        id: 'lastUpdatedTime',
        value: (item: SecretKeyItem) => (
          <CellLight>
            {item.lastUpdatedTime && timeFormat(item.lastUpdatedTime)}
          </CellLight>
        ),
        label: 'Last Updated Time',
        width: 210,
        isSorted: true,
        sortField: 'LAST_UPDATED_TIME',
      },
    ],
    [t]
  );
};

import { DateRange } from '../../../types/dateRange';

export enum ServiceStatus {
  UP = 'UP',
  DOWN = 'DOWN',
}

export type ServicesItem = {
  time: string;
  acquiringApi: string;
  backofficeApi: string;
  backofficeUI: string;
  settlementService: string;
  sftpReports: string;
  sftpFiserv: string;
  sftpTranscard: string;
  nmiApi: string;
  fiServApi: string;
};

export type FiltersParams = { day: DateRange | null };

export const defaultFilters: FiltersParams = {
  day: null,
};

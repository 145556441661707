import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'react-virtualized/styles.css';
import './styles/index.css';
import './styles/tailwind.css';
import './styles/modal.css';
import './i18n';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'styles/react-dates.scss';
import 'typeface-gothic-a1';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { PageHeader } from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMainState, isUserDetailLoading, userNotFound } from '../selectors';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Input, TextArea } from 'components/inputs';
import { roleList } from 'api/roles';
import { FilterSelect } from 'components/filters/FilterSelect';
import { merchantList } from 'api/merchants';
import {
  ACTIVE_STATUS,
  ADMIN_ROLE,
  LOCKED_STATUS,
  MERCHANT_ROLE,
  PENDING_STATUS,
  UNLOCK_USER_REASON,
} from '../utils';
import { notificationTypeList, updateUser } from '../thunks';
import { LoadingButton } from '@mui/lab';
import { getUserDetailsIconColor } from './utils';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditOffIcon from '@mui/icons-material/EditOff';
import { useTranslation } from 'react-i18next';
import { RectLoader } from 'components/RectLoader';
import { selectClassLabel } from 'domain/system/merchants/utils';
import { useEffect, useMemo } from 'react';
import capitalize from 'lodash/capitalize';

export const UserDetailsUpdate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { details: userDetailsPreview } = useAppSelector(getMainState);
  const isDetailLoading = useAppSelector(isUserDetailLoading);
  const notFound = useAppSelector(userNotFound);
  const history = useHistory();

  const merchantMap = () =>
    userDetailsPreview.merchants && userDetailsPreview.merchants.length
      ? userDetailsPreview.merchants.map((item) => ({
          label: item.name.toString(),
          value: item.id.toString(),
        }))
      : [];

  const notificationTypeMap = () =>
    userDetailsPreview.notificationType &&
    userDetailsPreview.notificationType.length
      ? userDetailsPreview.notificationType.map((item) => ({
          value: item.toString(),
          label: capitalize(item.replaceAll(/(_)/g, ' ')),
        }))
      : [];

  const statusList = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'DISABLED', value: 'DISABLED' },
  ];

  const getStatusUpdate = () =>
    userDetailsPreview.status !== PENDING_STATUS
      ? statusList.filter((it) => it.label === userDetailsPreview.status)[0]
      : {
          label: '',
          value: '',
        };

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting },
    formState,
    getValues,
    setError,
    control,
    watch,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: userDetailsPreview.firstName,
      lastName: userDetailsPreview.lastName,
      email: userDetailsPreview.email,
      notificationType: notificationTypeMap(),
      reason: '',
      role: {
        label: userDetailsPreview.role.name,
        value: userDetailsPreview.role.id,
      },
      status: getStatusUpdate(),
      merchants: merchantMap(),
    },
  });

  const { role, merchants } = watch();

  useEffect(() => {
    if (role.label === MERCHANT_ROLE && merchants.length < 1) {
      setError('merchants', {
        type: 'required',
        message: 'Select Required',
      });
    } else if (role.label === MERCHANT_ROLE && merchants.length > 0) {
      clearErrors('merchants');
    }
  }, [clearErrors, merchants, role.label, setError]);

  const save = (data: any) => {
    const values = getValues();

    const isAdmin =
      values.role.label === ADMIN_ROLE
        ? data.notificationType.map((item: any) => item.value)
        : [];

    dispatch(
      updateUser({
        id: userDetailsPreview.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        roleId: data.role?.value,
        reason: data.reason,
        status:
          data.status.label && data.status.label.length
            ? data.status.label
            : null,
        notificationType: isAdmin,
        merchants:
          data.role?.label === MERCHANT_ROLE
            ? values.merchants?.map((merchant: any) => merchant.value) ||
              undefined
            : [],
      })
    );
    history.goBack();
  };

  const unlockUser = () => {
    dispatch(
      updateUser({
        id: userDetailsPreview.id,
        firstName: userDetailsPreview.firstName,
        lastName: userDetailsPreview.lastName,
        email: userDetailsPreview.email,
        roleId: userDetailsPreview.role.id,
        reason: UNLOCK_USER_REASON,
        status: ACTIVE_STATUS,
        notificationType: userDetailsPreview.notificationType,
        merchants: userDetailsPreview.merchants?.map((item) => item.id),
      })
    );
    history.goBack();
  };

  const isSaveDisabled = useMemo(
    () => !isDirty || (role.label === MERCHANT_ROLE && merchants.length < 1),
    [isDirty, merchants.length, role.label]
  );

  return (
    <div className="relative flex flex-col h-auto bg-gray-200">
      <div className="absolute w-full">
        <PageHeader title="Update User" />
      </div>
      <div className="h-full p-3 pt-19">
        {/*form*/}
        <form onSubmit={handleSubmit(save)} className="w-full">
          <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
            {/*back bar*/}
            <div className="flex flex-row justify-between col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
              <div>
                {notFound || !userDetailsPreview.id ? (
                  <Button
                    onClick={() => history.push('/user-management/user-list')}
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Users List
                  </Button>
                ) : (
                  <>
                    <div>
                      <Button
                        onClick={() => history.goBack()}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        Back
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {!isDetailLoading && !notFound && (
                <div className="flex gap-4 items-end justify-end justify-items-center">
                  {userDetailsPreview.status === LOCKED_STATUS && (
                    <div>
                      <Button
                        onClick={() => unlockUser()}
                        variant="outlined"
                        color="primary"
                        startIcon={<LockOpenIcon />}
                      >
                        {t('userManagement.userDetails.unlockUser')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
            {(notFound || !userDetailsPreview.id) && (
              <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border mx-4 mt-5 p-2 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8">
                    <div className="flex items-center justify-center justify-items-center w-full sm:w-40 h-16 rounded-md sm:ml-3 box-shadow-nero box-bg-nero-300">
                      <div>
                        <EditOffIcon fontSize="large" sx={{ color: '#fff' }} />
                      </div>
                      <div className="text-xs text-white ml-2">No Data</div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                    <div>No data to update</div>
                  </div>
                </div>
              </div>
            )}
            {isDetailLoading && (
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                  <div className="w-full">
                    <RectLoader width={150} height={18} />
                  </div>
                </div>
              </div>
            )}
            {!isDetailLoading && !notFound && userDetailsPreview.id && (
              <>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="-mt-8 ">
                      <div
                        className={classNames(
                          'flex items-center justify-center justify-items-center w-full sm:w-40 h-16 rounded-md sm:ml-3',
                          getUserDetailsIconColor(userDetailsPreview.status)
                        )}
                      >
                        <div>
                          <AccountBoxOutlinedIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-2">Details</div>
                      </div>
                    </div>
                    <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                      <div className="w-full">
                        <Input
                          label={
                            <RequiredFieldComponent labelText="First Name" />
                          }
                          placeholder="First Name"
                          className="h-8"
                          labelFontClassName="font-medium tracking-wide text-xxs"
                          labelColorClassName="text-gray-700"
                          error={errors.firstName}
                          inputProps={register('firstName', {
                            required: true,
                          })}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          label={
                            <RequiredFieldComponent labelText="Last Name" />
                          }
                          placeholder="Last Name"
                          className="h-8"
                          labelFontClassName="font-medium tracking-wide text-xxs"
                          labelColorClassName="text-gray-700"
                          error={errors.lastName}
                          inputProps={register('lastName', {
                            required: true,
                          })}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          label={
                            <RequiredFieldComponent labelText="Email Name" />
                          }
                          placeholder="Email"
                          className="h-8"
                          labelFontClassName="font-medium tracking-wide text-xxs"
                          labelColorClassName="text-gray-700"
                          error={errors.email}
                          inputProps={register('email', {
                            required: true,
                          })}
                        />
                      </div>
                      <div className="w-full">
                        <FilterSelect
                          name="role"
                          label={<RequiredFieldComponent labelText="Role" />}
                          control={control}
                          labelClassName={selectClassLabel}
                          loadOptions={roleList}
                          closeMenuOnSelect
                          isClearable={false}
                          isMulti={false}
                        />
                      </div>
                      {userDetailsPreview.status !== PENDING_STATUS && (
                        <div className="w-full">
                          <FilterSelect
                            name="status"
                            label={
                              <RequiredFieldComponent labelText="Status" />
                            }
                            control={control}
                            options={statusList}
                            labelClassName={selectClassLabel}
                            closeMenuOnSelect
                            isClearable={false}
                            isMulti={false}
                          />
                        </div>
                      )}
                      {role.label === MERCHANT_ROLE && (
                        <div className="w-full">
                          <FilterSelect
                            name="merchants"
                            label={
                              <RequiredFieldComponent labelText="Merchants" />
                            }
                            control={control}
                            error={formState.errors.merchants}
                            labelClassName={selectClassLabel}
                            loadOptions={merchantList}
                          />
                        </div>
                      )}
                      {role.label === ADMIN_ROLE ? (
                        <div className="w-full">
                          <FilterSelect
                            name="notificationType"
                            label={
                              <RequiredFieldComponent
                                isRequired={false}
                                labelText="Notification Type"
                              />
                            }
                            control={control}
                            error={formState.errors.notificationType}
                            labelClassName={selectClassLabel}
                            loadOptions={notificationTypeList}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full sm:px-4 sm:pb-4">
                    <TextArea
                      label={
                        <RequiredFieldComponent labelText="Change Reason" />
                      }
                      className="h-40"
                      error={errors.reason}
                      labelFontClassName="font-medium tracking-wide text-xs"
                      inputProps={register('reason', {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="flex items-center justify-center justify-items-stretch">
                      <div className="mx-2 sm:w-3/12">
                        <LoadingButton
                          loading={isSubmitting}
                          loadingPosition="start"
                          disabled={isSaveDisabled}
                          type="submit"
                          className="w-full"
                          variant="contained"
                          color="success"
                          startIcon={<BookmarkAddedIcon />}
                        >
                          <span className="text-xs sm:text-sm">
                            Save Changes
                          </span>
                        </LoadingButton>
                      </div>
                      <div className="mx-2 sm:w-3/12">
                        <Button
                          onClick={() => history.goBack()}
                          className="w-full"
                          variant="contained"
                          color="error"
                          startIcon={<CancelPresentationIcon />}
                        >
                          <span className="text-xs sm:text-sm">Cancel</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

import { ReactNode } from 'react';
import { StatusBadge } from 'components/StatusBadge';
import { MerchantStatuses } from '../utils';

const statusBadge = (status: string) => {
  if (status === MerchantStatuses.ACTIVE) {
    return 'success';
  } else if (status === MerchantStatuses.PENDING) {
    return 'warning';
  } else if (status === MerchantStatuses.APPROVED) {
    return 'approved';
  } else if (status === MerchantStatuses.REJECTED) {
    return 'rejected';
  } else if (status === MerchantStatuses.UNKNOWN) {
    return 'unknown';
  } else if (status === MerchantStatuses.BLOCKED) {
    return 'blocked';
  } else {
    return 'danger';
  }
};

export const MerchantToggleStatusBadge = ({
  status,
  children,
  heightClass,
  textSizeClass,
  widthClass,
}: {
  status: string;
  children: ReactNode;
  heightClass?: string;
  textSizeClass?: string;
  widthClass?: string;
}) => (
  <StatusBadge
    heightClass={heightClass}
    widthClass={widthClass}
    textSizeClass={textSizeClass}
    variant={statusBadge(status)}
  >
    {children}
  </StatusBadge>
);

import moment, { Moment } from 'moment';

import { TransactionType } from '../../authorization';

export const startDayPeriod = (day: number) => {
  return moment()
    .subtract(day, 'days')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const endDayPeriod = () => {
  return moment()
    .subtract(1, 'days')
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
};

type DateButton = { start: Moment | null; end: Moment | null; label: string };

export const dateButtons = (t: (key: string) => string): DateButton[] => [
  {
    start: moment().subtract(2, 'hours'),
    end: moment(),
    label: t('datePicker.lastTwoHours'),
  },
  {
    start: startDayPeriod(1),
    end: endDayPeriod(),
    label: t('datePicker.lastDay'),
  },
  {
    start: startDayPeriod(7),
    end: endDayPeriod(),
    label: t('datePicker.lastSevenDays'),
  },
  {
    start: startDayPeriod(30),
    end: endDayPeriod(),
    label: t('datePicker.lastThirtyDays'),
  },
  {
    start: null,
    end: null,
    label: t('kyc.filters.allTime'),
  },
];

export const options: any = [
  {
    label: 'Merchant',
    value: 0,
  },
  {
    label: 'Transaction Type',
    value: 1,
  },
  {
    label: 'Transmission Date',
    value: 2,
  },
  {
    label: 'Card last 4',
    value: 4,
  },
  {
    label: 'Name on card',
    value: 5,
  },
  {
    label: 'Authorization Code',
    value: 6,
  },
  {
    label: 'Amount',
    value: 7,
  },
  {
    label: 'Transaction Status',
    value: 8,
  },
  {
    label: 'Reference',
    value: 9,
  },
  {
    label: 'Trace ID (STAN)',
    value: 10,
  },
];

export const getTransactionTypes = (t) =>
  [
    TransactionType.ACH,
    TransactionType.AUTHORIZATION,
    TransactionType.PARTIAL_REFUNDED,
    TransactionType.CAPTURE,
    TransactionType.PURCHASE,
    TransactionType.REFUND,
    TransactionType.REVERSAL,
    TransactionType.VERIFICATION,
    TransactionType.VOID,
  ].map((item: string) => ({
    value: item,
    label: t(`transactionsManagement.authorizations.transactionType.${item}`),
  }));

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AuthorizationItem,
  ItemSort,
  RowsPerPage,
  TransactionDetails,
} from 'entities';
import { DateRange } from 'types/dateRange';
import { ItemAttributes } from 'components/itemAttributeType';
import {
  captureProcess,
  fetchAuthorizationDetails,
  loadItems,
  markAsRefundThunk,
  markAsVoidThunk,
  voidProcess,
} from './thunks';
import { mappedData, transactionDetails } from './utils';

type ItemFilters = {
  merchants: ItemAttributes[];
  transactionTypes: ItemAttributes[];
  cardTypes: ItemAttributes[];
  status: ItemAttributes[];
};

type MiscFilters = {
  cardLastFour: string;
  authorizationCode: string;
  stan: string;
  id: string;
  nameOnCard: string;
  merchantExternalReference: string;
  transactionDate: DateRange;
  amount: number | null;
};

export type ListFilters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {
  merchants: [],
  transactionTypes: [],
  cardTypes: [],
  status: [],
};

const miscFilters: MiscFilters = {
  cardLastFour: '',
  authorizationCode: '',
  stan: '',
  id: '',
  nameOnCard: '',
  merchantExternalReference: '',
  transactionDate: {
    from: null,
    to: null,
  },
  amount: null,
};

export const defaultFilters: ListFilters = {
  ...itemFilters,
  ...miscFilters,
};

export interface AuthorizationsState {
  items: AuthorizationItem[];
  itemsLoading: boolean;
  itemNotFound: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  filters: ListFilters;
  sort: ItemSort | null;
  appliedFilters: ListFilters;
  downloadLoading: boolean;
  transactionDetails: TransactionDetails;
  isTransactionDetailsLoading: boolean;
  isOperationLoading: boolean;
}

export const initialState: AuthorizationsState = {
  items: [] as AuthorizationItem[],
  itemsLoading: false,
  itemNotFound: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
  downloadLoading: false,
  transactionDetails: transactionDetails,
  isTransactionDetailsLoading: false,
  isOperationLoading: false,
};

export const storeName = 'transaction-management/authorizations';

const authorizationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    resetAll(state) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = initialState.sort;
      state.filters = initialState.filters;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = initialState.appliedFilters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthorizationDetails.fulfilled, (state, action) => {
      state.isTransactionDetailsLoading = false;
      state.itemNotFound = false;
      state.transactionDetails = action.payload;
    });
    builder.addCase(fetchAuthorizationDetails.pending, (state) => {
      state.isTransactionDetailsLoading = true;
    });
    builder.addCase(fetchAuthorizationDetails.rejected, (state) => {
      state.isTransactionDetailsLoading = false;
      state.itemNotFound = true;
    });

    builder.addCase(markAsRefundThunk.fulfilled, (state) => {
      state.isOperationLoading = false;
    });
    builder.addCase(markAsRefundThunk.pending, (state) => {
      state.isOperationLoading = true;
    });
    builder.addCase(markAsRefundThunk.rejected, (state) => {
      state.isOperationLoading = false;
    });

    builder.addCase(captureProcess.fulfilled, (state) => {
      state.isOperationLoading = false;
    });
    builder.addCase(captureProcess.pending, (state) => {
      state.isOperationLoading = true;
    });
    builder.addCase(captureProcess.rejected, (state) => {
      state.isOperationLoading = false;
    });

    builder.addCase(voidProcess.fulfilled, (state) => {
      state.isOperationLoading = false;
    });
    builder.addCase(voidProcess.pending, (state) => {
      state.isOperationLoading = true;
    });
    builder.addCase(voidProcess.rejected, (state) => {
      state.isOperationLoading = false;
    });

    builder.addCase(markAsVoidThunk.fulfilled, (state) => {
      state.isOperationLoading = false;
    });
    builder.addCase(markAsVoidThunk.pending, (state) => {
      state.isOperationLoading = true;
    });
    builder.addCase(markAsVoidThunk.rejected, (state) => {
      state.isOperationLoading = false;
    });

    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: AuthorizationItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.items = mappedData(data);
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  setPage,
  setSort,
  resetAll,
  setFilters,
} = authorizationsSlice.actions;

export default authorizationsSlice.reducer;

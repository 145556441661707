export const sequenceValidation = (password: string): boolean => {
  let count = 1;
  for (let i = 1; i < password.length; i++) {
    if (
      password.charAt(i) === String.fromCharCode(password.charCodeAt(i - 1) + 1)
    ) {
      count++;
    } else {
      count = 1;
    }
    if (count > 4) {
      return false;
    }
  }
  return true;
};

export const repeatedValidation = (password: string): boolean => {
  let count = 1;
  for (let i = 1; i < password.length; i++) {
    if (
      password.charAt(i) === String.fromCharCode(password.charCodeAt(i - 1))
    ) {
      count++;
    } else {
      count = 1;
    }
    if (count > 2) {
      return false;
    }
  }
  return true;
};

import React from 'react';
import { NotificationCategoryType } from '../utils';
import { TransactionType } from '../../../transaction-management/authorizations/authorization';
import { AuthorizationStatus } from '../../../../entities';
import classNames from 'classnames';
import { NotificationColsDetails } from './utilComponent';
import { NotificationType } from 'domain/dashboard/utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Notifications } from '../list/context';
import { useHistory } from 'react-router-dom';

interface ActionButtonProps {
  item: Notifications;
}

interface ActionButtonProps {
  item: Notifications;
}

const actionDone = (item: Notifications, history: any) => {
  switch (item.category) {
    case NotificationCategoryType.DFM_FILE_MISSING:
      return {
        action: history.push('/transaction-management/authorizations', {
          transactionType: [
            TransactionType.AUTHORIZATION,
            TransactionType.CAPTURE,
            TransactionType.PURCHASE,
            TransactionType.REFUND,
          ],
          status: [AuthorizationStatus.PENDING, AuthorizationStatus.COMPLETED],
        }),
      };
    case NotificationCategoryType.MERCHANT_VOLUME_LIMITS:
      return {
        action: history.push('/compliance-audit', {
          merchant: {
            label: item.details.merchantName,
            value: item.details.merchantId,
          },
        }),
      };
    case NotificationCategoryType.SERVICE_MONITORING:
      return {
        action: history.push('/monitoring/service-monitoring'),
      };
    default:
      return null;
  }
};

export const ActionButton: React.FC<ActionButtonProps> = ({ item }) => {
  const history = useHistory();

  return (
    <span
      role="button"
      aria-hidden="true"
      onClick={() => actionDone(item, history)?.action}
      className={classNames(
        'ml-2 hover:bg-blue-200',
        NotificationColsDetails[NotificationType.INFORMATIONAL].color
      )}
    >
      <RemoveRedEyeOutlinedIcon />
      <span className="ml-1">Show Data</span>
    </span>
  );
};

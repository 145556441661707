import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Settings } from '@mui/icons-material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import Icon from '@mdi/react';
import { mdiCreditCardLockOutline } from '@mdi/js';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import KeyIcon from '@mui/icons-material/Key';

export enum MenuItemIds {
  DUE_DILIGENCE = 'DUE_DILIGENCE',
  KYC = 'KYC',
  TAB_VALUE_DOES_NOT_EXIST = 'TAB_VALUE_DOES_NOT_EXIST',
  TRANSACTION_MANAGEMENT = 'TRANSACTION_MANAGEMENT',
  AUTHORIZATIONS = 'AUTHORIZATIONS',
  TRANSACTIONS = 'TRANSACTIONS',
  UNMATCHED_TRANSACTIONS = 'UNMATCHED_TRANSACTIONS',
  REPORTS = 'REPORTS',
  ACQUIRING = 'ACQUIRING',
  DAILY_REPORTS = 'DAILY_REPORTS',
  MONTHLY_REPORTS = 'MONTHLY_REPORTS',
  MERCHANTS = 'MERCHANTS',
  LOGS = 'LOGS',
  MONITORING = 'MONITORING',
  API_LOGS = 'API_LOGS',
  SERVICE_MONITORING = 'SERVICE_MONITORING',
  INACTIVE_USERS = 'INACTIVE_USERS',
  CHARGEBACKS = 'CHARGEBACKS',
  ADJUSTMENTS = 'ADJUSTMENTS',
  DISPUTE_RESOLUTION = 'DISPUTE_RESOLUTION',
  DASHBOARD = 'DASHBOARD',
  COMPLIANCE_AUDIT = 'Compliance Audit',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SYSTEM = 'SYSTEM',
  VIRTUAL_TERMINAL = 'VIRTUAL_TERMINAL',
  FRAUD_PREVENTION = 'FRAUD_PREVENTION',
  BLOCKED_PANS = 'BLOCKED_PANS',
  SETTINGS = 'SETTINGS',
  MERCHANT_MONTHLY_CLIENT_REPORTS = 'MERCHANT_MONTHLY_CLIENT_REPORTS',
  MONTHLY_EXCESSIVE_ACTIVITY_REPORTING = 'MONTHLY_EXCESSIVE_ACTIVITY_REPORTING',
  INTERNAL_MERCHANT_PORTFOLIO_REPORT = 'INTERNAL_MERCHANT_PORTFOLIO_REPORT',
  GENERAL_ACCOUNT_DATA_REPORT = 'GENERAL_ACCOUNT_DATA_REPORT',
  DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY = 'DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY',
  DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY_LIVE = 'DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY_LIVE',
  PORTFOLIO_TRANSACTION_SUMMARY = 'PORTFOLIO_TRANSACTION_SUMMARY',
  DAILY_TRANSACTION_SUMMARY = 'DAILY_TRANSACTION_SUMMARY',
  DAILY_TRANSACTIONS_REPORT = 'DAILY_TRANSACTIONS_REPORT',
  DAILY_EXCESSIVE_ACTIVITY_REPORTING = 'DAILY_EXCESSIVE_ACTIVITY_REPORTING',
  DAILY_EXCEPTION_REPORT_REFUND_EXCEPTIONS = 'DAILY_EXCEPTION_REPORT_REFUND_EXCEPTIONS',
  DAILY_REPORT_BATCH_EXCEPTIONS = 'DAILY_REPORT_BATCH_EXCEPTIONS',
  DAILY_REPORT_TICKET_EXCEPTIONS = 'DAILY_REPORT_TICKET_EXCEPTIONS',
  DAILY_DUPLICATE_TRANSACTION = 'DAILY_DUPLICATE_TRANSACTION',
  PROGRAMS = 'PROGRAMS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  USER_LIST = 'USER_LIST',
  USER_CREATE = 'USER_CREATE',
  SECRET_KEY_MANAGEMENT = 'SECRET_KEY_MANAGEMENT',
  ROLE_LIST = 'ROLE_LIST',
}

export const menuItemsMap = {
  [MenuItemIds.DASHBOARD]: {
    label: 'Dashboard',
    icon: <DashboardIcon fontSize="medium" />,
    route: '/',
  },
  [MenuItemIds.COMPLIANCE_AUDIT]: {
    label: 'Compliance Audit',
    // icon: <AssuredWorkloadOutlinedIcon fontSize="medium" />,
    icon: <QueryStatsOutlinedIcon fontSize="medium" />,
    route: '/compliance-audit',
  },
  [MenuItemIds.SETTINGS]: {
    label: 'Settings',
    icon: <Settings fontSize="medium" />,
    route: '/settings',
  },
  [MenuItemIds.SECRET_KEY_MANAGEMENT]: {
    label: 'API Management',
    icon: <KeyIcon fontSize="medium" />,
    route: '/api-secret-key-management',
  },
  [MenuItemIds.DISPUTE_RESOLUTION]: {
    label: 'Dispute Resolution',
    icon: <CurrencyExchangeOutlinedIcon fontSize="medium" />,
    route: '/dispute-resolution',
  },
  [MenuItemIds.DUE_DILIGENCE]: {
    label: 'Due Diligence',
    icon: <AccountBalanceWalletOutlinedIcon fontSize="medium" />,
    route: '/due-diligence',
  },
  [MenuItemIds.TRANSACTION_MANAGEMENT]: {
    label: 'Transaction Management',
    icon: <AccountBalanceOutlinedIcon fontSize="medium" />,
    route: '/transaction-management',
  },
  [MenuItemIds.NOTIFICATIONS]: {
    label: 'Notifications',
    icon: <NotificationsNoneOutlinedIcon fontSize="medium" />,

    route: '/notifications',
  },
  [MenuItemIds.REPORTS]: {
    label: 'Reports',
    icon: <AddchartOutlinedIcon fontSize="medium" />,
    route: '/reports',
  },
  [MenuItemIds.SYSTEM]: {
    label: 'Merchant Management',
    icon: <StorefrontOutlinedIcon fontSize="medium" />,
    route: '/system',
  },
  [MenuItemIds.VIRTUAL_TERMINAL]: {
    label: 'Virtual Terminal',
    icon: <LocalAtmIcon fontSize="medium" />,
    route: '/virtual-terminal',
  },
  [MenuItemIds.FRAUD_PREVENTION]: {
    label: 'Fraud Prevention',
    icon: <Icon path={mdiCreditCardLockOutline} size={1} />,
    route: '/fraud-prevention',
  },
  [MenuItemIds.USER_MANAGEMENT]: {
    label: 'User Management',
    icon: <PeopleAltOutlinedIcon fontSize="medium" />,
    route: '/user-management',
  },
  [MenuItemIds.MONITORING]: {
    label: 'Monitoring',
    icon: <SyncAltOutlinedIcon fontSize="medium" />,
    route: '/monitoring',
  },
};

export const menuSubItemsMap = {
  [MenuItemIds.KYC]: {
    label: 'KYC',
    route: '/due-diligence/kyc',
  },
  [MenuItemIds.TAB_VALUE_DOES_NOT_EXIST]: {
    label: 'TAB VALUE DOES NOT EXIST',
    route: '',
  },
  [MenuItemIds.AUTHORIZATIONS]: {
    label: 'Authorizations',
    route: '/transaction-management/authorizations',
  },
  [MenuItemIds.TRANSACTIONS]: {
    label: 'Settlements',
    route: '/transaction-management/settlements',
  },
  [MenuItemIds.UNMATCHED_TRANSACTIONS]: {
    label: 'Unmatched Transactions',
    route: '/transaction-management/unmatched-transactions',
  },
  [MenuItemIds.ACQUIRING]: {
    label: 'Acquiring',
    route: '/reports/acquiring',
  },
  [MenuItemIds.DAILY_REPORTS]: {
    label: 'Daily Reports',
    route: '/reports/daily',
  },
  [MenuItemIds.MONTHLY_REPORTS]: {
    label: 'Monthly Reports',
    route: '/reports/monthly',
  },
  [MenuItemIds.MERCHANTS]: {
    label: 'Merchants',
    route: '/system/merchants',
  },
  [MenuItemIds.BLOCKED_PANS]: {
    label: 'Blocked PANs',
    route: '/fraud-prevention/blocked-pans',
  },
  [MenuItemIds.API_LOGS]: {
    label: 'API Logs',
    route: '/monitoring/logs-api',
  },
  [MenuItemIds.SERVICE_MONITORING]: {
    label: 'Service Monitoring',
    route: '/monitoring/service-monitoring',
  },
  [MenuItemIds.INACTIVE_USERS]: {
    label: 'Disabled Inactive Users',
    route: '/reports/inactive-users',
  },
  [MenuItemIds.CHARGEBACKS]: {
    label: 'Chargebacks',
    route: '/dispute-resolution/chargebacks',
  },
  [MenuItemIds.ADJUSTMENTS]: {
    label: 'Adjustments',
    route: '/transaction-management/adjustments',
  },
  [MenuItemIds.MERCHANT_MONTHLY_CLIENT_REPORTS]: {
    label: 'Merchant Monthly Client',
    route: '/reports/monthly/merchant-reports',
  },
  [MenuItemIds.INTERNAL_MERCHANT_PORTFOLIO_REPORT]: {
    label: 'Monthly Internal Merchant Portfolio',
    route: '/reports/internal-merchant-reports',
  },
  [MenuItemIds.MONTHLY_EXCESSIVE_ACTIVITY_REPORTING]: {
    label: 'Monthly Excessive Activity',
    route: '/reports/monthly-excessive-activity',
  },
  [MenuItemIds.GENERAL_ACCOUNT_DATA_REPORT]: {
    label: 'General Account Data',
    route: '/reports/general-account-data-report',
  },
  [MenuItemIds.DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY]: {
    label: 'Daily Exception Excessive Activity',
    route: '/reports/daily-exception-report-excessive-activity',
  },
  [MenuItemIds.DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY_LIVE]: {
    label: 'Daily Exception Excessive Activity Live',
    route: '/reports/daily-exception-report-excessive-activity-live',
  },
  [MenuItemIds.PORTFOLIO_TRANSACTION_SUMMARY]: {
    label: 'Portfolio Transaction Summary',
    route: '/reports/portfolio-transaction-summary',
  },
  [MenuItemIds.DAILY_TRANSACTION_SUMMARY]: {
    label: 'Daily Transaction Summary',
    route: '/reports/daily-transaction-summary',
  },
  [MenuItemIds.DAILY_TRANSACTIONS_REPORT]: {
    label: 'Daily Transactions',
    route: '/reports/daily-transactions',
  },
  [MenuItemIds.DAILY_EXCESSIVE_ACTIVITY_REPORTING]: {
    label: 'Daily Excessive Activity',
    route: '/reports/daily-excessive-activity',
  },
  [MenuItemIds.DAILY_EXCEPTION_REPORT_REFUND_EXCEPTIONS]: {
    label: 'Daily Exception Refund',
    route: '/reports/daily-exception-refund',
  },
  [MenuItemIds.DAILY_REPORT_BATCH_EXCEPTIONS]: {
    label: 'Daily Exception Batch',
    route: '/reports/daily-exception-batch',
  },
  [MenuItemIds.DAILY_REPORT_TICKET_EXCEPTIONS]: {
    label: 'Daily Exception Ticket',
    route: '/reports/daily-exception-ticket',
  },
  [MenuItemIds.DAILY_DUPLICATE_TRANSACTION]: {
    label: 'Daily Duplicate Transactions',
    route: '/reports/daily-duplicate',
  },
  [MenuItemIds.PROGRAMS]: {
    label: 'Programs',
    route: '/system/programs',
  },
  [MenuItemIds.USER_LIST]: {
    label: 'Users',
    route: '/user-management/user-list',
  },
  [MenuItemIds.USER_CREATE]: {
    label: 'Create User',
    route: '/user-management/user-create',
  },
  [MenuItemIds.SECRET_KEY_MANAGEMENT]: {
    label: 'Secret Key Management',
    route: '/user-management/secret-key-management',
  },
  [MenuItemIds.ROLE_LIST]: {
    label: 'Roles',
    route: '/user-management/role-list',
  },
};

export const mappedData = (data: any, isDashboardActive: any) => {
  const showItems = data
    .filter((item: any) => Object.values(MenuItemIds).includes(item.name))
    .map((item: any) =>
      item.name !== MenuItemIds.DUE_DILIGENCE ||
      process.env.REACT_APP_HAS_KYC === 'true'
        ? {
            ...menuItemsMap[item.name as keyof typeof menuItemsMap],
            subitems: item.items
              .filter((subItem: any) => menuSubItemsMap[subItem.name])
              .map((subItem: any) => ({
                ...menuSubItemsMap[
                  subItem.name as keyof typeof menuSubItemsMap
                ],
              })),
          }
        : null
    )
    .filter(Boolean);

  showItems.push(menuItemsMap[MenuItemIds.SETTINGS]);

  if (isDashboardActive) {
    showItems.push(menuItemsMap[MenuItemIds.NOTIFICATIONS]);
    showItems.splice(1, 0, menuItemsMap[MenuItemIds.VIRTUAL_TERMINAL]);
    showItems.splice(6, 0, menuItemsMap[MenuItemIds.SECRET_KEY_MANAGEMENT]);
    showItems.unshift(menuItemsMap[MenuItemIds.COMPLIANCE_AUDIT]);
    showItems.unshift(menuItemsMap[MenuItemIds.DASHBOARD]);
  }

  return showItems;
};

export const userHasPermission = (items: any, pathname: string) => {
  return items.find(
    (item) =>
      item.route === pathname ||
      (item.subitems &&
        item.subitems.find((subitem) => subitem.route === pathname))
  );
};

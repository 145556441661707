import { CardBrand } from 'entities/transaction-management';
import { IconAmex } from 'components/icons/IconAmex';
import { IconMasterCard } from 'components/icons/IconMasterCard';
import { IconDiners } from 'components/icons/IconDiners';
import { IconDiscover } from 'components/icons/IconDiscover';
import { IconJCB } from 'components/icons/IconJCB';
import { IconVisa } from 'components/icons/IconVisa';

const statusBadge = (status: CardBrand | string) => {
  switch (status) {
    case CardBrand.AMEX:
      return <IconAmex />;
    case CardBrand.MASTER_CARD:
      return <IconMasterCard />;
    case CardBrand.DINERS:
      return <IconDiners />;
    case CardBrand.DISCOVER:
      return <IconDiscover />;
    case CardBrand.JCB:
      return <IconJCB />;
    case CardBrand.VISA:
      return <IconVisa />;
    default:
      return 'Unknown';
  }
};

export const CardBrandBadge = ({
  status,
}: {
  status: CardBrand | string;
  widthClass?: string;
}) => <div className="rounded-md">{statusBadge(status)}</div>;

import { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useUnmatchedTransactionsColumns } from './useUnmatchedTransactionsColumns';
import { Table } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loadUnmatchedTransactionItems } from '../thunks';
import { getUnmatchedTransactions } from '../selectors';

export const UnmatchedTransactions = () => {
  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useUnmatchedTransactionsColumns();

  const unmatchedTransactions = useAppSelector(getUnmatchedTransactions);

  const fetchTransactions = useCallback(
    () => dispatch(loadUnmatchedTransactionItems()),
    [dispatch]
  );

  useEffect(() => {
    fetchTransactions();

    const interval = setInterval(() => {
      fetchTransactions();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchTransactions]);

  return (
    <div className="bg-white border p-4 rounded-md box-shadow-dark col-span-8 xxl:col-span-12">
      <div className="flex items-center mb-2">
        <p className="text-black mr-4">Unmatched Transactions</p>
        <Link
          to="/transaction-management/unmatched-transactions"
          className="text-blue-600 text-sm"
        >
          View All
        </Link>
      </div>
      <div ref={tableRef} className="h-full max-h-full overflow-auto">
        <Table items={unmatchedTransactions} columns={columns} />
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components/PageHeader';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { FilterSelect } from 'components/filters/FilterSelect';
import { selectClassLabel } from '../../system/merchants/utils';
import { LoadingButton } from '@mui/lab';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { Button } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Settings } from '@mui/icons-material';
import moment from 'moment-timezone';
import { useAppDispatch } from 'hooks/redux';
import { setUserDateTimeDetails } from '../thunks';
import {
  dateTimeFormat,
  dateTimeZone,
  getFirstMenuItem,
  userId,
} from '../../account/selectors';
import { useSelector } from 'react-redux';
import { setDateTimeFormat, setDateTimeZone } from '../../account/accountSlice';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { dateFormatUtil } from 'components/dateTimeFormatUtil';

export const SettingsComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const id = useSelector(userId);
  const timeZone = useSelector(dateTimeZone);
  const dateFormat = useSelector(dateTimeFormat);
  const firstMenuItem = useSelector(getFirstMenuItem);

  const setTimeZone = moment.tz
    .names()
    .map((val) => ({ label: val.replace('_', ' '), value: val }));

  const dateTimeValues = [
    { label: 'DD MMMM YYYY HH:mm:ss', value: 'DD MMMM YYYY' },
    { label: 'DD-MMMM-YYYY HH:mm:ss', value: 'DD-MMMM-YYYY' },
    { label: 'DD/MMMM/YYYY HH:mm:ss', value: 'DD/MMMM/YYYY' },

    { label: 'YYYY MMMM DD HH:mm:ss', value: 'YYYY MMMM DD' },
    { label: 'YYYY-MMMM-DD HH:mm:ss', value: 'YYYY-MMMM-DD' },
    { label: 'YYYY/MMMM/DD HH:mm:ss', value: 'YYYY/MMMM/DD' },

    { label: 'MMMM DD YYYY HH:mm:ss', value: 'MMMM DD YYYY' },
    { label: 'MMMM-DD-YYYY HH:mm:ss', value: 'MMMM-DD-YYYY' },
    { label: 'MMMM/DD/YYYY HH:mm:ss', value: 'MMMM/DD/YYYY' },

    { label: 'DD MMMM YY HH:mm:ss', value: 'DD MMMM YY' },
    { label: 'DD-MMMM-YY HH:mm:ss', value: 'DD-MMMM-YY' },
    { label: 'DD/MMMM/YY HH:mm:ss', value: 'DD/MMMM/YY' },

    { label: 'MM DD YY HH:mm:ss', value: 'MM DD YY' },
    { label: 'MM-DD-YY HH:mm:ss', value: 'MM-DD-YY' },
    { label: 'MM/DD/YY HH:mm:ss', value: 'MM/DD/YY' },
  ];

  const defaultZone = setTimeZone.filter((val) => val.value === timeZone)[0];

  const defaultFormat = dateTimeValues.filter(
    (val) => val.value === dateFormat
  )[0];

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      timeZone: defaultZone,
      timeFormat: defaultFormat,
    },
  });

  const save = (data: any) => {
    dispatch(
      setUserDateTimeDetails({
        adminId: id,
        dateTimeZone: data.timeZone.value,
        dateTimeFormat: data.timeFormat.value,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setDateTimeZone(data.timeZone.value));
        dispatch(setDateTimeFormat(data.timeFormat.value));
        history.push(firstMenuItem);
      });
  };

  const saveDefaultValue = () => {
    const zone = moment.tz.guess();
    dispatch(
      setUserDateTimeDetails({
        adminId: id,
        dateTimeZone: null,
        dateTimeFormat: null,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setDateTimeZone(zone));
        dispatch(setDateTimeFormat(dateFormatUtil));
        history.push(firstMenuItem);
      });
  };

  const cancelChanges = () => {
    history.push(firstMenuItem);
    reset();
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('settings.breadcrumb')} />
      </div>
      <div className="h-full pt-5 overflow-auto custom-scrollbar">
        <form onSubmit={handleSubmit(save)} className="w-full">
          <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
            <div className="flex col-span-8 md:col-start-5 md:col-span-4 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 ">
                  <div className="flex flex-row items-center justify-start justify-items-start sm:ml-3 p-4 w-full sm:w-40 h-16 rounded-md box-shadow-roman box-bg-roman-300">
                    <div>
                      <Settings fontSize="large" sx={{ color: '#fff' }} />
                    </div>
                    <div className="text-xs text-white ml-2">Settings</div>
                  </div>
                </div>
                <div className="grid gap-3 grid-cols-1 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                  <div className="w-full">
                    <Button
                      className="w-full"
                      onClick={() => history.push('/change-password')}
                      startIcon={<LockResetIcon />}
                      color="primary"
                      variant="contained"
                    >
                      Change Password
                    </Button>
                  </div>
                  <div className="w-full">
                    <Button
                      className="w-full box-shadow-roman box-bg-roman-300"
                      style={{
                        backgroundColor: '#E06666',
                      }}
                      onClick={saveDefaultValue}
                      startIcon={<SettingsApplicationsIcon />}
                      variant="contained"
                    >
                      Default DateTime Settings
                    </Button>
                  </div>
                  <div className="w-full">
                    <FilterSelect
                      name="timeZone"
                      label={<RequiredFieldComponent labelText="Time Zone" />}
                      control={control}
                      labelClassName={selectClassLabel}
                      options={setTimeZone}
                      closeMenuOnSelect
                      isClearable={false}
                      isMulti={false}
                    />
                  </div>
                  <div className="w-full">
                    <FilterSelect
                      name="timeFormat"
                      label={<RequiredFieldComponent labelText="Time Format" />}
                      control={control}
                      labelClassName={selectClassLabel}
                      options={dateTimeValues}
                      closeMenuOnSelect
                      isClearable={false}
                      isMulti={false}
                    />
                  </div>
                  <div className="w-full">
                    <LoadingButton
                      loading={isSubmitting}
                      loadingPosition="start"
                      disabled={!isDirty}
                      type="submit"
                      className="w-full"
                      variant="contained"
                      color="success"
                      startIcon={<BookmarkAddedIcon />}
                    >
                      <span className="text-xs sm:text-sm">Save Changes</span>
                    </LoadingButton>
                  </div>
                  <div className="w-full">
                    <Button
                      onClick={cancelChanges}
                      className="w-full"
                      variant="contained"
                      color="error"
                      startIcon={<CancelPresentationIcon />}
                    >
                      <span className="text-xs sm:text-sm">Cancel</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';
import { ReportDetails, ReportItem, ReportsFilterProp } from 'entities/reports';
import {
  defaultFilters,
  initReportDetails,
  mappedData,
} from '../components/utils';
import {
  downloadItems,
  fetchDetails,
  generateReport,
  loadItems,
  updateReportStatus,
} from './thunks';
import { ItemSort } from 'entities';

export interface ReportState {
  items: ReportItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  filters: ReportsFilterProp;
  sort: ItemSort | null;
  reportFilterApply: ReportsFilterProp;
  rowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  reportDetails: ReportDetails;
  isDetailsLoading: boolean;
  isStatusLoading: boolean;
  itemNotFound: boolean;
  generationLoading: boolean;
  generationDate: string;
  merchantReport: string;
  reportType: string;
}

export const storeName = 'reportStoreName';

const initialState: ReportState = {
  items: [] as ReportItem[],
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  sort: null,
  reportFilterApply: defaultFilters,
  itemsLoading: false,
  itemNotFound: false,
  downloadLoading: false,
  generationLoading: false,
  reportDetails: initReportDetails,
  isDetailsLoading: false,
  isStatusLoading: false,
  generationDate: '',
  merchantReport: '',
  reportType: '',
};

const reportState = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },

    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.reportFilterApply = initialState.reportFilterApply;
      state.totalNumber = initialState.totalNumber;
      state.page = initialState.page;
    },
    setGenerationDate(state, action: PayloadAction<string>) {
      state.generationDate = action.payload;
    },

    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.reportFilterApply = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    resetAllItems(state) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = initialState.sort;
      state.totalNumber = initialState.totalNumber;
      state.generationDate = initialState.generationDate;
      state.filters = initialState.filters;
      state.rowsPerPage = initialState.rowsPerPage;
      state.reportFilterApply = initialState.reportFilterApply;
      state.reportType = initialState.reportType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.itemNotFound = false;
      state.reportDetails = action.payload;
    });
    builder.addCase(fetchDetails.pending, (state) => {
      state.isDetailsLoading = true;
      state.filters = initialState.filters;
    });
    builder.addCase(fetchDetails.rejected, (state) => {
      state.isDetailsLoading = false;
      state.itemNotFound = true;
    });

    builder.addCase(updateReportStatus.fulfilled, (state) => {
      state.isStatusLoading = false;
    });
    builder.addCase(updateReportStatus.pending, (state) => {
      state.isStatusLoading = true;
    });
    builder.addCase(updateReportStatus.rejected, (state) => {
      state.isStatusLoading = false;
    });

    builder.addCase(generateReport.fulfilled, (state) => {
      state.generationLoading = false;
      state.generationDate = initialState.generationDate;
      state.merchantReport = initialState.merchantReport;
    });
    builder.addCase(generateReport.pending, (state) => {
      state.generationLoading = true;
    });
    builder.addCase(generateReport.rejected, (state) => {
      state.generationLoading = false;
    });

    builder.addCase(downloadItems.pending, (state) => {
      state.downloadLoading = true;
    });
    builder.addCase(downloadItems.fulfilled, (state) => {
      state.downloadLoading = false;
    });
    builder.addCase(downloadItems.rejected, (state) => {
      state.downloadLoading = false;
    });

    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: ReportItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.items = mappedData(data);
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  resetAllItems,
  resetAllFilters,
  setSort,
  setApplyFilters,
  setFilters,
} = reportState.actions;

export default reportState.reducer;

import { Control, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { ItemAttributes } from 'components/itemAttributeType';
import { useAppDispatch } from 'hooks/redux';
import { Label } from 'components/inputs';
import {
  SelectorInput,
  SelectorOption,
  stylesStyles,
} from 'components/inputs/SelectHelper';

interface SelectControllerProps {
  name: string;
  control: Control<any>;
  label?: string | React.ReactElement;
  placeholder?: string;
  loadOptions?: any;
  params?: any;
  error?: any;
  height?: string;
  options?: ItemAttributes[];
  onSelect?: (option: any) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  className?: string;
  isDisabled?: boolean;
  required?: boolean;
}

export const SelectController: React.FC<SelectControllerProps> = ({
  name,
  label = '',
  height = '',
  placeholder = ' ',
  control,
  loadOptions,
  params,
  error,
  options = [],
  onSelect,
  isMulti = false,
  isClearable = true,
  closeMenuOnSelect = false,
  className = '',
  isDisabled = false,
  required = false,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [list, setList] = useState<ItemAttributes[]>(options);

  useEffect(() => {
    if (loadOptions) {
      const fetchOptions = async () => {
        setIsLoading(true);
        const data = params ? await loadOptions(params) : await loadOptions();
        setList(data);
        setIsLoading(false);
      };

      fetchOptions();
    }
  }, [dispatch, loadOptions]);

  const handleChange = (option: ItemAttributes) => {
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <Label name="roleId" label={label} error={error}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { value, onChange } }) => (
          <Select
            inputId={`select-controller-${name}`}
            components={{ Option: SelectorOption, Input: SelectorInput }}
            placeholder={
              <span className="text-gray-600 cursor-pointer text-xs font-bold">
                {placeholder}
              </span>
            }
            onChange={(option: ItemAttributes) => {
              onChange(option);
              handleChange(option);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            openMenuOnFocus={false}
            className={className}
            styles={stylesStyles({
              error,
              label,
              height,
              singleValue: {
                fontWeight: '700',
                fontSize: '0.75rem',
              },
            })}
            closeMenuOnSelect={closeMenuOnSelect}
            menuPortalTarget={document.body}
            hideSelectedOptions={false}
            isClearable={isClearable}
            isLoading={isLoading}
            menuPosition="fixed"
            menuPlacement="auto"
            isMulti={isMulti}
            options={list}
            value={value}
            isDisabled={isDisabled}
          />
        )}
      />
    </Label>
  );
};

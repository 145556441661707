/* eslint-disable react/display-name */
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { memo } from 'react';

import { MaskedNumberInput } from 'components/inputs';

export const FilterCardLastFour = memo(({ control }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Controller
        name="cardLastFour"
        control={control}
        render={({ fieldState: { error }, field: { value, onChange } }) => (
          <MaskedNumberInput
            format="####"
            label={t(
              'transactionsManagement.transactions.list.filters.cardLastFour'
            )}
            name="cardLastFour"
            labelFontClassName="text-xs font-medium"
            className="h-8 text-xs font-bold"
            maxLength={4}
            error={error}
            onChange={onChange}
            value={value}
          />
        )}
      />
    </div>
  );
});

import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  isDashboardActiveSelector,
} from 'domain/account/selectors';

export const PublicRoute = (props: RouteProps) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isDashboardActive = useSelector(isDashboardActiveSelector);

  const showDashboard = isDashboardActive
    ? '/'
    : '/transaction-management/authorizations';

  return !isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: showDashboard,
      }}
    />
  );
};

import api from 'api';

const createPayment = async (data: any) => {
  return api.post('/virtual-terminal/payment', data);
};

const refundPayment = async (data: any) => {
  return api.post('/virtual-terminal/refund', data);
};

const capturePayment = async (data: any) => {
  return api.post('/virtual-terminal/capture', data);
};

const voidPayment = async (data: any) => {
  return api.post('/virtual-terminal/void', data);
};

const paymentList = async (data: any) => {
  return api.post('/authorizations/list', data);
};

const getCardBrand = async (data: any) => {
  return api.post('/card-helper/card-brand', data);
};

export const virtualTerminalResource = {
  createPayment,
  refundPayment,
  capturePayment,
  voidPayment,
  paymentList,
  getCardBrand,
};

import { NotificationType } from 'domain/dashboard/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { NotificationSeverityType } from '../utils';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export const NotificationColsDetails = {
  [NotificationType.INFORMATIONAL]: {
    color:
      'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10',
    icon: <InfoOutlinedIcon />,
  },
  [NotificationType.ERROR]: {
    color:
      'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10',
    icon: <DangerousIcon />,
  },
  [NotificationType.ALERT]: {
    color:
      'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10',
    icon: <ErrorOutlineOutlinedIcon fontSize="small" />,
  },
  [NotificationType.WARNING]: {
    color:
      'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20',
    icon: <WarningAmberOutlinedIcon />,
  },
  [NotificationType.SUCCESS]: {
    color:
      'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20',
    icon: <CheckCircleOutlineOutlinedIcon />,
  },
  [NotificationType.REMINDER]: {
    color:
      'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10',
    icon: <NotificationsActiveOutlinedIcon />,
  },
  [NotificationType.SYSTEM_MAINTENANCE]: {
    color:
      'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10',
    icon: <ManageHistoryOutlinedIcon />,
  },
  [NotificationType.SECURITY_ALERT]: {
    color:
      'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10',
    icon: <GppMaybeOutlinedIcon />,
  },
  [NotificationType.ACTION_REQUIRED]: {
    color:
      'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20',
    icon: <TouchAppOutlinedIcon />,
  },
  [NotificationType.PROMOTIONAL]: {
    color:
      'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10',
    icon: <CampaignOutlinedIcon />,
  },
};

export const NotificationSeverity = {
  [NotificationSeverityType.HIGH]: {
    colorName: 'box-bg-info-400',
    icon: <ArrowDropUpIcon fontSize="large" style={{ color: 'red' }} />,
  },
  [NotificationSeverityType.MEDIUM]: {
    colorName: 'box-bg-error-400',
    icon: (
      <CircleOutlinedIcon
        fontSize="medium"
        style={{ color: '#17b676', marginLeft: '5px' }}
      />
    ),
  },
  [NotificationSeverityType.LOW]: {
    colorName: 'box-bg-error-400',
    icon: <ArrowDropDownIcon fontSize="large" style={{ color: '#60A5FA' }} />,
  },
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { FormEvent, KeyboardEventHandler, ReactNode, Ref } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

type InputProps = {
  label?: string;
  disabled?: boolean;
  labelFontClassName?: string;
  labelColorClassName?: string;
  name: string;
  inputRef?: Ref<HTMLInputElement & HTMLTextAreaElement>;
  type?: 'text' | 'password' | 'textarea';
  icon?: ReactNode;
  error?: FieldError;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: KeyboardEventHandler;
};

const getInputProps = ({
  type,
  error,
  hasError,
  className,
  placeholder,
  name,
  disabled = false,
}: Pick<
  InputProps,
  | 'type'
  | 'error'
  | 'hasError'
  | 'className'
  | 'placeholder'
  | 'name'
  | 'disabled'
>) => ({
  id: name,
  name: name,
  placeholder,
  className: classNames(
    'block w-full mt-1 form-input',
    {
      'border-red-500 focus:border-red-500 focus:shadow-outline-red':
        !!error || hasError,
      'h-24 text-xs': type === 'textarea',
      'bg-gray-300 text-gray-700': disabled,
    },
    className
  ),
});

const Label = ({
  children,
  label,
  name,
  labelFontClassName = 'text-sm font-bold',
  labelColorClassName = 'text-gray-900',
  error,
}: { children: ReactNode; name?: string } & Pick<
  InputProps,
  'labelColorClassName' | 'labelFontClassName' | 'label' | 'error'
>) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={name} className="relative block">
      <span
        className={classNames(
          'tracking-wide',
          labelColorClassName,
          labelFontClassName
        )}
      >
        {label}
      </span>
      {error && (
        <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
          {error?.message || t(`validations.${error?.type}`)}
        </span>
      )}
      {children}
    </label>
  );
};

export const Input = ({
  label,
  disabled = false,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  inputRef,
  error,
  hasError,
  type = 'text',
  placeholder = ' ',
  className,
  maxLength,
  onKeyPress,
  onChange,
}: InputProps) => {
  const inputProps = {
    ref: inputRef,
    ...getInputProps({
      type,
      error,
      hasError,
      className,
      placeholder,
      name,
      disabled,
    }),
  };

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <div className="relative">
        {type === 'textarea' ? (
          <textarea
            style={{ resize: 'none' }}
            maxLength={maxLength}
            onChange={onChange}
            onKeyPress={onKeyPress}
            disabled={disabled}
            {...inputProps}
          />
        ) : (
          <input
            type={type}
            maxLength={maxLength}
            onChange={onChange}
            onKeyPress={onKeyPress}
            disabled={disabled}
            {...inputProps}
          />
        )}
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

export const MaskedNumberInput = ({
  label,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  type = 'text',
  placeholder = ' ',
  className,
  ...props
}: {
  format?: string;
} & InputProps) => {
  const inputProps = getInputProps({
    type,
    error,
    className,
    placeholder,
    name,
  });

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <div className="relative">
        <NumberFormat {...props} {...inputProps} isNumericString mask={''} />
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

export const onKeyPressAllowCharacters: KeyboardEventHandler = (e) => {
  if (/[0-9]/i.test(e.key)) {
    e.preventDefault();

    e.stopPropagation();
  }
};

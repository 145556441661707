import {
  Dispatch,
  FocusEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Manager, Popper, Reference } from 'react-popper';
import { animated, useTransition } from 'react-spring';
import { IconCross } from 'components/icons/IconCross';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { PopoverPortal } from '../PopoverPortal';
import { HorizontalDivider } from '../HorizontalDivider';
import { IconSearch } from '../icons/IconSearch';
import { Button } from '../Button';
import { IconCrossCircle } from '../icons/IconCrossCircle';
import { ESC_KEY, useOnKeyDown } from 'hooks/useOnKeyDown';
import { LoadingSwitch } from '../Loading';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '../icons/IconChevronLeft';
import { FieldError } from 'react-hook-form';
import * as PopperJS from 'popper.js';

export const InputDropdownComponent = ({
  name,
  value,
  popoverIsOpen,
  placeholder,
  label,
  disabled = false,
  togglePopoverIsOpen,
  icon,
  error,
  inputHeightClassName = 'h-8',
  labelClass = 'font-medium tracking-wide text-xs',
}: {
  name: string;
  inputHeightClassName?: string;
  value?: string;
  label?: any | ReactNode;
  labelClass?: string;
  placeholder?: string;
  disabled?: boolean;
  popoverIsOpen: boolean;
  icon?: ReactNode;
  error?: FieldError;
  togglePopoverIsOpen: (
    event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
  ) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        'opacity-50': disabled,
      })}
    >
      <label htmlFor={name} className="relative block">
        <span className={labelClass}>{label}</span>
        {error && (
          <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
            {error?.message || t(`validations.${error?.type}`)}
          </span>
        )}
      </label>

      <div
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
        onClick={togglePopoverIsOpen}
        className="relative cursor-pointer"
      >
        <input
          ref={inputRef}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
          readOnly
          className={classNames(
            'block w-full mt-1 text-xs font-bold cursor-pointer form-input rounded',
            disabled ? 'cursor-default' : 'cursor-pointer',
            {
              'border-red-500 focus:border-red-500 focus:shadow-outline-red':
                !!error,
            },
            inputHeightClassName
          )}
        />
        <button
          type="button"
          disabled={disabled}
          onClick={() => inputRef.current?.focus()}
          className="absolute top-0 right-0 flex items-center justify-center w-12 h-full focus:outline-none"
        >
          {icon || (
            <IconChevronLeft
              className={classNames(
                'ml-2 transform transition text-gray-700 duration-200',
                {
                  '-rotate-90': !popoverIsOpen,
                  'rotate-90 text-blue-600': popoverIsOpen,
                }
              )}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export const DropdownDateTime = ({
  title,
  errorMessage = null,
  value,
  popoverWidth = 240,
  headerSecondaryLabel,
  searchPlaceholder,
  withSearch = false,
  searchLoading = false,
  children,
  trigger: dropdown,
  footer,
  onSearchQueryChange = () => {},
  onOpen = () => {},
  onClose = () => {},
  onClearFilter = () => {},
  scrollRef,
  searchOffset,
  setItemsLoaded,
  setItemsLoading,
  setSearchOffset,
  placementPosition = 'top-end',
}: {
  popoverWidth?: number;
  popoverHeight?: number;
  title: string;
  errorMessage?: any;
  headerSecondaryLabel?: string;
  searchPlaceholder?: string;
  withSearch?: boolean;
  searchLoading?: boolean;
  children: (close: () => any) => ReactNode;
  value?: string;
  trigger: ({
    togglePopoverIsOpen,
    popoverIsOpen,
  }: {
    togglePopoverIsOpen: (
      event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
    ) => void;
    popoverIsOpen: boolean;
  }) => ReactNode;
  footer?: ReactNode;
  onSearchQueryChange?: (searchQuery: string) => any;
  onOpen?: (searchQuery: string) => any;
  onClose?: () => any;
  onClearFilter?: () => any;
  scrollRef?: RefObject<HTMLDivElement>;
  searchOffset?: number;
  placementPosition?: PopperJS.Placement;
  setItemsLoaded?: Dispatch<SetStateAction<any[]>>;
  setItemsLoading?: Dispatch<SetStateAction<boolean>>;
  setSearchOffset?: Dispatch<SetStateAction<number>>;
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const searchQueryRef = useRef(searchQuery);

  const { t } = useTranslation();

  useEffect(() => {
    if (searchQueryRef.current !== searchQuery) {
      setItemsLoaded?.([]);
      setItemsLoading?.(true);

      onSearchQueryChange(searchQuery);
      searchQueryRef.current = searchQuery;
    }
  }, [onSearchQueryChange, searchQuery, setItemsLoaded, setItemsLoading]);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const ref = scrollRef?.current;

  useEffect(() => {
    const close = () => setPopoverIsOpen(false);

    ref?.addEventListener('scroll', close);

    return () => ref?.removeEventListener('scroll', close);
  }, [ref]);

  useEffect(() => {
    if (popoverIsOpen) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !searchOffset && setItemsLoaded?.([]);
      setItemsLoading?.(true);
      onOpen(searchQuery);
    } else {
      setSearchOffset?.(0);
      onClose();
    }
  }, [
    onOpen,
    onClose,
    popoverIsOpen,
    searchOffset,
    searchQuery,
    setItemsLoaded,
    setItemsLoading,
    setSearchOffset,
  ]);

  const transitions = useTransition(popoverIsOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPopoverIsOpenDebounced = useCallback(
    debounce((state: boolean) => setPopoverIsOpen(state), 200),
    []
  );

  const togglePopoverIsOpen = useCallback(
    (event: MouseEvent | TouchEvent | FocusEvent) => {
      event.stopPropagation();

      setPopoverIsOpenDebounced(!popoverIsOpen);
    },
    [setPopoverIsOpenDebounced, popoverIsOpen]
  );

  useOnKeyDown({ keyCode: [ESC_KEY] }, () => setPopoverIsOpenDebounced(false));

  const popoverRef = useRef<HTMLDivElement>(null);

  const searchInputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(
    {
      ref: popoverRef,
    },
    () => setPopoverIsOpenDebounced(false)
  );

  const resetSearchQuery = () => {
    setSearchQuery('');

    searchInputRef?.current?.focus();
  };

  return (
    <Manager>
      <Reference>
        {({ ref: initRef }) => (
          <div ref={initRef}>
            {dropdown({ togglePopoverIsOpen, popoverIsOpen })}
          </div>
        )}
      </Reference>
      <PopoverPortal>
        <Popper
          placement={placementPosition}
          eventsEnabled={false}
          modifiers={{
            offset: {
              offset: '1',
            },
            preventOverflow: {
              priority: ['top', 'bottom'],
            },
          }}
        >
          {({ ref: initRef, style, placement }) =>
            transitions.map(
              ({ item: transitionItem, key, props }) =>
                transitionItem && (
                  <animated.div
                    ref={initRef}
                    key={key}
                    style={{
                      ...style,
                      ...props,
                      width: popoverWidth,
                      zIndex: 999999,
                      marginTop: '10px',
                    }}
                    className="relative"
                    data-placement={placement}
                  >
                    <div
                      ref={popoverRef}
                      className="flex flex-col bg-white shadow-popover rounded-px"
                    >
                      <div className="flex items-center justify-between p-3">
                        <span>
                          <span className="text-xs font-semibold text-gray-900">
                            {title}
                          </span>{' '}
                          <span className="text-xs text-red-700 mr-4">
                            {errorMessage}
                          </span>
                        </span>
                        <div className="flex items-center">
                          {headerSecondaryLabel && (
                            <span className="mr-3 text-gray-700 text-xxs">
                              {headerSecondaryLabel}
                            </span>
                          )}
                          <button
                            onClick={() => setPopoverIsOpenDebounced(false)}
                            className="transition-shadow duration-300 rounded focus:outline-none focus:shadow-outline"
                          >
                            <IconCross className="w-4 h-4 text-gray-700" />
                          </button>
                        </div>
                      </div>
                      <HorizontalDivider />
                      {withSearch && (
                        <>
                          <div className="relative h-10">
                            <div className="absolute left-0 flex items-center justify-center h-full ml-3 pointer-events-none">
                              <LoadingSwitch
                                animationProps={{
                                  className: 'text-gray-500',
                                  width: 14,
                                  height: 14,
                                }}
                                loading={searchLoading}
                              >
                                <IconSearch
                                  className={classNames(
                                    'w-3 h-3 transition-colors',
                                    {
                                      'text-gray-700': !isSearchFocused,
                                      'text-blue-500': isSearchFocused,
                                    }
                                  )}
                                />
                              </LoadingSwitch>
                            </div>
                            {searchQuery && (
                              <div className="absolute right-0 flex items-center justify-center h-full mr-1">
                                <button
                                  className="transition-shadow duration-300 rounded focus:outline-none focus:shadow-outline"
                                  onClick={resetSearchQuery}
                                >
                                  <IconCrossCircle className="w-4 h-4 text-gray-300" />
                                </button>
                              </div>
                            )}
                            <input
                              ref={searchInputRef}
                              value={searchQuery}
                              className="w-full h-full px-8 text-xs focus:outline-none"
                              placeholder={searchPlaceholder}
                              onFocus={() => setIsSearchFocused(true)}
                              onBlur={() => setIsSearchFocused(false)}
                              onChange={(event) =>
                                setSearchQuery(event.target.value)
                              }
                            />
                          </div>
                          <HorizontalDivider />
                        </>
                      )}
                      <div className="p-3">
                        {children(() => setPopoverIsOpenDebounced(false))}
                      </div>
                      <HorizontalDivider />
                      {footer || (
                        <div className="p-3 w-full">
                          <Button
                            variant={value ? 'primary' : 'secondary'}
                            widthClass="w-full"
                            className="font-bold"
                            onClick={onClearFilter}
                          >
                            {t('kyc.filters.clearFilter')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </animated.div>
                )
            )
          }
        </Popper>
      </PopoverPortal>
    </Manager>
  );
};

export const IconPayment = () => (
  <img
    style={{
      width: '30px',
      height: '30px',
    }}
    src={require('../img/security-number.png')}
    alt={''}
  />
);

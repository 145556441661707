import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import { CellBase, CellLight, ColumnProps } from 'components/table/Table';
import { UnmatchedTransactionItem } from 'entities/transaction-management';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  generationDateTimeFormatUtil,
  timeWithZoneFormatUtil,
} from 'components/dateTimeFormatUtil';
import { TransactionStatusBadge } from '../../transactions/list/TransactionStatusBadge';
import { TransactionType } from '../../transactions/transaction';

export const useUnmatchedTransactionsListColumns = () => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const dateFormatVal = (value: any) =>
    generationDateTimeFormatUtil(value, dateFormat, dateZone);
  const timeFormatVal = (value: any) => timeWithZoneFormatUtil(value, dateZone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'transactionDate',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>
            {item.transactionDate &&
              dateFormatVal(item.transactionDate) +
                ' ' +
                (item.transactionTime
                  ? timeFormatVal(item.transactionTime)
                  : '')}
          </CellLight>
        ),
        label: t(
          'transactionsManagement.unmatchedTransactions.list.transactionDate'
        ),
        isSorted: true,
        sortField: 'UNMATCHED_TRANSACTION_DATE_TIME',
        width: 220,
      },
      {
        id: 'transactionAmount',
        value: (item: UnmatchedTransactionItem) => (
          <CellBase>
            {item.transactionAmount &&
              numeral(item.transactionAmount).format('0.00')}
          </CellBase>
        ),
        label: t(
          'transactionsManagement.unmatchedTransactions.list.transactionAmount'
        ),
        isSorted: true,
        sortField: 'UNMATCHED_AMOUNT',
        width: 140,
      },
      {
        id: 'transactionCurrencyCode',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.currencyCode}</CellLight>
        ),
        label: t(
          'transactionsManagement.unmatchedTransactions.list.transactionCurrencyCode'
        ),
        isSorted: false,
        width: 120,
      },
      {
        id: 'transactionStatus',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>
            {item.transactionStatus && (
              <TransactionStatusBadge
                status={item.transactionStatus.toUpperCase()}
              >
                {item.transactionStatus}
              </TransactionStatusBadge>
            )}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionStatus'),
        isSorted: false,
        width: 120,
      },
      {
        id: 'transactionType',
        value: (item: UnmatchedTransactionItem) => (
          <CellBase>
            {item?.transactionType &&
            Object.values(TransactionType).includes(item.transactionType)
              ? t(
                  `transactionsManagement.transactions.transactionType.${item.transactionType}`
                )
              : item?.transactionType}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionType'),
        isSorted: false,
        width: 90,
      },
      {
        id: 'cardNumber',
        value: (item: UnmatchedTransactionItem) => (
          <CellBase>{item.cardNumber}</CellBase>
        ),
        label: t(
          'transactionsManagement.unmatchedTransactions.list.cardNumber'
        ),
        isSorted: false,
        width: 140,
      },
      {
        id: 'cardBrand',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.cardBrand}</CellLight>
        ),
        label: t('transactionsManagement.unmatchedTransactions.list.cardBrand'),
        isSorted: false,
        width: 90,
      },
      {
        id: 'authCode',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.authCode}</CellLight>
        ),
        label: t('transactionsManagement.unmatchedTransactions.list.authCode'),
        isSorted: false,
        width: 130,
      },
      {
        id: 'merchantName',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.merchantName}</CellLight>
        ),
        label: t(
          'transactionsManagement.unmatchedTransactions.list.merchantName'
        ),
        isSorted: true,
        sortField: 'MERCHANT_NAME',
        width: 200,
      },
    ],
    [t]
  );
};

import { getInputProps, InputProps } from './utils';
import Label from './Label';
import NumberFormat from 'react-number-format';

export const MaskedNumberInput = ({
  label,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  type = 'text',
  placeholder = ' ',
  className,
  ...props
}: {
  format?: string;
} & InputProps) => {
  const inputProps = getInputProps({
    type,
    error,
    className,
    placeholder,
    name,
  });

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <div className="relative">
        <NumberFormat {...props} {...inputProps} isNumericString mask={''} />
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

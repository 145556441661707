import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';
import {
  dateTimeFormat,
  dateTimeZone,
  getProfilePermission,
  isMerchantSelector,
} from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  dateWithInitFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { useEffect, useState } from 'react';
import { downloadItems, fetchDetails, updateReportStatus } from '../../thunks';
import { PageHeader } from 'components/PageHeader';
import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { RectLoader } from 'components/RectLoader';
import {
  getDetailsLoading,
  getReportDetails,
  hasDownloading,
  hasStatusLoading,
  isReportNotFound,
} from '../../selectors';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import classNames from 'classnames';
import { FAILED_STATUS, getReportsDetailsIconColor } from '../../../utils';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { TextArea } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { useForm } from 'react-hook-form';
import { PENDING_STATUS } from '../../../../user-management/users/utils';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { ReportActions } from 'entities/reports';
import LoadingButton from '@mui/lab/LoadingButton';
import { SplitButton } from './SplitButton';
import { useSelectedReport } from '../selectedReport';
import { UserPermissions } from '../../../../../enums/Permissions';

const ReportValue = ({
  fieldName,
  reportValue,
}: {
  fieldName: string;
  reportValue?: any;
}) => {
  return (
    <>
      {reportValue && (
        <div className="flex flex-col mt-2">
          <div className="text-xs font-normal text-gray-700">{fieldName}</div>
          <div className="text-base font-normal">{reportValue}</div>
        </div>
      )}
    </>
  );
};

export const ReportPreview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isDetailLoading = useAppSelector(getDetailsLoading);
  const notFound = useAppSelector(isReportNotFound);
  const details = useAppSelector(getReportDetails);
  const isStatusLoading = useAppSelector(hasStatusLoading);
  const isDownloading = useAppSelector(hasDownloading);
  const isMerchant = useSelector(isMerchantSelector);

  const { id, reportType } = useParams<{ id: string; reportType: string }>();
  const reportId = Base64.decode(id);
  const routeMatch = useRouteMatch();
  const permissions: any = useAppSelector(getProfilePermission);
  const hasMultiDownloads = useSelectedReport(
    routeMatch.url
  )?.hasMultipleDownloads;
  const [isRejected, setIsRejected] = useState<boolean>(false);

  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string | null | undefined) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  const dateFormatConv = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);

  const hasStatusChangePermission =
    permissions.includes(UserPermissions.REPORTS_CHANGE_STATUS) ||
    permissions.includes(UserPermissions.MERCHANT_REPORTS_CHANGE_STATUS);

  const hasDownloadPermission = permissions.includes(
    UserPermissions.REPORTS_DOWNLOAD
  );

  const withInitValue = (value: string) =>
    dateWithInitFormatUtil(value, 'MMM-YYYY', 'MMMM YYYY', dateZone);

  useEffect(() => {
    dispatch(fetchDetails({ id: reportId }));
  }, [dispatch, id, reportId]);

  const {
    register,
    formState: { isDirty, errors },
    getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      reason: null,
    },
  });
  const save = (status: any) => {
    const values = getValues();
    dispatch(
      updateReportStatus({
        id: reportId,
        status: status,
        rejectedReason: values.reason,
      })
    )
      .unwrap()
      .then(() => {
        if (isMerchant) {
          history.push('/reports/monthly/merchant-reports');
        } else {
          location.reload();
        }
      });
  };

  const downloadReport = () => {
    dispatch(
      downloadItems({
        id: reportId,
      })
    );
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('reports.reportPreview.preview')} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          {/*back bar*/}
          <div className="flex flex-row justify-between col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <div>
              <Button
                onClick={() => history.goBack()}
                startIcon={<KeyboardBackspaceIcon />}
              >
                Back
              </Button>
            </div>
            {!isDetailLoading && !notFound && (
              <div className="flex gap-4 items-end justify-end justify-items-center">
                {!isRejected &&
                  hasDownloadPermission &&
                  !isStatusLoading &&
                  details.status !== FAILED_STATUS && (
                    <div>
                      {hasMultiDownloads ? (
                        <SplitButton reportSubTypes={details.subReports} />
                      ) : (
                        <LoadingButton
                          loading={isDownloading}
                          onClick={downloadReport}
                          variant="contained"
                          color="primary"
                          startIcon={<CloudDownloadIcon />}
                        >
                          <span className="text-xs sm:text-sm ">
                            {t('reports.reportPreview.download')}
                          </span>
                        </LoadingButton>
                      )}
                    </div>
                  )}
                {hasStatusChangePermission && (
                  <>
                    {details.status === PENDING_STATUS && !isRejected && (
                      <>
                        <div>
                          <LoadingButton
                            loading={isStatusLoading}
                            onClick={() => save(ReportActions.ACCEPTED)}
                            variant="contained"
                            color="success"
                            startIcon={<ThumbUpOffAltIcon />}
                          >
                            <span className="text-xs sm:text-sm">
                              {t('reports.accept')}
                            </span>
                          </LoadingButton>
                        </div>
                        <div>
                          <Button
                            onClick={() => setIsRejected(true)}
                            variant="contained"
                            color="error"
                            startIcon={<ThumbDownOffAltIcon />}
                          >
                            <span className="text-xs sm:text-sm">
                              {t('reports.reject')}
                            </span>
                          </Button>
                        </div>
                      </>
                    )}
                    {details.status === PENDING_STATUS && isRejected && (
                      <>
                        <div>
                          <LoadingButton
                            loading={isStatusLoading}
                            onClick={() => save(ReportActions.REJECTED)}
                            variant="contained"
                            color="warning"
                            startIcon={<CheckBoxOutlinedIcon />}
                            disabled={!isDirty}
                          >
                            <span className="text-xs sm:text-sm">
                              {t('reports.saveReason')}
                            </span>
                          </LoadingButton>
                        </div>
                        <div>
                          <Button
                            disabled={isStatusLoading}
                            onClick={() => setIsRejected(false)}
                            variant="contained"
                            color="error"
                            startIcon={<CancelPresentationOutlinedIcon />}
                          >
                            <span className="text-xs sm:text-sm">
                              {t('reports.cancel')}
                            </span>
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {notFound && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8">
                  <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-shadow-nero box-bg-nero-300">
                    <div>
                      <InsertChartOutlinedIcon
                        fontSize="large"
                        sx={{ color: '#fff' }}
                      />
                    </div>
                    <div className="text-xs text-white">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start my-4 sm:mx-4 sm:p-3">
                  <div>No such report</div>
                </div>
              </div>
            </div>
          )}
          {isDetailLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}
          {!isDetailLoading && !notFound && (
            <>
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8">
                    <div
                      className={classNames(
                        'flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3',
                        getReportsDetailsIconColor(details.status)
                      )}
                    >
                      <div>
                        <InsertChartOutlinedIcon
                          fontSize="large"
                          sx={{ color: '#fff' }}
                        />
                      </div>
                      <div className="text-xs text-white ml-2">Details</div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                    <ReportValue
                      fieldName="Title"
                      reportValue={details.title}
                    />
                    <ReportValue
                      fieldName="Date Range"
                      reportValue={
                        reportType === 'daily'
                          ? dateFormatConv(details.dateRange)
                          : withInitValue(details.dateRange)
                      }
                    />
                    <ReportValue
                      fieldName="Generated By User"
                      reportValue={`${details.generatedBy}`}
                    />
                    <ReportValue
                      fieldName="Generated At Date"
                      reportValue={timeFormat(details.generatedAt)}
                    />
                    <ReportValue
                      fieldName="Report Status"
                      reportValue={details.status}
                    />
                    <ReportValue
                      fieldName="Reviewed By User"
                      reportValue={details.reviewedBy}
                    />
                    <ReportValue
                      fieldName="Reviewed By Date"
                      reportValue={timeFormat(details.reviewedAt)}
                    />
                    <ReportValue
                      fieldName="Report Rejection Reason"
                      reportValue={details.reason}
                    />
                    <ReportValue
                      fieldName="Report Fail Cause"
                      reportValue={details.cause}
                    />
                  </div>
                  {details.merchants && details.merchants.length !== 0 && (
                    <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                      {details.merchants.map((val, key) => (
                        <ReportValue
                          key={key}
                          fieldName={`Report Merchant Name ${key + 1}`}
                          reportValue={val.merchantName}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {details.status === PENDING_STATUS && isRejected && (
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full sm:px-4 sm:pb-4 ">
                    <TextArea
                      label={
                        <RequiredFieldComponent labelText="Change Reason" />
                      }
                      disabled={isStatusLoading}
                      className="h-40"
                      error={errors.reason}
                      labelFontClassName="font-medium tracking-wide text-xs"
                      inputProps={register('reason', {
                        required: true,
                      })}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/redux';
import { useHistory, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeWithZoneFormatUtil,
} from 'components/dateTimeFormatUtil';
import { PageHeader } from 'components/PageHeader';
import { Button } from '@mui/material';
import {
  getItemDetails,
  getItemNotFound,
  getTransactionDetailsLoading,
} from '../selectors';
import { useEffect } from 'react';
import { fetchTransactionDetails } from '../thunks';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { RectLoader } from 'components/RectLoader';
import Divider from '@mui/material/Divider';
import { ItemView } from 'components/grid/ItemView';
import { AuthorizationStatusBadge } from '../../authorizations/list/AuthorizationStatusBadge';
import numeral from 'numeral';
import { CardBrandBadge } from '../../authorizations/list/component/CardBrandBadge';
import { TransactionStatuses } from '../../unmatched-transactions/utils';
import { TransactionType } from '../transaction';
import PaymentIcon from '@mui/icons-material/Payment';

export const TransactionDetailsPreview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const itemDetail = useSelector(getItemDetails);
  const itemNotFound = useSelector(getItemNotFound);
  const isDetailsLoading = useSelector(getTransactionDetailsLoading);
  const { id } = useParams<{ id: string }>();
  const transactionId = Base64.decode(id);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const dateNoTimeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);
  const timeFormatVal = (value: string) =>
    timeWithZoneFormatUtil(value, dateZone);

  useEffect(() => {
    dispatch(fetchTransactionDetails({ id: transactionId }));
  }, [transactionId, dispatch]);

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title="Settlement Details" />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <div>
              <Button onClick={() => history.goBack()} variant="outlined">
                Back
              </Button>
            </div>
          </div>
          {itemNotFound && (
            <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-col items-center justify-center justify-items-center w-40 h-16 rounded-md ml-3 box-shadow-nero box-bg-nero-300">
                    <div className="border rounded-lg">
                      <AccountBalanceIcon
                        fontSize="large"
                        sx={{ color: '#fff' }}
                      />
                    </div>
                    <div className="text-xs text-white ml-1">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                  <div>No such log</div>
                </div>
              </div>
            </div>
          )}
          {isDetailsLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}
          {!isDetailsLoading && !itemNotFound && (
            <>
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <div className="-mt-8 w-full sm:w-auto">
                      <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-cream-can box-cream-can">
                        <div>
                          <PaymentIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-1">Details</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <Divider>Merchant</Divider>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                    <ItemView
                      labelName="Hierarchy ID"
                      isNotSet
                      isUnknown
                      value={itemDetail.hierarchyId}
                    />
                    <ItemView
                      labelName="DBA Name"
                      isNotSet
                      isUnknown
                      value={itemDetail.dbaName}
                    />
                    <ItemView
                      labelName="Terminal ID"
                      isNotSet
                      isUnknown
                      value={itemDetail.terminalId}
                    />
                    <ItemView
                      labelName="Location ID"
                      isNotSet
                      isUnknown
                      value={itemDetail.locationId}
                    />
                    <ItemView
                      labelName="Store Number"
                      isNotSet
                      isUnknown
                      value={itemDetail.storeNumber}
                    />
                    <ItemView
                      labelName="Merchant ID"
                      isNotSet
                      isUnknown
                      value={itemDetail.merchantId}
                    />
                  </div>
                  <div className="mt-5">
                    <Divider>
                      {t(
                        'transactionsManagement.transactions.details.transaction'
                      )}
                    </Divider>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                    <ItemView
                      labelName="Settlement Date"
                      isNotSet
                      isUnknown
                      value={dateNoTimeFormat(itemDetail.settlementDate)}
                    />
                    <ItemView
                      labelName="Batch Number"
                      isNotSet
                      isUnknown
                      value={itemDetail.batchNumber}
                    />
                    <ItemView
                      labelName="Batch Date"
                      isNotSet
                      isUnknown
                      value={dateNoTimeFormat(itemDetail.batchDate)}
                    />
                    <ItemView
                      labelName="Authorization Code"
                      isNotSet
                      isUnknown
                      value={itemDetail.authorizationCode}
                    />
                    <ItemView
                      labelName="Trace ID"
                      isNotSet
                      isUnknown
                      value={itemDetail.traceId}
                    />
                    <ItemView
                      labelName="ARN Number"
                      isNotSet
                      isUnknown
                      value={itemDetail.arnNumber}
                    />
                    <ItemView
                      labelName="RRN Number"
                      isNotSet
                      isUnknown
                      value={itemDetail.rrnNumber}
                    />
                    <ItemView
                      labelName="Card Number"
                      isNotSet
                      isUnknown
                      value={itemDetail.cardNumber}
                    />
                    <ItemView
                      labelName="Card Brand"
                      isNotSet
                      isUnknown
                      value={<CardBrandBadge status={itemDetail.cardBrand} />}
                    />
                    <ItemView
                      labelName="Transaction Date"
                      isNotSet
                      isUnknown
                      value={dateNoTimeFormat(itemDetail.transactionDate)}
                    />
                    <ItemView
                      labelName="Settlement Amount"
                      isNotSet
                      isUnknown
                      value={
                        itemDetail?.settlementAmount
                          ? numeral(itemDetail.settlementAmount).format('0.00')
                          : undefined
                      }
                    />
                    <ItemView
                      labelName="Currency Code"
                      isNotSet
                      isUnknown
                      value={itemDetail.settlementCurrencyCode}
                    />
                    <ItemView
                      labelName="Billing Currency Amount"
                      isNotSet
                      isUnknown
                      value={
                        itemDetail?.billingCurrencyAmount
                          ? numeral(itemDetail.billingCurrencyAmount).format(
                              '0.00'
                            )
                          : undefined
                      }
                    />
                    <ItemView
                      labelName="Billing Currency Code"
                      isNotSet
                      isUnknown
                      value={itemDetail.billingCurrencyCode}
                    />
                    <ItemView
                      labelName="Currency Conversion Rate"
                      isNotSet
                      isUnknown
                      value={
                        itemDetail?.currencyConversionRate
                          ? numeral(itemDetail.currencyConversionRate).format(
                              '0.00'
                            )
                          : undefined
                      }
                    />
                    <ItemView
                      labelName="Status"
                      isNotSet
                      isUnknown
                      value={
                        <AuthorizationStatusBadge
                          widthClass={'w-23'}
                          heightClass={'h-8'}
                          textSizeClass={'text-xxs'}
                          status={itemDetail.transactionStatus}
                        >
                          {itemDetail.transactionStatus
                            ? itemDetail.transactionStatus ===
                              TransactionStatuses.PROCESSED_A
                              ? TransactionStatuses.PROCESSED
                              : TransactionStatuses.REJECTED
                            : 'Unknown'}
                        </AuthorizationStatusBadge>
                      }
                    />
                    <ItemView
                      labelName="Transaction Type"
                      isNotSet
                      isUnknown
                      value={
                        itemDetail?.transactionType &&
                        Object.values(TransactionType).includes(
                          itemDetail?.transactionType
                        )
                          ? t(
                              `transactionsManagement.transactions.transactionType.${itemDetail.transactionType}`
                            )
                          : itemDetail?.transactionType
                      }
                    />
                    <ItemView
                      labelName="Transaction Mode"
                      isNotSet
                      isUnknown
                      value={itemDetail.transactionMode}
                    />
                    <ItemView
                      labelName="Transaction Source"
                      isNotSet
                      isUnknown
                      value={itemDetail.transactionSource}
                    />
                    <ItemView
                      labelName="Transaction Time"
                      isNotSet
                      isUnknown
                      value={timeFormatVal(itemDetail.transactionTime)}
                    />
                    <ItemView
                      labelName="Match Type"
                      isNotSet
                      isUnknown
                      value={itemDetail.matchType}
                    />
                    <ItemView
                      labelName="Entry Mode"
                      isNotSet
                      isUnknown
                      value={itemDetail.entryMode}
                    />
                    <ItemView
                      labelName="POS Entry Mode"
                      isNotSet
                      isUnknown
                      value={itemDetail.posEntryMode}
                    />
                    <ItemView
                      labelName="PC Terminal Capability"
                      isNotSet
                      isUnknown
                      value={itemDetail.pcTerminalCapability}
                    />
                    <ItemView
                      labelName="Partial Auth Indicator"
                      isNotSet
                      isUnknown
                      value={itemDetail.partialAuthIndicator}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

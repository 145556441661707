import { ReactNode } from 'react';
import { StatusBadge } from 'components/StatusBadge';
import { TransactionStatuses } from '../../unmatched-transactions/utils';

const statusBadge = (status: string) => {
  switch (status) {
    case TransactionStatuses.PROCESSED:
      return 'success';
    case TransactionStatuses.PROCESSED_A:
      return 'success';
    case TransactionStatuses.REJECTED:
      return 'danger';
    case TransactionStatuses.REJECTED_R:
      return 'danger';
    default:
      return 'primary';
  }
};

export const TransactionStatusBadge = ({
  status,
  children,
}: {
  status: string;
  children: ReactNode;
}) => <StatusBadge variant={statusBadge(status)}>{children}</StatusBadge>;

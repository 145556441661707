import api from 'api';

const pageNotificationPost = async (data: any) => {
  return api.post('/notifications/page', data);
};

const resolveNotificationPost = async (data: any) => {
  return api.post('/notifications/resolve', data);
};

export const notificationResource = {
  pageNotificationPost,
  resolveNotificationPost,
};

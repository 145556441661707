export const VoidVerify = () => (
  <img
    style={{
      width: '25px',
      height: '25px',
    }}
    src={require('../img/delete.png')}
    alt={''}
  />
);

import { useTranslation } from 'react-i18next';

import { PageHeader } from 'components/PageHeader';
import { MerchantFormNew } from '../components/MerchantFormNew';

export const CreateMerchantNew = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('merchants.createMerchant')} />
      </div>
      <MerchantFormNew />
    </div>
  );
};

import { HintInfoMessage } from '../domain/auth/components/HintInfoMessage';

export const RequiredFieldComponent = ({
  labelText,
  isRequired = true,
  className = '',
  infoIconMessage,
}: {
  labelText: string;
  infoIconMessage?: string;
  isRequired?: boolean;
  className?: string;
}) => {
  return (
    <span className={className}>
      {labelText}{' '}
      <span
        style={{
          fontSize: '20px',
          verticalAlign: 'middle',
          color: 'red',
          fontWeight: 'bold',
        }}
      >
        {isRequired ? '*' : ''}
      </span>
      {infoIconMessage && (
        <span>
          <HintInfoMessage message={infoIconMessage} />
        </span>
      )}
    </span>
  );
};

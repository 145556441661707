import React, { ReactNode } from 'react';
import { IconPendingWarning } from './icons/IconPendingWarning';

interface ErrorBoundaryProps {
  errorPlug?: string;
  ErrorPage?: any;
  reportFunc?: (value: any) => void;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full text-center text-red-500">
      <IconPendingWarning className="w-16 h-16 mb-2 text-red-500" />
      <p className="text-lg font-medium">
        Something went wrong. <br />
        <span
          aria-hidden="true"
          onClick={() => location.reload()}
          className="underline cursor-pointer text-red-500"
        >
          Refresh the page and try again.
        </span>
      </p>
    </div>
  );
};

class AppErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(message: any, errorInfo: any) {
    const { reportFunc } = this.props;
    if (reportFunc) {
      reportFunc({ message: `${message}`, stack: errorInfo.componentStack });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default AppErrorBoundary;

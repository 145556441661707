import { ItemAttributes } from 'components/itemAttributeType';
import { ListFilters } from '../domain/transaction-management/authorizations/authorizationsSlice';

type StateFilters = Record<string | number | symbol, ItemAttributes[]>;

export const addFilterToState = <T extends StateFilters>({
  filters,
  filterKey,
  payload,
}: {
  filters: T;
  filterKey: keyof T;
  payload: ItemAttributes;
}) => ({
  ...filters,
  [filterKey]: [...filters[filterKey], payload],
});

export const removeFilterFromState = <T extends StateFilters>({
  filters,
  filterKey,
  payload,
}: {
  filters: T;
  filterKey: keyof T;
  payload: ItemAttributes;
}) => ({
  ...filters,
  [filterKey]: filters[filterKey].filter(
    (item) => item.value !== payload.value
  ),
});

export const resetFilterInState = <T extends StateFilters>({
  filters,
  filterKey,
}: {
  filters: T;
  filterKey: keyof T;
}) => {
  if (!filters[filterKey].length) {
    return filters;
  }

  return {
    ...filters,
    [filterKey]: [],
  };
};

export const mappedSelectData = ({ data }: any) => {
  return {
    data: (data || [])
      .filter((item: any) => !!item.label)
      .map((item: any) => ({
        label: item.label || '',
        value: item.id!,
      })),
    meta: {
      totalItems: 0,
    },
  };
};

export const addItem = (state, permission) => [...state, permission];
export const resetItems = () => [];
export const removeItem = (state, permission) => {
  return state.filter((item) => item.value !== permission.value);
};

export const valuesHaveChanged = (allValues: any, previousValues: any) =>
  Object.keys(allValues).some(
    (key) =>
      allValues[key as keyof ListFilters] !==
      previousValues.current![key as keyof ListFilters]
  );

import { useEffect } from 'react';
import { NotificationList } from './NotificationList';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loadItems } from '../thunks';
import { getMainState } from '../selectors';
import { resetAllItems } from '../notificationSlice';
import { useLocation } from 'react-router-dom';

export const Container = () => {
  const { t } = useTranslation();

  const { page, appliedFilters, rowsPerPage, sort } =
    useAppSelector(getMainState);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: 'Notifications',
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(() => {
    dispatch(loadItems());
  }, [dispatch, page, appliedFilters, rowsPerPage, sort]);

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return <NotificationList />;
};

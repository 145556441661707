import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CardInfoItem,
  CardsInfo,
  TransactionByStatus,
  TransactionByType,
  UnmatchedTransactionItem,
} from './utils';

import {
  loadAuthorizationStatusInfoItems,
  loadAuthorizationTypeInfoItems,
  loadCardInfoItems,
  loadTransactionStatusInfoItems,
  loadTransactionTypeInfoItems,
  loadUnmatchedTransactionItems,
} from './thunks';

export interface DashboardState {
  cardInfoItem: CardsInfo;
  unmatchedTransactions: UnmatchedTransactionItem[];
  itemsLoading: boolean;
  transactionStatusLoading: boolean;
  transactionTypeLoading: boolean;
  authorizationStatusLoading: boolean;
  authorizationTypeLoading: boolean;
}

export const storeName = 'dashboard';

const initialState: DashboardState = {
  cardInfoItem: CardInfoItem,
  unmatchedTransactions: [] as UnmatchedTransactionItem[],
  itemsLoading: false,
  transactionStatusLoading: false,
  transactionTypeLoading: false,
  authorizationStatusLoading: false,
  authorizationTypeLoading: false,
};

const dashboardSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadCardInfoItems.fulfilled,
      (state, action: PayloadAction<CardsInfo>) => {
        state.cardInfoItem = action.payload;
        state.itemsLoading = false;
      }
    );
    builder.addCase(loadCardInfoItems.pending, (state) => {
      state.itemsLoading = true;
    });
    builder.addCase(loadCardInfoItems.rejected, (state) => {
      state.itemsLoading = false;
    });
    builder.addCase(
      loadTransactionStatusInfoItems.fulfilled,
      (state, action: PayloadAction<TransactionByStatus[]>) => {
        state.cardInfoItem.transactionByStatus = action.payload;
        state.transactionStatusLoading = false;
      }
    );
    builder.addCase(loadTransactionStatusInfoItems.pending, (state) => {
      state.transactionStatusLoading = true;
    });
    builder.addCase(loadTransactionStatusInfoItems.rejected, (state) => {
      state.transactionStatusLoading = false;
    });
    builder.addCase(
      loadTransactionTypeInfoItems.fulfilled,
      (state, action: PayloadAction<TransactionByType[]>) => {
        state.cardInfoItem.transactionByType = action.payload;
        state.transactionTypeLoading = false;
      }
    );
    builder.addCase(loadTransactionTypeInfoItems.pending, (state) => {
      state.transactionTypeLoading = true;
    });
    builder.addCase(loadTransactionTypeInfoItems.rejected, (state) => {
      state.transactionTypeLoading = false;
    });
    builder.addCase(
      loadUnmatchedTransactionItems.fulfilled,
      (state, action) => {
        state.unmatchedTransactions = action.payload;
      }
    );
    builder.addCase(
      loadAuthorizationStatusInfoItems.fulfilled,
      (state, action: PayloadAction<TransactionByStatus[]>) => {
        state.cardInfoItem.authorizationsByStatus = action.payload;
        state.authorizationStatusLoading = false;
      }
    );
    builder.addCase(loadAuthorizationStatusInfoItems.pending, (state) => {
      state.authorizationStatusLoading = true;
    });
    builder.addCase(loadAuthorizationStatusInfoItems.rejected, (state) => {
      state.authorizationStatusLoading = false;
    });
    builder.addCase(
      loadAuthorizationTypeInfoItems.fulfilled,
      (state, action: PayloadAction<TransactionByType[]>) => {
        state.cardInfoItem.authorizationsByType = action.payload;
        state.authorizationTypeLoading = false;
      }
    );
    builder.addCase(loadAuthorizationTypeInfoItems.pending, (state) => {
      state.authorizationTypeLoading = true;
    });
    builder.addCase(loadAuthorizationTypeInfoItems.rejected, (state) => {
      state.authorizationTypeLoading = false;
    });
  },
});

export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;

import api from 'api';

export type Program = {
  id: string;
  name: string;
};

export const programsList = (options?: any) => {
  return api.post('/dropdown/programs', options);
};

export const programList = async (options = {} as any) => {
  try {
    const { data } = await programsList(options);

    return data;
  } catch (e) {
    return [];
  }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';
import { Log, LogItem } from 'entities/logs';

import { ApiFilter, defaultApiFilter, details } from './utils';
import { fetchDetails, loadItems } from './thunks';
import { ItemSort } from '../../../entities';

export interface ApiState {
  items: LogItem[];
  itemsLoading: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  sort: ItemSort | null;
  rowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  details: Log;
  isDetailsLoading: boolean;
  itemId: string | null;
  itemNotFound: boolean;
  filter: ApiFilter;
  appliedFilters: ApiFilter;
}

export const storeName = 'drApi';

const initialState: ApiState = {
  items: [] as LogItem[],
  itemId: null as string | null,
  itemNotFound: false,
  itemsLoading: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  sort: null,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  details: details,
  isDetailsLoading: false,
  filter: defaultApiFilter,
  appliedFilters: defaultApiFilter,
};

const apiSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
      state.totalNumber = initialState.totalNumber;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
      state.filter = initialState.filter;
      state.appliedFilters = initialState.appliedFilters;
    },
    setFilters(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.appliedFilters = {
        ...state.filter,
        ...(action?.payload || {}),
      };
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllFilters(state) {
      state.filter = initialState.filter;
      state.appliedFilters = initialState.appliedFilters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.itemNotFound = false;

      state.details = action.payload;
    });
    builder.addCase(fetchDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });

    builder.addCase(fetchDetails.rejected, (state) => {
      state.isDetailsLoading = false;
      state.itemNotFound = true;
    });

    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: LogItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });

    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  resetAllItems,
  setPage,
  setRowsPerPage,
  setSort,
  setApplyFilters,
  resetAllFilters,
  setFilters,
} = apiSlice.actions;

export default apiSlice.reducer;

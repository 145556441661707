import { useHistory } from 'react-router-dom';
import { RectLoader } from 'components/RectLoader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { getCardInfo, getItemsLoadingComputed } from '../selectors';
import { NotificationType } from '../utils';
import classNames from 'classnames';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { useEffect } from 'react';
import { setNotificationNumber } from '../../account/accountSlice';

export const NotificationDetails = {
  [NotificationType.INFORMATIONAL]: {
    colorName: 'box-bg-info-400',
    icon: <InfoOutlinedIcon />,
  },
  [NotificationType.ERROR]: {
    colorName: 'box-bg-error-400',
    icon: <DangerousIcon />,
  },
  [NotificationType.ALERT]: {
    colorName: 'box-bg-error-400',
    icon: <ErrorOutlineOutlinedIcon />,
  },
  [NotificationType.WARNING]: {
    colorName: 'box-bg-warning-400',
    icon: <WarningAmberOutlinedIcon />,
  },
  [NotificationType.SUCCESS]: {
    colorName: 'box-bg-success-400',
    icon: <CheckCircleOutlineOutlinedIcon />,
  },
  [NotificationType.REMINDER]: {
    colorName: 'box-bg-info-400',
    icon: <NotificationsActiveOutlinedIcon />,
  },
  [NotificationType.SYSTEM_MAINTENANCE]: {
    colorName: 'box-bg-info-400',
    icon: <ManageHistoryOutlinedIcon />,
  },
  [NotificationType.SECURITY_ALERT]: {
    colorName: 'box-bg-error-400',
    icon: <GppMaybeOutlinedIcon />,
  },
  [NotificationType.ACTION_REQUIRED]: {
    colorName: 'box-bg-warning-400',
    icon: <TouchAppOutlinedIcon />,
  },
  [NotificationType.PROMOTIONAL]: {
    colorName: 'box-bg-info-400',
    icon: <CampaignOutlinedIcon />,
  },
};

const NotificationCardInfo = ({ cardInfo }) => {
  const history = useHistory();
  return (
    <div
      role="button"
      aria-hidden="true"
      onClick={() =>
        history.push('/notifications', {
          notifyId: cardInfo.id,
        })
      }
      className={classNames(
        'flex flex-col justify-between items-stretch h-23 box-shadow-dark border mt-3 p-4 rounded-md',
        NotificationDetails[cardInfo.type].colorName
      )}
    >
      <div className="flex flex-row justify-between items-stretch">
        <div>
          {NotificationDetails[cardInfo.type].icon}
          <span className="ml-2 font-bold">{cardInfo.title}</span>
        </div>
        <div>{cardInfo.timeInfo}</div>
      </div>
      <div className="mt-1 ml-8 font-medium">{cardInfo.content}</div>
    </div>
  );
};

export const NotificationCard = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const cardInfoItem = useAppSelector(getCardInfo);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);

  useEffect(() => {
    dispatch(
      setNotificationNumber(
        cardInfoItem.notificationsSummary?.notifications.length
      )
    );
  }, [dispatch, cardInfoItem]);

  return (
    <>
      <div
        aria-hidden="true"
        className="flex flex-col justify-start items-stretch col-span-8 lg:col-span-5 h-auto bg-white border p-4 rounded-md box-shadow-dark"
      >
        <div className="flex flex-row justify-between items-stretch">
          <div
            role="button"
            aria-hidden="true"
            onClick={() => history.push('/notifications')}
            className="flex items-center justify-center justify-items-center w-16 h-16 rounded-md hover:box-bg-dark-red-300 box-bg-cinnabar box-shadow-red-400 cursor-pointer"
          >
            <NotificationsIcon fontSize="large" sx={{ color: '#fff' }} />
          </div>
          <div className="flex flex-col mr-2 items-end">
            <div className="text-sm text-black">Notifications</div>
            <div className="text-2xl text-black">
              {itemsLoading ? (
                <RectLoader width={150} height={18} />
              ) : (
                cardInfoItem.notificationsSummary?.count
              )}
            </div>
          </div>
        </div>
        {!itemsLoading && cardInfoItem.notificationsSummary?.notifications
          ? cardInfoItem.notificationsSummary.notifications.map((e) => (
              <NotificationCardInfo key={e.id} cardInfo={e} />
            ))
          : null}
      </div>
    </>
  );
};

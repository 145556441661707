import { useMemo } from 'react';

import { CellLight, ColumnProps } from 'components/table/Table';
import { ServicesItem, ServiceStatus } from '../utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';

export const useColumns = () => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  const setStatus = (value: string) =>
    value === ServiceStatus.UP ? (
      <span className="text-emerald-700">
        {ServiceStatus.UP} <ArrowUpwardIcon />
      </span>
    ) : (
      <span className="text-red-700">
        {ServiceStatus.DOWN} <ArrowDownwardIcon />
      </span>
    );
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'time',
        label: 'Time',
        value: (item: ServicesItem) => (
          <CellLight>{timeFormat(item.time)}</CellLight>
        ),
      },
      {
        id: 'acquiringApi',
        label: 'Acquiring Api',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.acquiringApi)}</CellLight>
        ),
      },
      {
        id: 'backofficeApi',
        label: 'Backoffice Api',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.backofficeApi)}</CellLight>
        ),
      },
      {
        id: 'backofficeUI',
        label: 'Backoffice UI',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.backofficeUI)}</CellLight>
        ),
      },
      {
        id: 'settlementService',
        label: 'Settlement Service',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.settlementService)}</CellLight>
        ),
      },
      {
        id: 'sftpReports',
        label: 'Sftp Reports',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.sftpReports)}</CellLight>
        ),
      },
      {
        id: 'sftpFiserv',
        label: 'Sftp Fiserv',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.sftpFiserv)}</CellLight>
        ),
      },
      {
        id: 'sftpTranscard',
        label: 'Sftp Transcard',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.sftpTranscard)}</CellLight>
        ),
      },
      {
        id: 'nmiApi',
        label: 'Nmi Api',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.nmiApi)}</CellLight>
        ),
      },
      {
        id: 'fiServApi',
        label: 'FiServ Api',
        value: (item: ServicesItem) => (
          <CellLight>{setStatus(item.fiServApi)}</CellLight>
        ),
      },
    ],
    []
  );
};

import api from 'api';

const list = async (data: any) => {
  return api.post('/programs/page', data);
};

const createMerchant = async (data: any) => {
  return api.post('/merchants/create', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/merchants/get', data);
};

const fetchProgram = async (data: { id: string }) => {
  return api.post('/programs/get', data);
};

const createPrograms = async (data: any) => {
  return api.post('/programs/create', data);
};

const updatePrograms = async (data: any) => {
  return api.post('/programs/update', data);
};

export const programsResource = {
  list,
  createPrograms,
  updatePrograms,
  fetchDetails,
  fetchProgram,
  createMerchant,
};

import { useAppSelector } from 'hooks/redux';
import { MerchantRapidConnectComponent } from './MerchantRapidConnectComponent';
import { MerchantNMIComponent } from './MerchantNMIComponent';
import { getMerchantCreateInfoItemStatus } from '../selectors';
import { GatewaysValues } from '../utils';

export const MerchantMethodCreateComponent = () => {
  const merchantInfoItem = useAppSelector(getMerchantCreateInfoItemStatus);

  return (
    <>
      {merchantInfoItem.gateway &&
      merchantInfoItem.gateway.value === GatewaysValues.RAPID_CONNECT ? (
        <MerchantRapidConnectComponent />
      ) : (
        merchantInfoItem.gateway &&
        merchantInfoItem.gateway.value === GatewaysValues.NMI && (
          <MerchantNMIComponent />
        )
      )}
    </>
  );
};

import { useTranslation } from 'react-i18next';

import { ItemSort, TransactionItem } from 'entities/transaction-management';
import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LayoutList } from 'layouts/list/LayoutList';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { useTransactionsListColumns } from './useTransactionsListColumns';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../transactionSlice';
import { Filters } from './filters/Filters';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { ListFilters } from '../utils';
import { countDecimalPlaces } from '../../authorizations/utils';

export const SettlementsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();

  const items: TransactionItem[] = useAppSelector(getItems);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const filters: ListFilters = useAppSelector(getFilters);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const permissions: any = useAppSelector(getProfilePermission);

  const columns: ColumnProps[] = useTransactionsListColumns();

  const handleRowClick = (item: TransactionItem) => {
    history.push(`settlements/${Base64.encodeURI(item.id)}`);
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isOnRowClick = permissions.includes(UserPermissions.TRANSACTION_GET);
  const isFilterSelected = [
    filters.id.length ||
      filters.authorizationCode.length ||
      filters.cardLastFour ||
      (filters.settlementAmount &&
        countDecimalPlaces(filters.settlementAmount) <= 2 &&
        filters.settlementAmount >= 0) ||
      filters.matchTypes.length ||
      filters.merchants.length ||
      filters.transactionTypes.length ||
      filters.transactionStatus.length ||
      (filters.settlementDate.from && filters.settlementDate.to) ||
      (filters.transactionDate.from && filters.transactionDate.to),
  ].some((value) => !!value);

  return (
    <LayoutList title={t('transactionsManagement.transactions.breadcrumb')}>
      <>
        <TableCard
          loading={itemsLoadingComputed}
          columns={columns}
          items={items}
          totalNumber={totalNumber}
          setSort={setCurrentSort}
          onRowClick={isOnRowClick ? handleRowClick : undefined}
          onHover={isOnRowClick}
          pagination={pagination}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          filterApply={
            isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
          }
          filters={<Filters />}
        />
      </>
    </LayoutList>
  );
};

import { useTranslation } from 'react-i18next';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { ItemSort, UnmatchedTransactionItem } from 'entities';
import { useAppDispatch } from 'hooks/redux';
import { LayoutList } from 'layouts/list/LayoutList';

import { useUnmatchedTransactionsListColumns } from './useUnmatchedTransactionsListColumns';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../unmatchedTransactionSlice';
import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { Filters } from './filters/Filters';
import { useSelector } from 'react-redux';
import { countDecimalPlaces } from '../../authorizations/utils';

export const UnmatchedTransactionsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const items: UnmatchedTransactionItem[] = useSelector(getItems);
  const pagination: PaginationProps = useSelector(getPagination);
  const totalNumber: number = useSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useSelector(getItemsLoadingComputed);
  const filters = useSelector(getFilters);

  const isFilterSelected = [
    filters.authorizationCode?.length ||
      filters.cardLast4 ||
      filters.merchants.length ||
      (filters.transactionAmount &&
        countDecimalPlaces(filters.transactionAmount) <= 2 &&
        filters.transactionAmount >= 0) ||
      filters.cardBrand.length ||
      filters.transactionStatus.length ||
      (filters.transactionDate?.from && filters.transactionDate?.to),
  ].some((value) => !!value);

  const columns: ColumnProps[] = useUnmatchedTransactionsListColumns();

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  return (
    <LayoutList
      title={t('transactionsManagement.unmatchedTransactions.breadcrumb')}
    >
      <TableCard
        loading={itemsLoadingComputed}
        columns={columns}
        items={items}
        totalNumber={totalNumber}
        setSort={setCurrentSort}
        pagination={pagination}
        filterApply={
          isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
        }
        filters={<Filters />}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        onHover={false}
      />
    </LayoutList>
  );
};

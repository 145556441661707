import { ReactNode, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const PopoverPortal = ({ children }: { children: ReactNode }) => {
  const [popoverContainer] = useState(document.createElement('div'));

  useEffect(() => {
    const root = document.getElementById('popovers');

    root?.appendChild(popoverContainer);

    return function cleanup() {
      root?.removeChild(popoverContainer);
    };
  }, [popoverContainer]);

  return createPortal(children, popoverContainer);
};

import api from 'api';

export interface MerchantsListProps {
  limit?: number;
  offset?: number;
  searchTerm?: string;
  status?: string;
}

export const listCountryCode = (options: any) => {
  return api.post('/dropdown/country-codes', options);
};

export const listCurrencyCode = (options: any) => {
  return api.post('/dropdown/currency-codes', options);
};

export const listTransactionStatus = (options: any) => {
  return api.post('/dropdown/transaction/status', options);
};
export const listTransactionType = (options: any) => {
  return api.post('/dropdown/transaction/type', options);
};

export const merchants = async (data: any) => {
  return api.post('/dropdown/merchants', data);
};

export const users = async (data: any) => {
  return api.post('/dropdown/users', data);
};

export const clients = async (data: any) => {
  return api.post('/dropdown/clients', data);
};

export const merchantsProgram = async (data: any) => {
  return api.post(`/merchants/${data}/programs`);
};

export const merchantsPortal = async (options: MerchantsListProps) => {
  return api.post('/dropdown/merchant-portal/merchants', options);
};

export const countryCodeList = async (options: any) => {
  try {
    const { data } = await listCountryCode(options);

    return data;
  } catch (e) {
    return [];
  }
};

export const currencyCodeList = async (options: any) => {
  try {
    const { data } = await listCurrencyCode(options);

    return data;
  } catch (e) {
    return [];
  }
};

export const merchantList = async (options?: any) => {
  try {
    const { data } = await merchants(options ? options : { status: null });
    return data;
  } catch (e) {
    return [];
  }
};

export const usersList = async (options?: any) => {
  try {
    const { data } = await users(options);
    return data;
  } catch (e) {
    return [];
  }
};

export const clientsList = async (options?: any) => {
  try {
    const { data } = await clients(options);
    return data;
  } catch (e) {
    return [];
  }
};

export const merchantPortalList = async (
  options = {} as MerchantsListProps
) => {
  try {
    const { data } = await merchantsPortal(options);

    return data;
  } catch (e) {
    return [];
  }
};

export const merchantProgramsList = async (options?: any) => {
  try {
    const { data } = await merchantsProgram(options.merchantId);
    return data;
  } catch (e) {
    return [];
  }
};

export const transactionStatusList = async (options: any) => {
  try {
    const { data } = await listTransactionStatus(options);

    return data;
  } catch (e) {
    return [];
  }
};

export const transactionTypeList = async (options: any) => {
  try {
    const { data } = await listTransactionType(options);

    return data;
  } catch (e) {
    return [];
  }
};

import React, { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getItemsLoadingComputed,
  getTotalSalesPerDayLoading,
} from '../selectors';
import { dayRunningInitRange } from './ComplianceAuditIndexComponent';
import { useSelector } from 'react-redux';
import { dateTimeZone } from 'domain/account/selectors';
import { TotalDailySalesChartComponent } from './charts-components/TotalDailySalesChartComponent';
import Divider from '@mui/joy/Divider';
import moment from 'moment-timezone';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { loadTotalSalesPerDayItems } from '../thunks';
import {
  dateMonthFormatUtil,
  dateTimeFormatUtil,
  generationDateFormatUtil,
} from 'components/dateTimeFormatUtil';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';

type TotalDailySalesProps = {
  control: any;
  watch: any;
  setValue: any;
  merchantRef: any;
};

export const TotalDailySalesComponent: React.FC<TotalDailySalesProps> = ({
  control,
  watch,
  setValue,
  merchantRef,
}) => {
  const dispatch = useAppDispatch();
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const dateZone = useSelector(dateTimeZone);
  const totalSalesPerDayLoading = useSelector(getTotalSalesPerDayLoading);

  const dateFormatVal = (value: any) =>
    dateTimeFormatUtil(value, generationDateFormatUtil, dateZone);
  const dateMonthFormatVal = (value: any) =>
    dateMonthFormatUtil(value, dateZone);

  const intiTotalDailyRange = dayRunningInitRange(dateZone, 30);

  const totalDailySalesRangeRef = useRef(intiTotalDailyRange);
  const [isMonthSelected, setMonthSelected] = useState(false);

  const { totalDailySalesRange } = watch();

  const isDailyRangeDisabled = () => {
    const dailyDateRangeDiffDays = moment(totalDailySalesRange?.to)
      ?.tz(dateZone)
      .diff(moment(totalDailySalesRange.from).tz(dateZone), 'days');

    return dailyDateRangeDiffDays + 1 < 7 || dailyDateRangeDiffDays + 1 > 31;
  };

  const isDailyRangeNone =
    !totalDailySalesRange.from || !totalDailySalesRange.to;

  const onTotalSalesPerDayApply = () => {
    dispatch(
      loadTotalSalesPerDayItems({
        merchantId: merchantRef.current?.value,
        dateRange: {
          from: dateFormatVal(totalDailySalesRange.from),
          to: dateFormatVal(totalDailySalesRange.to),
        },
      })
    );
    totalDailySalesRangeRef.current = totalDailySalesRange;
  };

  const clickLast30DaysRangeView = () => {
    setMonthSelected(false);
    setValue('totalDailySalesRange', dayRunningInitRange(dateZone, 30));
  };

  const clickLast7DaysRangeView = () => {
    setMonthSelected(false);
    setValue('totalDailySalesRange', dayRunningInitRange(dateZone, 7));
  };

  const clickMonthRangeView = () => {
    setMonthSelected(true);
  };

  const clickDailyRangeView = () => {
    setMonthSelected(false);
  };

  const handleChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, { from: start, to: null });
    }

    if (start && end) {
      setDateFilter(callback, { from: start, to: end });
    }
  };

  const handleMonthYearChanges = (callback: any, dates: any) => {
    const start = moment(dates).tz(dateZone).startOf('month').toDate();
    const end = moment(dates).tz(dateZone).endOf('month').toDate();

    if (dates) {
      setDateFilter(callback, { from: start, to: end });
    }
  };

  const handleMonthlyRange = (callback: any, dates: any) => {
    if (isMonthSelected) {
      handleMonthYearChanges(callback, dates);
      return;
    }
    handleChange(callback, dates);
  };

  const showMonthRangeValue = (value: any) => {
    return value && value.to && value.from ? dateMonthFormatVal(value.to) : '';
  };

  return (
    <div className="col-span-2 l1250g:col-span-1 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      <TotalDailySalesChartComponent
        isMonthYearPicker={isMonthSelected}
        dateRange={totalDailySalesRangeRef.current}
      />
      <div className="m-3">
        <Divider />
      </div>
      {merchantRef.current && !itemsLoading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 sm:col-span-2">
            <DatePickerControl
              control={control}
              errorMessage={
                isDailyRangeDisabled()
                  ? 'Range min 7 days - max 31 days '
                  : null
              }
              datePickerType={
                isMonthSelected
                  ? DatePickerType.DATE_MONTH_RANGE
                  : DatePickerType.STANDARD_DATE_RANGE
              }
              popoverWidth={520}
              handleChange={handleMonthlyRange}
              showValueChange={isMonthSelected ? showMonthRangeValue : null}
              name="totalDailySalesRange"
              maxDate={moment().subtract(1, 'd').toDate()}
              title="Date Range"
              footer={
                <div className="grid justify-center justify-items-center m-4">
                  <ButtonGroup
                    variant="outlined"
                    size="small"
                    aria-label="outlined button group"
                  >
                    <Button
                      style={{ width: '120px' }}
                      onClick={clickLast30DaysRangeView}
                    >
                      Last 30 days
                    </Button>
                    <Button
                      style={{ width: '120px' }}
                      onClick={clickLast7DaysRangeView}
                    >
                      Last 7 days
                    </Button>
                    {!isMonthSelected ? (
                      <Button
                        style={{ width: '120px' }}
                        onClick={clickMonthRangeView}
                      >
                        Month
                      </Button>
                    ) : (
                      <Button
                        style={{ width: '120px' }}
                        onClick={clickDailyRangeView}
                      >
                        Daily
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              }
            />
          </div>
          <div className="items-end justify-stretch justify-items-end mt-1 w-full col-span-3 sm:col-span-1">
            <LoadingButton
              size="small"
              variant="contained"
              disabled={isDailyRangeDisabled() || isDailyRangeNone}
              style={{ width: '100%' }}
              loading={totalSalesPerDayLoading}
              onClick={onTotalSalesPerDayApply}
            >
              View Data
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

import { ReactNode } from 'react';
import { StatusBadge } from 'components/StatusBadge';

const statusBadge = (status: string) => {
  if (status.toUpperCase() === 'ACCEPTED') {
    return 'success';
  } else if (status.toUpperCase() === 'PENDING') {
    return 'warning';
  } else if (status.toUpperCase() === 'REJECTED') {
    return 'danger';
  } else if (status.toUpperCase() === 'FAILED') {
    return 'failed';
  } else if (status.toUpperCase() === 'PROCESSED') {
    return 'processed';
  } else {
    return 'secondary';
  }
};

export const ReportStatusBadge = ({
  status,
  children,
  widthClass,
  heightClass,
  textSizeClass,
}: {
  status: string;
  children: ReactNode;
  widthClass?: string;
  heightClass?: string;
  textSizeClass?: string;
}) => (
  <StatusBadge
    widthClass={widthClass}
    heightClass={heightClass}
    textSizeClass={textSizeClass}
    variant={statusBadge(status)}
  >
    {children}
  </StatusBadge>
);

import api from 'api';
import { mappedSelectData } from 'utils/filters';

const list = async (data: any) => {
  return api.post('/roles/page', data);
};

const fetchDetails = async (data: { roleId: string }) => {
  return api.post('/roles/get', data);
};

const createRole = async (data: any) => {
  return api.post('/roles/add', data);
};
const updateRole = async (data: any) => {
  return api.post('/roles/update', data);
};

const fetchPermisions = async () => {
  const response = await api.post('/roles/permissions/list');
  return mappedSelectData(response);
};

export const userResource = {
  list,
  fetchDetails,
  createRole,
  updateRole,
  fetchPermisions,
};

import { Input } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import React from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';
import { DateTimeComponent } from './DateTimeComponent';
import { useAppSelector } from 'hooks/redux';
import { getMainState } from '../../selectors';
import { RectLoader } from 'components/RectLoader';
import { selectClassLabel } from '../../utils';

interface AccountHolderDetailsInputProps {
  register: any;
  control: any;
  errors: any;
}

export const AccountHolderDetailsInputComponent: React.FC<
  AccountHolderDetailsInputProps
> = ({ register, errors, control }) => {
  const { isCountryCodesLoading, countryCodes } = useAppSelector(getMainState);
  return (
    <>
      {isCountryCodesLoading && (
        <div className="w-full">
          <RectLoader width={150} height={18} />
        </div>
      )}
      {!isCountryCodesLoading && (
        <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:m-4 sm:p-3">
          <div className="w-full">
            <Input
              label={<RequiredFieldComponent labelText="First Name" />}
              placeholder="First Name"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.firstName}
              inputProps={{
                ...register('firstName', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={<RequiredFieldComponent labelText="Last Name" />}
              placeholder="Last Name"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.lastName}
              inputProps={{
                ...register('lastName', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={<RequiredFieldComponent labelText="Email" />}
              placeholder="Email"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.email}
              inputProps={{
                ...register('email', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="countryCode"
              labelClassName={selectClassLabel}
              label={<RequiredFieldComponent labelText="Country Code" />}
              control={{ ...control }}
              options={countryCodes}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <Input
              label={<RequiredFieldComponent labelText="Phone" />}
              placeholder="Phone"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.phone}
              inputProps={{
                ...register('phone', {
                  required: false,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={<RequiredFieldComponent labelText="Legal Entity Type" />}
              placeholder="Legal Entity Type"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.legalEntityType}
              inputProps={{
                ...register('legalEntityType', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="billingAddressCountryCode"
              labelClassName={selectClassLabel}
              label={
                <RequiredFieldComponent labelText="Billing Address Country Code" />
              }
              control={{ ...control }}
              options={countryCodes}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent labelText="Billing Address Sub Division Code" />
              }
              placeholder="Billing Address Sub Division Code"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.billingAddressSubDivisionCode}
              inputProps={{
                ...register('billingAddressSubDivisionCode', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent labelText="Billing Address Postal Code" />
              }
              placeholder="Billing Address Postal Code"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.billingAddressPostalCode}
              inputProps={{
                ...register('billingAddressPostalCode', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent labelText="Billing Address Address Line 1" />
              }
              placeholder="Billing Address Address Line1"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.billingAddressAddressLine1}
              inputProps={{
                ...register('billingAddressAddressLine1', {
                  required: true,
                }),
              }}
            />
          </div>

          <DateTimeComponent
            control={control}
            name="birthDate"
            labelText="BirthDate"
            placeholder="Select Birthdate"
            isRequired={true}
          />
        </div>
      )}
    </>
  );
};

import { ChargebackItem } from 'entities/dispute-resolution';

export const chargebackDetails = {
  acquirerReferenceNumber: '',
  adjustmentAmount: 0,
  adjustmentDate: '',
  airlineTicketNumber: '',
  authorizationCode: '',
  batchDate: '',
  batchNumber: '',
  cardNumber: '',
  cardType: '',
  cbResponseReceived: '',
  chargebackCurrencyCode: '',
  chargebackCycle: '',
  currentStatus: '',
  customTracking: '',
  debitNetworkDescription: '',
  debitNetworkId: '',
  disputeAmount: 0,
  disputeType: '',
  dueDate: '',
  expDate: '',
  externalId: '',
  filler: '',
  firstChargebackDate: '',
  hierarchyId: '',
  hierarchyLevelNo: '',
  idsCaseNumber: '',
  invoiceNumber: '',
  loanNumber: '',
  locationDBAName: '',
  locationId: '',
  mobileIndicator: '',
  processedTransactionAmount: 0,
  processedTransactionCurrencyCode: '',
  reasonCode: '',
  reasonCodeDescription: '',
  receiveDate: '',
  recordType: '',
  requestDate: '',
  requestType: '',
  respondedOnDate: '',
  secondChargebackDate: '',
  sicCode: '',
  softDescriptor: '',
  specialReference1: '',
  specialReference2: '',
  statusDate: '',
  statusDescription: '',
  storeNumber: '',
  subMerchantId: '',
  submittedTransactionAmount: 0,
  submittedTransactionCurrencyCode: '',
  transactionDate: '',
  transactionPOSEntryMode: '',
  transactionServiceCode: '',
  userData2: '',
  workOrderNumber: '',
  workType: '',
};

export const normalizeChargeback = (chargeback: any): ChargebackItem => ({
  ...chargeback,
});

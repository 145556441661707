import api from 'api';

const listUnmatched = async (data: any) => {
  return api.post('/transactions/unmatched', data);
};

const getCardInfoItem = async () => {
  return api.post('/dashboard/general-info');
};

const getTransactionStatusInfoItems = async (data: {
  dashboardChartPeriod: string;
}) => {
  return api.post('/dashboard/transaction/status-info', data);
};

const getTransactionTypeInfoItems = async (data: {
  dashboardChartPeriod: string;
}) => {
  return api.post('/dashboard/transaction/type-info', data);
};

const getAuthorizationStatusInfoItems = async (data: {
  dashboardChartPeriod: string;
}) => {
  return api.post('/dashboard/authorization/status-info', data);
};

const getAuthorizationTypeInfoItems = async (data: {
  dashboardChartPeriod: string;
}) => {
  return api.post('/dashboard/authorization/type-info', data);
};

export const dashboardResource = {
  listUnmatched,
  getCardInfoItem,
  getTransactionStatusInfoItems,
  getTransactionTypeInfoItems,
  getAuthorizationStatusInfoItems,
  getAuthorizationTypeInfoItems,
};

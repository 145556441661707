import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { storeName } from './merchantsSlice';

export const getRoot = (state: RootState) => state[storeName];

export const getMainState = createSelector([getRoot], (state) => state);

export const getItems = createSelector([getRoot], ({ items }) => items);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getMerchantCreateInfoItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantInfoItem
);

export const getMerchantRapidConnectItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantRapidConnectItem
);

export const getMerchantNMIItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantNMIItem
);

export const getMerchantAccountHolderItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantAccountHolderItem
);

export const getMerchantCreateStepStatus = createSelector(
  [getRoot],
  ({ merchantCreateStepNumber }) => merchantCreateStepNumber
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getIsMerchantCreationLoading = createSelector(
  [getRoot],
  ({ isMerchantCreationLoading }) => isMerchantCreationLoading
);

export const getFilters = createSelector([getRoot], ({ filters }) => filters);
export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);

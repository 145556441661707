import { ProgramDetails, ProgramItem } from 'entities/system';
import { ItemAttributes } from 'components/itemAttributeType';

export type FiltersParams = {
  programUrl: string;
  programName: string;
  processor: ItemAttributes[];
  corporates: ItemAttributes[];
};

export const defaultFilters: FiltersParams = {
  programUrl: '',
  programName: '',
  processor: [] as ItemAttributes[],
  corporates: [] as ItemAttributes[],
};

export const details: ProgramDetails = {
  title: '',
  fee: '',
  logoUrl: '',
  contactPhone: '',
  corporate: undefined,
  processor: undefined,
  product: undefined,
  manager: undefined,
};

// eslint-disable-next-line @typescript-eslint/ban-types
type LoadItemsOptions = {} & FiltersParams;

export const listRequestPayload = (options: LoadItemsOptions) => ({
  filters: {
    'program-url': options.programUrl || undefined,
    'program-name': options.programName || undefined,
    processorIds: options.processor?.map((value) => value.value),
    corporateIds: options.corporates?.map((value) => value.value),
  },
});

export const normalizeChargeback = (chargeback: any): ProgramItem => ({
  ...chargeback,
});

import { useTranslation } from 'react-i18next';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

type FilterWrapperProps = {
  filterFields: object;
  onApply: () => void;
  onClear: () => void;
  isDisabledApply?: boolean;
  isDisabledClear?: boolean;
  isFormAction?: boolean;
};

export const FilterObjectWrapper: React.FC<FilterWrapperProps> = ({
  filterFields,
  onApply,
  onClear,
  isDisabledApply = false,
  isDisabledClear = false,
  isFormAction = false,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={onApply} className="w-full h-full">
      <div className="h-full flex flex-col justify-between">
        <div className="grid grid-flow-row gap-y-3">
          {Object.values(filterFields).map((element, index) => (
            <div key={index}>{element}</div>
          ))}
        </div>

        <div className="mt-4 grid grid-cols-2 gap-x-4">
          {isFormAction ? (
            <LoadingButton
              size="small"
              disabled={isDisabledApply}
              type="submit"
              startIcon={<FilterListIcon />}
              variant="contained"
            >
              {t('filters.apply')}
            </LoadingButton>
          ) : (
            <Button
              size="small"
              disabled={isDisabledApply}
              onClick={onApply}
              startIcon={<FilterListIcon />}
              variant="contained"
            >
              {t('filters.apply')}
            </Button>
          )}
          <Button
            size="small"
            variant="outlined"
            onClick={onClear}
            disabled={isDisabledClear}
            startIcon={<FilterListOffIcon />}
          >
            {t('filters.clear')}
          </Button>
        </div>
      </div>
    </form>
  );
};

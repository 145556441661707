import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { dashboardResource } from './api';

export const loadCardInfoItems: any = createAsyncThunk(
  'dashboard/loadCardInfoItems',
  async () => {
    try {
      const response = await dashboardResource.getCardInfoItem();

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadUnmatchedTransactionItems: any = createAsyncThunk(
  'dashboard/loadUnmatchedTransactionItems',
  async () => {
    try {
      const response = await dashboardResource.listUnmatched({
        limit: 10,
        filters: {
          from: '2000-01-01',
          to: moment().format('YYYY-MM-DD'),
        },
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadTransactionStatusInfoItems: any = createAsyncThunk(
  'dashboard/loadTransactionStatusCardInfoItems',
  async (data: { dashboardChartPeriod: string }) => {
    try {
      const response =
        await dashboardResource.getTransactionStatusInfoItems(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadTransactionTypeInfoItems: any = createAsyncThunk(
  'dashboard/loadTransactionTypeCardInfoItems',
  async (data: { dashboardChartPeriod: string }) => {
    try {
      const response =
        await dashboardResource.getTransactionTypeInfoItems(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadAuthorizationStatusInfoItems: any = createAsyncThunk(
  'dashboard/loadAuthorizationStatusCardInfoItems',
  async (data: { dashboardChartPeriod: string }) => {
    try {
      const response =
        await dashboardResource.getAuthorizationStatusInfoItems(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadAuthorizationTypeInfoItems: any = createAsyncThunk(
  'dashboard/loadAuthorizationTypeCardInfoItems',
  async (data: { dashboardChartPeriod: string }) => {
    try {
      const response =
        await dashboardResource.getAuthorizationTypeInfoItems(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

import api from 'api';

const chargebacksList = async (data: any) => {
  return api.post('/chargebacks/list', data);
};

const merchantChargebacksList = async (data: any) => {
  return api.post('/merchant-portal/chargebacks/list', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/chargebacks/get', data);
};

const fetchMerchantDetails = async (data: { id: string }) => {
  return api.post('/merchant-portal/chargebacks/get', data);
};

export const chargebacksResource = {
  chargebacksList,
  fetchDetails,
  merchantChargebacksList,
  fetchMerchantDetails,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';

import { defaultFilters, FiltersParams, ServicesItem } from './utils';
import { fetchServiceMonitor } from './thunks';

export interface ServiceMonitorState {
  items: ServicesItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  filters: FiltersParams;
  appliedFilters: FiltersParams;
}

export const storeName = 'serviceMonitorStore';

const initialState: ServiceMonitorState = {
  items: [] as ServicesItem[],
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
};

const serviceMonitorSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.items = initialState.items;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
    },
    setDateRangeFilter(state, action) {
      state.filters = {
        ...state.filters,
        day: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchServiceMonitor.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: ServicesItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;

        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(fetchServiceMonitor.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(fetchServiceMonitor.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  setFilters,
} = serviceMonitorSlice.actions;

export default serviceMonitorSlice.reducer;

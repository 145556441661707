import React, { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getItemsLoadingComputed,
  getTotalSalesRollingPer7DayLoading,
} from '../selectors';
import { dayRunningInitRange } from './ComplianceAuditIndexComponent';
import { useSelector } from 'react-redux';
import { dateTimeZone } from 'domain/account/selectors';
import Divider from '@mui/joy/Divider';
import moment from 'moment-timezone';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { loadTotalSalesRollingPerDayItems } from '../thunks';
import {
  dateTimeFormatUtil,
  generationDateFormatUtil,
} from 'components/dateTimeFormatUtil';
import { Total7DaySalesChartComponent } from './charts-components/Total7DaySalesChartComponent';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { RangeType } from '../utils';

type TotalDailySalesProps = {
  control: any;
  watch: any;
  setValue: any;
  merchantRef: any;
};

export const Running7DaysRangeComponent: React.FC<TotalDailySalesProps> = ({
  control,
  watch,
  setValue,
  merchantRef,
}) => {
  const dispatch = useAppDispatch();
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const dateZone = useSelector(dateTimeZone);
  const totalSalesRollingPer7DayLoading = useAppSelector(
    getTotalSalesRollingPer7DayLoading
  );

  const dateFormatVal = (value: any) =>
    dateTimeFormatUtil(value, generationDateFormatUtil, dateZone);

  const running7RangeRef = useRef(dayRunningInitRange(dateZone, 7));
  const [isWeekSelected, setWeekSelected] = useState(false);

  const { running7Range } = watch();

  const click7Days = () => {
    setWeekSelected(false);
    setValue('running7Range', dayRunningInitRange(dateZone, 7));
  };

  const onDate7SaleRunningRangeApply = () => {
    dispatch(
      loadTotalSalesRollingPerDayItems({
        merchantId: merchantRef.current?.value,
        date: dateFormatVal(running7Range.to),
        rangeType: RangeType.SEVEN_DAYS,
      })
    );
    running7RangeRef.current = running7Range;
  };

  const handleWeeksChanges = (callback: any, dates: any) => {
    const start = moment(dates).tz(dateZone).startOf('week').toDate();
    const end = moment(dates).tz(dateZone).endOf('week').toDate();
    setDateFilter(callback, { from: start, to: end });
  };

  const handle7DaysChanges = (callback: any, dates: any) => {
    const start = moment(dates).tz(dateZone).subtract(6, 'day').toDate();

    if (dates) {
      setDateFilter(callback, { from: start, to: dates });
    }
  };

  const handleWeekDaysRange = (callback: any, dates: any) => {
    if (isWeekSelected) {
      handleWeeksChanges(callback, dates);
      return;
    }
    handle7DaysChanges(callback, dates);
  };

  return (
    <div className="col-span-8 4xl:col-span-3 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark ">
      <Total7DaySalesChartComponent dateRange={running7RangeRef.current} />
      <div className="m-3">
        <Divider />
      </div>
      {merchantRef.current && !itemsLoading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 sm:col-span-2">
            <DatePickerControl
              control={control}
              handleChange={handleWeekDaysRange}
              datePickerType={DatePickerType.STANDARD_DATE_SELECT}
              popoverWidth={520}
              name="running7Range"
              maxDate={moment().subtract(1, 'd').toDate()}
              title="Date Range"
              footer={
                <div className="grid justify-center justify-items-center m-4">
                  <ButtonGroup
                    variant="outlined"
                    size="small"
                    aria-label="outlined button group"
                  >
                    <Button style={{ width: '120px' }} onClick={click7Days}>
                      Last 7 days
                    </Button>
                    {!isWeekSelected ? (
                      <Button
                        style={{ width: '120px' }}
                        onClick={() => setWeekSelected(true)}
                      >
                        Week Range
                      </Button>
                    ) : (
                      <Button
                        style={{ width: '120px' }}
                        onClick={() => setWeekSelected(false)}
                      >
                        7 Days Range
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              }
            />
          </div>
          <div className="items-end justify-stretch justify-items-end mt-1 w-full col-span-3 sm:col-span-1">
            <LoadingButton
              size="small"
              variant="contained"
              style={{ width: '100%' }}
              loading={totalSalesRollingPer7DayLoading}
              onClick={onDate7SaleRunningRangeApply}
            >
              View Data
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';
import { ProgramDetails, ProgramItem } from 'entities/system';

import { defaultFilters, details, FiltersParams } from './utils';
import { fetchDetails, loadItems } from './thunks';
import { ItemSort } from '../../../entities';

export interface MerchantsState {
  items: ProgramItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  filters: FiltersParams;
  sort: ItemSort | null;
  appliedFilters: FiltersParams;
  details: ProgramDetails;
  itemNotFound: boolean;
  isDetailsLoading: boolean;
  itemId: string | null;
}

export const storeName = 'sysPrograms';

const initialState: MerchantsState = {
  items: [] as ProgramItem[],
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
  sort: null,
  details: details,
  itemNotFound: false,
  isDetailsLoading: false,
  itemId: null,
};

const merchantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setItemId(state, action: PayloadAction<string | null>) {
      state.itemId = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state) {
      state.appliedFilters = {
        ...state.filters,
      };
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.appliedFilters = initialState.appliedFilters;
      state.sort = initialState.sort;
      state.totalNumber = initialState.totalNumber;
    },

    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    setProcessorFilter(state, action) {
      state.filters = { ...state.filters, processor: action.payload };
    },
    setCorporatesFilter(state, action) {
      state.filters = { ...state.filters, corporates: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: ProgramItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;

        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });

    builder.addCase(fetchDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(fetchDetails.rejected, (state) => {
      state.itemNotFound = true;
      state.isDetailsLoading = false;
    });
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.itemNotFound = false;
      state.details = action.payload;
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setProcessorFilter,
  setCorporatesFilter,
  setApplyFilters,
  resetAllFilters,
  setSort,
  setFilters,
} = merchantsSlice.actions;

export default merchantsSlice.reducer;

import { forwardRef, MouseEvent, ReactNode, Ref } from 'react';
import classNames from 'classnames';
import { LoadingSwitch } from './Loading';

type ButtonVariant =
  | 'primary'
  | 'link'
  | 'secondary'
  | 'success-outline'
  | 'edit-outline'
  | 'cancel-outline'
  | 'second-cancel-outline'
  | 'text';

// eslint-disable-next-line react/display-name
export const Button = forwardRef(
  (
    {
      type = 'button',
      size = 'medium',
      children,
      widthClass = 'w-32',
      paddingClass = 'px-3',
      variant = 'primary',
      loading = false,
      disabled = false,
      className,
      onClick = () => {},
    }: {
      type?: 'submit' | 'reset' | 'button';
      size?: 'small' | 'medium' | 'medium-large' | 'large';
      children: ReactNode;
      onClick?: (event: MouseEvent<HTMLButtonElement>) => any;
      loading?: boolean;
      disabled?: boolean;
      variant?: ButtonVariant;
      widthClass?: string;
      paddingClass?: string;
      className?: string;
    },
    ref: Ref<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      type={type}
      disabled={disabled || loading}
      className={classNames(
        widthClass,
        className,
        paddingClass,
        'transition-all duration-200 rounded-sm focus:outline-none focus:shadow-outline disabled:cursor-not-allowed inline-flex items-center justify-center',
        {
          'bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-700':
            variant === 'primary',
          'text-gray-700 disabled:text-gray-500': variant === 'text',
          'text-blue-500 disabled:text-gray-500': variant === 'link',
          'button-text-green border button-border-green hover:button-border-green hover:text-white disabled:text-gray-500':
            variant === 'success-outline',
          'text-yellow-500 border border-yellow-500 hover:bg-yellow-500 hover:text-white disabled:text-gray-500':
            variant === 'edit-outline',
          'button-text-red border button-border-red hover:button-border-red hover:text-white disabled:text-gray-500':
            variant === 'cancel-outline',
          'text-ruby-500 border border-ruby-500 hover:border-ruby-500 hover:text-ruby-500 disabled:text-gray-500':
            variant === 'second-cancel-outline',
          'bg-gray-300 text-gray-700 disabled:text-white':
            variant === 'secondary',
          'h-6': size === 'small',
          'h-8': size === 'medium',
          'h-10': size === 'medium-large',
          'h-12': size === 'large',
        }
      )}
      onClick={onClick}
    >
      <span
        className={classNames('text-center font-semibold w-full', {
          'text-xs': size === 'medium' || size === 'small',
          'text-sm': size === 'medium-large',
          'text-lg': size === 'large',
        })}
      >
        <LoadingSwitch
          loading={loading}
          animationProps={{
            width: 16,
            height: 16,
            className: 'mx-auto',
          }}
        >
          <div className="flex items-center justify-center">{children}</div>
        </LoadingSwitch>
      </span>
    </button>
  )
);

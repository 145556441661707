import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';

import { defaultFilters, FiltersParams } from './utils';
import { loadItems, resolveNotification } from './thunks';
import { ItemSort } from 'entities';
import { Notifications } from './list/context';

export interface NotificationState {
  items: Notifications[];
  itemsLoading: boolean;
  solveLoading: boolean;
  currentNotificationId: any;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  itemNotFound: boolean;
  filters: FiltersParams;
  sort: ItemSort | null;
  appliedFilters: FiltersParams;
}

export const storeName = 'umNotificationList';

const initialState: NotificationState = {
  items: [] as Notifications[],
  itemsLoading: false,
  solveLoading: false,
  currentNotificationId: null,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  itemNotFound: false,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
};

const notificationSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setCurrentNotificationId(state, action: PayloadAction<any>) {
      state.currentNotificationId = action.payload;
    },
    resetCurrentNotificationId(state) {
      state.currentNotificationId = initialState.currentNotificationId;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.page = initialState.page;
      state.items = initialState.items;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.totalNumber = initialState.totalNumber;
      state.page = initialState.page;
      state.appliedFilters = initialState.appliedFilters;
    },

    setTypeFilter(state, action) {
      state.filters = {
        ...state.filters,
        type: action.payload,
      };
    },

    setSeverityFilter(state, action) {
      state.filters = {
        ...state.filters,
        severity: action.payload,
      };
    },

    setStatusFilter(state, action) {
      state.filters = {
        ...state.filters,
        status: action.payload,
      };
    },
    setCategoryFilter(state, action) {
      state.filters = {
        ...state.filters,
        category: action.payload,
      };
    },
    setCreatedOnFilter(state, action) {
      state.filters.createdOn = action.payload;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: Notifications[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;

        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );

    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });

    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
    builder.addCase(resolveNotification.fulfilled, (state) => {
      state.solveLoading = false;
    });

    builder.addCase(resolveNotification.pending, (state) => {
      state.solveLoading = true;
    });

    builder.addCase(resolveNotification.rejected, (state) => {
      state.solveLoading = false;
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  setStatusFilter,
  setSeverityFilter,
  setCategoryFilter,
  setTypeFilter,
  setCreatedOnFilter,
  setSort,
  resetAllItems,
  setCurrentNotificationId,
  resetCurrentNotificationId,
  setFilters,
} = notificationSlice.actions;

export default notificationSlice.reducer;

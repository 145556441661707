import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import React from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';
import { merchantStatusList, selectClassLabel } from '../../utils';
import { DateTimeComponent } from './DateTimeComponent';
import { TextArea } from 'components/inputs';
import moment from 'moment';

interface MerchantDetailsInputProps {
  register: any;
  control: any;
  errors: any;
}

export const MerchantToggleStatusComponent: React.FC<
  MerchantDetailsInputProps
> = ({ register, errors, control }) => {
  return (
    <div className="xxl:mx-56">
      <div className="grid gap-3 sm:grid-cols-2 items-center justify-items-start my-4 py-10 sm:m-4 sm:p-3">
        <div className="w-full">
          <FilterSelect
            name="status"
            placeholder="Select Status"
            labelClassName={selectClassLabel}
            label={<RequiredFieldComponent labelText="Select Status" />}
            control={{ ...control }}
            options={merchantStatusList}
            closeMenuOnSelect
            isClearable={false}
            isMulti={false}
          />
        </div>
        <div className="w-full">
          <DateTimeComponent
            control={control}
            name="fromDate"
            minDate={moment().toDate()}
            labelText="From Date"
            placeholder="Select From Date"
          />
        </div>
        <div className="w-full sm:col-span-2">
          <TextArea
            label={<RequiredFieldComponent labelText="Change Reason" />}
            className="h-40"
            error={errors.reasonChangeStatus}
            labelFontClassName="font-medium tracking-wide text-xs"
            inputProps={register('reasonChangeStatus', {
              required: true,
            })}
          />
        </div>
      </div>
    </div>
  );
};

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useSelector } from 'react-redux';
import {
  dateTimeFormat,
  dateTimeZone,
  isMerchantSelector,
} from 'domain/account/selectors';
import {
  generationDateFormatUtil,
  generationDateTimeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { getGenerationLoading } from '../../selectors';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import { GenerateWrapper } from 'components/filters/GenerateWrapper';
import { FilterSelect } from 'components/filters/FilterSelect';
import { merchantList, merchantPortalList } from 'api/merchants';
import { DatePickerDropDown } from 'components/DatePickerDropDown';
import { generationMonthYearFormat, monthYearFormat } from '../../../utils';
import { generateReport } from '../../thunks';

type ReportProps = {
  hasMerchant?: boolean;
  hasMultipleMerchant?: boolean;
  reportGenerationType: string;
  isYearDate?: boolean;
  maxDate?: Date;
};

export const ReportGenerate: React.FC<ReportProps> = ({
  reportGenerationType,
  hasMerchant = false,
  hasMultipleMerchant = false,
  isYearDate = false,
  maxDate = new Date(),
}) => {
  const dispatch = useAppDispatch();
  const isMerchantRole = useSelector(isMerchantSelector);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const dateFormatVal = (value: any) =>
    generationDateTimeFormatUtil(value, dateFormat, dateZone);

  const generationLoading = useAppSelector(getGenerationLoading);
  const [generationDate, setGenerationDate] = useState<string>('');
  const [reportGenerationDate, setReportGenerationDate] = useState<string>('');

  const { control, watch, setValue } = useForm({
    defaultValues: { merchant: null },
  });

  const merchant: any = watch('merchant');

  const cleanAllFields = () => {
    setGenerationDate('');
    if (hasMerchant) {
      setValue('merchant', null);
    }
  };

  const payload = () => {
    if (hasMerchant) {
      if (isMerchantRole) {
        return {
          merchantId: merchant.value,
          type: reportGenerationType,
          yearMonth: moment(generationDate, monthYearFormat).format(
            generationMonthYearFormat
          ),
        };
      }

      const merchantsValue = Array.isArray(merchant)
        ? merchant.map((e: any) => e.value)
        : [merchant.value];

      if (isYearDate) {
        return {
          data: {
            merchants: merchantsValue,
            type: reportGenerationType,
            yearMonth: moment(generationDate, monthYearFormat).format(
              generationMonthYearFormat
            ),
          },
        };
      }
      return {
        data: {
          merchants: merchantsValue,
          type: reportGenerationType,
          day: reportGenerationDate,
        },
      };
    }

    if (isYearDate) {
      return {
        data: {
          type: reportGenerationType,
          yearMonth: moment(generationDate, monthYearFormat).format(
            generationMonthYearFormat
          ),
        },
      };
    }

    return {
      data: {
        type: reportGenerationType,
        day: reportGenerationDate,
      },
    };
  };

  const createReport = async () => {
    dispatch(generateReport(payload()))
      .unwrap()
      .then((response: any) => {
        if (response.error) {
          throw new Error(response);
        }
        location.reload();
      })
      .catch(() => {
        cleanAllFields();
      });
  };

  const allFiltersSelected = useMemo(() => {
    return hasMerchant ? merchant && generationDate : generationDate;
  }, [hasMerchant, merchant, generationDate]);

  const handleDateChange = (date: Date) => {
    if (isYearDate) {
      setReportGenerationDate(
        moment(date).add(1, 'days').format(monthYearFormat)
      );
      setGenerationDate(moment(date).add(1, 'days').format(monthYearFormat)!);
      return;
    }

    setReportGenerationDate(
      moment(date).add(1, 'days').format(generationDateFormatUtil)
    );
    setGenerationDate(dateFormatVal(date)!);
  };

  const dateClean = () => {
    setGenerationDate('');
    setReportGenerationDate('');
  };

  //todo add changes
  const isCleanAllFieldsDisabled = !merchant && !generationDate;

  return (
    <GenerateWrapper
      onApply={createReport}
      onClear={cleanAllFields}
      isLoading={generationLoading}
      isDisabledApply={!allFiltersSelected}
      isDisabledClear={isCleanAllFieldsDisabled}
    >
      <>
        {hasMerchant && (
          <FilterSelect
            label="Select Merchant"
            name="merchant"
            loadOptions={!isMerchantRole ? merchantList : merchantPortalList}
            control={control}
            isMulti={hasMultipleMerchant}
            closeMenuOnSelect
          />
        )}
        <DatePickerDropDown
          title={'Select Date'}
          label={'Select Date'}
          popoverWidth={263}
          generationDate={generationDate}
          onClearFilter={dateClean}
          maxDate={maxDate}
          handleDateChange={handleDateChange}
          isMonthYearPicker={isYearDate}
        />
      </>
    </GenerateWrapper>
  );
};

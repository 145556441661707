import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { ChargebackItem } from 'entities/dispute-resolution';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';

export const useCharbacksColumns = () => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  const dateTimeFormatUtils = (value: string) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'cardType',
        value: (item: ChargebackItem) => <CellLight>{item.cardType}</CellLight>,
        label: 'Card Type',
        width: 80,
        isSorted: false,
        sortField: 'CHARGEBACK_CARD_TYPE',
      },
      {
        id: 'cardNumber',
        value: (item: ChargebackItem) => (
          <CellLight>{item.cardNumber}</CellLight>
        ),
        label: 'Card Number',
        width: 150,
        isSorted: false,
        sortField: 'CHARGEBACK_CARD_NUMBER',
      },
      {
        id: 'authorizationCode',
        value: (item: ChargebackItem) => (
          <CellLight>{item.authorizationCode}</CellLight>
        ),
        label: 'Authorization Code',
        width: 135,
        isSorted: true,
        sortField: 'CHARGEBACK_AUTHORIZATION_CODE',
      },
      {
        id: 'refNumber',
        value: (item: ChargebackItem) => (
          <CellLight>{item.acquireReferenceNumber}</CellLight>
        ),
        label: 'Acquirer Reference Number',
        width: 190,
        isSorted: true,
        sortField: 'CHARGEBACK_ACQUIRER_REFERENCE_NUMBER',
      },
      {
        id: 'adjustmentDate',
        value: (item: ChargebackItem) => (
          <CellLight>
            {item.adjustmentDate && timeFormat(item.adjustmentDate)}
          </CellLight>
        ),
        label: 'Adjustment Date',
        width: 200,
        isSorted: true,
        sortField: 'CHARGEBACK_ADJUSTMENT_DATE',
      },
      {
        id: 'transactionDate',
        value: (item: ChargebackItem) => (
          <CellLight>
            {item.transactionDate && timeFormat(item.transactionDate)}
          </CellLight>
        ),
        label: 'Transaction Date',
        width: 200,
        isSorted: true,
        sortField: 'CHARGEBACK_TRANSACTION_DATE',
      },
      {
        id: 'dueDate',
        value: (item: ChargebackItem) => (
          <CellLight>
            {item.dueDate && dateTimeFormatUtils(item.dueDate)}
          </CellLight>
        ),
        label: 'Due Date',
        width: 120,
        isSorted: true,
        sortField: 'CHARGEBACK_DUE_DATE',
      },
      {
        id: 'disputeAmount',
        value: (item: ChargebackItem) => (
          <CellLight>{item.disputeAmount}</CellLight>
        ),
        label: 'Dispute Amount',
        width: 115,
        isSorted: true,
        sortField: 'CHARGEBACK_DISPUTE_AMOUNT',
      },
      {
        id: 'currencyCode',
        value: (item: ChargebackItem) => (
          <CellLight>{item.chargebackCurrencyCode}</CellLight>
        ),
        width: 115,
        label: 'Currency Code',
        isSorted: false,
        sortField: 'CHARGEBACK_CURRENCY_CODE',
      },
      {
        id: 'status',
        value: (item: ChargebackItem) => (
          <CellLight>{item.currentStatus}</CellLight>
        ),
        width: 115,
        label: 'Current Status',
        isSorted: false,
        sortField: 'CHARGEBACK_CURRENT_STATUS',
      },
    ],
    []
  );
};

import axios from 'axios';

import { ACCESS_TOKEN_HEADER_KEY } from 'constants/accessTokenHeaderKey';
import getBaseUrl from 'utils/getBaseUrl';
import { headers } from 'common/auth';

const api = axios.create({
  headers,
  baseURL: getBaseUrl(),
});

api.interceptors.request.use((config) => {
  const authCookie = sessionStorage.getItem(ACCESS_TOKEN_HEADER_KEY);

  if (authCookie && config?.headers) {
    config.headers[ACCESS_TOKEN_HEADER_KEY] = authCookie;
  }

  return config;
});

export default api;

import { useMemo } from 'react';

import { CellLight, ColumnProps } from 'components/table/Table';
import { Role } from 'entities/user-management/role-list';

export const useColumns = () => {
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'name',
        value: (item: Role) => <CellLight>{item.name}</CellLight>,
        label: 'Name',
        width: 250,
        isSorted: true,
        sortField: 'ROLE_NAME',
      },
      {
        id: 'description',
        value: (item: Role) => <CellLight>{item.description}</CellLight>,
        label: 'Description',
        width: 550,
        isSorted: true,
        sortField: 'ROLE_DESCRIPTION',
      },
    ],
    []
  );
};

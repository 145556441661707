import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../merchantsSlice';
import { getAppliedFilters, getFilters } from '../../selectors';
import { defaultFilters, merchantStatusItems } from '../../utils';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { useForm } from 'react-hook-form';
import { FiltersParams } from 'entities/system';
import { Input } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { FilterSelect } from 'components/filters/FilterSelect';
import { useTranslation } from 'react-i18next';
import { programList } from 'api/programs';
import { processorList } from 'api/processors';
import { useHistory } from 'react-router-dom';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { useFilterEffect } from 'utils/useFilterEffect';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history: any = useHistory();

  const permissions: any = useAppSelector(getProfilePermission);
  const filters: any = useAppSelector(getFilters);
  const appliedFilters: FiltersParams = useAppSelector(getAppliedFilters);
  const hasProgramsPermission = permissions.includes(
    UserPermissions.PROGRAMS_CORPORATE
  );
  const hasProcessorPermission = permissions.includes(
    UserPermissions.PROCESSOR_LIST
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset: resetFilters,
    watch,
  } = useForm<any>({
    defaultValues: appliedFilters,
  });

  const allValues = watch();
  const previousValues = useRef<FiltersParams>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const reset = () => {
    dispatch(resetAllFilters());
    resetFilters(defaultFilters);
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  const merchantsHasFilter = [
    filters.status.length ||
      filters.merchantName.length ||
      filters.programsIds.length ||
      filters.processor.length,
  ].some((value) => !!value);

  useEffect(() => {
    if (history.location.state?.status) {
      setValue('status', history.location.state.status);
      dispatch(setApplyFilters({ status: history.location.state.status }));
    }
  }, [history.location.state, dispatch, setValue]);

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('merchants.list.merchantName')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('merchantName'),
        }}
      />
    ),
    1: hasProgramsPermission ? (
      <FilterSelect
        name="programsIds"
        label={t('merchants.list.programs')}
        loadOptions={programList}
        control={control}
        isClearable={false}
      />
    ) : null,
    2: hasProcessorPermission ? (
      <FilterSelect
        name="processor"
        label={t('merchants.list.processor')}
        loadOptions={processorList}
        control={control}
        isClearable={false}
      />
    ) : null,
    3: (
      <FilterSelect
        name="status"
        label={t('merchants.list.status')}
        options={merchantStatusItems}
        control={control}
        isClearable={false}
      />
    ),
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!merchantsHasFilter}
      isDisabledApply={!merchantsHasFilter}
    />
  );
};

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container as SecretKeyList } from './secret-key-management/list/Container';
import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux';
import { resetAllItems } from './secret-key-management/secretKeyManagementSlice';

export const SecretKeyRout = () => {
  const match = useRouteMatch();

  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <SecretKeyList />
      </Route>
    </Switch>
  );
};

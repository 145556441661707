/* eslint-disable @typescript-eslint/ban-types */
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { ItemAttributes } from 'components/itemAttributeType';
import { User } from 'entities/user-management';

export type ItemFilters = {
  rolesId: ItemAttributes[];
  status: any;
  permissionIds: ItemAttributes[];
};

export type MiscFilters = {
  status: any;
  rolesId: ItemAttributes[];
  permissionIds: ItemAttributes[];
};

export type Filters = ItemFilters & MiscFilters;

export const itemFilters: ItemFilters = {
  status: '',
  rolesId: [],
  permissionIds: [],
};

export const miscFilters: MiscFilters = {
  status: '',
  rolesId: [],
  permissionIds: [],
};

export const details = {
  id: '',
  name: '',
  permissions: [],
  tabs: [],
  description: '',
  createdTime: '',
};

export const roleSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      name: yup.string().required(t('validations.required')),
      description: yup.string().required(t('validations.required')),
      tabs: yup.array().required(t('validations.required')),
      permissions: yup.array(),
    })
    .required();
};

export const getItemFilters = (filters: Filters): ItemFilters =>
  Object.keys(itemFilters).reduce(
    (result, key) => ({
      ...result,
      [key]: filters[key as keyof Filters],
    }),
    {} as ItemFilters
  );

export const defaultFilters: Filters = {
  ...itemFilters,
  ...miscFilters,
};

type LoadItemsOptions = {} & Filters;

export const normalizeChargeback = (chargeback: any): User => ({
  ...chargeback,
});

interface Vlues {
  readonly label: string;
  readonly value: string;
}

export interface Items {
  readonly label: string;
  readonly options: Vlues[];
}

export const colourOptions = [
  { value: 'ocean', label: 'Ocean' },
  { value: 'blue', label: 'Blue' },
];

export const mappedUiTabs = (sidebar: any) => {
  return sidebar.map(({ name: groupName, items }: any) => {
    return {
      label: groupName,
      options: items?.map(({ name }: any) => ({
        label: groupName,
        value: name,
      })),
    };
  });
};

export const mappedUiTabsDetails = (tabs: any) => {
  return tabs.reduce((acc: any, { name: groupName, items }: any): any => {
    const mapItems = items?.map(({ name }: any) => ({
      label: groupName,
      value: name,
    }));
    return [...acc, ...mapItems];
  }, []);
};

export const mappedUiTabsResult = (props: any[]) => {
  const reduceProps = props.reduce((acc, { label, value }) => {
    if (!acc[label]) {
      const newValue = { [label]: [{ name: value }] };
      return { ...acc, ...newValue };
    } else {
      acc[label].push({ name: value });
      return acc;
    }
  }, {});
  const keys = Object.keys(reduceProps);
  return keys.map((key) => ({
    name: key,
    items: reduceProps[key],
  }));
};

export const mappedDefaultOptions = (tabs: any) =>
  tabs.reduce((acc: any, { options }: any): any => {
    return [...acc, ...options];
  }, []);

export const listRequestPayload = (options: LoadItemsOptions) => ({
  status: options.status || undefined,
  rolesId: options.rolesId?.map(({ value }) => value) || undefined,
});

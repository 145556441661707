export const IconLocation = () => (
  <img
    style={{
      width: '30px',
      height: '30px',
    }}
    src={require('../img/location.png')}
    alt={''}
  />
);

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReportTypeComponent } from './types/ReportTypeComponent';
import { ReportsViewRout } from './ReportsViewRout';

export const Reports = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:reportType/:reportName`}>
        <ReportsViewRout />
      </Route>
      <Route path={`${match.path}/:reportType`}>
        <ReportTypeComponent />
      </Route>
    </Switch>
  );
};

import { useAppDispatch } from 'hooks/redux';

import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from 'react-router-dom';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { Input } from 'components/inputs';
import { processorList } from 'api/processors';
import { FilterSelect } from 'components/filters/FilterSelect';
import { selectClassLabel } from '../../merchants/utils';
import { corporateList } from 'api/corporates';
import { LoadingButton } from '@mui/lab';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { createPrograms } from '../thunks';
import { PageHeader } from 'components/PageHeader';

export const ProgramCreateComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      fee: 0.0,
      corporateId: null,
      processorId: null,
      contactPhone: '',
    },
  });
  const save = (data: any) => {
    dispatch(
      createPrograms({
        title: data.title,
        fee: data.fee,
        contactPhone: data.contactPhone,
        corporateId: data.corporateId.value,
        processorId: data.processorId.value,
      })
    );
    history.goBack();
  };

  const { title, fee, corporateId, processorId, contactPhone } = watch();

  const isDisabled =
    !title.trim().length ||
    !fee ||
    !contactPhone.trim().length ||
    !corporateId ||
    !processorId;

  const positiveValidation = (value: number): boolean => {
    return value >= 0;
  };

  const programValidations = {
    positiveNumber: positiveValidation,
  };

  return (
    <div className="relative flex flex-col h-auto bg-gray-200">
      <div className="absolute w-full">
        <PageHeader title="Create Program" />
      </div>
      <div className="h-full p-3 pt-19">
        {/*form*/}
        <form onSubmit={handleSubmit(save)} className="w-full">
          <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
            <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
              <div>
                <Button
                  onClick={() => history.push('/system/programs')}
                  startIcon={<KeyboardBackspaceIcon />}
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 ">
                  <div className="flex items-center justify-center justify-items-center w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-americano-300 box-shadow-americano">
                    <div>
                      <DisplaySettingsIcon
                        fontSize="large"
                        sx={{ color: '#fff' }}
                      />
                    </div>
                    <div className="text-xs text-white ml-2">
                      Program Details
                    </div>
                  </div>
                </div>
                <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                  <div className="w-full">
                    <Input
                      label={
                        <RequiredFieldComponent labelText="Program Name" />
                      }
                      placeholder="Program Name"
                      className="h-8"
                      labelFontClassName="font-medium tracking-wide text-xxs"
                      labelColorClassName="text-gray-700"
                      error={errors.title}
                      inputProps={register('title', {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      label={<RequiredFieldComponent labelText="Program Fee" />}
                      placeholder="Program Fee"
                      className="h-8"
                      type="number"
                      min={0.0}
                      step={0.01}
                      labelFontClassName="font-medium tracking-wide text-xxs"
                      labelColorClassName="text-gray-700"
                      error={errors.fee}
                      inputProps={register('fee', {
                        required: true,
                        validate: {
                          positiveNumber: programValidations.positiveNumber,
                        },
                      })}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      label={
                        <RequiredFieldComponent labelText="Contact Phone" />
                      }
                      placeholder="Contact Phone"
                      className="h-8"
                      labelFontClassName="font-medium tracking-wide text-xxs"
                      labelColorClassName="text-gray-700 "
                      error={errors.contactPhone}
                      inputProps={register('contactPhone', {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="w-full">
                    <FilterSelect
                      name="processorId"
                      placeholder="Select Processor"
                      label={<RequiredFieldComponent labelText="Processor" />}
                      loadOptions={processorList}
                      isMulti={false}
                      control={control}
                      closeMenuOnSelect={true}
                      labelClassName={selectClassLabel}
                    />
                  </div>
                  <div className="w-full">
                    <FilterSelect
                      name="corporateId"
                      placeholder="Select Corporate"
                      label={<RequiredFieldComponent labelText="Corporate" />}
                      loadOptions={corporateList}
                      isMulti={false}
                      control={control}
                      closeMenuOnSelect={true}
                      labelClassName={selectClassLabel}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="flex items-center justify-center justify-items-stretch">
                  <div className="mx-2 sm:w-3/12">
                    <LoadingButton
                      loading={isSubmitting}
                      loadingPosition="start"
                      disabled={isDisabled}
                      type="submit"
                      className="w-full"
                      variant="contained"
                      color="success"
                      startIcon={<BookmarkAddedIcon />}
                    >
                      <span className="text-xs sm:text-sm">Save Changes</span>
                    </LoadingButton>
                  </div>
                  <div className="mx-2 sm:w-3/12">
                    <Button
                      onClick={() => history.goBack()}
                      className="w-full"
                      variant="contained"
                      color="error"
                      startIcon={<CancelPresentationIcon />}
                    >
                      <span className="text-xs sm:text-sm">Cancel</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

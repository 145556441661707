import { useTranslation } from 'react-i18next';
import { Input } from 'components/inputs';
import { onKeyPressDisallowSpecialCharacters } from 'components/inputs/utils';
import { UseFormReturn } from 'react-hook-form';
import React from 'react';

interface FilterAuthorizationCodeProps {
  register: UseFormReturn['register'];
}

export const FilterAuthorizationCode: React.FC<
  FilterAuthorizationCodeProps
> = ({ register }) => {
  const { t } = useTranslation();

  return (
    <Input
      label={t(
        'transactionsManagement.transactions.list.filters.authorizationCode'
      )}
      labelFontClassName="text-xs font-medium"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressDisallowSpecialCharacters}
      inputProps={register('authorizationCode')}
    />
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { emailNotificationTypesList, userResource } from './api';
import { RootState } from 'store/store';
import {
  listRequestPayload,
  mappedUserDetails,
  normalizeLoggingChargeback,
} from './utils';
import { getPagination, getRoot } from './selectors';
import { PaginationProps } from 'components/table/TableFooter';
import { LoadItems, SearchLoggingCriteria } from 'entities';

export const loadItems: any = createAsyncThunk(
  'umUserList/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { page, rowsPerPage, appliedFilters, sort } = getRoot(
        getState() as RootState
      );

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await userResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
//todo check needed data info
export const loadLoggingItems: any = createAsyncThunk(
  'umUserList/loadLoggingItems',
  async (
    data: {
      adminId: string;
      search: SearchLoggingCriteria;
    },
    thunkApi
  ) => {
    try {
      const { getState } = thunkApi;
      const { loggingPage, loggingRowsPerPage, loggingDetails, sort } = getRoot(
        getState() as RootState
      );

      const params = {
        adminId: data.adminId,
        limit: loggingRowsPerPage,
        offset: (loggingPage - 1) * loggingRowsPerPage,
        sort: sort,
        search: data.search,
      };

      const response = await userResource.loggingList(params);

      const result = (response.data || []).map(normalizeLoggingChargeback);

      return params.offset > 0 ? [...loggingDetails, ...result] : result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const reloadItems: any = createAsyncThunk(
  `${'umRolesList'}/reloadItems`,
  async (_: void, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;
      const { page, rowsPerPage }: PaginationProps = getPagination(
        getState() as RootState
      );

      dispatch(
        loadItems({
          offset: 0,
          limit: page * rowsPerPage,
        })
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'umUserList/fetchDetails',
  async (data: { adminId: string }) => {
    try {
      const response = await userResource.fetchDetails(data);
      return mappedUserDetails(response);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateUser: any = createAsyncThunk(
  'umUserList/updateUser',
  async (data, thunkApi) => {
    try {
      const response = await userResource.updateUser(data);

      toast.success('User updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      thunkApi.dispatch(reloadItems());

      return mappedUserDetails(response);
    } catch (e) {
      toast.error('User could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

//todo remove
export const disableUser: any = createAsyncThunk(
  'umUserList/disableUser',
  async (data: { adminId: string }, thunkApi) => {
    try {
      await userResource.disableUser(data);

      toast.success('User status updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      thunkApi.dispatch(reloadItems());

      return data;
    } catch (e) {
      toast.error('User status could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

//todo remove
export const activateUser: any = createAsyncThunk(
  'umUserList/activateUser',
  async (data: { adminId: string }, thunkApi) => {
    try {
      await userResource.activateUser(data);

      toast.success('User status updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      thunkApi.dispatch(reloadItems());

      return data;
    } catch (e) {
      toast.error('User status could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const resendInvitation: any = createAsyncThunk(
  'umUserList/resendInvitation',
  async (data: { adminId: string }) => {
    try {
      await userResource.resendInvitation(data);
      toast.info('User invitation sent', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return data;
    } catch (e) {
      toast.error('User invitation could not be sent', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const passwordResetRequest: any = createAsyncThunk(
  'umUserList/passwordResetRequest',
  async (data: { id: string }) => {
    try {
      await userResource.passwordResetRequest(data);
      toast.info('User Password Reset Request Sent', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return data;
    } catch (e) {
      toast.error('User Password Reset Request could not be sent', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const createUser: any = createAsyncThunk(
  'umUserList/createUser',
  async (data: any, thunkApi) => {
    try {
      await userResource.createUser(data);

      toast.success(`User ${data.email} created`, {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      thunkApi.dispatch(reloadItems());

      return data;
    } catch (e: any) {
      const message =
        e.error.code === 'EMAIL_USED'
          ? `Email ${data.email} already exists`
          : 'User creation error';

      toast.error(message, {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return Promise.reject(e);
    }
  }
);

export const notificationTypeList = async () => {
  try {
    const { data } = await emailNotificationTypesList();

    return data;
  } catch (e) {
    return [];
  }
};

import Card from '@mui/material/Card';

import GenerateDrawer from 'components/filters/GenerateDrawer';
import { FilterDrawer } from 'components/filters/FilterDrawer';
import { ItemSort } from 'entities';

import { default as TableFooter, PaginationProps } from './TableFooter';
import { ColumnProps, Table } from './Table';
import React from 'react';

type TableCardProps = {
  items: any;
  columns: ColumnProps[];
  loading?: boolean;
  onHover?: boolean;
  totalNumber?: any;
  tableRef?: React.LegacyRef<HTMLDivElement> | undefined;
  onRowClick?: (row: any) => any;
  setSort?: (sort: ItemSort) => void;
  pagination?: PaginationProps;
  setPage?: any;
  setRowsPerPage?: any;
  filters?: React.ReactNode;
  generate?: React.ReactNode;
  create?: React.ReactNode;
  backButton?: React.ReactNode;
  filterApply?: () => void;
};

const TableCard: React.FC<TableCardProps> = ({
  items,
  columns,
  loading = false,
  totalNumber = null,
  tableRef = null,
  onRowClick,
  setSort,
  pagination,
  setPage,
  setRowsPerPage,
  filters,
  generate,
  create,
  onHover = true,
  backButton,
  filterApply,
}) => (
  <Card className="h-full w-full flex flex-col">
    <div className="flex items-center justify-end">
      {backButton && backButton}
      {create && create}
      {generate && <GenerateDrawer>{generate}</GenerateDrawer>}
      {filters && <FilterDrawer apply={filterApply}>{filters}</FilterDrawer>}
    </div>
    <div
      ref={tableRef}
      className="h-full max-h-full overflow-x-auto overflow-y-auto custom-scrollbar"
    >
      <Table
        loading={loading}
        columns={columns}
        items={items}
        setSort={setSort}
        onRowClick={onRowClick}
        onHover={onHover}
      />
    </div>
    {pagination && (
      <TableFooter
        pagination={pagination}
        totalNumber={totalNumber}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    )}
  </Card>
);

export default TableCard;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';
import { Chargeback, ChargebackItem } from 'entities/dispute-resolution';

import { chargebackDetails } from './utils';
import { fetchDetails, loadItems } from './thunks';
import { ItemSort } from '../../../entities';

export interface ChargebacksState {
  items: ChargebackItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  details: Chargeback;
  isDetailsLoading: boolean;
  chargebackId: string | null;
  chargebackNotFound: boolean;
  sort: ItemSort | null;
}

export const storeName = 'drChargebacks';

const initialState: ChargebacksState = {
  items: [] as ChargebackItem[],
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  details: chargebackDetails,
  isDetailsLoading: false,
  chargebackNotFound: false,
  chargebackId: null as string | null,
  sort: null,
};

const chargebacksSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
    },
    setChargbackId(state, action) {
      state.chargebackId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.chargebackNotFound = false;
      state.details = action.payload;
    });
    builder.addCase(fetchDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(fetchDetails.rejected, (state) => {
      state.isDetailsLoading = false;
      state.chargebackNotFound = true;
    });

    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: ChargebackItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  resetAllItems,
  setPage,
  setRowsPerPage,
  setChargbackId,
  setSort,
} = chargebacksSlice.actions;

export default chargebacksSlice.reducer;

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container as ListContainer } from './list/Container';
import { ApiLogsDetailsComponent } from './components/ApiLogsDetailsComponent';
import { useEffect } from 'react';
import { resetAllItems } from './apiSlice';
import { useAppDispatch } from 'hooks/redux';

export const ApiLogs = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/details/:id`}>
        <ApiLogsDetailsComponent />
      </Route>
      <Route path={match.path}>
        <ListContainer />
      </Route>
    </Switch>
  );
};

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

export const HistoryBackButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Button onClick={handleClick} startIcon={<KeyboardBackspaceIcon />}>
      {t('buttons.back')}
    </Button>
  );
};

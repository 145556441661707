import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { TransactionDetailsPreview } from './transactions/component/TransactionDetailsPreview';
import { Transactions } from './transactions/Transactions';
import { resetAll } from './transactions/transactionSlice';

export const TransactionManagementRout = () => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch();

  useEffect(
    () => () => {
      dispatch(resetAll());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <TransactionDetailsPreview />
      </Route>
      <Route path={`${match.path}`}>
        <Transactions />
      </Route>
    </Switch>
  );
};

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect, useRef } from 'react';
import { Base64 } from 'js-base64';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch } from 'hooks/redux';
import LayoutList from 'layouts/list/LayoutList';
import { FiltersParams, MerchantItem } from 'entities/system';
import { ItemSort } from 'entities';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../merchantsSlice';
import { Filters } from './filters/Filters';
import { useColumns } from './useColumns';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { useSelector } from 'react-redux';

export const MerchantsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const items: MerchantItem[] = useSelector(getItems);
  const pagination: PaginationProps = useSelector(getPagination);
  const filters: FiltersParams = useSelector(getFilters);
  const totalNumber: number = useSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useSelector(getItemsLoadingComputed);
  const permissions: any = useSelector(getProfilePermission);

  const columns: ColumnProps[] = useColumns();

  const tableRef = useRef<HTMLDivElement>(null);

  const isOnRowClick = permissions.includes(UserPermissions.MERCHANT_GET);
  const hasPermissionCreate = permissions.includes(
    UserPermissions.MERCHANT_CREATE
  );

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [itemsLoadingComputed]);

  const handleRowClick = (item: MerchantItem) => {
    history.push(`/system/merchants/details/${Base64.encodeURI(item.id)}`);
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isFilterSelected = [
    filters.status.length ||
      filters.merchantName.length ||
      filters.programsIds.length ||
      filters.processor.length,
  ].some((value) => !!value);

  const filterAction = isFilterSelected
    ? () => dispatch(setApplyFilters({}))
    : undefined;

  return (
    <LayoutList title={t('merchants.breadcrumb')}>
      <TableCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        totalNumber={totalNumber}
        items={items}
        setSort={setCurrentSort}
        onRowClick={isOnRowClick ? handleRowClick : undefined}
        onHover={isOnRowClick}
        pagination={pagination}
        setPage={setPage}
        create={
          hasPermissionCreate ? (
            <div className="px-3 py-2 flex-1">
              <Button
                onClick={() => history.push('/system/merchants/create')}
                className="ml-2"
                variant="contained"
                startIcon={<AddBusinessIcon />}
              >
                <span className="text-xxs sm:text-sm">
                  {t('merchants.createMerchant')}
                </span>
              </Button>
            </div>
          ) : null
        }
        setRowsPerPage={setRowsPerPage}
        filters={<Filters />}
        filterApply={filterAction}
      />
    </LayoutList>
  );
};

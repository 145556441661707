import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ApiLogs } from './api/ApiLogs';
import { Container as ServiceMonitor } from './service-monitoring/list/Container';

export const Logs = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/logs-api`}>
        <ApiLogs />
      </Route>
      <Route path={`${match.path}/service-monitoring`}>
        <ServiceMonitor />
      </Route>
    </Switch>
  );
};

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import React from 'react';

type CancelButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
};

export const CancelButton: React.FC<CancelButtonProps> = ({
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      disabled={disabled}
      className="w-full max-w-[220px]"
      variant="contained"
      color="error"
      startIcon={<CancelPresentationIcon />}
      onClick={handleClick}
    >
      <span className="text-xs sm:text-sm">{t('buttons.cancel')}</span>
    </Button>
  );
};

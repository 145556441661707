import { useTranslation } from 'react-i18next';
import React from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';
import { logTypesItems } from '../utils';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';

type FiltersProps = {
  control: any;
  reset: () => void;
  isDisabledClean: boolean;
  onApply: any;
};

export const Filters: React.FC<FiltersProps> = ({
  control,
  reset,
  isDisabledClean,
  onApply,
}) => {
  const { t } = useTranslation();

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const filterComponentsList = {
    0: (
      <FilterSelect
        label={t('userManagement.filters.logType')}
        name="logTypes"
        control={control}
        options={logTypesItems}
      />
    ),
    1: (
      <DatePickerControl
        control={control}
        label={t('userManagement.filters.logTimeRange')}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="timeRage"
        maxDate={new Date()}
        footer={<></>}
        title={t('userManagement.filters.logTimeRange')}
      />
    ),
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={onApply}
      onClear={reset}
      isDisabledClear={isDisabledClean}
    />
  );
};

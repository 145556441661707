import { Input } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import React from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';
import {
  reportsTypeList,
  selectClassLabel,
  storageTypeList,
} from '../../utils';

interface AccountHolderDetailsInputProps {
  register: any;
  control: any;
  errors: any;
}

export const ReportConfigurationDetailsInputComponent: React.FC<
  AccountHolderDetailsInputProps
> = ({ register, errors, control }) => {
  return (
    <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:m-4 sm:p-3">
      <div className="w-full">
        <FilterSelect
          name="reports"
          labelClassName={selectClassLabel}
          label={
            <RequiredFieldComponent isRequired={false} labelText="Reports" />
          }
          control={{ ...control }}
          options={reportsTypeList}
          isClearable={true}
          isMulti={true}
        />
      </div>
      <div className="w-full">
        <FilterSelect
          name="storageTypes"
          labelClassName={selectClassLabel}
          label={
            <RequiredFieldComponent
              isRequired={false}
              labelText="Storage Types"
            />
          }
          control={{ ...control }}
          options={storageTypeList}
          isClearable={true}
          isMulti={true}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={false}
              labelText="Folder Name"
            />
          }
          placeholder="Folder Name"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.phone}
          inputProps={{
            ...register('folderName', {
              required: false,
            }),
          }}
        />
      </div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

import { setUserDateTimeDetails } from './thunks';

export interface SettingsState {
  isSettingsProcessed: boolean;
}

export const storeName = 'settings';

const initialState: SettingsState = {
  isSettingsProcessed: false,
};

const settingsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUserDateTimeDetails.fulfilled, (state) => {
      state.isSettingsProcessed = false;
    });
    builder.addCase(setUserDateTimeDetails.pending, (state) => {
      state.isSettingsProcessed = true;
    });
    builder.addCase(setUserDateTimeDetails.rejected, (state) => {
      state.isSettingsProcessed = false;
    });
  },
});

export const {} = settingsSlice.actions;
export default settingsSlice.reducer;

import React, { ReactNode } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { IconCalendar } from 'components/icons/IconCalendar';
import { months, years } from 'constants/date';
import {
  DropdownDateTime,
  InputDropdownComponent,
} from './dropdown-component/DropdownDateTime';

interface DatePickerWithRangeProps {
  className?: string;
  handleDateChange: (data: any) => void;
  maxDate?: Date;
  minDate?: Date;
  isMonthYearPicker?: boolean;
  startDate?: Date;
  endDate?: any;
}

interface DatePickerWithNoRangeProps {
  className?: string;
  handleDateChange: (data: any) => void;
  maxDate?: Date;
  minDate?: Date;
  isMonthYearPicker?: boolean;
  isBirthdatePicker?: boolean;
  selected?: string;
}

const DatePickerRange: React.FC<DatePickerWithRangeProps> = ({
  className,
  handleDateChange,
  maxDate,
  minDate,
  isMonthYearPicker = true,
  startDate,
  endDate,
}) => {
  return (
    <>
      {isMonthYearPicker ? (
        <DatePicker
          className={className}
          maxDate={maxDate}
          onChange={handleDateChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          selectsRange
          inline
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <DatePicker
          className={className}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDateChange}
          dateFormat="yyyy/MM/dd"
          inline
          selectsRange
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};

const BirthdateDatepickerHeader = ({
  date,
  maxDate,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className="w-full px-[10px] pb-[12px] flex justify-center items-center">
    <button
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
      className="react-datepicker__navigation react-datepicker__navigation--previous"
      style={{ top: '-10px' }}
    >
      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
    </button>
    <select
      value={moment(date).year()}
      onChange={({ target: { value } }) => changeYear(value)}
      className="mr-2"
    >
      {years
        .filter((year) => !maxDate || year <= maxDate.getFullYear())
        .map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
    </select>

    <select
      value={months[moment(date).month()]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <button
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
      className="react-datepicker__navigation react-datepicker__navigation--next"
      style={{ top: '-10px' }}
    >
      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
    </button>
  </div>
);

const DatePickerNoRange: React.FC<DatePickerWithNoRangeProps> = ({
  className,
  handleDateChange,
  maxDate,
  minDate,
  isMonthYearPicker = true,
  isBirthdatePicker = false,
  selected,
}) => (
  <>
    {isMonthYearPicker ? (
      <DatePicker
        selected={selected ? new Date(selected) : null}
        className={className}
        maxDate={maxDate}
        onChange={handleDateChange}
        dateFormat="MMMM/yyyy"
        showMonthYearPicker
        inline
      />
    ) : (
      <DatePicker
        {...(isBirthdatePicker && {
          renderCustomHeader: (props) => (
            <BirthdateDatepickerHeader {...props} maxDate={maxDate} />
          ),
        })}
        selected={selected ? new Date(selected) : null}
        className={className}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        dateFormat="yyyy/MM/dd"
        inline
        disabled
      />
    )}
  </>
);

interface DatePickerDropDownProps {
  title: string;
  generationDate: string;
  onClearFilter?: () => void;
  className?: string;
  popoverWidth?: number;
  handleDateChange: (data: any) => void;
  maxDate?: Date;
  minDate?: Date;
  isMonthYearPicker?: boolean;
  isBirthdatePicker?: boolean;
  label?: any;
  footer?: ReactNode;
  placeholder?: string;
  isDateRange?: boolean;
  disabled?: boolean;
  startDate?: Date;
  endDate?: any;
  closeOnSelect?: boolean;
  inputHeightClassName?: string;
  labelClass?: string;
}

export const DatePickerDropDown: React.FC<DatePickerDropDownProps> = ({
  title,
  generationDate,
  onClearFilter,
  className,
  popoverWidth = 250,
  disabled = false,
  handleDateChange,
  maxDate,
  minDate,
  isMonthYearPicker = true,
  isBirthdatePicker = false,
  label,
  footer,
  placeholder,
  isDateRange = false,
  startDate,
  endDate,
  closeOnSelect = true,
  inputHeightClassName,
  labelClass,
}) => {
  const handleChange = (data, close) => {
    handleDateChange(data);

    if (closeOnSelect) {
      if (!isDateRange || data.filter((date) => !date).length === 0) {
        close();
      }
    }
  };

  return (
    <DropdownDateTime
      title={title}
      footer={footer}
      popoverWidth={popoverWidth}
      onClearFilter={onClearFilter}
      value={generationDate}
      trigger={(dropdownProps) => (
        <InputDropdownComponent
          name={'filterKey'}
          label={label}
          disabled={disabled}
          labelClass={labelClass}
          placeholder={placeholder}
          value={generationDate}
          inputHeightClassName={inputHeightClassName}
          icon={
            <div className="px-2 -mr-3 bg-white">
              <IconCalendar
                className={
                  dropdownProps.popoverIsOpen
                    ? 'text-blue-500'
                    : 'text-gray-700'
                }
              />
            </div>
          }
          {...dropdownProps}
        />
      )}
    >
      {(close) => (
        <div className={className}>
          {isDateRange ? (
            <DatePickerRange
              handleDateChange={(data) => handleChange(data, close)}
              isMonthYearPicker={isMonthYearPicker}
              endDate={endDate}
              startDate={startDate}
              maxDate={maxDate}
              minDate={minDate}
              className={className}
            />
          ) : (
            <DatePickerNoRange
              handleDateChange={(data) => handleChange(data, close)}
              isMonthYearPicker={isMonthYearPicker}
              isBirthdatePicker={isBirthdatePicker}
              maxDate={maxDate}
              minDate={minDate}
              className={className}
              selected={generationDate}
            />
          )}
        </div>
      )}
    </DropdownDateTime>
  );
};

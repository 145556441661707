import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/redux';
import { useSelector } from 'react-redux';
import { getAppliedFilters, getFilters } from '../../selectors';
import { useForm } from 'react-hook-form';
import React, { useRef } from 'react';
import {
  defaultFilters,
  isFilterSelected,
  TerminalFiltersProps,
  virtualTerminalTransactionTypes,
} from '../../utils';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../virtualTerminalSlice';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { Input, InputNumber, SelectController } from 'components/inputs';
import { FilterSelect } from 'components/filters/FilterSelect';
import { clientsList, merchantList, usersList } from 'api/merchants';
import { authorizationStatusList } from 'domain/transaction-management/authorizations/api';
import { useFilterEffect } from 'utils/useFilterEffect';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { countDecimalPlaces } from '../../../../transaction-management/authorizations/utils';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filter: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appliedFilters = useSelector(getAppliedFilters);
  const filters = useSelector(getFilters);

  const {
    getValues,
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset: resetFilters,
    watch,
  } = useForm<any>({ mode: 'onBlur', defaultValues: appliedFilters });

  const allValues = watch();
  const previousValues = useRef<TerminalFiltersProps>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.transactions.list.filters.transactionId'
            )}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        error={errors.id}
        inputProps={{
          ...register('id', {
            validate: {
              uuidValidation: (id: string) =>
                id === null ||
                id.length === 0 ||
                /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(
                  id
                ),
            },
          }),
        }}
      />
    ),
    1: (
      <FilterSelect
        label={t(
          'transactionsManagement.transactions.list.filters.transactionType'
        )}
        name="transactionTypes"
        control={control}
        options={virtualTerminalTransactionTypes(t)}
        isClearable={false}
      />
    ),
    2: (
      <DatePickerControl
        control={control}
        label={t('virtualTerminal.filterPayments.submittedDate')}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="transactionDate"
        footer={<></>}
        maxDate={new Date()}
        title={t('virtualTerminal.filterPayments.submittedDate')}
      />
    ),
    3: (
      <DatePickerControl
        control={control}
        label={t('virtualTerminal.filterPayments.settledDate')}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="settledDate"
        footer={<></>}
        maxDate={new Date()}
        title={t('virtualTerminal.filterPayments.settledDate')}
      />
    ),
    4: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.authorizations.list.filters.amount'
            )}
          />
        }
        isBottomError
        type="number"
        min={0.0}
        max={99999.99}
        step={0.01}
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        error={errors.amount}
        inputProps={{
          ...register('amount', {
            validate: {
              maxRefundAmountDecimals: (value: any) =>
                value ? countDecimalPlaces(value) <= 2 : true,
              minAmountFilter: (value: any) => value >= 0,
            },
          }),
        }}
      />
    ),
    5: (
      <InputNumber
        control={control}
        name="cardLastFour"
        label={t(
          'transactionsManagement.transactions.list.filters.cardLastFour'
        )}
      />
    ),
    6: (
      <SelectController
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('virtualTerminal.filterPayments.status')}
            className="font-medium tracking-wide text-xs text-black"
          />
        }
        name="status"
        control={control}
        loadOptions={authorizationStatusList}
        isMulti
        isClearable={false}
      />
    ),
    7: (
      <SelectController
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('virtualTerminal.filterPayments.merchants')}
            className="font-medium tracking-wide text-xs text-black"
          />
        }
        isMulti
        name="merchants"
        control={control}
        isClearable={false}
        loadOptions={merchantList}
      />
    ),
    8: (
      <SelectController
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('virtualTerminal.filterPayments.submittedByUser')}
            className="font-medium tracking-wide text-xs text-black"
          />
        }
        isMulti
        name="submittedBy"
        control={control}
        loadOptions={usersList}
        isClearable={false}
      />
    ),
    9: (
      <SelectController
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('virtualTerminal.filterPayments.submittedByClient')}
            className="font-medium tracking-wide text-xs text-black"
          />
        }
        isMulti
        name="submittedByClient"
        control={control}
        loadOptions={clientsList}
        isClearable={false}
      />
    ),
  };

  const handleApplyFilters = () => {
    const values = getValues();
    dispatch(setApplyFilters(values));
    if (toggleDrawer) toggleDrawer();
  };

  const reset = () => {
    dispatch(resetAllFilters());
    resetFilters(defaultFilters);
  };

  const isVirtualSelected = isFilterSelected(filters);

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!isVirtualSelected}
      isDisabledApply={!isVirtualSelected}
    />
  );
};

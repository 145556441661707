export enum UserPermissions {
  ADMINS_REASON_LIST = 'ADMINS_REASON_LIST',
  REPORT_CONFIGURATION_MODIFY = 'REPORT_CONFIGURATION_MODIFY',
  REPORT_CONFIGURATION_GET = 'REPORT_CONFIGURATION_GET',
  MERCHANT_CONFIGURATION_MODIFY = 'MERCHANT_CONFIGURATION_MODIFY',
  MERCHANT_CONFIGURATION_GET = 'MERCHANT_CONFIGURATION_GET',
  DASHBOARD_GENERAL_INFO = 'DASHBOARD_GENERAL_INFO',
  PROGRAMS_PROCESSOR = 'PROGRAMS_PROCESSOR',
  PROGRAMS_CORPORATE = 'PROGRAMS_CORPORATE',
  REPORTS_JOB_CREATE_TEST = 'REPORTS_JOB_CREATE_TEST',
  DASHBOARD_AUTHORIZATION_STATUS_INFO = 'DASHBOARD_AUTHORIZATION_STATUS_INFO',
  DASHBOARD_AUTHORIZATION_TYPE_INFO = 'DASHBOARD_AUTHORIZATION_TYPE_INFO',
  ACCOUNT_CHANGE_PASSWORD = 'ACCOUNT_CHANGE_PASSWORD',
  ACCOUNT_PASSWORD_RESET_REQUEST = 'ACCOUNT_PASSWORD_RESET_REQUEST',
  ADMINS_LOGGING_DETAILS = 'ADMINS_LOGGING_DETAILS',
  SECRET_UPDATE = 'SECRET_UPDATE',
  RAPID_CONNECT_MERCHANTS_CREATE = 'RAPID_CONNECT_MERCHANTS_CREATE',
  ADMINS_DATE_SETTINGS = 'ADMINS_DATE_SETTINGS',
  REPORTS_DAILY_SUMMARY_REPORT = 'REPORTS_DAILY_SUMMARY_REPORT',
  MERCHANT_REPORTS_LIST = 'MERCHANT_REPORTS_LIST',
  REPORTS_CHANGE_STATUS = 'REPORTS_CHANGE_STATUS',
  MERCHANT_REPORTS_GET = 'MERCHANT_REPORTS_GET',
  REPORTS_LIST = 'REPORTS_LIST',
  REPORTS_DOWNLOAD = 'REPORTS_DOWNLOAD',
  MERCHANT_REPORTS_DOWNLOAD = 'MERCHANT_REPORTS_DOWNLOAD',
  PROGRAMS_LIST = 'PROGRAMS_LIST',
  MERCHANT_REPORTS_CHANGE_STATUS = 'MERCHANT_REPORTS_CHANGE_STATUS',
  MERCHANT_REPORTS_CREATE = 'MERCHANT_REPORTS_CREATE',
  MERCHANT_ADJUSTMENTS_GET = 'MERCHANT_ADJUSTMENTS_GET',
  MERCHANT_ADJUSTMENTS_LIST = 'MERCHANT_ADJUSTMENTS_LIST',
  MERCHANT_CHARGEBACKS_GET = 'MERCHANT_CHARGEBACKS_GET',
  MERCHANT_CHARGEBACKS_LIST = 'MERCHANT_CHARGEBACKS_LIST',
  MERCHANT_LIMITS_UPDATE = 'MERCHANT_LIMITS_UPDATE',
  NOTIFICATION_LIST = 'NOTIFICATION_LIST',
  NOTIFICATION_RESOLVE = 'NOTIFICATION_RESOLVE',
  COMPLIANCE_TOTAL_SALES = 'COMPLIANCE_TOTAL_SALES',
  ADMINS_ADD = 'ADMINS_ADD',
  ADMINS_LIST = 'ADMINS_LIST',
  ADMINS_GET = 'ADMINS_GET',
  ACCOUNT_SIGN_OUT = 'ACCOUNT_SIGN_OUT',
  ACCOUNT_GET = 'ACCOUNT_GET',
  ACCOUNT_HOLDER_LIST = 'ACCOUNT_HOLDER_LIST',
  ACCOUNT_HOLDER_GET = 'ACCOUNT_HOLDER_GET',
  REPORTS_TRANSACTION_FEE = 'REPORTS_TRANSACTION_FEE',
  CLIENT_CREATE = 'CLIENT_CREATE',
  MERCHANT_CREATE = 'MERCHANT_CREATE',
  PROGRAMS_CREATE = 'PROGRAMS_CREATE',
  PROCESSOR_LIST = 'PROCESSOR_LIST',
  PRODUCT_LIST = 'PRODUCT_LIST',
  MERCHANT_UPDATE = 'MERCHANT_UPDATE',
  CORPORATE_LIST = 'CORPORATE_LIST',
  MERCHANT_GET = 'MERCHANT_GET',
  TRANSACTION_GET = 'TRANSACTION_GET',
  TRANSACTION_LIST = 'TRANSACTION_LIST',
  TRANSACTION_CSV = 'TRANSACTION_CSV',
  AUTHORIZATION_CSV = 'AUTHORIZATION_CSV',
  AUTHORIZATION_LIST = 'AUTHORIZATION_LIST',
  AUTHORIZATION_GET = 'AUTHORIZATION_GET',
  AUDIT_LIST = 'AUDIT_LIST',
  AUDIT_GET = 'AUDIT_GET',
  CHARGEBACKS_LIST = 'CHARGEBACKS_LIST',
  CHARGEBACKS_GET = 'CHARGEBACKS_GET',
  ADJUSTMENTS_LIST = 'ADJUSTMENTS_LIST',
  ADJUSTMENTS_GET = 'ADJUSTMENTS_GET',
  MERCHANT_TRANSACTIONS_LIST = 'MERCHANT_TRANSACTIONS_LIST',
  MERCHANT_AUTHORIZATION_LIST = 'MERCHANT_AUTHORIZATION_LIST',
  MERCHANT_AUTHORIZATION_GET = 'MERCHANT_AUTHORIZATION_GET',
  MERCHANT_TRANSACTIONS_GET = 'MERCHANT_TRANSACTIONS_GET',
  ADMINS_ACTIVATE = 'ADMINS_ACTIVATE',
  CLIENT_LIST = 'CLIENT_LIST',
  PROGRAMS_MANAGERS_LIST = 'PROGRAMS_MANAGERS_LIST',
  ADMINS_DISABLED_LIST = 'ADMINS_DISABLED_LIST',
  REPORTS_GET = 'REPORTS_GET',
  REPORTS_CREATE = 'REPORTS_CREATE',
  TRANSACTION_FEE_GET = 'TRANSACTION_FEE_GET',
  TRANSACTION_FEE_UPDATE = 'TRANSACTION_FEE_UPDATE',
  RAPID_CONNECT_MERCHANT_GET = 'RAPID_CONNECT_MERCHANT_GET',
  ROLE_LIST = 'ROLE_LIST',
  ROLE_ADD = 'ROLE_ADD',
  ROLE_GET = 'ROLE_GET',
  ROLE_PERMISSIONS_LIST = 'ROLE_PERMISSIONS_LIST',
  MERCHANT_LIST = 'MERCHANT_LIST',
  RECEIVERS_ADD = 'RECEIVERS_ADD',
  ACCOUNT_HOLDERS_UPDATE = 'ACCOUNT_HOLDERS_UPDATE',
  RAPID_CONNECT_MERCHANTS_UPDATE = 'RAPID_CONNECT_MERCHANTS_UPDATE',
  MERCHANT_TOGGLE = 'MERCHANT_TOGGLE',
  BLACKLIST_PAN = 'BLACKLIST_PAN',
  BLACKLIST_PAN_DELETE = 'BLACKLIST_PAN_DELETE',
  RECEIVERS_LIST = 'RECEIVERS_LIST',
  RECEIVERS_REMOVE = 'RECEIVERS_REMOVE',
  DASHBOARD_TRANSACTION_STATUS_INFO = 'DASHBOARD_TRANSACTION_STATUS_INFO',
  DASHBOARD_TRANSACTION_TYPE_INFO = 'DASHBOARD_TRANSACTION_TYPE_INFO',
  AUTHORIZATION_STATUS = 'AUTHORIZATION_STATUS',
  CURRENCY_CODE_GET = 'CURRENCY_CODE_GET',
  MERCHANT_TOGGLE_STATUS_GET = 'MERCHANT_TOGGLE_STATUS_GET',
  ADMINS_UPDATE = 'ADMINS_UPDATE',
  ADMINS_DISABLE = 'ADMINS_DISABLE',
  COUNTRY_CODE_GET = 'COUNTRY_CODE_GET',
  ADMINS_INVITATION_RESEND = 'ADMINS_INVITATION_RESEND',
  ROLE_UPDATE = 'ROLE_UPDATE',
  GENERAL_ACCOUNT = 'GENERAL_ACCOUNT',
  MONITORING_LIST = 'MONITORING_LIST',
  SFTP_LIST = 'SFTP_LIST',
  TRANSACTION_STATUSES_GET = 'TRANSACTION_STATUSES_GET',
  TRANSACTION_TYPES_GET = 'TRANSACTION_TYPES_GET',
  VIRTUAL_TERMINAL_REFUND_PAYMENT = 'VIRTUAL_TERMINAL_REFUND_PAYMENT',
  VIRTUAL_TERMINAL_CAPTURE_PAYMENT = 'VIRTUAL_TERMINAL_CAPTURE_PAYMENT',
  VIRTUAL_TERMINAL_VOID_PAYMENT = 'VIRTUAL_TERMINAL_VOID_PAYMENT',
}

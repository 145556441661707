/* eslint-disable @typescript-eslint/ban-types */
import { CardType, TransactionType } from './transactions';
import { DateRange } from 'types/dateRange';
import { ItemAttributes } from 'components/itemAttributeType';

export type ItemFilters = {
  merchants: ItemAttributes[];
  transactionTypes: ItemAttributes[];
  cardTypes: ItemAttributes[];
  status: ItemAttributes[];
};

export type ItemSort = {
  field: string;
  type: string;
};

export type SearchLoggingCriteria = {
  timeRage: TimeRange;
  logTypes: string[];
};

export type TimeRange = {
  from: any | null;
  to: any | null;
};

export type MiscFilters = {
  cardLastFour: string;
  id: string;
  authorizationCode: string;
  stan: string;
  nameOnCard: string;
  merchantExternalReference: string;
  transactionDate: DateRange;
  amount: number | null;
};

export enum AuthorizationStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  REVERSED = 'REVERSED',
  SETTLED = 'SETTLED',
  CLOSED = 'CLOSED',
  VOIDED = 'VOIDED',
  REFUNDED = 'REFUNDED',
  DECLINED = 'DECLINED',
  DISABLED = 'DISABLED',
  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
  FAILED = 'FAILED',
}

export enum AuthorizationType {
  AUTHORIZATION = 'AUTHORIZATION',
  PURCHASE = 'PURCHASE',
}

export enum CardBrand {
  MASTER_CARD = 'MasterCard',
  VISA = 'Visa',
  JCB = 'JCB',
  DISCOVER = 'Discover',
  DINERS = 'Diners',
  AMEX = 'Amex',
}

export enum AuthorizationActions {
  VOID = 'VOID',
  REFUND = 'REFUND',
  CAPTURE = 'CAPTURE',
  NONE = 'NONE',
}

export enum CcvResult {
  'MATCH' = 'VALUES_MATCH',
  'NO_MATCH' = 'VALUES_DO_NOT_MATCH',
}

export enum AvsResponse {
  'Y' = 'Y',
  'N' = 'N',
  'Z' = 'Z',
}

export type AuthorizationItem = {
  id: string;
  localDate: string;
  transmissionDate: string;
  settledDate: string;
  stan: string;
  merchantExternalReference: string;
  terminalId: string;
  merchantId: string;
  merchantName: string;
  submittedBy: string;
  posEntryMode: string;
  transactionAmount: number;
  transactionCurrency: string;
  cardType: CardType | string;
  responseCode: string;
  authId: string;
  transactionType: TransactionType | string;
  cardNumber: string;
  amount: string;
  status: AuthorizationStatus | string;
};

export type TransactionDetails = {
  terminalId: string;
  merchantId: string;
  merchantName: string;
  cardType: CardType | string;
  country?: string;
  zip?: string;
  cardNumber: string;
  nameOnCard: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  billingAddress?: string;
  transactionType: TransactionType | string;
  localDate: string;
  transmissionDate: string;
  updatedAt: string;
  originalTransactionId: string;
  posEntryMode: string;
  transactionAmount: number;
  refundedAmount: number;
  transactionCurrency: string;
  stan: string;
  user: string;
  merchantRefNumber: string;
  merchantOrderNumber: string;
  merchantExternalReference: string;
  avsResponse: AvsResponse | string;
  ccvResult: CcvResult | string;
  banknetData: string;
  responseCode: string;
  authId: string;
  additionalResponseData: string;
  request: Object;
  response: Object;
  status: AuthorizationStatus | string;
  availableAction: AuthorizationActions[] | string[];
  refundIdList: string[];
};

export type ListFilters = ItemFilters & MiscFilters;

import api from 'api';

const blockedPansList = async (data: any) => {
  return api.post('/fraud-prevention/blocked-card-number/list', data);
};

const createBlockedPan = async (data: any) => {
  return api.post('/fraud-prevention/card-number/block', data);
};
const deleteBlockedPan = async (id: string) => {
  return api.post(`/fraud-prevention/blocked-card-number/${id}/delete`);
};

const blockedPanDetails = async (id: string) => {
  return api.post(`/fraud-prevention/blocked-card-number/${id}/get`);
};

export const blockedPansResource = {
  blockedPansList,
  blockedPanDetails,
  createBlockedPan,
  deleteBlockedPan,
};

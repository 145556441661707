import React, { Fragment, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { initSubReportTypeItems } from '../../../components/utils';
import { ItemAttributes } from 'components/itemAttributeType';
import { SubReportTypes } from 'entities/reports';
import { useAppDispatch } from 'hooks/redux';
import { downloadItems } from '../../thunks';
import { useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import capitalize from 'lodash/capitalize';

type SplitButtonProps = {
  reportSubTypes: any;
};

export const SplitButton: React.FC<SplitButtonProps> = ({ reportSubTypes }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const reportId = Base64.decode(id!);
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedAttribute, setSelectedAttribute] =
    useState<ItemAttributes | null>({
      label: 'All in one',
      value: SubReportTypes.COMPLETE_REPORT,
    });

  const subReportTypeItemsAttributes = reportSubTypes
    ?.map((item: any) => item.type)
    .map((item: any) => ({
      value: item || '',
      label: capitalize(item.replaceAll(/(_)/g, ' ')),
    }));

  const allSubRep = [
    ...initSubReportTypeItems,
    ...subReportTypeItemsAttributes,
  ];

  const handleClick = () => {
    const reportTypeName =
      selectedAttribute?.value === SubReportTypes.ALL_SUB_REPORTS_TYPE
        ? allSubRep
            .filter(
              (type) =>
                SubReportTypes.COMPLETE_REPORT !== type.value &&
                SubReportTypes.ALL_SUB_REPORTS_TYPE !== type.value
            )
            .map((item) => item.value)
        : [selectedAttribute?.value];

    dispatch(
      downloadItems({
        id: reportId,
        subReports: reportTypeName,
      })
    );
  };

  const handleMenuItemClick = (itemAttribute: ItemAttributes) => {
    setSelectedAttribute(itemAttribute);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick} startIcon={<CloudDownloadIcon />}>
          {
            allSubRep.filter((val) => val.value === selectedAttribute?.value)[0]
              ?.label
          }
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {allSubRep.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={option.value === selectedAttribute?.value}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

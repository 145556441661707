import { StatusBadge } from 'components/StatusBadge';
import { KeyStatus } from '../utils';

const statusBadge = (enabled: boolean) => (enabled ? 'success' : 'danger');
const statusText = (enabled: boolean) =>
  enabled ? KeyStatus.ACTIVE : KeyStatus.DISABLED;

export const KeyStatusBadge = ({
  enabled,
  heightClass,
  textSizeClass,
  widthClass,
}: {
  enabled: boolean;
  heightClass?: string;
  textSizeClass?: string;
  widthClass?: string;
}) => (
  <StatusBadge
    heightClass={heightClass}
    widthClass={widthClass}
    textSizeClass={textSizeClass}
    variant={statusBadge(enabled)}
  >
    {statusText(enabled)}
  </StatusBadge>
);

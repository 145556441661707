export const IconPendingWarning = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.00065 14.6646C11.6825 14.6646 14.6673 11.6799 14.6673 7.99797C14.6673 4.31607 11.6825 1.3313 8.00065 1.3313C4.31875 1.3313 1.33398 4.31607 1.33398 7.99797C1.33398 11.6799 4.31875 14.6646 8.00065 14.6646Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.3313V7.99797"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6672H8.00667"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect y="-0.000732422" width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

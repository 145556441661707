import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { PasswordInput } from 'components/inputs/PasswordInput';
import { login, verifyCodeAuth } from 'domain/account/thunks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getIsLoading } from 'domain/account/selectors';
import { emailPattern } from 'validation/patterns';
import { Input } from 'components/inputs/Input';
import { ErrorCodes } from 'enums/errorCodes';
import { Link } from 'react-router-dom';

import { ACCESS_TOKEN_HEADER_KEY } from '../../../constants/accessTokenHeaderKey';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type LoginFormSchema = {
  email: string;
  password: string;
  verificationCode?: string;
};

export const FormLogin = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LoginFormSchema>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loading: boolean = useAppSelector(getIsLoading);

  const [showVerificationCode, setShowVerificationCode] = useState(false);

  const verificationCodeCall = async (data: LoginFormSchema, tokenKey: any) => {
    const { error } = await dispatch(
      verifyCodeAuth({
        email: data.email,
        code: data.verificationCode?.trim(),
      })
    );

    if (error?.code) {
      setValue('verificationCode', '');
      setShowVerificationCode(false);

      const code = error.code;

      switch (code) {
        case ErrorCodes.CAPTCHA_REQUIRED:
          break;

        case ErrorCodes.CODE_INVALID:
          toast.warning(
            'The verification code that you entered is incorrect.',
            {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
            }
          );
          break;

        case ErrorCodes.CREDENTIALS_INVALID:
          setError('email', {
            type: 'custom',
            message: t('validations.invalidCredentials'),
          });
          setValue('password', '');
          break;

        default:
          setError('email', {
            type: 'custom',
            message: t('validations.invalidCredentials'),
          });
          setValue('password', '');
          break;
      }
    }
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const callLogin = async (data: LoginFormSchema, tokenKey: any) => {
    sessionStorage.removeItem(ACCESS_TOKEN_HEADER_KEY);
    const { error, payload } = await dispatch(
      login({
        data: data,
        header: {
          recaptcha: tokenKey,
          'Content-Type': 'application/json',
        },
      })
    );

    if (error?.code) {
      const code = error.code;

      switch (code) {
        case ErrorCodes.CAPTCHA_REQUIRED:
          break;

        case ErrorCodes.CREDENTIALS_INVALID:
          setError('email', {
            type: 'custom',
            message: t('validations.invalidCredentials'),
          });
          setValue('password', '');
          break;

        case ErrorCodes.FORBIDDEN:
          toast.error(
            'Your account has been locked. Please contact our service customer.service@prysym.com.',
            {
              theme: 'colored',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
            }
          );
          toast.warning('An email was sent to your email address!', {
            theme: 'colored',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
          });
          setValue('email', '');
          setValue('password', '');
          break;

        default:
          setError('email', {
            type: 'custom',
            message: t('validations.invalidCredentials'),
          });
          setValue('password', '');
          break;
      }
    } else {
      if (!payload?.authenticated) setShowVerificationCode(true);
    }
  };

  const onSubmit = async (data: LoginFormSchema) => {
    if (!executeRecaptcha) {
      return;
    }

    const result = await executeRecaptcha('login');

    if (showVerificationCode) {
      verificationCodeCall(data, result);
    } else {
      callLogin(data, result);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full px-3">
      <div className={showVerificationCode ? 'hidden' : 'block'}>
        <div className="mb-5">
          <Input
            label={t('loginForm.email')}
            error={errors.email}
            inputProps={register('email', {
              required: true,
              validate: {
                email: (value) => emailPattern.test(value),
              },
            })}
          />
        </div>
        <div className="mb-8">
          <PasswordInput
            label={t('loginForm.password')}
            error={errors.password}
            inputProps={register('password', { required: true })}
          />
        </div>
      </div>
      {showVerificationCode && (
        <div className="mb-5">
          <Input
            label={t('loginForm.verificationCode')}
            error={errors.verificationCode}
            inputProps={register('verificationCode', {
              required: true,
            })}
          />
        </div>
      )}
      <LoadingButton
        loading={loading}
        type="submit"
        className="w-full"
        variant="contained"
      >
        {t('loginForm.logIn')}
      </LoadingButton>
      {!showVerificationCode && (
        <div className="flex justify-center mt-3">
          <Link
            to="/forgot-password"
            className="text-sm font-semibold text-blue-500"
          >
            {t('loginForm.forgotPassword')}
          </Link>
        </div>
      )}
    </form>
  );
};

import { useEffect } from 'react';
import { valuesHaveChanged } from './filters';
import { useAppDispatch } from 'hooks/redux';

export const useFilterEffect = (
  previousValues: any,
  allValues: any,
  setFilters: any
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (previousValues.current) {
      const valuesHaveChangedResult = valuesHaveChanged(
        allValues,
        previousValues
      );

      if (valuesHaveChangedResult) {
        dispatch(setFilters(allValues));
      }
    }

    previousValues.current = allValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues, dispatch]);
};

import { Fragment, ReactNode, useCallback } from 'react';
import classNames from 'classnames';

import { RectLoader } from '../RectLoader';
import { IconEmpty } from '../icons/IconEmpty';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { ColumnComponent } from './ColumnComponent';
import { ItemSort } from '../../entities';

export type ColumnProps = {
  value: (item: any) => ReactNode;
  headerContent?: () => ReactNode;
  columnFilter?: (item: any) => ReactNode;
  isSorted?: boolean;
  sortField?: string;
  label?: string;
  id: string;
  minWidth?: number;
  width?: number;
  tooltip?: string;
};

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Sorting = {
  column: ColumnProps;
  direction: SortingDirection;
};

export const CellBase = ({ children }: { children: ReactNode }) => (
  <span className="block overflow-x-auto text-xs font-medium leading-normal text-black break-words whitespace-pre-line">
    {children}
  </span>
);
export const CellLight = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={classNames('text-black', className)}>{children}</div>;

export const Table = ({
  items,
  itemExpand,
  itemExpandContent,
  columns,
  loading = false,
  onRowClick,
  setSort,
  onHover = true,
}: {
  items: any[];
  itemExpand?: any;
  itemExpandContent?: ReactNode;
  columns: ColumnProps[];
  loading?: boolean;
  onRowClick?: (row: any) => any;
  setSort?: (sort: ItemSort) => void;
  currentSort?: ItemSort | null;
  onHover?: boolean;
}) => {
  const { t } = useTranslation();

  const rowClickHandle = useCallback(
    (item: any) => {
      if (!loading && onRowClick) {
        onRowClick(item);
      }
    },
    [loading, onRowClick]
  );

  return (
    <table className="relative w-full table-auto">
      <thead className="w-full z-0">
        <tr className="text-left">
          {columns.map((item, index) => (
            <ColumnComponent key={index} item={item} setSort={setSort} />
          ))}
        </tr>
      </thead>
      <tbody>
        {!loading && !items.length && (
          <tr>
            <td colSpan={20} className="h-64">
              <div className="flex flex-col items-center justify-center w-full h-full pt-20">
                <div className="mb-5 text-center">
                  <IconEmpty />
                </div>
                <span className="flex flex-col items-center text-xs font-medium text-gray-700">
                  <div>{t('kyc.table.noResults')}</div>
                  <div>{t('kyc.table.tryAgain')}</div>
                </span>
              </div>
            </td>
          </tr>
        )}
        {(loading ? [...Array(10)] : items).map((item, index) => (
          <Fragment key={index}>
            <tr
              className={classNames('transition-colors duration-300', {
                'hover:bg-gray-100': onHover,
                'bg-gray-100': isEqual(item, itemExpand),
                'cursor-pointer': onRowClick,
              })}
              onClick={() => rowClickHandle(item)}
            >
              {columns.map((column, columnIndex) => (
                <td
                  key={`${index}-${columnIndex}`}
                  className={classNames('p-0 relative', {
                    'border-b': !isEqual(item, itemExpand),
                  })}
                >
                  <div
                    className="py-4 pl-4 items-center"
                    style={{ minWidth: column.minWidth, width: column.width }}
                  >
                    {isEqual(item, itemExpand) &&
                      columnIndex === 0 &&
                      !loading && (
                        <div className="absolute top-0 left-0 w-1 h-full bg-blue-500" />
                      )}

                    {loading ? (
                      <RectLoader
                        className={'w-full'}
                        width={100}
                        height={20}
                      />
                    ) : (
                      <CellBase>{column.value(item)}</CellBase>
                    )}
                  </div>
                </td>
              ))}
            </tr>
            {isEqual(item, itemExpand) && (
              <tr className="bg-gray-100">
                <td colSpan={columns.length} className="relative p-0 border-b">
                  <div className="absolute top-0 left-0 w-1 h-full bg-blue-500" />
                  <div className="border-l-4 border-blue-500">
                    {itemExpandContent}
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

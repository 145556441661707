import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container as PaymentList } from './virtual-terminal-component/list/Container';
import { CreatePayment } from './virtual-terminal-component/components/create-payment/CreatePayment';
import { PreviewDetails } from '../transaction-management/authorizations/list/component/PreviewDetails';
import { useEffect } from 'react';
import { resetAllItems } from './virtual-terminal-component/virtualTerminalSlice';
import { useAppDispatch } from 'hooks/redux';

export const VirtualTerminal = () => {
  const match = useRouteMatch();

  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/payment/create`}>
        <CreatePayment />
      </Route>
      <Route path={`${match.path}/payment/details/:id`}>
        <PreviewDetails />
      </Route>
      <Route path={`${match.path}`}>
        <PaymentList />
      </Route>
    </Switch>
  );
};

import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'hooks/redux';

import { getAppliedFilters, getFilters } from '../../selectors';
import { useSelector } from 'react-redux';
import { FiltersParam, itemFilters } from '../../utils';
import {
  resetAllFilters,
  setApplyFilters,
  setCardNumberFilter,
  setFilters,
} from '../../blockedPansSlice';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { Input } from 'components/inputs';
import { isEqual } from 'lodash';
import { useFilterEffect } from 'utils/useFilterEffect';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters: FiltersParam = useSelector(getFilters);
  const appliedFilters: FiltersParam = useSelector(getAppliedFilters);

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
    reset: resetFilters,
  } = useForm<FiltersParam>({ mode: 'onBlur', defaultValues: appliedFilters });

  const { cardNumber } = watch();

  const maskNumber = (number: string) => {
    const newValue = number.replace(/\D/g, '').slice(0, 19);
    return newValue.replace(/(.{4})/g, '$1 ').trim();
  };

  const [maskedValue, setMaskedValue] = useState(maskNumber(cardNumber));
  const [value, setValue] = useState(maskedValue);

  useEffect(() => {
    setValue(maskedValue);
  }, [maskedValue]);

  const reset = () => {
    dispatch(resetAllFilters());
    setMaskedValue('');
    resetFilters(itemFilters);
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  useEffect(() => {
    if (!isEqual(cardNumber, filters.cardNumber)) {
      dispatch(setCardNumberFilter(cardNumber));
    }
  }, [cardNumber, filters.cardNumber, dispatch]);

  const allValues = watch();
  const previousValues = useRef<FiltersParam>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const fraudHasFilter = Boolean(filters.cardNumber?.length);

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('blockedPans.cardPan')}
          />
        }
        placeholder={t('blockedPans.cardPlaceholder')}
        className="h-12"
        labelFontClassName="font-medium tracking-wide text-xs"
        labelColorClassName="text-back"
        error={errors.cardNumber}
        inputProps={{
          value: value || '',
          ...register('cardNumber', {
            onChange: (e) => setMaskedValue(maskNumber(e.currentTarget.value)),
            maxLength: {
              value: 23,
              message: t('blockedPans.cardMaxLength', {
                maxLength: 19,
              }),
            },
            minLength: {
              value: 16,
              message: t('blockedPans.cardMinLength', {
                minLength: 12,
              }),
            },
          }),
        }}
      />
    ),
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!fraudHasFilter}
      isDisabledApply={!fraudHasFilter}
    />
  );
};

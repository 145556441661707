import { CreateParams } from '../../utils';
import React, { useEffect, useState } from 'react';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/inputs';
import { PaymentCreateSteps } from './PaymentCreateSteps';
import moment from 'moment';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { upPaymentCreateStep } from '../../virtualTerminalSlice';
import { useAppDispatch } from 'hooks/redux';
import { CardBrand } from '../../../../../entities';
import classNames from 'classnames';
import { findCardBrand } from '../../thunks';
import { useSelector } from 'react-redux';
import { isCardBrandLoadingSelect } from '../../selectors';
import { CircularProgress } from '@mui/material';

const expirationCard = (value: string) => {
  const cleanValue = value.replace(/\D+/g, '');
  if (cleanValue.length === 4) {
    const test = moment(cleanValue, 'MMYY');
    return test.startOf('M').isSameOrAfter(moment().startOf('M'));
  }

  return false;
};

export const PaymentMethodDetails: React.FC<CreateParams> = ({
  register,
  errors,
  watch,
  handleSubmit,
  setError,
  setValue,
}) => {
  const { t } = useTranslation();
  const { cardholderName, cardNumber, expirationDate, cVVcVC, cardBrandCSS } =
    watch();
  const dispatch = useAppDispatch();
  const isDetectLoading = useSelector(isCardBrandLoadingSelect);

  const cardBrands = new Map([
    [CardBrand.VISA.toString(), 'rccs__card--visa'],
    [CardBrand.MASTER_CARD.toString(), 'rccs__card--mastercard'],
    [CardBrand.JCB.toString(), 'rccs__card--jcb'],
    [CardBrand.DISCOVER.toString(), 'rccs__card--discover'],
    [CardBrand.DINERS.toString(), 'rccs__card--diners-club'],
    [CardBrand.AMEX.toString(), 'rccs__card--american-express'],
  ]);

  const cardNameValidations = (value: string) => {
    const trimmedText = value.trim();
    const words = trimmedText.split(/\s+/);
    return words.length >= 2;
  };

  const cardValidations = {
    cardName: cardNameValidations,
    expirationCard: expirationCard,
  };

  const clearNumber = (value = '') => {
    return value.replace(/\D+/g, '');
  };

  const maskNumber = (number: string) => {
    const newValue = number.replace(/\D/g, '').slice(0, 19);
    return newValue.replace(/(.{4})/g, '$1 ').trim();
  };

  const checkCvvNumberLength = (number: string) => {
    return number.replace(/\D/g, '').slice(0, 4).trim();
  };

  const maskExpirationDateNumber = (number: string) => {
    const clearValue = clearNumber(number);

    if (clearValue.length === 2 && parseInt(clearValue.slice(0, 2)) > 12) {
      return '12';
    }

    const currentYear = moment().year().toString()?.slice(-2);

    if (
      clearValue.length === 4 &&
      parseInt(clearValue.slice(2, 4)) < parseInt(currentYear)
    ) {
      return clearValue.slice(0, 2);
    }

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)} / ${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  };

  const [maskedValue, setMaskedValue] = useState(maskNumber(cardNumber));
  const [valueCard, setValueCard] = useState(maskedValue);

  const [maskedCvvValue, setMaskedCvvValue] = useState(
    checkCvvNumberLength(cVVcVC)
  );
  const [valueCvvCard, setValueCvvCard] = useState(maskedCvvValue);

  const [maskedExpiryValue, setMaskedExpiryValue] = useState(
    maskExpirationDateNumber(expirationDate)
  );
  const [expiry, setExpiry] = useState(maskedExpiryValue);

  const [cardNumberPresent, setCardNumberPresent] = useState(
    cardNumber ? maskedValue : '•••• •••• •••• ••••'
  );

  const maskCardNumberPresentation = (input: string) => {
    const masked = '•••• •••• •••• ••••';
    const inputDigits = input.split('').filter((char) => !isNaN(Number(char)));
    const maskedDigits = masked.split('');

    for (let i = 0; i < inputDigits.length; i++) {
      const replaceIndex = maskedDigits.findIndex((char) => char === '•');
      if (replaceIndex !== -1) {
        maskedDigits[replaceIndex] = inputDigits[i];
      }
    }

    return maskedDigits.join('');
  };

  useEffect(() => {
    setValueCard(maskedValue);
    setCardNumberPresent(maskCardNumberPresentation(clearNumber(maskedValue)));
  }, [maskedValue]);

  useEffect(() => {
    setExpiry(maskedExpiryValue);
  }, [maskedExpiryValue]);

  useEffect(() => {
    setValueCvvCard(maskedCvvValue);
  }, [maskedCvvValue]);

  const nextUpdate = () => {
    dispatch(upPaymentCreateStep());
  };

  const getCardBrand = (value: string) => {
    const clearedValue = clearNumber(value);
    if (clearedValue.length < 12) {
      setValue('rccs__card--unknown');
      return;
    }
    dispatch(findCardBrand({ number: clearedValue }))
      .unwrap()
      .then((e: any) => {
        const newVar = cardBrands.get(e.data);
        setValue('cardBrandCSS', newVar ? newVar : 'rccs__card--unknown');
      })
      .catch(() => {
        setValue('cardBrandCSS', 'rccs__card--unkn');
        setError('cardNumber', {
          type: 'custom',
          message: t('validations.unknownCreditCardBrand'),
        });
      });
  };

  const isCardBrandColorDisabled = cardBrandCSS === 'rccs__card--unkn';

  return (
    <>
      <form onSubmit={handleSubmit(nextUpdate)}>
        <div className="grid gap-5 xxl:grid-cols-2 items-center justify-items-center my-4 sm:m-4 sm:p-3">
          <div>
            <div className="w-100">
              <Input
                label={
                  <RequiredFieldComponent
                    labelText={t(
                      'virtualTerminal.createDetails.cardholderName'
                    )}
                  />
                }
                placeholder={t(
                  'virtualTerminal.createDetails.cardholderNamePlaceholder'
                )}
                labelFontClassName="font-medium tracking-wide text-xs"
                labelColorClassName="text-back"
                error={errors.cardholderName}
                className="h-10"
                inputProps={{
                  ...register('cardholderName', {
                    required: true,
                    maxLength: {
                      value: 255,
                      message: t('virtualTerminal.validation.maxLengthSize', {
                        maxLength: 255,
                      }),
                    },
                    validate: {
                      cardName: cardValidations.cardName,
                    },
                  }),
                }}
              />
            </div>
            <div className="w-100">
              <Input
                label={
                  <RequiredFieldComponent
                    labelText={t('virtualTerminal.createDetails.cardNumber')}
                  />
                }
                placeholder={t(
                  'virtualTerminal.createDetails.cardNumberPlaceholder'
                )}
                icon={
                  isDetectLoading ? (
                    <CircularProgress color="inherit" size="1rem" />
                  ) : null
                }
                labelFontClassName="font-medium tracking-wide text-xs"
                labelColorClassName="text-back"
                className="h-10"
                error={errors.cardNumber}
                inputProps={{
                  value: valueCard || '',
                  ...register('cardNumber', {
                    onChange: (e: any) =>
                      setMaskedValue(maskNumber(e.currentTarget.value)),
                    onBlur: (e: any) => getCardBrand(e.target.value),
                    required: true,
                    maxLength: {
                      value: 24,
                      message: t('blockedPans.cardMaxLength', {
                        maxLength: 19,
                      }),
                    },
                    minLength: {
                      value: 14,
                      message: t('blockedPans.cardMinLength', {
                        minLength: 12,
                      }),
                    },
                  }),
                }}
              />
            </div>
            <div className="w-100 flex gap-1">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      labelText={t(
                        'virtualTerminal.createDetails.expirationDate'
                      )}
                    />
                  }
                  placeholder={t(
                    'virtualTerminal.createDetails.expirationDatePlaceholder'
                  )}
                  className="h-10"
                  labelFontClassName="font-medium tracking-wide text-xs"
                  labelColorClassName="text-back"
                  error={errors.expirationDate}
                  isBottomError
                  inputProps={{
                    value: expiry || '',
                    ...register('expirationDate', {
                      onChange: (e: any) =>
                        setMaskedExpiryValue(
                          maskExpirationDateNumber(e.currentTarget.value)
                        ),
                      required: true,
                      validate: {
                        expirationCard: cardValidations.expirationCard,
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      labelText={t('virtualTerminal.createDetails.cVVcVC')}
                    />
                  }
                  className="h-10"
                  placeholder={t(
                    'virtualTerminal.createDetails.cVVcVCPlaceholder'
                  )}
                  labelFontClassName="font-medium tracking-wide text-xs"
                  labelColorClassName="text-back"
                  error={errors.cVVcVC}
                  isBottomError
                  inputProps={{
                    value: valueCvvCard || '',
                    ...register('cVVcVC', {
                      onChange: (e: any) =>
                        setMaskedCvvValue(
                          checkCvvNumberLength(e.currentTarget.value)
                        ),
                      required: true,
                      maxLength: {
                        value: 5,
                        message: t('blockedPans.cardMaxLength', {
                          maxLength: 4,
                        }),
                      },
                      minLength: {
                        value: 3,
                        message: t('blockedPans.cardMinLength', {
                          minLength: 3,
                        }),
                      },
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-full pt-7 pr-28">
            <div className="rcsc">
              <div className={classNames('rccs__card', cardBrandCSS)}>
                <div className="rccs__card--front">
                  <div className="rccs__card__background"></div>
                  <div className="rccs__issuer"></div>
                  <div className="rccs__cvc__front"></div>
                  <div className="rccs__number">{cardNumberPresent}</div>
                  <div className="rccs__name">
                    {cardholderName ? cardholderName : 'YOUR NAME HERE'}
                  </div>
                  <div className="rccs__expiry">
                    <div className="rccs__expiry__valid">valid thru</div>
                    <div className="rccs__expiry__value">
                      {maskedExpiryValue ? maskedExpiryValue : '••/••'}
                    </div>
                  </div>
                  <div className="rccs__chip"></div>
                </div>
                <div className="rccs__card--back">
                  <div className="rccs__card__background"></div>
                  <div className="rccs__stripe"></div>
                  <div className="rccs__signature"></div>
                  <div className="rccs__cvc"></div>
                  <div className="rccs__issuer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentCreateSteps isDisabled={isCardBrandColorDisabled} />
      </form>
    </>
  );
};

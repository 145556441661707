import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Container as UserListContainer } from './users/list/Container';
import { UserDetailsUpdate } from './users/details/UserDetailsUpdate';
import { UserGetDetails } from './users/details/UserGetDetails';
import { UserCreate } from './users/create/UserCreate';
import { UserAdditionalInfoComponent } from './users/details/UserAdditionalInfoComponent';
import { useAppDispatch } from 'hooks/redux';
import { useEffect } from 'react';
import { resetAllItems } from './users/userSlice';

export const UserRoutManagement = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route exact path={`${match.path}/user-details/:id`}>
        <UserGetDetails />
      </Route>
      <Route exact path={`${match.path}/user-details/:id/:infoParam`}>
        <UserAdditionalInfoComponent />
      </Route>
      <Route path={`${match.path}/create`}>
        <UserCreate />
      </Route>
      <Route path={`${match.path}/update`}>
        <UserDetailsUpdate />
      </Route>
      <Route path={`${match.path}`}>
        <UserListContainer />
      </Route>
    </Switch>
  );
};

import { useTranslation } from 'react-i18next';
import { CellLight, ColumnProps } from 'components/table/Table';
import { ReportItem } from 'entities/reports';
import { useMemo } from 'react';

export const useReportMerchantsColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    (): ColumnProps => ({
      id: 'merchants',
      value: (item: ReportItem) => (
        <CellLight>
          {item.merchants.length
            ? item.merchants.map((it) => it.merchantName).join(', ')
            : 'All Merchants'}
        </CellLight>
      ),
      label: t('reports.acquiring.merchants'),
      width: 200,
    }),
    [t]
  );
};

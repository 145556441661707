import { StatusBadge } from 'components/StatusBadge';
import { UserStatus } from '../../status';

const statusBadge = (status: string) => {
  if (status === UserStatus.ACTIVE) {
    return 'success';
  } else if (status.toUpperCase() === UserStatus.PENDING) {
    return 'warning';
  } else if (status.toUpperCase() === UserStatus.LOCKED) {
    return 'locked';
  } else {
    return 'danger';
  }
};

export const UserStatusBadge = ({ status }: { status: string }) => (
  <StatusBadge variant={statusBadge(status)}>{status}</StatusBadge>
);

import { Button } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMerchantCreateStepStatus } from '../selectors';
import {
  clearMerchantCreate,
  downMerchantCreateStepNumber,
  upMerchantCreateStepNumber,
} from '../merchantsSlice';

import { useHistory } from 'react-router-dom';
import React from 'react';

interface MerchantStepProps {
  isNextStepDisabled: boolean;
  onClick: () => void;
}

export const MerchantCreateSteps: React.FC<MerchantStepProps> = ({
  isNextStepDisabled,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const merchantCreateStepNumber = useAppSelector(getMerchantCreateStepStatus);
  const nextUpdate = () => {
    onClick();
    dispatch(upMerchantCreateStepNumber());
  };

  const cancelCreation = () => {
    dispatch(clearMerchantCreate());
    history.push('/system/merchants');
  };

  return (
    <div className="grid justify-items-stretch grid-cols-2 gap-4 px-3 pb-6">
      <div className="justify-items-start">
        <Button
          color="info"
          disabled={merchantCreateStepNumber === 1}
          onClick={() => dispatch(downMerchantCreateStepNumber())}
          startIcon={<ChevronLeftOutlinedIcon />}
        >
          Back
        </Button>
      </div>
      <div className="flex justify-self-end">
        <div>
          <Button
            color="error"
            onClick={() => cancelCreation()}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </div>
        <div>
          {merchantCreateStepNumber !== 4 ? (
            <Button
              color="info"
              disabled={isNextStepDisabled}
              onClick={nextUpdate}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              Next
            </Button>
          ) : (
            <Button color="primary" variant="contained" onClick={onClick}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

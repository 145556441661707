import { components } from 'react-select';

export const SelectorInput = (props) => {
  const length = props.selectProps.value?.length;
  const value = length ? `${length} selected` : '';

  if (props.isHidden) {
    return <components.Input {...props} />;
  }

  return (
    <>
      {value && (
        <span className="text-xs font-bold h-4 whitespace-nowrap mr-1">
          {value}
        </span>
      )}
      <components.Input {...props} className="text-xs font-bold" />
    </>
  );
};

export const SelectorOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className="ml-2 text-xs font-semibold leading-tight text-gray-900">
          {props.label}
        </label>
      </div>
    </components.Option>
  );
};

export const stylesStyles = (props: any) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: props.height ? props.height : '28px',
    marginTop: props.label ? 4 : 0,
    borderColor: props.error ? 'red' : state.isFocused ? '#157BFA' : '#C8CACE',
    boxShadow: state.isFocused ? '0 0 5px 2px rgba(21, 123, 250, 0.4)' : 'none',
    ...(props.control || {}),
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: 0,
    caretColor: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    ...(props.singleValue || {}),
  }),
  option: (provided, state) => ({
    ...provided,
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 0,
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? 'rgba(189,197,209,.3)'
      : provided.backgroundColor,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 99,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
});

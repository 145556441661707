import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';

import { loadItems } from '../thunks';
import { getAuthorizationState } from '../selectors';
import { AuthorizationsList } from './AuthorizationsList';
import { useLocation } from 'react-router-dom';

export const Container = () => {
  const dispatch = useAppDispatch();
  const { page, appliedFilters, rowsPerPage, sort } = useAppSelector(
    getAuthorizationState
  );
  const { t } = useTranslation();

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('transactionsManagement.breadcrumb'),
          route: pathname,
        },
        {
          label: t('transactionsManagement.authorizations.breadcrumb'),
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(() => {
    dispatch(loadItems());
  }, [dispatch, page, appliedFilters, rowsPerPage, sort]);

  return <AuthorizationsList />;
};

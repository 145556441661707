import { FormEvent, KeyboardEventHandler, ReactNode, Ref } from 'react';
import classNames from 'classnames';

export type InputProps = {
  label?: any;
  subLabel?: any;
  disabled?: boolean;
  labelFontClassName?: string;
  labelColorClassName?: string;
  name?: string;
  type?: 'text' | 'password' | 'number';
  icon?: ReactNode;
  error?: any;
  value?: string;
  defaultValue?: string;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: KeyboardEventHandler;
  inputProps?: any;
  inputHeightClassName?: string;
  iconTop?: string;
  min?: number;
  max?: number;
  step?: number;
  isBottomError?: boolean;
};

export type TextAreaProps = {
  label?: any;
  disabled?: boolean;
  labelFontClassName?: string;
  labelColorClassName?: string;
  name?: string;
  inputRef?: Ref<HTMLInputElement & HTMLTextAreaElement>;
  type?: 'text';
  icon?: ReactNode;
  error?: any;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: KeyboardEventHandler;
  inputProps?: any;
};

export const getInputProps = ({
  error,
  hasError,
  className,
  placeholder,
  name,
  disabled = false,
  icon = false,
}: Pick<
  InputProps,
  | 'type'
  | 'error'
  | 'hasError'
  | 'className'
  | 'placeholder'
  | 'name'
  | 'disabled'
  | 'icon'
>) => ({
  id: name,
  placeholder,
  className: classNames(
    'block w-full mt-1 form-input text-xs font-bold cursor-pointer rounded',
    {
      'border-red-500 focus:border-red-500 focus:shadow-outline-red':
        !!error || hasError,
      'bg-gray-300 text-gray-700': disabled,
      'pr-10': icon,
    },
    className
  ),
});

export const getTextAreaProps = ({
  error,
  hasError,
  className,
  placeholder,
  name,
  disabled = false,
}: Pick<
  TextAreaProps,
  | 'type'
  | 'error'
  | 'hasError'
  | 'className'
  | 'placeholder'
  | 'name'
  | 'disabled'
>) => ({
  id: name,
  name: name,
  placeholder,
  className: classNames(
    'block w-full mt-1 form-input h-24 text-xs',
    {
      'border-red-500 focus:border-red-500 focus:shadow-outline-red':
        !!error || hasError,
      'bg-gray-300 text-gray-700': disabled,
    },
    className
  ),
});

export const onKeyPressAllowPattern =
  (pattern: RegExp): KeyboardEventHandler =>
  (e) => {
    if (!pattern.test(e.key)) {
      e.preventDefault();

      e.stopPropagation();
    }
  };

export const onKeyPressDisallowSpecialCharacters: KeyboardEventHandler = (
  e
) => {
  if (/\W|_/g.test(e.key)) {
    e.preventDefault();

    e.stopPropagation();
  }
};

export const onKeyPressAllowCharacters: KeyboardEventHandler = (e) => {
  if (/[0-9]/i.test(e.key)) {
    e.preventDefault();

    e.stopPropagation();
  }
};

export const defaultStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: 32,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: 0,
    caretColor: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    paddingLeft: 8,
    paddingBottom: 0,
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? 'rgba(189,197,209,.3)'
      : provided.backgroundColor,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 99,
  }),
};

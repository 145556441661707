export type CardPanGetDetails = {
  id: string;
  cardNumber: any;
  blockedOn: any;
  paymentAttempts: any;
  blockedBy: any;
  reason: any;
};

export const details = {
  id: '',
  cardNumber: null,
  blockedOn: null,
  paymentAttempts: null,
  reason: null,
  blockedBy: null,
};

export type CardPanItem = {
  id: string;
  cardNumber: string;
  blockedOn: string;
  paymentAttempts: number;
};

export type FiltersParam = {
  cardNumber: string;
};

export const itemFilters: FiltersParam = {
  cardNumber: '',
};

export const listRequestPayload = (options: FiltersParam) => ({
  filters: {
    cardNumber: options.cardNumber.replace(/\s/g, '').length
      ? options.cardNumber.replace(/\s/g, '')
      : undefined,
  },
});

import { Line } from 'react-chartjs-2';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { endDate, startDate, TotalTransactionFor30Days } from '../utils';
import { useAppSelector } from '../../../hooks/redux';
import { getCardInfo, getItemsLoadingComputed } from '../selectors';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { RectLoader } from 'components/RectLoader';

export const TotalAuthorizationsVsSettlementChartComponent = () => {
  const cardInfoItem = useAppSelector(getCardInfo);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const transactionDaysPeriod = useMemo(() => {
    const dateArray = [] as TotalTransactionFor30Days[];
    const currentDate = moment();
    let stopDate = moment().subtract(30, 'days');
    while (currentDate > stopDate) {
      const dayFormat = moment(stopDate).format('YYYY-MM-DD');
      const find = cardInfoItem.totalAuthorizationsFor30DaysDto.find(
        (it) => it.transactionDate === dayFormat
      );
      dateArray.push({
        transactionDate: moment(stopDate).format('MMM-DD'),
        numberOfTransactions: find ? find.numberOfTransactions : 0,
      });
      stopDate = moment(stopDate).add(1, 'days');
    }

    return dateArray;
  }, [cardInfoItem.totalAuthorizationsFor30DaysDto]);

  const settlementDaysPeriod = useMemo(() => {
    const dateArray = [] as TotalTransactionFor30Days[];
    const currentDate = moment();
    let stopDate = moment().subtract(30, 'days');
    while (currentDate > stopDate) {
      const dayFormat = moment(stopDate).format('YYYY-MM-DD');
      const find = cardInfoItem.totalTransactionFor30Days.find(
        (it) => it.transactionDate === dayFormat
      );
      dateArray.push({
        transactionDate: moment(stopDate).format('MMM-DD'),
        numberOfTransactions: find ? find.numberOfTransactions : 0,
      });
      stopDate = moment(stopDate).add(1, 'days');
    }

    return dateArray;
  }, [cardInfoItem.totalTransactionFor30Days]);

  const totalTransactionOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        color: '#000000',
        font: {
          size: 14,
          weight: 'normal' as const,
        },
        text: `Total Authorizations vs Settlements for ${startDate} - ${endDate}`,
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 8,
        hoverBackgroundColor: '1976D2',
        hitRadius: 20,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const totalTransactionData = {
    labels: transactionDaysPeriod.map((it) => it.transactionDate),
    datasets: [
      {
        label: 'Total Authorization transactions',
        data: transactionDaysPeriod.map((it) => it.numberOfTransactions),
        fill: false,
        borderColor: '#1976D2',
        tension: 0.1,
      },
      {
        label: 'Total Settlement transactions',
        data: settlementDaysPeriod.map((it) => it.numberOfTransactions),
        fill: false,
        borderColor: '#ec0215',
        tension: 0.1,
      },
    ],
    options: {
      animations: {
        radius: {
          duration: 400,
          easing: 'linear',
        },
      },
      interaction: {
        mode: 'point',
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const sizeWidth = useMemo(() => (windowSize[0] < 2500 ? 6 : 3), [windowSize]);

  return (
    // <div className="flex col-span-8 xxl:col-span-12 items-center justify-center justify-items-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
    <div className="flex col-span-8 xxl:col-span-7 items-center justify-center justify-items-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      {itemsLoading ? (
        <RectLoader width={150} height={28} />
      ) : (
        <Line
          options={totalTransactionOptions}
          data={totalTransactionData}
          height={sizeWidth}
          width={11}
        />
      )}
    </div>
  );
};

import { useAppSelector } from 'hooks/redux';
import { StepWizardComponent } from './StepWizardComponent';
import { MerchantMethodCreateComponent } from './MerchantMethodCreateComponent';
import { MerchantAccountHolderCreateComponent } from './MerchantAccountHolderCreateComponent';
import {
  getIsMerchantCreationLoading,
  getMerchantCreateStepStatus,
} from '../selectors';
import { MerchantCreationSummaryComponent } from './MerchantCreationSummaryComponent';
import { MerchantInfoCreateComponent } from './MerchantInfoCreateComponent';
import { RectLoader } from 'components/RectLoader';

export const MerchantFormNew = () => {
  const merchantCreateStepNumber = useAppSelector(getMerchantCreateStepStatus);
  const isMerchantCreationLoading = useAppSelector(
    getIsMerchantCreationLoading
  );

  return (
    <div className="grid h-full p-3 pt-19 items-start justify-center overflow-auto custom-scrollbar">
      <div className="grid h-auto bg-white border rounded-md box-shadow-dark 3xl:w-279 4xl:w-282 5xl:w-288">
        {isMerchantCreationLoading ? (
          <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
            <div className="w-full">
              <RectLoader width={150} height={18} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center py-10">
              <StepWizardComponent />
            </div>
            {merchantCreateStepNumber === 1 && <MerchantInfoCreateComponent />}
            {merchantCreateStepNumber === 2 && (
              <MerchantMethodCreateComponent />
            )}
            {merchantCreateStepNumber === 3 && (
              <MerchantAccountHolderCreateComponent />
            )}
            {merchantCreateStepNumber === 4 && (
              <MerchantCreationSummaryComponent />
            )}
          </>
        )}
      </div>
    </div>
  );
};

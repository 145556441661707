import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RowsPerPage } from 'entities/common';
import {
  Details,
  TransactionItem,
} from 'entities/transaction-management/transactions';
import {
  defaultFilters,
  ListFilters,
  mappedData,
  transactionDetails,
} from './utils';
import { fetchTransactionDetails, loadItems } from './thunks';
import { ItemSort } from '../../../entities';

export const storeName = 'tmTransaction';

export interface TransactionSliceProps {
  items: TransactionItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  itemNotFound: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  filters: ListFilters;
  sort: ItemSort | null;
  appliedFilters: ListFilters;
  transactionDetails: Details;
  isTransactionDetailsLoading: boolean;
}

const initialState: TransactionSliceProps = {
  items: [] as TransactionItem[],
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  itemNotFound: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
  transactionDetails: transactionDetails,
  isTransactionDetailsLoading: false,
};

const transactionSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
    resetAll(state) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = initialState.sort;
      state.filters = initialState.filters;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = initialState.appliedFilters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionDetails.fulfilled, (state, action) => {
      state.isTransactionDetailsLoading = false;
      state.itemNotFound = false;
      state.transactionDetails = action.payload;
    });
    builder.addCase(fetchTransactionDetails.pending, (state) => {
      state.isTransactionDetailsLoading = true;
    });
    builder.addCase(fetchTransactionDetails.rejected, (state) => {
      state.isTransactionDetailsLoading = false;
      state.itemNotFound = true;
      state.transactionDetails = transactionDetails;
    });
    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{ data: TransactionItem[]; paging: any }>
      ) => {
        const data = action.payload.data;
        state.items = mappedData(data);
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  resetAll,
  setSort,
  setFilters,
} = transactionSlice.actions;

export default transactionSlice.reducer;

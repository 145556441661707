import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';

import { Input } from 'components/inputs';
import React from 'react';

interface FilterMerchantExternalReferenceProps {
  register: UseFormReturn['register'];
}

export const FilterMerchantExternalReference: React.FC<
  FilterMerchantExternalReferenceProps
> = ({ register }) => {
  const { t } = useTranslation();

  return (
    <Input
      label={t('transactionsManagement.authorizations.list.reference')}
      labelFontClassName="text-xs font-medium"
      className="h-8 text-xs font-bold"
      inputProps={register('merchantExternalReference')}
    />
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { complianceAuditResource } from './api';

export const loadCardInfoItems: any = createAsyncThunk(
  'complianceAudit/loadCardInfoItems',
  async (data: { merchantId: string }) => {
    try {
      const response = await complianceAuditResource.getRecordInfoItem(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadTotalSalesPerDayItems: any = createAsyncThunk(
  'complianceAudit/total-sales-per-day',
  async (data: { merchantId: string; dateRange: any }) => {
    try {
      const response =
        await complianceAuditResource.getTotalSalesPerDayItem(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadTotalSalesPerMonthItems: any = createAsyncThunk(
  'complianceAudit/total-sales-per-month',
  async (data: { merchantId: string; dateRange: any }) => {
    try {
      const response =
        await complianceAuditResource.getTotalSalesPerMonthItem(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadTotalSalesRollingPerDayItems: any = createAsyncThunk(
  'complianceAudit/total-sales-rolling-per-day',
  async (data: { merchantId: string; date: any; rangeType: any }) => {
    try {
      const response =
        await complianceAuditResource.getTotalSalesRollingPerDayItem(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const loadTotalSalesRollingPerMonthItems: any = createAsyncThunk(
  'complianceAudit/total-sales-rolling-per-month',
  async (data: { merchantId: string; date: any }) => {
    try {
      const response =
        await complianceAuditResource.getTotalSalesRollingPerMonthItem(data);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

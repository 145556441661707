import { createAsyncThunk } from '@reduxjs/toolkit';

import { listRequestPayload } from './utils';
import { unmatchedTransactionsResource } from './api';
import { RootState } from 'store/store';

import { getUnmatchedTransactionState } from './selectors';
import { LoadItems } from 'entities';
import { dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';

export const loadItems: any = createAsyncThunk(
  'tmUnmatchedTransaction/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { appliedFilters, page, rowsPerPage, sort } =
        getUnmatchedTransactionState(getState() as RootState);
      const dateZone = dateTimeZone(getState() as RootState);
      const dateFormatVal = (value: any) =>
        dateTimeFormatUtil(value, 'YYYY-MM-DD', dateZone);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...listRequestPayload(appliedFilters, dateFormatVal),
      };

      return await unmatchedTransactionsResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

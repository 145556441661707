import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { storeName } from './apiSlice';

export const getRoot = (state: RootState) => state[storeName];

export const getMainState = createSelector([getRoot], (state) => state);

export const getItems = createSelector([getRoot], ({ items }) => items);
export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

export const getItemDetail = createSelector(
  [getRoot],
  ({ details }) => details
);

export const getItemsNotFound = createSelector(
  [getRoot],
  ({ itemNotFound }) => itemNotFound
);

export const getDetailsLoading = createSelector(
  [getRoot],
  ({ isDetailsLoading }) => isDetailsLoading
);

export const getFilters = createSelector([getRoot], ({ filter }) => filter);

export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);

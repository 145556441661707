import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input } from 'components/inputs';

import { getAppliedFilters, getFilters } from '../../selectors';
import { defaultFilters, FiltersParams } from '../../utils';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../programsSlice';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { FilterSelect } from 'components/filters/FilterSelect';
import { corporateList } from 'api/corporates';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { processorList } from 'api/processors';
import { useFilterEffect } from '../../../../../utils/useFilterEffect';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const permissions: any = useAppSelector(getProfilePermission);
  const filters: FiltersParams = useAppSelector(getFilters);
  const appliedFilters: FiltersParams = useAppSelector(getAppliedFilters);
  const hasProcessorsPermission = permissions.includes(
    UserPermissions.PROCESSOR_LIST
  );
  const hasCorporatePermission = permissions.includes(
    UserPermissions.CORPORATE_LIST
  );

  const {
    register,
    watch,
    reset: resetFilters,
    control,
    handleSubmit,
  } = useForm<any>({
    defaultValues: appliedFilters,
  });

  const allValues = watch();
  const previousValues = useRef<FiltersParams>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const merchantsHasFilter = [
    filters.programName.length ||
      filters.programUrl.length ||
      filters.corporates.length ||
      filters.processor.length,
  ].some((value) => !!value);

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('programs.list.filters.program')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('programName'),
        }}
      />
    ),
    1: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('programs.list.filters.url')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('programUrl'),
        }}
      />
    ),
    2: hasProcessorsPermission ? (
      <FilterSelect
        name="processor"
        label={t('merchants.list.processor')}
        loadOptions={processorList}
        control={control}
        isClearable={false}
      />
    ) : null,
    3: hasCorporatePermission ? (
      <FilterSelect
        name="corporates"
        label={t('programs.list.filters.corporates')}
        loadOptions={corporateList}
        control={control}
        isClearable={false}
      />
    ) : null,
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters());
    if (toggleDrawer) toggleDrawer();
  };

  const reset = () => {
    dispatch(resetAllFilters());
    resetFilters(defaultFilters);
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!merchantsHasFilter}
      isDisabledApply={!merchantsHasFilter}
    />
  );
};

import api from 'api';

export type Corporate = {
  createdTime: string;
  id: string;
  name: string;
  primaryRepresentative: any;
  type: string;
};

export const corporatePost = (options: any) => {
  return api.post('/dropdown/corporates', options);
};

export const corporateList = async (options = {} as any) => {
  try {
    const { data } = await corporatePost(options);

    return data;
  } catch (e) {
    return [];
  }
};

import { ItemAttributes } from 'components/itemAttributeType';

export enum KeyStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export const keyStatusItems: ItemAttributes[] = [
  { label: KeyStatus.ACTIVE, value: KeyStatus.ACTIVE },
  { label: KeyStatus.DISABLED, value: KeyStatus.DISABLED },
];

export type FiltersParams = {
  companyName: string | null;
  keyPrefix: string | null;
  businessName: string | null;
  enabled: ItemAttributes[];
};

export const defaultFilters: FiltersParams = {
  companyName: null,
  keyPrefix: null,
  businessName: null,
  enabled: [] as ItemAttributes[],
};

const isActiveStatus = (status: string) => status === KeyStatus.ACTIVE;

export const listRequestPayload = (options: FiltersParams) => ({
  enabled:
    options.enabled?.map(({ value }) => isActiveStatus(value)) || undefined,
  keyPrefix: options.keyPrefix || undefined,
  companyName: options.companyName || undefined,
  businessName: options.businessName || undefined,
});

export type SecretKeyItem = {
  companyName: string;
  keyPrefix: string;
  businessName: string;
  isEnabled: boolean;
  lastUsed: string;
  lastUpdatedTime: string;
};

import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { getAppliedFilters, getFilters } from '../../selectors';
import { defaultFilters, FiltersParams } from '../../utils';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../serviceMonitorSlice';
import { useForm } from 'react-hook-form';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { useFilterEffect } from 'utils/useFilterEffect';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const dispatch = useAppDispatch();

  const appliedFilters: FiltersParams = useAppSelector(getAppliedFilters);
  const filters: FiltersParams = useAppSelector(getFilters);

  const { control, handleSubmit, reset, watch } = useForm<any>({
    defaultValues: appliedFilters,
  });

  const allValues = watch();
  const previousValues = useRef<FiltersParams>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const resetFilter = () => {
    dispatch(resetAllFilters());
    reset(defaultFilters);
  };

  const handleDateChange = (callback: any, dates: any) => {
    if (dates) {
      setDateFilter(callback, {
        from: dates,
        to: null,
      });
    }
  };

  const filterComponentsList = {
    0: (
      <DatePickerControl
        control={control}
        label="Select Date"
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH_NO_RANGE}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="day"
        maxDate={new Date()}
        footer={<></>}
        title="Select Date"
      />
    ),
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  const monitoringHasFilter = [filters.day?.from].some((value) => !!value);

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={resetFilter}
      isDisabledClear={!monitoringHasFilter}
      isDisabledApply={!monitoringHasFilter}
    />
  );
};

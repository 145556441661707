export const IconEmpty = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#F2F4F5" />
    <path
      d="M23.75 18.5H13.25"
      stroke="#87949E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.75 15.5H13.25"
      stroke="#87949E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.75 21.5H13.25"
      stroke="#87949E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.75 24.5H13.25"
      stroke="#87949E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';
import moment from 'moment';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { RectLoader } from 'components/RectLoader';

import { endDate, startDate, TotalTransactionFor30Days } from '../utils';
import { getCardInfo, getItemsLoadingComputed } from '../selectors';
import { useAppSelector } from 'hooks/redux';

export const TotalSettlementsChartComponent = () => {
  const cardInfoItem = useAppSelector(getCardInfo);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const transactionDaysPeriod = useMemo(() => {
    const dateArray = [] as TotalTransactionFor30Days[];
    const currentDate = moment();
    let stopDate = moment().subtract(30, 'days');
    while (currentDate > stopDate) {
      const dayFormat = moment(stopDate).format('YYYY-MM-DD');
      const find = cardInfoItem.totalTransactionFor30Days.find(
        (it) => it.transactionDate === dayFormat
      );
      dateArray.push({
        transactionDate: moment(stopDate).format('MMM-DD'),
        numberOfTransactions: find ? find.numberOfTransactions : 0,
      });
      stopDate = moment(stopDate).add(1, 'days');
    }

    return dateArray;
  }, [cardInfoItem.totalTransactionFor30Days]);

  const totalTransactionOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        color: '#000000',
        font: {
          weight: 'normal' as const,
          size: 14,
        },
        text: `Total Settlements for ${startDate} - ${endDate}`,
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 8,
        hoverBackgroundColor: '1976D2',
        hitRadius: 20,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const totalTransactionData = {
    labels: transactionDaysPeriod.map((it) => it.transactionDate),
    datasets: [
      {
        label: 'Total Settlements transactions',
        data: transactionDaysPeriod.map((it) => it.numberOfTransactions),
        fill: false,
        borderColor: '#ec0215',
        tension: 0.1,
      },
    ],
    options: {
      animations: {
        radius: {
          duration: 400,
          easing: 'linear',
          loop: (context) => context.active,
        },
      },
      interaction: {
        mode: 'point',
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
  };

  return (
    <div className="flex col-span-8 lg:col-span-4 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      {itemsLoading ? (
        <RectLoader width={150} height={28} />
      ) : (
        <Line
          options={totalTransactionOptions}
          data={totalTransactionData}
          height="350px"
          width="100%"
        />
      )}
    </div>
  );
};

import api from 'api';

const list = async (data: any) => {
  return api.post('/admins/list', data);
};

const loggingList = async (data: any) => {
  return api.post('admins/logging-details', data);
};

const fetchDetails = async (data: { adminId: string }) => {
  return api.post('/admins/get', data);
};

const updateUser = async (data: any) => {
  return api.post('/admins/update', data);
};

const createUser = async (data: any) => {
  return api.post('/admins/add', data);
};

const activateUser = async (data: { adminId: string }) => {
  return api.post('/admins/activate', data);
};

const disableUser = async (data: { adminId: string }) => {
  return api.post('/admins/disable', data);
};

const resendInvitation = async (data: { adminId: string }) => {
  return api.post('/admins/invitation/resend', data);
};

const passwordResetRequest = async (data: { id: string }) => {
  return api.post('/account/reset-password', data);
};

export const emailNotificationTypesList = () => {
  return api.post('dropdown/email-notification-types');
};

export const userResource = {
  activateUser,
  disableUser,
  list,
  updateUser,
  createUser,
  fetchDetails,
  resendInvitation,
  passwordResetRequest,
  loggingList,
  emailNotificationTypesList,
};

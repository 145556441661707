import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getItemsLoadingComputed,
  getTotalSalesItems,
  getTotalSalesPerMonthLoading,
} from '../selectors';
import { monthlyInitRange } from './ComplianceAuditIndexComponent';
import { useSelector } from 'react-redux';
import { dateTimeZone } from 'domain/account/selectors';
import Divider from '@mui/joy/Divider';
import moment from 'moment-timezone';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { loadTotalSalesPerMonthItems } from '../thunks';
import {
  dateMonthFormatUtil,
  dateTimeFormatUtil,
  generationMonthYearFormat,
} from 'components/dateTimeFormatUtil';
import { TotalMonthlySalesChartComponent } from './charts-components/TotalMonthlySalesChartComponent';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';

type TotalDailySalesProps = {
  control: any;
  watch: any;
  setValue: any;
  merchantRef: any;
};

export const TotalMonthlySalesComponent: React.FC<TotalDailySalesProps> = ({
  control,
  watch,
  setValue,
  merchantRef,
}) => {
  const dispatch = useAppDispatch();
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const totalSalesItems = useAppSelector(getTotalSalesItems);
  const dateZone = useSelector(dateTimeZone);
  const totalSalesPerMonthLoading = useAppSelector(
    getTotalSalesPerMonthLoading
  );

  const dateMonthFormatVal = (value: any) =>
    dateMonthFormatUtil(value, dateZone);

  const monthFormatVal = (value: any) =>
    dateTimeFormatUtil(value, generationMonthYearFormat, dateZone);

  const { totalMonthlySalesRange } = watch();
  const [monthlyRangeState, setMonthlyRangeState] = useState<any>(null);
  const [isDefaultRange, setDefaultRangeState] = useState<boolean>(false);
  const [isApprovalRange, setApprovalRangeState] = useState<any>(null);

  const initMonthRange = useMemo(() => {
    const monthlyInitRange1 = monthlyInitRange(
      totalSalesItems.approvalDate,
      dateZone
    );
    setMonthlyRangeState(monthlyInitRange1);
    if (merchantRef.current) {
      setValue('totalMonthlySalesRange', monthlyInitRange1);
      if (totalSalesItems.approvalDate) {
        setApprovalRangeState(monthlyInitRange1);
      }
    }
    return monthlyInitRange1;
  }, [merchantRef.current, totalSalesItems.approvalDate]);

  const clickDefaultRange = () => {
    setDefaultRangeState(true);
    setValue('totalMonthlySalesRange', initMonthRange);
  };

  const isMonthRangeNotChanged =
    monthFormatVal(monthlyRangeState?.to) ===
      monthFormatVal(totalMonthlySalesRange.to) &&
    monthFormatVal(monthlyRangeState?.from) ===
      monthFormatVal(totalMonthlySalesRange.from);

  const isMonthRangeNone =
    !totalMonthlySalesRange.from || !totalMonthlySalesRange.to;

  const isApprovalRangeSet =
    totalMonthlySalesRange && isApprovalRange
      ? monthFormatVal(totalMonthlySalesRange?.to) ===
          monthFormatVal(isApprovalRange.to) &&
        monthFormatVal(totalMonthlySalesRange?.from) ===
          monthFormatVal(isApprovalRange.from)
      : false;

  const isMonthlyRangeDisabled = () => {
    if (isApprovalRangeSet) {
      return false;
    }
    const monthlyDateRangeDiffDays = moment(totalMonthlySalesRange?.to)
      ?.tz(dateZone)
      .diff(totalMonthlySalesRange.from, 'months');

    if (totalSalesItems.approvalDate && isDefaultRange) {
      const beforeApprovalDate = moment(totalMonthlySalesRange?.from)
        .tz(dateZone)
        .isBefore(totalSalesItems.approvalDate, 'month');
      setDefaultRangeState(false);
      return monthlyDateRangeDiffDays + 1 < 3 || beforeApprovalDate;
    }

    return (
      monthlyDateRangeDiffDays + 1 < 3 || monthlyDateRangeDiffDays + 1 >= 13
    );
  };

  const onTotalSalesPerMonthApply = () => {
    dispatch(
      loadTotalSalesPerMonthItems({
        merchantId: merchantRef.current?.value,
        dateRange: {
          from: monthFormatVal(totalMonthlySalesRange.from),
          to: monthFormatVal(totalMonthlySalesRange.to),
        },
      })
    );
    setMonthlyRangeState(totalMonthlySalesRange);
  };

  const showRangeValue = (value: any) => {
    return value?.to && value?.from
      ? `${dateMonthFormatVal(value.from)} : ${dateMonthFormatVal(value.to)}`
      : '';
  };

  const handleChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, { from: start, to: null });
    }

    if (start && end) {
      setDateFilter(callback, { from: start, to: end });
    }
  };

  return (
    <div className="col-span-2 l1250g:col-span-1 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      <TotalMonthlySalesChartComponent dateRange={monthlyRangeState} />
      <div className="m-3">
        <Divider />
      </div>
      {merchantRef.current && !itemsLoading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 sm:col-span-2">
            <DatePickerControl
              control={control}
              popoverWidth={320}
              errorMessage={
                isMonthlyRangeDisabled()
                  ? 'Range min 3 months - max 12 months '
                  : null
              }
              name="totalMonthlySalesRange"
              datePickerType={DatePickerType.MONTH_RANGE_SELECT}
              showValueChange={showRangeValue}
              handleChange={handleChange}
              maxDate={moment().subtract(1, 'd').toDate()}
              title="Monthly Range"
              footer={
                <div className="grid justify-center justify-items-center m-4">
                  <ButtonGroup
                    variant="outlined"
                    size="small"
                    aria-label="outlined button group"
                  >
                    <Button onClick={clickDefaultRange}>
                      Default Range Period
                    </Button>
                  </ButtonGroup>
                </div>
              }
            />
          </div>
          <div className="items-end justify-stretch justify-items-end mt-1 w-full col-span-3 sm:col-span-1">
            <LoadingButton
              size="small"
              variant="contained"
              disabled={isMonthlyRangeDisabled() || isMonthRangeNone}
              style={{ width: '100%' }}
              loading={totalSalesPerMonthLoading}
              onClick={onTotalSalesPerMonthApply}
            >
              View Data
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

export enum TransactionStatus {
  'PROCESSED' = 'A',
  'REJECTED' = 'R',
}

export enum TransactionType {
  'SALE' = '5',
  'CREDIT' = '6',
  'CASH_ADVANCE' = '7',
  'CONDITIONAL_DEPOSIT_SALE' = '8',
  'CONDITIONAL_DEPOSIT_CASH_ADVANCE' = '9',
  'SALE_AUTH_REQUEST' = 'A',
  'CASH_ADVANCE_AUTH_REQUEST' = 'B',
  'AMERICAN_EXPRESS_AUTH_REQUEST' = 'C',
  'CARTE_BLANCHE_AUTH_REQUEST' = 'D',
  'DINERS_CLUB_AUTH_REQUEST' = 'E',
  'JBS_AUTH_REQUEST' = 'F',
  'PRIVATE_LABEL_AUTH_REQUEST' = 'G',
  'ACCOUNT_VERIFY_REQUEST' = 'H',
  'REGULAR_VOID' = 'X',
  'VOID_UNDELIVERED' = 'Y',
  'VOID_REVERSAL' = 'Z',
}

export enum CardType {
  'MASTERCARD' = 'MasterCard',
  'VISA' = 'Visa',
  'AMEX' = 'Amex',
  'JCB' = 'JCB',
  'DISCOVER' = 'Discover',
  'DINERS' = 'Diners',
}

export enum MatchType {
  'PENDING' = 'PENDING',
  'SUCCESS' = 'SUCCESS',
  'DUP' = 'DUP',
  'NEQSTAN' = 'NEQSTAN',
  'NEQAMT' = 'NEQAMT',
  'NEQNWID' = 'NEQNWID',
  'NOAUTH' = 'NOAUTH',
  'NOSTL' = 'NOSTL',
}

export enum MatchTypeLabel {
  'PENDING' = 'Pending',
  'SUCCESS' = 'Success',
  'DUPLICATE' = 'Duplicate',
  'NEQSTAN' = 'Not equal STAN',
  'NEQAMT' = 'Not equal amount',
  'NEQNWID' = 'Not equal network',
  'NOAUTH' = 'Not equal auth id',
  'NOSTL' = 'Not equal order number',
}

export type TransactionItem = {
  id: string;
  settlementDate: string;
  cardType: CardType;
  cardBrand: string;
  cardNumber: string;
  terminalId: string;
  authorizationCode: string;
  referenceNumber: string;
  transactionDate: string;
  transactionAmount: number;
  settlementCurrencyCode: string;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  transactionId: string;
  transactionTime: string;
  subMerchantId: string;
  subMerchantName: string;
  matchType: string;
};

export type UnmatchedTransactionItem = {
  id: string;
  cardNumber: string;
  transactionDate: string;
  transactionTime: string;
  currencyCode: string;
  transactionAmount: number;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  cardBrand: string;
  authCode: string;
  merchantName: string;
  matchType: string;
  messageType: string;
};

export type Details = {
  hierarchyId: string;
  dbaName: string;
  terminalId: string;
  locationId: string;
  storeNumber: string;
  merchantId: string;
  cardBrand: CardType | string;
  country: string;
  zip: string;
  cardNumber: string;
  state: string;
  city: string;
  addressLineOne: string;
  addressLineTwo: string;
  expiryDate: string;
  settlementDate: string;
  batchNumber: string;
  batchDate: string;
  authorizationCode: string;
  traceId: string;
  arnNumber: string;
  rrnNumber: string;
  transactionDate: string;
  settlementAmount: number;
  settlementCurrencyCode: string;
  billingCurrencyAmount: number;
  billingCurrencyCode: string;
  currencyConversionRate: number;
  transactionStatus: TransactionStatus | string;
  transactionType: any;
  transactionMode: string;
  transactionSource: string;
  transactionTime: string;
  matchType: string;
  entryMode: string;
  pcTerminalCapability: string;
  posEntryMode: string;
  partialAuthIndicator: string;
  user: string;
};

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input, SelectController } from 'components/inputs';

import { defaultFilters, FiltersParams, keyStatusItems } from '../../utils';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../secretKeyManagementSlice';
import { getProfilePermission } from 'domain/account/selectors';
import { getAppliedFilters, getFilters } from '../../selectors';

import { useFilterEffect } from 'utils/useFilterEffect';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const permissions: any = useAppSelector(getProfilePermission);
  const filters: FiltersParams = useAppSelector(getFilters);
  const appliedFilters: FiltersParams = useAppSelector(getAppliedFilters);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    reset: resetFilters,
  } = useForm<FiltersParams>({ defaultValues: appliedFilters });

  const allValues = watch();
  const previousValues = useRef<FiltersParams>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('secretKeyManagement.filters.companyName')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('companyName'),
        }}
      />
    ),
    1: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('secretKeyManagement.filters.keyPrefix')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('keyPrefix'),
        }}
      />
    ),
    2: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('secretKeyManagement.filters.businessName')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('businessName'),
        }}
      />
    ),
    3: (
      <SelectController
        name="enabled"
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('secretKeyManagement.filters.enabled')}
            className="font-medium tracking-wide text-xs text-black"
          />
        }
        height="2.5rem"
        options={keyStatusItems}
        control={control}
        isClearable={false}
        isMulti
      />
    ),
  };

  const usersHasFilter = [
    filters.enabled.length ||
      filters.keyPrefix?.length ||
      filters.businessName?.length ||
      filters.companyName?.length,
  ].some((value) => !!value);

  const reset = () => {
    dispatch(resetAllFilters());
    resetFilters(defaultFilters);
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!usersHasFilter}
      isDisabledApply={!usersHasFilter}
    />
  );
};

import classNames from 'classnames';
import React from 'react';

type CardProps = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  icon?: React.ReactElement;
};

export const Card: React.FC<CardProps> = ({
  children,
  className,
  title,
  icon,
}) => (
  <div
    className={classNames(
      'bg-white border rounded-md box-shadow-dark',
      title ? 'mt-4 p-4' : 'p-2',
      className
    )}
  >
    {title && (
      <div className="-mt-8 w-full sm:w-auto">
        <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-dodger-blue-300 box-shadow-dodger-blue">
          {icon}
          <div className="text-xs text-white ml-2">{title}</div>
        </div>
      </div>
    )}
    {children}
  </div>
);

import { MerchantCreateSteps } from './MerchantCreateSteps';

import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getMerchantAccountHolderItemStatus,
  getMerchantCreateInfoItemStatus,
  getMerchantNMIItemStatus,
  getMerchantRapidConnectItemStatus,
} from '../selectors';
import { createMerchant } from '../thunks';
import { useHistory } from 'react-router-dom';
import { clearMerchantCreate } from '../merchantsSlice';
import React from 'react';
import { Base64 } from 'js-base64';
import { countryStateList, GatewaysValues } from '../utils';

interface SummaryProp {
  labelText: string;
  text: string;
  isRequired?: boolean;
}

export const SummaryContent: React.FC<SummaryProp> = ({
  labelText,
  text,
  isRequired = true,
}) => {
  return (
    <div>
      <div>
        <RequiredFieldComponent isRequired={isRequired} labelText={labelText} />
      </div>
      <span className="text-gray-700">{text}</span>
    </div>
  );
};

export const MerchantCreationSummaryComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const merchantInfoItem = useAppSelector(getMerchantCreateInfoItemStatus);
  const merchantRapidConnectItem = useAppSelector(
    getMerchantRapidConnectItemStatus
  );
  const merchantAccountItem = useAppSelector(
    getMerchantAccountHolderItemStatus
  );
  const merchantNMIItem = useAppSelector(getMerchantNMIItemStatus);

  const isRapidConnect =
    merchantInfoItem.gateway?.value === GatewaysValues.RAPID_CONNECT;

  const createNewMerchant = () => {
    const rapidConnectRequest = {
      terminalId: merchantRapidConnectItem.rapidConnectTerminalId,
      groupId: merchantRapidConnectItem.rapidConnectGroupId,
      merchantExternalId: merchantRapidConnectItem.merchantExternalId,
      primaryServiceUrl: merchantRapidConnectItem.rapidConnectPrimaryServiceUrl,
      secondaryServiceUrl:
        merchantRapidConnectItem.rapidConnectSecondaryServiceUrl,
      merchantCategoryCode:
        merchantRapidConnectItem.rapidConnectMerchantCategoryCode,
      ecommerceUrl: merchantRapidConnectItem.rapidConnectEcommerceUrl,
    };

    const internalNMIMerchantCreate = {
      gateway: GatewaysValues.NMI,
      currency: merchantInfoItem.currency?.value,
      countryCode: merchantInfoItem.countryCode?.value,
      state: merchantInfoItem.stateUS
        ? merchantInfoItem.stateUS?.value
        : merchantInfoItem.state,
      name: merchantInfoItem.merchantName,
      username: merchantNMIItem.username,
      feePlan: merchantNMIItem.feePlan,
      address1: merchantInfoItem.address1,
      address2: merchantInfoItem.address2,
      city: merchantInfoItem.city,
      postal: merchantInfoItem.postal,
      contactFirstName: merchantInfoItem.contactFirstName,
      contactLastName: merchantInfoItem.contactLastName,
      contactPhone: merchantInfoItem.contactPhone,
      contactEmail: merchantInfoItem.contactEmail,
      url: merchantInfoItem.url,
      accountNumber: merchantNMIItem.accountNumber,
      routingNumber: merchantNMIItem.routingNumber,
      locale: merchantNMIItem.locale?.value,
      timezoneId: merchantNMIItem.timezoneId?.value,
      accountType: merchantNMIItem.accountType?.value,
      accountHolderType: merchantNMIItem.accountHolderType?.value,
      merchantType: merchantNMIItem.merchantType?.value,
      captureHigherThanAuthed: merchantNMIItem.captureHigherThanAuthed?.value,
      allowPaymentFacilitatorFields:
        merchantNMIItem.allowPaymentFacilitatorFields?.value,
    };

    const internalFISERVMerchantCreate = {
      gateway: GatewaysValues.FISERV,
      currency: merchantInfoItem.currency?.value,
      countryCode: merchantInfoItem.countryCode?.value,
      state: merchantInfoItem.stateUS
        ? merchantInfoItem.stateUS?.value
        : merchantInfoItem.state,
      name: merchantInfoItem.merchantName,
      url: merchantInfoItem.url,
      address1: merchantInfoItem.address1,
      address2: merchantInfoItem.address2,
      city: merchantInfoItem.city,
      postal: merchantInfoItem.postal,
      contactFirstName: merchantInfoItem.contactFirstName,
      contactLastName: merchantInfoItem.contactLastName,
      contactPhone: merchantInfoItem.contactPhone,
      contactEmail: merchantInfoItem.contactEmail,
    };
    const internalMerchantCreate = isRapidConnect
      ? internalFISERVMerchantCreate
      : internalNMIMerchantCreate;

    const rapidConnectMerchantDetails = isRapidConnect
      ? rapidConnectRequest
      : null;

    const merchantAccountHolderDetails = {
      countryCode: merchantAccountItem.accountHolderCountryCode?.value,
      firstName: merchantAccountItem.accountHolderFirstName,
      lastName: merchantAccountItem.accountHolderLastName,
      email: merchantAccountItem.accountHolderEmail,
      phone: merchantAccountItem.accountHolderPhone,
      birthDate: merchantAccountItem.accountHolderBirthdate,
      billingAddressCountryCode:
        merchantAccountItem.accountHolderBillingAddressCountryCode?.value,
      billingAddressSubDivisionCode:
        merchantAccountItem.accountHolderBillingAddressSubDivisionCode,
      billingAddressCity: merchantAccountItem.accountHolderBillingAddressCity,
      billingAddressPostalCode:
        merchantAccountItem.accountHolderBillingAddressPostalCode,
      billingAddressAddressLine1:
        merchantAccountItem.accountHolderBillingAddressAddressLine1,
      legal_entity_type: merchantAccountItem.legalEntityType,
    };

    dispatch(
      createMerchant({
        programId: merchantInfoItem.program?.value,
        corporateId: merchantInfoItem.corporate?.value,
        merchant: internalMerchantCreate,
        rapidConnectMerchant: rapidConnectMerchantDetails,
        accountHolder: merchantAccountHolderDetails,
      })
    )
      .unwrap()
      .then((response) => {
        history.push(`/system/merchants/details/${Base64.encodeURI(response)}`);
      });
    dispatch(clearMerchantCreate());
  };

  return (
    <>
      <div className="grid grid-cols-1 m2d:grid-cols-2 l2g:grid-cols-3 xl:grid-cols-4 gap-4 px-4 md:px-10 pb-6">
        <div className="xxl:col-span-4 text-xl font-bold">Merchant Info</div>
        <SummaryContent
          labelText="Merchant Name"
          text={merchantInfoItem.merchantName}
        />
        {merchantInfoItem.url && (
          <SummaryContent labelText="URl" text={merchantInfoItem.url} />
        )}
        <SummaryContent
          labelText="Currency"
          text={merchantInfoItem.currency?.label as string}
        />
        <SummaryContent
          labelText="Country"
          text={merchantInfoItem.countryCode?.label as string}
        />
        {merchantInfoItem.countryCode &&
          (merchantInfoItem.countryCode.value === 'US' ||
            countryStateList.includes(merchantInfoItem.countryCode.value)) && (
            <SummaryContent
              labelText="State"
              isRequired={false}
              text={
                merchantInfoItem.stateUS
                  ? merchantInfoItem.stateUS?.value
                  : merchantInfoItem.state
              }
            />
          )}
        <SummaryContent labelText="Address1" text={merchantInfoItem.address1} />
        <SummaryContent
          isRequired={false}
          labelText="Address 2"
          text={merchantInfoItem.address2}
        />
        <SummaryContent labelText="City" text={merchantInfoItem.city} />
        <SummaryContent labelText="Postal" text={merchantInfoItem.postal} />
        <SummaryContent
          labelText="Contact First Name"
          text={merchantInfoItem.contactFirstName}
        />
        <SummaryContent
          labelText="Contact Last Name"
          text={merchantInfoItem.contactLastName}
        />
        <SummaryContent
          labelText="Contact Phone"
          text={merchantInfoItem.contactPhone}
        />
        <SummaryContent
          labelText="Contact Email"
          text={merchantInfoItem.contactEmail}
        />
        <SummaryContent
          labelText="Contact Email"
          text={merchantInfoItem.contactEmail}
        />
        <SummaryContent
          labelText="Corporate"
          text={merchantInfoItem.corporate?.label as string}
        />
        <SummaryContent
          labelText="Program"
          text={merchantInfoItem.program?.label as string}
        />
        {merchantInfoItem && isRapidConnect ? (
          <>
            <div className="xxl:col-span-4 text-xl font-bold">
              {GatewaysValues.FISERV}
            </div>
            <SummaryContent
              labelText="Merchant External ID"
              text={merchantRapidConnectItem.merchantExternalId}
            />
            <SummaryContent
              labelText="Terminal Id"
              text={merchantRapidConnectItem.rapidConnectTerminalId}
            />
            <SummaryContent
              labelText="Group Id"
              text={merchantRapidConnectItem.rapidConnectGroupId}
            />
            <SummaryContent
              labelText="Primary Service Url"
              text={merchantRapidConnectItem.rapidConnectPrimaryServiceUrl}
            />
            <SummaryContent
              labelText="Secondary Service Url"
              isRequired={false}
              text={merchantRapidConnectItem.rapidConnectSecondaryServiceUrl}
            />
            <SummaryContent
              labelText="Ecommerce Url"
              text={merchantRapidConnectItem.rapidConnectEcommerceUrl}
            />
            <SummaryContent
              labelText="Merchant Category Code"
              text={merchantRapidConnectItem.rapidConnectMerchantCategoryCode}
            />
          </>
        ) : (
          <>
            <div className="xxl:col-span-4 text-xl font-bold">
              {GatewaysValues.NMI}
            </div>
            {merchantNMIItem.accountNumber && (
              <SummaryContent
                labelText="Account Number"
                text={merchantNMIItem.accountNumber}
              />
            )}
            <SummaryContent
              labelText="Routing Number"
              isRequired={true}
              text={merchantNMIItem.routingNumber!}
            />
            <SummaryContent
              labelText="User name"
              isRequired={false}
              text={merchantNMIItem.username}
            />
            <SummaryContent
              labelText="Fee Plan"
              isRequired={false}
              text={merchantNMIItem.feePlan}
            />
            <SummaryContent
              labelText="Locale"
              isRequired={false}
              text={merchantNMIItem.locale?.label as string}
            />
            <SummaryContent
              labelText="Time Zone Id"
              isRequired={false}
              text={merchantNMIItem.timezoneId?.label as string}
            />
            <SummaryContent
              labelText="Account Type"
              isRequired={false}
              text={merchantNMIItem.accountType?.label as string}
            />
            <SummaryContent
              labelText="Account Holder Type"
              isRequired={false}
              text={merchantNMIItem.accountHolderType?.label as string}
            />
            <SummaryContent
              labelText="Merchant Type"
              isRequired={false}
              text={merchantNMIItem.merchantType?.label as string}
            />
            <SummaryContent
              labelText="Capture Higher Than Authed"
              isRequired={false}
              text={merchantNMIItem.captureHigherThanAuthed?.label as string}
            />
            <SummaryContent
              labelText="Allow Payment Facilitator Fields"
              isRequired={false}
              text={
                merchantNMIItem.allowPaymentFacilitatorFields?.label as string
              }
            />
          </>
        )}
        <div className="xxl:col-span-4 text-xl font-bold">
          Account Holder Info
        </div>
        <SummaryContent
          labelText="First Name"
          text={merchantAccountItem.accountHolderFirstName}
        />
        <SummaryContent
          labelText="Last Name"
          text={merchantAccountItem.accountHolderLastName}
        />
        <SummaryContent
          labelText="Phone"
          text={merchantAccountItem.accountHolderPhone}
        />
        <SummaryContent
          labelText="Email"
          text={merchantAccountItem.accountHolderEmail}
        />
        <SummaryContent
          labelText="Birthdate"
          text={merchantAccountItem.accountHolderBirthdate}
        />
        <SummaryContent
          labelText="Country Code"
          text={merchantAccountItem.accountHolderCountryCode?.label as string}
        />
        <SummaryContent
          labelText="Billing Address Country Code"
          text={
            merchantAccountItem.accountHolderBillingAddressCountryCode
              ?.label as string
          }
        />
        <SummaryContent
          labelText="Billing Address City"
          text={merchantAccountItem.accountHolderBillingAddressCity}
        />
        <SummaryContent
          labelText="Billing Address Address Line1"
          text={merchantAccountItem.accountHolderBillingAddressAddressLine1}
        />
        <SummaryContent
          labelText="Billing Address Postal Code"
          text={merchantAccountItem.accountHolderBillingAddressPostalCode}
        />
        <SummaryContent
          labelText="Billing Address Sub Division Code"
          text={merchantAccountItem.accountHolderBillingAddressSubDivisionCode}
        />
        <SummaryContent
          labelText="Legal Entity Type"
          text={merchantAccountItem.legalEntityType}
        />
      </div>
      <MerchantCreateSteps
        isNextStepDisabled={false}
        onClick={createNewMerchant}
      />
    </>
  );
};

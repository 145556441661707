import api from 'api';

const reportListPost = async (data: any) => {
  return api.post('/reports/list', data);
};

const merchantReportListPost = async (data: any) => {
  return api.post('/merchant-portal/reports/list', data);
};

const merchantReportDownloadPost = async (data: any) => {
  return api.post('/merchant-portal/reports/download', data);
};

const merchantReportDetails = async (data: any) => {
  return api.post('/merchant-portal/reports/get', data);
};

const merchantGenerateReport = async (data: any) => {
  return api.post('/merchant-portal/reports/create', data);
};

const merchantUpdateReportStatus = async (data: any) => {
  const response: any = await api.post(
    '/merchant-portal/reports/status/update',
    data
  );

  return {
    reportData: {
      data: {
        status: response.status,
      },
    },
  };
};

const listDownload = async (data: any) => {
  return api.post('/reports/download', data);
};

const merchantListDownload = async (data: any) => {
  return api.post('/merchant-portal/reports/download', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/reports/get', data);
};

const generateReport = async (data: any) => {
  return api.post('/reports/create', data);
};

const updateReportStatus = async (data: any) => {
  const response: any = await api.post('/reports/status/update', data);

  return {
    reportData: {
      data: {
        status: response.status,
      },
    },
  };
};

const updateMerchantReportStatus = async (data: any) => {
  const response: any = await api.post(
    '/merchant-portal/reports/status/update',
    data
  );

  return {
    reportData: {
      data: {
        status: response.status,
      },
    },
  };
};

export const reportsResource = {
  reportListPost,
  generateReport,
  updateReportStatus,
  listDownload,
  fetchDetails,
  merchantReportListPost,
  merchantReportDownloadPost,
  merchantReportDetails,
  merchantGenerateReport,
  merchantUpdateReportStatus,
  merchantListDownload,
  updateMerchantReportStatus,
};

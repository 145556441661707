import api from 'api';

const list = async (data: any) => {
  return api.post('/merchants/page', data);
};

const createMerchant = async (data: any) => {
  return api.post('/merchants/create', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/merchants/get', data);
};

const updateMerchantToggleStatus = async (data: any) => {
  return api.post('/merchants/status/toggle', data);
};

const updateMerchantDetails = async (data: any) => {
  return api.post('/merchants/update', data);
};

const updateAccountHolderDetails = async (data: any) => {
  return api.post('/account-holders/update', data);
};

const updateReportsConfigDetails = async (data: any) => {
  return api.post('/merchants/report-configuration/update', data);
};

const updateRapidConnectDetailsPost = async (data: any) => {
  return api.post('/rapidconnect-merchants/update', data);
};

const createRapidConnectDetailsPost = async (data: any) => {
  return api.post('/rapidconnect-merchants/create', data);
};

const updateMerchantsConfigDetails = async (data: any) => {
  return api.post('/merchants/merchant-configuration/update', data);
};

const updateMerchantsConfigLimitDetails = async (
  data: any,
  merchantId: any
) => {
  return api.post(`/merchants/${merchantId}/merchant-limits/update`, data);
};

const getAccountHolder = async (data: { id: string }) => {
  if (!data.id) {
    return null;
  }
  return api.post('/account-holders/get', data);
};

const getMerchantToggleStatus = async (data: any) => {
  const response = await api.post('/status/toggle/get', data);
  const res = response.data;
  return {
    id: res.id,
    merchantId: res.merchantId,
    isEnabled: res.isEnabled,
    fromDateTime: res.fromDateTime,
  };
};

export const merchantsResource = {
  list,
  getAccountHolder,
  getMerchantToggleStatus,
  fetchDetails,
  createMerchant,
  updateMerchantToggleStatus,
  updateMerchantDetails,
  updateAccountHolderDetails,
  updateReportsConfigDetails,
  updateMerchantsConfigDetails,
  updateRapidConnectDetailsPost,
  createRapidConnectDetailsPost,
  updateMerchantsConfigLimitDetails,
};

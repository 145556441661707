import { createAsyncThunk } from '@reduxjs/toolkit';
import { listRequestPayload, mappedAuthDetails } from '../components/utils';
import { reportsResource } from '../report-api/report-api';
import { RootState } from 'store/store';

import { getReportState } from './selectors';
import { toast } from 'react-toastify';
import { downloadReports, errorMessage } from '../utils';
import { dateTimeZone, isMerchantSelector } from 'domain/account/selectors';
import {
  dateRangeFormatUtil,
  dateTimeFormatUtil,
} from 'components/dateTimeFormatUtil';

export const loadItems: any = createAsyncThunk(
  'reportStoreName/loadItems',
  async (dataReq: { reportTypeName: string }, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { rowsPerPage, page, reportFilterApply, sort } = getReportState(
        getState() as RootState
      );

      const isMerchantRole = isMerchantSelector(getState() as RootState);
      const dateZone = dateTimeZone(getState() as RootState);
      const dateFormatVal = (value: any) =>
        dateTimeFormatUtil(value, dateRangeFormatUtil, dateZone);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        type: dataReq.reportTypeName,
        sort: sort,
        ...listRequestPayload(reportFilterApply, dateFormatVal),
      };

      return !isMerchantRole
        ? await reportsResource.reportListPost(params)
        : await reportsResource.merchantReportListPost(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'reportStoreName/fetchAuthorizationDetails',
  async (data: { id: string }, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootState);

      const response = !isMerchantRole
        ? await reportsResource.fetchDetails(data)
        : await reportsResource.merchantReportDetails(data);
      return mappedAuthDetails(response);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const generateReport: any = createAsyncThunk(
  'reportStoreName/generateReport',
  async (data, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootState);

      const response = !isMerchantRole
        ? await reportsResource.generateReport(data)
        : await reportsResource.merchantGenerateReport(data);

      toast.success('Report generated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return response;
    } catch (e: any) {
      toast.error(errorMessage(e.error?.code), {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const updateReportStatus: any = createAsyncThunk(
  'reportStoreName/updateReportStatus',
  async (data, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootState);

      return !isMerchantRole
        ? await reportsResource.updateReportStatus(data)
        : await reportsResource.updateMerchantReportStatus(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const downloadItems: any = createAsyncThunk(
  'reportStoreName/downloadItems',
  async (options: { id: string }, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootState);

      const response = !isMerchantRole
        ? await reportsResource.listDownload(options)
        : await reportsResource.merchantListDownload(options);

      if (response.data.length !== 0) {
        downloadReports(response);
      }

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

import { ReactNode, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { fetchProfile, logout } from 'domain/account/thunks';
import { i18n } from 'i18n';
import { isAuthenticatedSelector } from 'domain/account/selectors';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

// const IDLE_TIMEOUT = 15000000000; // minutes
const IDLE_TIMEOUT = 15; // minutes

export const AuthContainer = ({ children }: { children: ReactNode }) => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProfile());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const loadResource = async () => {
      i18n.addResourceBundle(
        'en',
        'translation',
        await import('../locales/en/translationAuth.json'),
        true,
        true
      );

      setTranslationsLoaded(true);
    };

    loadResource();
  }, []);

  useIdleTimer({
    crossTab: true,
    timeout: 1000 * 60 * IDLE_TIMEOUT,
    debounce: 500,
    onIdle: () => dispatch(logout({ sigOutReason: 'SIGN_OUT_SYSTEM' })),
    onAction: () => {},
  });

  return <>{translationsLoaded && children}</>;
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { programsResource } from './api';
import { RootState } from '../../../store/store';
import { getRoot } from './selectors';
import { dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';

export const fetchServiceMonitor: any = createAsyncThunk(
  'serviceMonitorStore/fetchServiceMonitor',
  async (data: any, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { rowsPerPage, appliedFilters, page } = getRoot(
        getState() as RootState
      );

      const dateZone = dateTimeZone(getState() as RootState);
      const dateFormatVal = (value: any) =>
        dateTimeFormatUtil(value, 'YYYY-MM-DD', dateZone);

      const params = {
        page,
        limit: rowsPerPage,
        day: dateFormatVal(appliedFilters.day?.from),
        offset: (page - 1) * rowsPerPage,
      };

      return await programsResource.serviceMonitorListPost(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

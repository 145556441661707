import { ReactNode } from 'react';
import { StatusBadge } from 'components/StatusBadge';
import { AuthorizationStatus } from 'entities/transaction-management';
import { TransactionStatuses } from '../../unmatched-transactions/utils';

const statusBadge = (status: AuthorizationStatus | string) => {
  switch (status) {
    case TransactionStatuses.PROCESSED:
    case TransactionStatuses.PROCESSED_A:
    case AuthorizationStatus.COMPLETED:
      return 'success';
    case TransactionStatuses.REJECTED_R:
    case AuthorizationStatus.REJECTED:
    case AuthorizationStatus.EXPIRED:
      return 'danger';
    case AuthorizationStatus.PENDING:
      return 'warning';
    case AuthorizationStatus.PARTIAL_REFUNDED:
      return AuthorizationStatus.PARTIAL_REFUNDED;
    case AuthorizationStatus.DECLINED:
      return AuthorizationStatus.DECLINED;
    case AuthorizationStatus.VOIDED:
      return 'processed';
    case AuthorizationStatus.REFUNDED:
      return 'refunded';
    case AuthorizationStatus.FAILED:
      return 'failed';
    case AuthorizationStatus.REVERSED:
      return 'reversed';
    case AuthorizationStatus.SETTLED:
      return AuthorizationStatus.SETTLED;
    default:
      return 'DISABLED';
  }
};

export const AuthorizationStatusBadge = ({
  status,
  children,
  widthClass,
  heightClass,
  textSizeClass,
}: {
  status: AuthorizationStatus | string;
  children: ReactNode;
  widthClass?: string;
  heightClass?: string;
  textSizeClass?: string;
}) => (
  <StatusBadge
    widthClass={widthClass}
    heightClass={heightClass}
    textSizeClass={textSizeClass}
    variant={statusBadge(status)}
  >
    {children}
  </StatusBadge>
);

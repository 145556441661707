import { useMemo } from 'react';

import { CellLight, ColumnProps } from 'components/table/Table';
import { ProgramItem } from 'entities/system/programs';

export const useColumns = () => {
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'name',
        label: 'Name',
        value: (item: ProgramItem) => <CellLight>{item.name}</CellLight>,
        isSorted: true,
        sortField: 'PROGRAM_NAME',
      },
      {
        id: 'url',
        label: 'URL',
        value: (item: ProgramItem) => <CellLight>{item.logoUrl}</CellLight>,
      },
      {
        id: 'feeAmount',
        label: 'Fee Amount',
        value: (item: ProgramItem) => <CellLight>{item.feeAmount}</CellLight>,
      },
      {
        id: 'corporateName',
        label: 'Corporate',
        value: (item: ProgramItem) => (
          <CellLight>{item.corporateName}</CellLight>
        ),
      },
      {
        id: 'processor',
        label: 'Processor',
        value: (item: ProgramItem) => (
          <CellLight>{item.processor.replace('_', ' ')}</CellLight>
        ),
      },
    ],
    []
  );
};

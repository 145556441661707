import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { onKeyPressAllowCharacters } from 'components/Input';

import { Input } from 'components/inputs/Input';
import React from 'react';

interface FilterNameOnCardProps {
  register: UseFormReturn['register'];
}

export const FilterNameOnCard: React.FC<FilterNameOnCardProps> = ({
  register,
}) => {
  const { t } = useTranslation();

  return (
    <Input
      label={t('transactionsManagement.authorizations.list.filters.nameOnCard')}
      labelFontClassName="text-xs font-medium"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressAllowCharacters}
      inputProps={register('nameOnCard')}
    />
  );
};

import moment from 'moment-timezone';

export const timeFormatUtil = ' hh:mm:ss A';
export const timeFormatNoSpaceUtil = 'hh:mm:ss A';
export const timeFormatISOUtil = 'HH:mm:ss';
export const dateFormatUtil = 'YYYY MMMM DD';
export const generationDateFormatUtil = 'yyyy-MM-DD';
// export const generationDateFormatUtil = 'MM-dd-yyyy';
export const dateRangeFormatUtil = 'MM-DD-YYYY';
export const monthYearFormat = 'MMMM YYYY';
export const generationMonthYearFormat = 'yyyy-MM';
export const dateTimeSecondsFormat = 'YYYY-MM-DD[T]HH:mm:ss';

export const dateTimeFormatUtil = (value: any, format: any, zone: any) => {
  const dateZone = zone ? zone : moment.tz.guess();
  const dateTimeFormatValue = format ? format : dateFormatUtil;
  return value
    ? moment(value).tz(dateZone).format(dateTimeFormatValue)
    : undefined;
};

export const dateMonthFormatUtil = (value: any, zone: any) => {
  const dateZone = zone ? zone : moment.tz.guess();
  return value ? moment(value).tz(dateZone).format(monthYearFormat) : undefined;
};
export const dateMonthFormatUtil2 = (
  value: any,
  zone: any,
  format: any = null
) => {
  const dateZone = zone ? zone : moment.tz.guess();
  const dateFormat = format ? format : monthYearFormat;
  return value ? moment(value).tz(dateZone).format(dateFormat) : undefined;
};

export const dateTestFormatUtil = (value: any, zone: any) => {
  const dateZone = zone ? zone : moment.tz.guess();
  return value ? moment(value).tz(dateZone).format(monthYearFormat) : undefined;
};

export const timeWithZoneFormatUtil = (value: any, zone: any) => {
  const dateTimeZoneValue = zone ? zone : moment.tz.guess();

  return value
    ? moment(value, timeFormatUtil).tz(dateTimeZoneValue).format(timeFormatUtil)
    : undefined;
};

export const dateWithInitFormatUtil = (
  value: any,
  initFormat: any,
  format: any,
  zone: any
) => {
  const dateTimeZoneValue = zone ? zone : moment.tz.guess();
  const dateTimeFormatValue = format ? format : dateFormatUtil;
  return value
    ? moment(value, initFormat)
        .tz(dateTimeZoneValue)
        .format(dateTimeFormatValue)
    : undefined;
};

export const generationDateTimeFormatUtil = (
  value: any,
  format: any,
  zone: any
) => {
  const dateTimeZoneValue = zone ? zone : moment.tz.guess();
  const dateTimeFormatValue = format ? format : dateFormatUtil;

  return value
    ? moment(value)
        .endOf('day')
        .tz(dateTimeZoneValue)
        .format(dateTimeFormatValue)
    : undefined;
};

export const convertToTimeUtil = (value, zone) => {
  const dateTimeZoneValue = zone ? zone : moment.tz.guess();

  return value
    ? moment(value, timeFormatISOUtil)
        .tz(dateTimeZoneValue)
        .format(timeFormatNoSpaceUtil)
    : undefined;
};

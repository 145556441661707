import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components/PageHeader';
import { InfoCard } from './InfoCard';
import { TotalSettlementsChartComponent } from './TotalSettlementsChartComponent';
import { SettlementsStatusChartComponent } from './SettlementsStatusChartComponent';
import { SettlementsTypeChartComponent } from './SettlementsTypeChartComponent';
import { UnmatchedTransactions } from './UnmatchedTransactions';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { TotalAuthorizationsChartComponent } from './TotalAuthorizationsChartComponent';
import { AuthorizationsStatusChartComponent } from './AuthorizationsStatusChartComponent';
import { AuthorizationTypeChartComponent } from './AuthorizationTypeChartComponent';
import { TotalAuthorizationsVsSettlementChartComponent } from './TotalAuthorizationsVsSettlementChartComponent';
import { NotificationCard } from './NotificationCard';

export const DashboardIndexComponent = () => {
  const { t } = useTranslation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('dashboard.breadcrumb')} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          <InfoCard />
          <NotificationCard />
          <TotalAuthorizationsVsSettlementChartComponent />
          <TotalSettlementsChartComponent />
          <SettlementsStatusChartComponent />
          <SettlementsTypeChartComponent />
          <TotalAuthorizationsChartComponent />
          <AuthorizationsStatusChartComponent />
          <AuthorizationTypeChartComponent />
          <UnmatchedTransactions />
        </div>
      </div>
    </div>
  );
};

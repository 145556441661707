import { useEffect } from 'react';

export const ESC_KEY = 'Escape';
export const ENTER_KEY = 'Enter';

export const useOnKeyDown = (
  { keyCode }: { keyCode: string[] },
  callback: (event: KeyboardEvent) => any
) => {
  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (keyCode.includes(event.key)) {
        callback(event);
      }
    };

    window.addEventListener('keydown', handleKey);

    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [keyCode, callback]);
};

/* eslint-disable react/display-name */
import React from 'react';
import { Controller } from 'react-hook-form';

import { MaskedNumberInput } from 'components/inputs';

export const InputNumber = React.memo(
  ({ control, name, label, format = '####', maxLength = 4 }: any) => {
    return (
      <div>
        <Controller
          name={name}
          control={control}
          render={({ fieldState: { error }, field: { value, onChange } }) => (
            <MaskedNumberInput
              format={format}
              label={label}
              name={name}
              labelFontClassName="text-xs font-medium"
              className="h-8 text-xs font-bold rounded"
              maxLength={maxLength}
              error={error}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
    );
  }
);

import { useTranslation } from 'react-i18next';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AuthorizationItem, ItemSort } from 'entities';
import { LayoutList } from 'layouts/list/LayoutList';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { useAuthorizationsListColumns } from './useAuthorizationsListColumns';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../authorizationsSlice';
import { Filters } from './filters/Filters';
import { LogItem } from 'entities/logs';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { countDecimalPlaces } from '../utils';

export const AuthorizationsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const history: any = useHistory();
  const items: AuthorizationItem[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const permissions: any = useAppSelector(getProfilePermission);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const filters: any = useAppSelector(getFilters);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const columns: ColumnProps[] = useAuthorizationsListColumns();

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const handleRowClick = (item: LogItem) => {
    history.push(`authorizations/${Base64.encodeURI(item.id)}`);
  };

  const isOnRowClick = permissions.includes(UserPermissions.AUTHORIZATION_GET);

  const isFilterSelected = [
    filters.id.length ||
      filters.authorizationCode.length ||
      (filters.amount &&
        countDecimalPlaces(filters.amount) <= 2 &&
        filters.amount >= 0) ||
      filters.authorizationCode ||
      filters.cardLastFour ||
      filters.merchants.length ||
      filters.merchantExternalReference ||
      filters.stan ||
      filters.status.length ||
      filters.transactionTypes.length ||
      filters.nameOnCard ||
      (filters.transactionDate.from && filters.transactionDate.to),
  ].some((value) => !!value);

  return (
    <LayoutList title={t('transactionsManagement.authorizations.breadcrumb')}>
      <>
        <TableCard
          loading={itemsLoadingComputed}
          columns={columns}
          items={items}
          totalNumber={totalNumber}
          setSort={setCurrentSort}
          onRowClick={isOnRowClick ? handleRowClick : undefined}
          onHover={isOnRowClick}
          pagination={pagination}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          filterApply={
            isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
          }
          filters={<Filters />}
        />
      </>
    </LayoutList>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { blockedPansResource } from './api';
import { RootState } from 'store/store';

import { getRoot } from './selectors';
import { LoadItems } from 'entities';
import { listRequestPayload } from './utils';

export const loadItems: any = createAsyncThunk(
  'blockedPans/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { rowsPerPage, page, sort, appliedFilters } = getRoot(
        getState() as RootState
      );

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await blockedPansResource.blockedPansList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'blockedPans/fetchDetails',
  async (data: { id: string }) => {
    try {
      const response = await blockedPansResource.blockedPanDetails(data.id);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const createBlockedPan: any = createAsyncThunk(
  'blockedPans/createBlockedPan',
  async (data) => {
    try {
      const response = await blockedPansResource.createBlockedPan(data);

      toast.success('PAN Blocked', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return response.data;
    } catch (e: any) {
      toast.error(e.error.message, {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

export const deleteBlockedPan: any = createAsyncThunk(
  'blockedPans/deleteBlockedPan',
  async (data: { id: string }) => {
    try {
      const response = await blockedPansResource.deleteBlockedPan(data.id);

      toast.success('PAN removed', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return response.data;
    } catch (e) {
      toast.error('PAN could not be removed', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

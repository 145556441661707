import { ReactNode } from 'react';
import classNames from 'classnames';
import { StatusVariants } from 'types/statusVariants';
import { LoggingStatus } from '../domain/user-management/users/utils';
import { AuthorizationStatus } from '../entities';

const bgClassName = (type: StatusVariants | string | null) => {
  switch (type) {
    case 'warning':
      return 'box-bg-orange-300 text-white rounded';
    case 'danger':
      return 'box-bg-red-400 text-white rounded';
    case 'secondary':
      return 'border-gray-500 text-gray-500';
    case 'success':
      return 'box-bg-green-300 text-white rounded';
    case 'failed':
      return 'border-red-500 text-red-500 rounded';
    case 'processed':
      return 'bg-orchid-status text-white rounded';
    case 'refunded':
      return 'bg-refunded-status text-white rounded';
    case 'reversed':
      return 'bg-refunded-status text-white rounded';
    case 'locked':
      return 'box-bg-grey-300 text-white rounded-lg';
    case 'approved':
      return 'bg-mantis-status text-white rounded';
    case 'rejected':
      return 'bg-bright-red-status text-white rounded';
    case 'blocked':
      return 'box-bg-night-rider-300 text-white rounded';
    case 'unknown':
      return 'bg-blue-status text-blue rounded';
    case LoggingStatus.SIGN_IN:
      return 'box-bg-valencia-400 text-white rounded';
    case LoggingStatus.SIGN_OUT:
      return 'box-bg-neon-blue-400 text-white rounded';
    case LoggingStatus.SIGN_OUT_SYSTEM:
      return 'box-bg-bright-red-200 text-white rounded';
    case LoggingStatus.SIGN_OUT_USER:
      return 'box-bg-yellow-orange-300 text-white rounded';
    case LoggingStatus.MAXIMUM_LOGIN_ATTEMPTS:
      return 'box-bg-zest-300 text-white rounded';
    case LoggingStatus.INVALID_PASSWORD_ENTRIES:
      return 'box-bg-hippie-blue-300 text-white rounded';
    case LoggingStatus.INVALID_OTP_ENTRIES:
      return 'box-bg-roti-400 text-white rounded';
    case AuthorizationStatus.PARTIAL_REFUNDED:
      return 'inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10';
    case AuthorizationStatus.DECLINED:
      return 'inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10';
    case AuthorizationStatus.DISABLED:
      return 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10';
    case AuthorizationStatus.SETTLED:
      return 'bg-atlantis-status text-white font-bold rounded';
    default:
      return 'bg-blue-500 text-white rounded';
  }
};

export const StatusBadge = ({
  variant,
  children,
  widthClass = 'w-18',
  heightClass = 'h-6',
  textSizeClass = 'text-xxxs',
}: {
  variant: StatusVariants | string | null;
  children: ReactNode;
  widthClass?: string;
  heightClass?: string;
  textSizeClass?: string;
}) => (
  <div
    className={classNames(
      'py-1 px-2 border rounded-lg flex items-center justify-center text-center',
      bgClassName(variant),
      widthClass,
      heightClass
    )}
    style={{ minWidth: '64px' }}
  >
    <span
      className={classNames(
        'font-semibold leading-none uppercase',
        textSizeClass
      )}
    >
      {children}
    </span>
  </div>
);

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { storeName } from './accountSlice';

export const getRoot = (state: RootState) => state[storeName];

export const getMainState = createSelector([getRoot], (state) => state);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading
);

export const getFirstMenuItem = createSelector(
  [getRoot],
  ({ firstMenuItem }) => firstMenuItem
);

export const getHasNotification = createSelector(
  [getRoot],
  ({ hasNotifications }) => hasNotifications
);

export const getSidebar = createSelector(
  [getRoot],
  ({ isLoadingSidebar, sidebar }) => ({ isLoadingSidebar, sidebar })
);

export const profileSelector = createSelector([getRoot], ({ profile }) => ({
  profile,
}));

export const fullNameSelector = createSelector(
  [getRoot],
  ({ profile }) => profile?.firstName.trim() + ' ' + profile?.lastName.trim()
);

export const userId = createSelector([getRoot], ({ profile }) => profile?.id);

export const dateTimeZone = createSelector(
  [getRoot],
  ({ profile }) => profile?.dateTimeZone
);

export const dateTimeFormat = createSelector(
  [getRoot],
  ({ profile }) => profile?.dateTimeFormat
);

export const isAuthenticatedSelector = createSelector(
  [getRoot],
  ({ profile, authenticated }) => profile !== null && authenticated
);

export const isAdminSelector = createSelector(
  [getRoot],
  ({ profile }) => profile?.role === 'ADMIN'
);

export const getProfilePermission = createSelector(
  [getRoot],
  ({ profile }) => profile?.permissions
);

export const isMerchantSelector = createSelector(
  [getRoot],
  ({ profile }) => profile?.role === 'MERCHANT'
);

const dashboardAcceptedRole = ['ADMIN', 'FRAUD RISK', 'SUPERVISOR'];

export const isDashboardActiveSelector = createSelector(
  [getRoot],
  ({ profile }) =>
    (profile?.role &&
      dashboardAcceptedRole.includes(profile?.role.toUpperCase())) ||
    false
);

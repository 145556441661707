export enum TransactionType {
  'ACH' = 'ACH',
  'AUTHORIZATION' = 'AUTHORIZATION',
  'REFUND' = 'REFUND',
  'CAPTURE' = 'CAPTURE',
  'PURCHASE' = 'PURCHASE',
  'VOID' = 'VOID',
  'REVERSAL' = 'REVERSAL',
  'VERIFICATION' = 'VERIFICATION',
  'PARTIAL_REFUNDED' = 'PARTIAL_REFUNDED',
}

export enum TransactionStatus {
  'VOIDED' = 'VOIDED',
  'REFUNDED' = 'REFUNDED',
}

export enum CcvResult {
  'MATCH' = 'VALUES_MATCH',
  'NO_MATCH' = 'VALUES_DO_NOT_MATCH',
}

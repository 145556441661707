import { Input } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import React from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';
import { rapidConnectStatusList, selectClassLabel } from '../../utils';
import { useAppSelector } from 'hooks/redux';
import { getMainState } from '../../selectors';

interface MerchantRapidConnectProps {
  register: any;
  control: any;
  errors: any;
  isCreated: boolean;
}

export const MerchantRapidConnectComponent: React.FC<
  MerchantRapidConnectProps
> = ({ register, errors, control, isCreated }) => {
  const { details: merchantDetails } = useAppSelector(getMainState);
  return (
    <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:m-4 sm:p-3">
      <div className="w-full">
        <Input
          label={<RequiredFieldComponent labelText="Terminal Id " />}
          placeholder="Terminal Id"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.terminalId}
          inputProps={{
            ...register('terminalId', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent isRequired={true} labelText="Group Id" />
          }
          placeholder="Group Id"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.groupId}
          inputProps={{
            ...register('groupId', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Merchant External Id"
            />
          }
          placeholder="Merchant External Id"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.merchantExternalId}
          inputProps={{
            ...register('merchantExternalId', {
              required: true,
            }),
          }}
        />
      </div>
      {!isCreated && (
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={false}
                labelText="Datawire Id"
              />
            }
            placeholder="Datawire Id"
            className="h-8"
            labelFontClassName="font-medium tracking-wide text-xxs"
            labelColorClassName="text-gray-700"
            error={errors.datawireId}
            inputProps={{
              ...register('datawireId', {
                required: false,
              }),
            }}
          />
        </div>
      )}
      {merchantDetails.rapidConnectMerchant?.id && (
        <div className="w-full">
          <FilterSelect
            name="rcStatus"
            labelClassName={selectClassLabel}
            label={
              <RequiredFieldComponent isRequired={false} labelText="Status" />
            }
            control={{ ...control }}
            options={rapidConnectStatusList}
            isClearable={false}
            isMulti={false}
          />
        </div>
      )}
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Merchant Category Code"
            />
          }
          placeholder="Merchant Category Code"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.merchantCategoryCode}
          inputProps={{
            ...register('merchantCategoryCode', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Primary Service Url"
            />
          }
          placeholder="Primary Service Url"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.primaryServiceUrl}
          inputProps={{
            ...register('primaryServiceUrl', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={false}
              labelText="Secondary Service Url"
            />
          }
          placeholder="Secondary Service Url"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.secondaryServiceUrl}
          inputProps={{
            ...register('secondaryServiceUrl', {
              required: false,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Ecommerce Url"
            />
          }
          placeholder="Ecommerce Url"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.ecommerceUrl}
          inputProps={{
            ...register('ecommerceUrl', {
              required: true,
            }),
          }}
        />
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';

import { HistoryBackButton } from 'components/buttons/HistoryBackButton';
import { PageHeader } from 'components/PageHeader';
import { Card } from 'components/Card';

import { RoleForm } from '../compoents/RoleForm';

export const RoleCreate = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-200">
      <PageHeader title={t('userManagement.roleDetails.create')} />

      <div className="pt-3 px-4 sm:px-7 pb-6 grid grid-rows gap-4">
        <Card>
          <HistoryBackButton />
        </Card>

        <RoleForm />
      </div>
    </div>
  );
};

export default RoleCreate;

import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isAuthenticatedSelector } from 'domain/account/selectors';
import { useAppDispatch } from 'hooks/redux';
import { Loading } from 'components/Loading';
import { logout } from 'domain/account/thunks';

export const Container = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isAuthenticated: boolean = useSelector(isAuthenticatedSelector);

  const logoutUse = useCallback(() => {
    dispatch(logout({ sigOutReason: 'SIGN_OUT_USER' }));
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      logoutUse();
    } else {
      history.push('/login');
    }
  }, [history, logoutUse, isAuthenticated]);

  return (
    <div className="flex items-center justify-center w-full min-h-screen py-12 px-3">
      <Loading className="text-blue-500" />
    </div>
  );
};

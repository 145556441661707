import { Controller } from 'react-hook-form';
import { DatePickerDropDown } from 'components/DatePickerDropDown';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import moment from 'moment/moment';
import React from 'react';

interface DateTimeComponentProps {
  name: string;
  control: any;
  labelText: string;
  placeholder: string;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
}
export const DateTimeComponent: React.FC<DateTimeComponentProps> = ({
  name,
  control,
  labelText,
  placeholder,
  isRequired = false,
  minDate,
  maxDate,
}) => {
  const generationDate = (value: any) =>
    value ? moment(value).add(1, 'day').format('yyyy MMMM DD') : '';

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={{ ...control }}
        render={({ field: { value, onChange } }) => (
          <DatePickerDropDown
            title={'Select Date'}
            minDate={minDate}
            maxDate={maxDate}
            label={
              <RequiredFieldComponent
                isRequired={isRequired}
                labelText={labelText}
              />
            }
            labelClass="font-medium tracking-wide text-xxs text-gray-700"
            placeholder={placeholder}
            generationDate={generationDate(value)}
            footer={<></>}
            inputHeightClassName="h-8"
            handleDateChange={(date) =>
              onChange(moment(date).format('yyyy-MM-DD'))
            }
            isMonthYearPicker={false}
            isBirthdatePicker={true}
          />
        )}
      />
    </div>
  );
};

import { IconLogo } from 'components/icons/IconLogo';
import { FormLogin } from './FormLogin';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const Container = () => {
  return (
    <div className="flex justify-center w-full min-h-screen py-12">
      <div className="flex flex-col items-center lg:justify-center w-full max-w-sm">
        <IconLogo className="mb-8" />
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        >
          <FormLogin />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
};

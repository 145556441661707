import LockResetIcon from '@mui/icons-material/LockReset';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { PASSWORD_LENGTH_MIN, PASSWORD_LENGTH_MAX } from 'validation/patterns';
import { PasswordInput } from 'components/inputs/PasswordInput';
import { changePassword } from 'domain/account/thunks';

import { repeatedValidation, sequenceValidation } from '../components/util';
import { PasswordHintInfo } from '../components/PasswordHintInfo';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type ChangePasswordSchema = {
  currentPassword: string;
  newPassword: string;
  repeatedPassword: string;
};

const passwordValidations = {
  minLength: PASSWORD_LENGTH_MIN,
  maxLength: PASSWORD_LENGTH_MAX,
  lowercase: (password: string) => /[a-z]/.test(password),
  uppercase: (password: string) => /[A-Z]/.test(password),
  digits: (password: string) => /[0-9]/.test(password),
  repeatedCharacters: repeatedValidation,
  special: (password: string) => /\W|_/g.test(password),
  sequenceCharacters: sequenceValidation,
};

export const FormChangePassword = ({
  token,
}: {
  onSubmit?: () => void;
  token: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<ChangePasswordSchema>({
    mode: 'onBlur',
  });

  const changePasswordData = async (data: ChangePasswordSchema) => {
    let result: any;

    try {
      if (token) {
        if (!executeRecaptcha) {
          return;
        }

        const tokenKey = await executeRecaptcha('formChangePassword');
        result = await dispatch(
          changePassword({
            data: { ...data, token: token },
            header: {
              recaptcha: tokenKey,
              'Content-Type': 'application/json',
            },
          })
        );
      } else {
        result = await dispatch(
          changePassword({
            data: { ...data, token: token },
            header: {
              recaptcha: null,
              'Content-Type': 'application/json',
            },
          })
        );
      }

      if (result.payload) {
        history.push('/login');
      }
    } catch ({ error: { code } }: any) {
      switch (code) {
        default:
          setError('newPassword', {
            type: 'custom',
            message: 'somethingWentWrong',
          });
          break;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(changePasswordData)} className="w-full">
      <div className="mb-5 w-full">
        <PasswordInput
          label={t('loginForm.currentPassword')}
          error={errors.currentPassword}
          inputProps={register('currentPassword', {
            required: true,
          })}
        />
      </div>
      <div className="mb-5">
        <div className="w-full">
          <PasswordInput
            label={
              <PasswordHintInfo
                minLength={passwordValidations.minLength}
                maxLength={passwordValidations.maxLength}
              />
            }
            subLabel="Must be at least 15 characters, must contain one character, uppercase, lowercase, numeric and special character."
            error={errors.newPassword}
            inputProps={register('newPassword', {
              required: true,
              minLength: {
                value: passwordValidations.minLength,
                message: t('resetPasswordForm.passwordMinLengthHint', {
                  minLength: passwordValidations.minLength,
                }),
              },
              maxLength: {
                value: passwordValidations.maxLength,
                message: t('resetPasswordForm.passwordMaxLengthHint', {
                  maxLength: passwordValidations.maxLength,
                }),
              },
              validate: {
                passwordUppercaseHint: passwordValidations.uppercase,
                passwordLowercaseHint: passwordValidations.lowercase,
                passwordDigitHint: passwordValidations.digits,
                passwordSpecialHint: passwordValidations.special,
                sequenceCharacters: passwordValidations.sequenceCharacters,
                repeatedCharacters: passwordValidations.repeatedCharacters,
              },
            })}
          />
        </div>
      </div>

      <div className="mb-8">
        <PasswordInput
          label={t('resetPasswordForm.passwordRepeat')}
          error={errors.repeatedPassword}
          subLabel="Both passwords must match"
          inputProps={register('repeatedPassword', {
            required: true,
            validate: {
              passwordConfirmation: (value) =>
                value === getValues().newPassword,
            },
          })}
        />
      </div>
      <LoadingButton
        loading={isSubmitting}
        loadingPosition="start"
        type="submit"
        className="w-full"
        variant="contained"
        startIcon={<LockResetIcon />}
      >
        {t('resetPasswordForm.resetPasswordSubmit')}
      </LoadingButton>
    </form>
  );
};

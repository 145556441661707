import { Input } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import React, { useState } from 'react';
import { DateTimeComponent } from './DateTimeComponent';
import { FilterSelect } from 'components/filters/FilterSelect';
import {
  conditionResultList,
  internalRiskRatingList,
  selectClassLabel,
} from '../../utils';
// import { TimeSelector } from 'components/timeComponent/TimeSelector';
import Accordion from '@mui/material/Accordion';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { useWatch } from 'react-hook-form';
import { HintInfoMessage } from '../../../../auth/components/HintInfoMessage';

interface MerchantDetailsInputProps {
  register: any;
  control: any;
  errors: any;
}

export const MerchantConfigDetailsInputComponent: React.FC<
  MerchantDetailsInputProps
> = ({ register, errors, control }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const dailyBlock = useWatch({
    control,
    name: 'rolling24hTransactionVolumeLimitBlock',
  });

  const weeklyBlock = useWatch({
    control,
    name: 'rolling7DaysTransactionVolumeLimitBlock',
  });

  const monthlyBlock = useWatch({
    control,
    name: 'rolling30DaysTransactionVolumeLimitBlock',
  });

  const monthlyNotice = useWatch({
    control,
    name: 'rolling30DaysTransactionVolumeLimitBlock',
  });

  const annualLimits = useWatch({
    control,
    name: 'rollingAnnualTransactionVolumeLimit',
  });

  // console.log(value2, 'test value');

  // useEffect(() => {
  //   console.log(value2, 'test');
  // }, [value2]);

  const positiveValidation = (value: number): boolean => {
    return value >= 0;
  };

  // const isBlockRequiredValidation = (
  //   blockValue: number,
  //   noticeValue: any
  // ): boolean => {
  //   return blockValue === 0 && noticeValue > 0;
  // };

  // const lessThanBlockValidation = (
  //   value: number,
  //   blockValue: number
  // ): boolean => {
  //   return value < blockValue;
  // };
  //
  // const merchantValidations = {
  //   positiveNumber: positiveValidation,
  //   lessThanBlock: (value: number, blockValue: number) =>
  //     lessThanBlockValidation(value, blockValue),
  // };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="w-full my-4 sm:m-4 sm:p-3">
      <Accordion
        expanded={expanded === 'general_info'}
        onChange={handleChange('general_info')}
        className="w-full"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="general_info"
          id="general_info"
        >
          <Typography>General configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div">
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-self-auto my-4 sm:m-4 sm:p-3">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Client Vertical"
                    />
                  }
                  placeholder="Client Vertical"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.clientVertical}
                  inputProps={{
                    ...register('clientVertical', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Credit Limit"
                    />
                  }
                  placeholder="Credit Limit"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.creditLimit}
                  inputProps={{
                    ...register('creditLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <DateTimeComponent
                control={control}
                name="approvalDate"
                labelText="Approval Date"
                placeholder="Select Approval Date"
              />
              <DateTimeComponent
                control={control}
                name="expiryDate"
                labelText="Expiry Date"
                placeholder="Select Expiry Date"
              />
              <div className="w-full">
                <FilterSelect
                  name="internalRiskRating"
                  labelClassName={selectClassLabel}
                  placeholder="Select Internal Risk Rating"
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Internal Risk Rating"
                    />
                  }
                  control={{ ...control }}
                  options={internalRiskRatingList}
                  closeMenuOnSelect
                  isClearable={true}
                  isMulti={false}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Lowest Ticket"
                    />
                  }
                  placeholder="Merchant Advised Lowest Ticket"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedLowestTicket}
                  inputProps={{
                    ...register('merchantAdvisedLowestTicket', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Lowest Ticket Count"
                    />
                  }
                  placeholder="Merchant Advised Lowest Ticket Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedLowestTicketCount}
                  inputProps={{
                    ...register('merchantAdvisedLowestTicketCount', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Average Ticket"
                    />
                  }
                  placeholder="Merchant Advised Average Ticket"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedAverageTicket}
                  inputProps={{
                    ...register('merchantAdvisedAverageTicket', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Highest Ticket"
                    />
                  }
                  placeholder="Merchant Advised Highest Ticket"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedHighestTicket}
                  inputProps={{
                    ...register('merchantAdvisedHighestTicket', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Daily Transaction Number"
                    />
                  }
                  placeholder="Merchant Advised Daily Transaction Number"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedDailyTransactionNumber}
                  inputProps={{
                    ...register('merchantAdvisedDailyTransactionNumber', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Daily Transaction Volume"
                    />
                  }
                  placeholder="Merchant Advised Daily Transaction Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedDailyTransactionVolume}
                  inputProps={{
                    ...register('merchantAdvisedDailyTransactionVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Refund Ratio"
                    />
                  }
                  placeholder="Merchant Advised Refund Ratio"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedRefundRatio}
                  inputProps={{
                    ...register('merchantAdvisedRefundRatio', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Chargeback Ratio"
                    />
                  }
                  placeholder="Merchant Advised Chargeback Ratio"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedChargebackRatio}
                  inputProps={{
                    ...register('merchantAdvisedChargebackRatio', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Chargeback Days"
                    />
                  }
                  placeholder="Merchant Advised Chargeback Days"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedChargebackDays}
                  inputProps={{
                    ...register('merchantAdvisedChargebackDays', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Advised Delivery Days"
                    />
                  }
                  placeholder="Merchant Advised Delivery Days"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantAdvisedDeliveryDays}
                  inputProps={{
                    ...register('merchantAdvisedDeliveryDays', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Expected Monthly Volume"
                    />
                  }
                  placeholder="Expected Monthly Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.expectedMonthlyVolume}
                  inputProps={{
                    ...register('expectedMonthlyVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant High Monthly Volume Percent To Flag"
                    />
                  }
                  placeholder="Merchant High Monthly Volume Percent To Flag"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantHighMonthlyVolumePercentToFlag}
                  inputProps={{
                    ...register('merchantHighMonthlyVolumePercentToFlag', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Low Monthly Volume Percent To Flag"
                    />
                  }
                  placeholder="Merchant Low Monthly Volume Percent To Flag"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantLowMonthlyVolumePercentToFlag}
                  inputProps={{
                    ...register('merchantLowMonthlyVolumePercentToFlag', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full h-full">
                <FilterSelect
                  name="highRiskAccount"
                  labelClassName={selectClassLabel}
                  placeholder="Select High Risk Account"
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="High Risk Account"
                    />
                  }
                  control={{ ...control }}
                  options={conditionResultList}
                  closeMenuOnSelect
                  isClearable={true}
                  isMulti={false}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="High Risk Account Description"
                    />
                  }
                  placeholder="High Risk Account Description"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.highRiskAccountDescription}
                  inputProps={{
                    ...register('highRiskAccountDescription', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Daily Transaction Number Limit Percent"
                    />
                  }
                  placeholder="Merchant Daily Transaction Number Limit Percent"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantDailyTransactionNumberLimitPercent}
                  inputProps={{
                    ...register('merchantDailyTransactionNumberLimitPercent', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Merchant Daily Transaction Volume Limit Percent"
                    />
                  }
                  placeholder="Merchant Daily Transaction Volume Limit Percent"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.merchantDailyTransactionVolumeLimitPercent}
                  inputProps={{
                    ...register('merchantDailyTransactionVolumeLimitPercent', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Daily Ticket Average"
                    />
                  }
                  placeholder="Daily Ticket Average"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.dailyTicketAverage}
                  inputProps={{
                    ...register('dailyTicketAverage', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Daily Ticket Volume Limit Percent"
                    />
                  }
                  placeholder="Daily Ticket Volume Limit Percent"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.dailyTicketVolumeLimitPercent}
                  inputProps={{
                    ...register('dailyTicketVolumeLimitPercent', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Refunds Number"
                    />
                  }
                  placeholder="Advised Daily Refunds Number"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyRefundsNumber}
                  inputProps={{
                    ...register('advisedDailyRefundsNumber', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Refunds Volume"
                    />
                  }
                  placeholder="Advised Daily Refunds Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyRefundsVolume}
                  inputProps={{
                    ...register('advisedDailyRefundsVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Refunds Volume Ratio"
                    />
                  }
                  placeholder="Advised Daily Refunds Volume Ratio"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyRefundsVolumeRatio}
                  inputProps={{
                    ...register('advisedDailyRefundsVolumeRatio', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Refunds Number Limit Percent"
                    />
                  }
                  placeholder="Advised Daily Refunds Number Limit Percent"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyRefundsNumberLimitPercent}
                  inputProps={{
                    ...register('advisedDailyRefundsNumberLimitPercent', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Chargebacks Number Ratio"
                    />
                  }
                  placeholder="Advised Daily Chargebacks Number Ratio"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyChargebacksNumberRatio}
                  inputProps={{
                    ...register('advisedDailyChargebacksNumberRatio', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Chargebacks Number"
                    />
                  }
                  placeholder="Advised Daily Chargebacks Number"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyChargebacksNumber}
                  inputProps={{
                    ...register('advisedDailyChargebacksNumber', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Chargebacks Count Limit Percent"
                    />
                  }
                  placeholder="Advised Daily Chargebacks Count Limit Percent"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyChargebacksCountLimitPercent}
                  inputProps={{
                    ...register('advisedDailyChargebacksCountLimitPercent', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Chargebacks Volume"
                    />
                  }
                  placeholder="Advised Daily Chargebacks Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyChargebacksVolume}
                  inputProps={{
                    ...register('advisedDailyChargebacksVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Expected Monthly Transactions Number"
                    />
                  }
                  placeholder="Expected Monthly Transactions Number"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.expectedMonthlyTransactionsNumber}
                  inputProps={{
                    ...register('expectedMonthlyTransactionsNumber', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Daily Refunds Number Ratio"
                    />
                  }
                  placeholder="Advised Daily Refunds Number Ratio"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedDailyRefundsNumberRatio}
                  inputProps={{
                    ...register('advisedDailyRefundsNumberRatio', {
                      required: false,
                    }),
                  }}
                />
              </div>

              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Card Number Count"
                    />
                  }
                  placeholder="Advised Repeated Card Number Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedCardNumberCount}
                  inputProps={{
                    ...register('advisedRepeatedCardNumberCount', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Card Number Volume"
                    />
                  }
                  placeholder="Advised Repeated Card Number Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedCardNumberVolume}
                  inputProps={{
                    ...register('advisedRepeatedCardNumberVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Bank Account Number Count"
                    />
                  }
                  placeholder="Advised Repeated Bank Account Number Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedBankAccountNumberCount}
                  inputProps={{
                    ...register('advisedRepeatedBankAccountNumberCount', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Bank Account Number Volume"
                    />
                  }
                  placeholder="Advised Repeated Bank Account Number Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedBankAccountNumberVolume}
                  inputProps={{
                    ...register('advisedRepeatedBankAccountNumberVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Ip Address Count"
                    />
                  }
                  placeholder="Advised Repeated Ip Address Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedIpAddressCount}
                  inputProps={{
                    ...register('advisedRepeatedIpAddressCount', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Ip Address Volume"
                    />
                  }
                  placeholder="Advised Repeated Ip Address Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedIpAddressVolume}
                  inputProps={{
                    ...register('advisedRepeatedIpAddressVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Card Number Utilized Count"
                    />
                  }
                  placeholder="Advised Repeated Card Number Utilized Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedCardNumberUtilizedCount}
                  inputProps={{
                    ...register('advisedRepeatedCardNumberUtilizedCount', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Repeated Card Number Utilized Volume"
                    />
                  }
                  placeholder="Advised Repeated Card Number Utilized Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRepeatedCardNumberUtilizedVolume}
                  inputProps={{
                    ...register('advisedRepeatedCardNumberUtilizedVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              {/*<div className="w-full">*/}
              {/*  <TimeSelector*/}
              {/*    control={control}*/}
              {/*    labelClassName={selectClassLabel}*/}
              {/*    label={*/}
              {/*      <RequiredFieldComponent*/}
              {/*        isRequired={false}*/}
              {/*        labelText="Advised Beginning Operating Hour"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    placeholder="Advised Beginning Operating Hour"*/}
              {/*    name="advisedBeginningOperatingHour"*/}
              {/*    title="Time Select"*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="w-full">*/}
              {/*  <TimeSelector*/}
              {/*    control={control}*/}
              {/*    labelClassName={selectClassLabel}*/}
              {/*    label={*/}
              {/*      <RequiredFieldComponent*/}
              {/*        isRequired={false}*/}
              {/*        labelText="Advised Ending Operating Hour"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    placeholder="Advised Ending Operating Hour"*/}
              {/*    name="advisedEndingOperatingHour"*/}
              {/*    title="Time Select"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Transactions Outside Operating Hours Count"
                    />
                  }
                  placeholder="Advised Transactions Outside Operating Hours Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedTransactionsOutsideOperatingHoursCount}
                  inputProps={{
                    ...register(
                      'advisedTransactionsOutsideOperatingHoursCount',
                      {
                        required: false,
                      }
                    ),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Transactions Outside Operating Hours Volume"
                    />
                  }
                  placeholder="Advised Transactions Outside Operating Hours Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedTransactionsOutsideOperatingHoursVolume}
                  inputProps={{
                    ...register(
                      'advisedTransactionsOutsideOperatingHoursVolume',
                      {
                        required: false,
                      }
                    ),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Rejected Checked Transaction Volume"
                    />
                  }
                  placeholder="Advised Rejected Checked Transaction Volume"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRejectedCheckedTransactionVolume}
                  inputProps={{
                    ...register('advisedRejectedCheckedTransactionVolume', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Advised Rejected Checked Transaction Count"
                    />
                  }
                  placeholder="Advised Rejected Checked Transaction Count"
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.advisedRejectedCheckedTransactionCount}
                  inputProps={{
                    ...register('advisedRejectedCheckedTransactionCount', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'limit_info'}
        onChange={handleChange('limit_info')}
        className="w-full"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="limit_info"
          id="limit_info"
        >
          <Typography>Limit configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div">
            <div>
              <Divider textAlign="center">Sale</Divider>
            </div>
            <div className="mt-3">Annual Limits</div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={true}
                      labelText="Annual Volume Limit"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rollingAnnualTransactionVolumeLimit}
                  inputProps={{
                    ...register('rollingAnnualTransactionVolumeLimit', {
                      required: true,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     isBlockRequiredValidation(value, monthlyNotice),
                      // },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={true}
                      labelText="Annual Volume Limit Notice"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rollingAnnualTransactionVolumeLimitNotice}
                  inputProps={{
                    ...register('rollingAnnualTransactionVolumeLimitNotice', {
                      required: true,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     merchantValidations.lessThanBlock(
                      //       value,
                      //       monthlyBlock
                      //     ),
                      // },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={true}
                      labelText="Annual Volume Limit Block"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rollingAnnualTransactionVolumeLimitBlock}
                  inputProps={{
                    ...register('rollingAnnualTransactionVolumeLimitBlock', {
                      required: true,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     merchantValidations.lessThanBlock(
                      //       value,
                      //       monthlyBlock
                      //     ),
                      // },
                    }),
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              Monthly Limits <HintInfoMessage message="Rolling 30 days" />
            </div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              {/*30*/}
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Monthly Volume Limit"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling30DaysTransactionVolumeLimit}
                  inputProps={{
                    ...register('rolling30DaysTransactionVolumeLimit', {
                      required: false,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     isBlockRequiredValidation(value, monthlyNotice),
                      // },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Monthly Volume Limit - Notice"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling30DaysTransactionVolumeLimitNotice}
                  inputProps={{
                    ...register('rolling30DaysTransactionVolumeLimitNotice', {
                      required: false,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     merchantValidations.lessThanBlock(
                      //       value,
                      //       monthlyBlock
                      //     ),
                      // },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Monthly Volume Limit - Block"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling30DaysTransactionVolumeLimitBlock}
                  inputProps={{
                    ...register('rolling30DaysTransactionVolumeLimitBlock', {
                      required: false,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     isBlockRequiredValidation(value, monthlyNotice),
                      // },
                    }),
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              Weekly Limits <HintInfoMessage message="Rolling 7 days" />
            </div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              {/*7d*/}
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Weekly Volume Limit"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling7DaysTransactionVolumeLimit}
                  inputProps={{
                    ...register('rolling7DaysTransactionVolumeLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Weekly Volume Limit - Notice"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling7DaysTransactionVolumeLimitNotice}
                  inputProps={{
                    ...register('rolling7DaysTransactionVolumeLimitNotice', {
                      required: false,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     merchantValidations.lessThanBlock(value, weeklyBlock),
                      // },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Weekly Volume Limit - Block"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  icon={<AttachMoneyIcon fontSize="small" />}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling7DaysTransactionVolumeLimitBlock}
                  inputProps={{
                    ...register('rolling7DaysTransactionVolumeLimitBlock', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="mt-3">Daily Limits</div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              {/*24h*/}
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Daily Volume Limit"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rolling24hTransactionVolumeLimit}
                  inputProps={{
                    ...register('rolling24hTransactionVolumeLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Daily Volume Limit - Notice"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  error={errors.rolling24hTransactionVolumeLimitNotice}
                  inputProps={{
                    ...register('rolling24hTransactionVolumeLimitNotice', {
                      required: false,
                      // validate: {
                      //   lessThanBlock: (value: any) =>
                      //     merchantValidations.lessThanBlock(value, dailyBlock),
                      // },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Daily Volume Limit - Block"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rolling24hTransactionVolumeLimitBlock}
                  inputProps={{
                    ...register('rolling24hTransactionVolumeLimitBlock', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <Divider textAlign="center">Refund</Divider>
            </div>
            <div className="mt-3">Annual Limits</div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit %"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<PercentIcon fontSize="small" />}
                  error={errors.rollingAnnualRefundVolumePercentLimit}
                  inputProps={{
                    ...register('rollingAnnualRefundVolumePercentLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rollingAnnualRefundVolumeLimit}
                  inputProps={{
                    ...register('rollingAnnualRefundVolumeLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Notice %"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<PercentIcon fontSize="small" />}
                  error={errors.rollingAnnualRefundPercentLimitNotice}
                  inputProps={{
                    ...register('rollingAnnualRefundPercentLimitNotice', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Notice"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rollingAnnualRefundLimitNotice}
                  inputProps={{
                    ...register('rollingAnnualRefundLimitNotice', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Block %"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<PercentIcon fontSize="small" />}
                  error={errors.rollingAnnualRefundPercentLimitBlock}
                  inputProps={{
                    ...register('rollingAnnualRefundPercentLimitBlock', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Block"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rollingAnnualRefundLimitBlock}
                  inputProps={{
                    ...register('rollingAnnualRefundLimitBlock', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <Divider textAlign="center">Chargeback</Divider>
            </div>
            <div className="mt-3">Annual Limits</div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit %"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<PercentIcon fontSize="small" />}
                  error={errors.rollingAnnualChargebackVolumePercentLimit}
                  inputProps={{
                    ...register('rollingAnnualChargebackVolumePercentLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rollingAnnualChargebackVolumeLimit}
                  inputProps={{
                    ...register('rollingAnnualChargebackVolumeLimit', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Notice %"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<PercentIcon fontSize="small" />}
                  error={errors.rollingAnnualChargebackPercentLimitNotice}
                  inputProps={{
                    ...register('rollingAnnualChargebackPercentLimitNotice', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Notice"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rollingAnnualChargebackLimitNotice}
                  inputProps={{
                    ...register('rollingAnnualChargebackLimitNotice', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start">
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Block %"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<PercentIcon fontSize="small" />}
                  error={errors.rollingAnnualChargebackPercentLimitBlock}
                  inputProps={{
                    ...register('rollingAnnualChargebackPercentLimitBlock', {
                      required: false,
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <Input
                  label={
                    <RequiredFieldComponent
                      isRequired={false}
                      labelText="Annual Volume Limit - Block"
                    />
                  }
                  type="number"
                  min={0.0}
                  step={0.01}
                  className="h-8"
                  labelFontClassName="font-medium tracking-wide text-xxs"
                  labelColorClassName="text-gray-700"
                  icon={<AttachMoneyIcon fontSize="small" />}
                  error={errors.rollingAnnualChargebackLimitBlock}
                  inputProps={{
                    ...register('rollingAnnualChargebackLimitBlock', {
                      required: false,
                    }),
                  }}
                />
              </div>
            </div>

            {/*<div className="w-full">*/}
            {/*  <Input*/}
            {/*    label={*/}
            {/*      <RequiredFieldComponent*/}
            {/*        isRequired={false}*/}
            {/*        labelText="rolling24hRefundVolumeLimitNotice"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    placeholder="rolling24hRefundVolumeLimitNotice"*/}
            {/*    className="h-8"*/}
            {/*    labelFontClassName="font-medium tracking-wide text-xxs"*/}
            {/*    labelColorClassName="text-gray-700"*/}
            {/*    error={errors.rolling24hRefundVolumeLimitNotice}*/}
            {/*    inputProps={{*/}
            {/*      ...register('rolling24hRefundVolumeLimitNotice', {*/}
            {/*        required: false,*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="w-full">*/}
            {/*  <Input*/}
            {/*    label={*/}
            {/*      <RequiredFieldComponent*/}
            {/*        isRequired={false}*/}
            {/*        labelText="rolling24hRefundVolumeLimitBlock"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    placeholder="rolling24hRefundVolumeLimitBlock"*/}
            {/*    className="h-8"*/}
            {/*    labelFontClassName="font-medium tracking-wide text-xxs"*/}
            {/*    labelColorClassName="text-gray-700"*/}
            {/*    error={errors.rolling24hRefundVolumeLimitBlock}*/}
            {/*    inputProps={{*/}
            {/*      ...register('rolling24hRefundVolumeLimitBlock', {*/}
            {/*        required: false,*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="w-full">*/}
            {/*  <Input*/}
            {/*    label={*/}
            {/*      <RequiredFieldComponent*/}
            {/*        isRequired={false}*/}
            {/*        labelText="rolling7DaysRefundVolumeLimitNotice"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    placeholder="rolling7DaysRefundVolumeLimitNotice"*/}
            {/*    className="h-8"*/}
            {/*    labelFontClassName="font-medium tracking-wide text-xxs"*/}
            {/*    labelColorClassName="text-gray-700"*/}
            {/*    error={errors.rolling7DaysRefundVolumeLimitNotice}*/}
            {/*    inputProps={{*/}
            {/*      ...register('rolling7DaysRefundVolumeLimitNotice', {*/}
            {/*        required: false,*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="w-full">*/}
            {/*  <Input*/}
            {/*    label={*/}
            {/*      <RequiredFieldComponent*/}
            {/*        isRequired={false}*/}
            {/*        labelText="rolling7DaysRefundVolumeLimitBlock"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    placeholder="rolling7DaysRefundVolumeLimitBlock"*/}
            {/*    className="h-8"*/}
            {/*    labelFontClassName="font-medium tracking-wide text-xxs"*/}
            {/*    labelColorClassName="text-gray-700"*/}
            {/*    error={errors.rolling7DaysRefundVolumeLimitBlock}*/}
            {/*    inputProps={{*/}
            {/*      ...register('rolling7DaysRefundVolumeLimitBlock', {*/}
            {/*        required: false,*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="w-full">*/}
            {/*  <Input*/}
            {/*    label={*/}
            {/*      <RequiredFieldComponent*/}
            {/*        isRequired={false}*/}
            {/*        labelText="rolling30DaysRefundVolumeLimitNotice"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    placeholder="rolling30DaysRefundVolumeLimitNotice"*/}
            {/*    className="h-8"*/}
            {/*    labelFontClassName="font-medium tracking-wide text-xxs"*/}
            {/*    labelColorClassName="text-gray-700"*/}
            {/*    error={errors.rolling30DaysRefundVolumeLimitNotice}*/}
            {/*    inputProps={{*/}
            {/*      ...register('rolling30DaysRefundVolumeLimitNotice', {*/}
            {/*        required: false,*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="w-full">*/}
            {/*  <Input*/}
            {/*    label={*/}
            {/*      <RequiredFieldComponent*/}
            {/*        isRequired={false}*/}
            {/*        labelText="rolling30DaysRefundVolumeLimitBlock"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    placeholder="rolling30DaysRefundVolumeLimitBlock"*/}
            {/*    className="h-8"*/}
            {/*    labelFontClassName="font-medium tracking-wide text-xxs"*/}
            {/*    labelColorClassName="text-gray-700"*/}
            {/*    error={errors.rolling30DaysRefundVolumeLimitBlock}*/}
            {/*    inputProps={{*/}
            {/*      ...register('rolling30DaysRefundVolumeLimitBlock', {*/}
            {/*        required: false,*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*</div>*/}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

import api from 'api';

const list = async (data: any) => {
  return api.post('/audit/list', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/audit/get', data);
};

export const logsResource = {
  list,
  fetchDetails,
};

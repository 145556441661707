import { useAppDispatch } from 'hooks/redux';
import { useSelector } from 'react-redux';
import {
  getDetailsLoading,
  getItemDetails,
  getItemNotFound,
  getItemRemoveLoading,
} from '../../selectors';
import { useHistory, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import { useTranslation } from 'react-i18next';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { useEffect, useState } from 'react';
import { deleteBlockedPan, fetchDetails } from '../../thunks';
import { PageHeader } from 'components/PageHeader';
import { Button } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { RectLoader } from 'components/RectLoader';
import { ItemCardView, ItemView } from 'components/grid/ItemView';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

export const BlockedPanDetails = () => {
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const itemNotFound = useSelector(getItemNotFound);
  const isDetailsLoading = useSelector(getDetailsLoading);
  const itemDetails = useSelector(getItemDetails);
  const itemRemoveLoading = useSelector(getItemRemoveLoading);
  const { id } = useParams<{ id: string }>();
  const bockedCardId = Base64.decode(id);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const { t } = useTranslation();

  const [canView, setView] = useState<boolean>(false);

  const timeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  useEffect(() => {
    dispatch(fetchDetails({ id: bockedCardId }));
  }, [bockedCardId, dispatch]);

  const deleteItem = () => {
    dispatch(deleteBlockedPan({ id: bockedCardId }))
      .unwrap()
      .then(() => {
        history.push('/fraud-prevention/blocked-pans');
      })
      .catch((error: any) => {});
  };

  const maskNumber = (input: any) => {
    if (!input) {
      return '';
    }
    const start = input?.substring(0, 6);
    const end = input?.substring(input.length - 4);
    const masked = input?.substring(6, input.length - 4).replace(/./g, 'X');
    return start + masked + end;
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('blockedPans.details.detailsPageName')} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <div>
              <Button
                onClick={() => history.goBack()}
                disabled={itemRemoveLoading}
                variant="outlined"
              >
                Back
              </Button>
            </div>
            {!itemNotFound ? (
              <div>
                <LoadingButton
                  loading={itemRemoveLoading}
                  loadingPosition="start"
                  onClick={deleteItem}
                  color="error"
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </LoadingButton>
              </div>
            ) : null}
          </div>
          {itemNotFound && (
            <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-col items-center justify-center justify-items-center w-40 h-16 rounded-md ml-3 box-shadow-nero box-bg-nero-300">
                    <CreditCardIcon fontSize="large" sx={{ color: '#fff' }} />
                    <div className="text-xs text-white ml-1">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                  <div>{t('blockedPans.details.noSuchCardDetails')}</div>
                </div>
              </div>
            </div>
          )}
          {isDetailsLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}
          {!isDetailsLoading && !itemNotFound && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="flex flex-wrap justify-between">
                  <div className="-mt-8 w-full sm:w-auto">
                    <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-laser box-shadow-laser">
                      <div>
                        <CreditCardIcon
                          fontSize="large"
                          sx={{ color: '#fff' }}
                        />
                      </div>
                      <div className="text-xs text-white ml-1">Details</div>
                    </div>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-start justify-center justify-items-start sm:m-4 sm:p-3">
                  <ItemCardView
                    labelName={t('blockedPans.details.cardPAN')}
                    isNotSet
                    isUnknown
                    onClick={() => setView(!canView)}
                    isHiddenNumber={canView}
                    value={
                      canView
                        ? itemDetails.cardNumber
                            ?.replace(/(.{4})/g, '$1 ')
                            .trim()
                        : maskNumber(itemDetails.cardNumber)
                            ?.replace(/(.{4})/g, '$1 ')
                            .trim()
                    }
                  />
                  <ItemView
                    labelName={t('blockedPans.details.blockedOn')}
                    isNotSet
                    isUnknown
                    value={timeFormat(itemDetails.blockedOn)}
                  />
                  <ItemView
                    labelName={t('blockedPans.details.blockedBy')}
                    isNotSet
                    isUnknown
                    value={itemDetails.blockedBy}
                  />
                  <ItemView
                    labelName={t('blockedPans.details.paymentAttempts')}
                    isNotSet
                    isUnknown
                    value={itemDetails.paymentAttempts}
                  />
                  <div className="col-span-2">
                    <ItemView
                      labelName={t('blockedPans.details.reason')}
                      isNotSet
                      isUnknown
                      value={itemDetails.reason}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '../../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { DAILY, MONTHLY, ReportTypes } from '../utils';
import React from 'react';

interface ReportTypeDisplayProps {
  reportType: any;
}

const ReportTypeDisplay: React.FC<ReportTypeDisplayProps> = ({
  reportType,
}) => {
  const history = useHistory();
  return (
    <div
      aria-hidden="true"
      key={reportType.name}
      onClick={() => history.push(`${reportType.link}`)}
      className="flex col-span-8 m2d:col-span-4 xxl:col-span-2 h-auto bg-white hover:bg-gray-100 border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark cursor-pointer"
    >
      <div className="w-full">
        <div className="flex flex-col items-center justify-center justify-items-center my-4 sm:mx-4 sm:p-3">
          <div className="grid ustify-start justify-items-start text-blue-600">
            {reportType.name}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReportTypeComponent = () => {
  const { t } = useTranslation();
  const { reportType } = useParams<{ reportType: string }>();
  const reportTypeName =
    reportType === DAILY
      ? t('reports.dailyReports')
      : t('reports.monthlyReports');
  return (
    <div className="relative flex flex-col h-auto bg-gray-200">
      <div className="absolute w-full">
        <PageHeader title={reportTypeName} />
      </div>
      <div className="h-full p-3 pt-19">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          <div className="grid col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10">
            <div className="grid grid-cols-8 justify-stretch justify-items-stretch w-full">
              {reportType === DAILY && (
                <>
                  <ReportTypeDisplay
                    reportType={ReportTypes.GENERAL_ACCOUNT_DATA}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY}
                  />
                  <ReportTypeDisplay
                    reportType={
                      ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY_LIVE
                    }
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_TRANSACTIONS}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_TRANSACTION_SUMMARY}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_EXCESSIVE_ACTIVITY}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_EXCEPTION_REFUND}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_EXCEPTION_BATCH}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DAILY_EXCEPTION_TICKET}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.DUPLICATE_TRANSACTION}
                  />
                </>
              )}
              {reportType === MONTHLY && (
                <>
                  <ReportTypeDisplay
                    reportType={ReportTypes.MERCHANT_MONTHLY_CLIENT}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.MONTHLY_INTERNAL_MERCHANT_PORTFOLIO}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.MONTHLY_EXCESSIVE_ACTIVITY}
                  />
                  <ReportTypeDisplay
                    reportType={ReportTypes.PORTFOLIO_TRANSACTION_SUMMARY}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect, useRef } from 'react';
import { Base64 } from 'js-base64';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import LayoutList from 'layouts/list/LayoutList';
import { MerchantItem } from 'entities/system';
import { ItemSort } from 'entities';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../blockedPansSlice';
import { useColumns } from './useColumns';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import AddCardIcon from '@mui/icons-material/AddCard';
import { Filters } from './filters/Filters';
import { FiltersParam } from '../utils';
import { useSelector } from 'react-redux';

export const BlockedPansList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();

  const items: unknown[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const permissions: any = useAppSelector(getProfilePermission);
  const filters: FiltersParam = useSelector(getFilters);

  const columns: ColumnProps[] = useColumns();

  const tableRef = useRef<HTMLDivElement>(null);

  const isOnRowClick = permissions.includes(UserPermissions.MERCHANT_GET);
  const hasPermissionCreate = permissions.includes(
    UserPermissions.MERCHANT_CREATE
  );

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [itemsLoadingComputed]);

  const handleRowClick = (item: MerchantItem) => {
    history.push(`blocked-pans/details/${Base64.encodeURI(item.id)}`);
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isFilterSelected = Boolean(filters.cardNumber?.length);

  return (
    <LayoutList title={t('blockedPans.breadcrumb')}>
      <TableCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        totalNumber={totalNumber}
        items={items}
        filters={<Filters />}
        filterApply={
          isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
        }
        setSort={setCurrentSort}
        onRowClick={isOnRowClick ? handleRowClick : undefined}
        onHover={isOnRowClick}
        pagination={pagination}
        setPage={setPage}
        create={
          hasPermissionCreate ? (
            <div className="px-3 py-2 flex-1">
              <Button
                onClick={() => history.push('blocked-pans/create')}
                className="ml-2"
                variant="contained"
                startIcon={<AddCardIcon />}
              >
                <span className="text-xxs sm:text-sm">
                  {t('blockedPans.createBlockedPan')}
                </span>
              </Button>
            </div>
          ) : null
        }
        setRowsPerPage={setRowsPerPage}
      />
    </LayoutList>
  );
};

import { useEffect, useRef } from 'react';

import { ColumnProps, PaginationProps } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import LayoutList from 'layouts/list/LayoutList';
import { ItemSort } from 'entities';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../notificationSlice';
import { Filters } from './filters/Filters';
import { useColumns } from './useColumns';
import { Notifications } from './context';
import { TableColapseCard } from '../components/TableColapseCard';
import { setNotificationNumber } from '../../../account/accountSlice';
import { FiltersParams } from '../utils';

export const NotificationList = () => {
  const dispatch = useAppDispatch();

  const items: Notifications[] = useAppSelector(getItems);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const filters: FiltersParams = useAppSelector(getFilters);

  const tableRef = useRef<HTMLDivElement>(null);

  const columns: ColumnProps[] = useColumns();

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  useEffect(() => {
    const length = items.filter((e) => e.status === 'ACTIVE').length;
    dispatch(setNotificationNumber(length));
  }, [dispatch, items]);

  const isFilterSelected = [
    filters.severity?.length ||
      filters.status?.length ||
      filters.type?.length ||
      filters.category?.length ||
      (filters.createdOn?.from && filters.createdOn?.to),
  ].some((value) => !!value);

  return (
    <LayoutList title="Notifications">
      <TableColapseCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        items={items}
        totalNumber={totalNumber}
        setSort={setCurrentSort}
        pagination={pagination}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        filters={<Filters />}
        filterApply={
          isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
        }
      />
    </LayoutList>
  );
};

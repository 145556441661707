import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LoadingButton from '@mui/lab/LoadingButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import React from 'react';

type GenerateWrapperProps = {
  children: React.ReactElement;
  onApply: () => void;
  onClear?: () => void;
  isDisabledApply?: boolean;
  isDisabledClear?: boolean;
  isLoading?: boolean;
};

export const GenerateWrapper: React.FC<GenerateWrapperProps> = ({
  children,
  onApply,
  onClear,
  isDisabledApply = false,
  isDisabledClear = false,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="grid grid-flow-row gap-y-3">{children}</div>
      <div className="mt-4 grid grid-cols-2 gap-x-4">
        <LoadingButton
          size="small"
          variant="contained"
          disabled={isDisabledApply}
          onClick={onApply}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<NoteAddIcon />}
        >
          {t('generate.generate')}
        </LoadingButton>
        {onClear && (
          <Button
            size="small"
            variant="outlined"
            onClick={onClear}
            disabled={isDisabledClear}
            startIcon={<ClearIcon />}
          >
            {t('generate.clear')}
          </Button>
        )}
      </div>
    </div>
  );
};

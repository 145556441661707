import React, { ReactNode } from 'react';

interface HintProps {
  children: ReactNode;
}
export const HintComponent: React.FC<HintProps> = ({ children }) => (
  <div className="flex items-center">
    <div className="w-2 h-2 mx-2 bg-blue-500 rounded-full" />
    <div className="text-xs font-medium">{children}</div>
  </div>
);

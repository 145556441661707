import React, { ReactNode, useState } from 'react';

import { Sidebar } from './Sidebar';
import { getSidebar, profileSelector } from 'domain/account/selectors';
import { useAppSelector } from 'hooks/redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import useIsMobile from 'hooks/useIsMobile';

interface LayoutAppProps {
  children: ReactNode;
}

export const LayoutApp: React.FC<LayoutAppProps> = ({ children }) => {
  const isMobile = useIsMobile();
  const { sidebar, isLoadingSidebar } = useAppSelector(getSidebar);
  const { profile } = useAppSelector(profileSelector);

  const [isOpen, setOpenState] = useState<boolean>(!isMobile);
  const [showContent, setShowContent] = useState<boolean>(false);

  const className = isOpen
    ? 'flex-grow w-full h-full bg-gray-200 pl-16 lg:pl-[16.5rem]'
    : 'flex-grow w-full h-full bg-gray-200 pl-16';

  const toggleButton = (
    <div
      tabIndex={0}
      role="button"
      aria-hidden="true"
      onClick={() => setOpenState(!isOpen)}
      className="flex h-7 w-7 items-center justify-center rounded-md border-solid border-2 bg-white item-border-color-blue"
    >
      {isOpen ? (
        <ArrowBackIosNewOutlinedIcon fontSize="small" className="text-black" />
      ) : (
        <ArrowForwardIosIcon fontSize="small" className="text-black" />
      )}
    </div>
  );

  const openModal = () => setOpenState(true);

  return (
    <div className="flex h-full min-h-screen">
      <Sidebar
        profile={profile}
        sidebarItems={sidebar}
        loading={isLoadingSidebar}
        isOpen={isOpen}
        openModal={openModal}
        setShowContent={setShowContent}
      >
        {toggleButton}
      </Sidebar>
      <div className="flex flex-col w-full h-screen">
        <div className={className}>{showContent ? children : null}</div>
      </div>
    </div>
  );
};

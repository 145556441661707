import {
  FormEvent,
  KeyboardEventHandler,
  ReactNode,
  Ref,
  useState,
} from 'react';
import { IconEye } from '../icons/IconEye';
import { FieldError } from 'react-hook-form';
import { TooltipPositions } from '../Tooltip';
import { Input } from './Input';

type InputProps = {
  label?: any;
  subLabel?: any;
  disabled?: boolean;
  labelFontClassName?: string;
  labelColorClassName?: string;
  name?: string;
  inputRef?: Ref<HTMLInputElement & HTMLTextAreaElement>;
  type?: 'text' | 'password' | 'textarea';
  icon?: ReactNode;
  error?: FieldError;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: KeyboardEventHandler;
  inputProps: any;
};

export type SelectProps = Omit<InputProps, 'type' | 'icon' | 'name'> & {
  name?: string;
  onChange?: (value: any) => any;
  value?: string;
  disabled?: boolean;
  items: { label: string | ReactNode; value: string }[];
  tooltipPosition?: TooltipPositions;
};

export const PasswordInput = (props: Omit<InputProps, 'type'>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Input
      type={passwordVisible ? 'text' : 'password'}
      {...props}
      icon={
        <button
          type="button"
          className="p-1 rounded focus:outline-none focus:shadow-outline"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          <IconEye className="text-gray-700" />
        </button>
      }
    />
  );
};

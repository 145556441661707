import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Authorizations } from './authorizations/Authorizations';
import { PreviewDetails } from './authorizations/list/component/PreviewDetails';
import { useEffect } from 'react';
import { resetAll } from './authorizations/authorizationsSlice';
import { useAppDispatch } from 'hooks/redux';

export const AuthorizationManagement = () => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch();

  useEffect(
    () => () => {
      dispatch(resetAll());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <PreviewDetails />
      </Route>
      <Route path={`${match.path}`}>
        <Authorizations />
      </Route>
    </Switch>
  );
};

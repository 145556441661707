import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UserRoutManagement } from './UserRoutManagement';
import { RoleRoutManagement } from './RoleRoutManagement';

export const UserManagement = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/user-list`}>
        <UserRoutManagement />
      </Route>
      <Route path={`${match.path}/role-list`}>
        <RoleRoutManagement />
      </Route>
    </Switch>
  );
};

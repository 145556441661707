import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FilterSelect } from 'components/filters/FilterSelect';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input } from 'components/inputs';
import { roleList } from 'api/roles';

import { defaultFilters, FiltersParams, userStatusItems } from '../../utils';
import { resetAllFilters, setApplyFilters, setFilters } from '../../userSlice';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { getAppliedFilters, getFilters } from '../../selectors';

import { useFilterEffect } from 'utils/useFilterEffect';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const permissions: any = useAppSelector(getProfilePermission);
  const filters: FiltersParams = useAppSelector(getFilters);
  const appliedFilters: FiltersParams = useAppSelector(getAppliedFilters);
  const hasRolePermission = permissions.includes(UserPermissions.ROLE_LIST);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    reset: resetFilters,
  } = useForm<FiltersParams>({ defaultValues: appliedFilters });

  useEffect(() => {
    if (history.location.state?.status) {
      setValue('status', history.location.state.status);
      dispatch(setApplyFilters({ status: history.location.state.status }));
    }
  }, [history.location.state, setValue, dispatch]);

  const allValues = watch();
  const previousValues = useRef<FiltersParams>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('userManagement.filters.email')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('userEmail'),
        }}
      />
    ),
    1: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t('userManagement.filters.username')}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('userName'),
        }}
      />
    ),
    2: hasRolePermission ? (
      <FilterSelect
        name="rolesId"
        label={t('userManagement.filters.role')}
        loadOptions={roleList}
        control={control}
        isClearable={false}
      />
    ) : null,
    3: (
      <FilterSelect
        name="status"
        label={t('userManagement.filters.status')}
        options={userStatusItems}
        control={control}
        isClearable={false}
      />
    ),
  };

  const usersHasFilter = [
    filters.status.length ||
      filters.rolesId.length ||
      filters.userEmail?.length ||
      filters.userName?.length,
  ].some((value) => !!value);

  const reset = () => {
    dispatch(resetAllFilters());
    resetFilters(defaultFilters);
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!usersHasFilter}
      isDisabledApply={!usersHasFilter}
    />
  );
};

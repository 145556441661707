import { createAsyncThunk } from '@reduxjs/toolkit';

import { virtualTerminalResource } from './api';
import { RootState } from 'store/store';
import { getRoot } from './selectors';
import { LoadItems } from 'entities';
import { listRequestPayload } from './utils';
import { dateTimeZone } from '../../account/selectors';

export const loadItems: any = createAsyncThunk(
  'virtualTerminal/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { page, rowsPerPage, appliedFilters, sort } = getRoot(
        getState() as RootState
      );

      const dateZone = dateTimeZone(getState() as RootState);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters, dateZone),
      };

      return await virtualTerminalResource.paymentList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const createPayment: any = createAsyncThunk(
  'virtualTerminal/createPayment',
  async (data) => {
    try {
      return await virtualTerminalResource.createPayment(data);
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const findCardBrand: any = createAsyncThunk(
  'virtualTerminal/findCardBrand',
  async (data) => {
    try {
      return await virtualTerminalResource.getCardBrand(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
);

import { createSlice } from '@reduxjs/toolkit';

export type BreadcrumbItem = {
  label: string;
  route: string;
};

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    items: [],
  } as { items: BreadcrumbItem[] },
  reducers: {
    setBreadcrumbs(state, action: { payload: BreadcrumbItem[] }) {
      state.items = action.payload;
    },
    resetBreadcrumbs(state) {
      state.items = [];
    },
  },
});

import classNames from 'classnames';
import { getPaymentStep } from '../../selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const StepWizardComponent = () => {
  const paymentStep = useSelector(getPaymentStep);
  const { t } = useTranslation();

  return (
    <ul className="step-wizard-list w-full">
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item ',
          {
            'current-item': paymentStep === 1,
          }
        )}
      >
        <span className="progress-count">1</span>
        <span className="progress-label">
          {t('virtualTerminal.createDetails.transactionsDetails')}
        </span>
      </li>
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item',
          {
            'current-item': paymentStep === 2,
          }
        )}
      >
        <span className="progress-count">2</span>
        <span className="progress-label">
          {t('virtualTerminal.createDetails.paymentMethodDetails')}
        </span>
      </li>
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item',
          {
            'current-item': paymentStep === 3,
          }
        )}
      >
        <span className="progress-count">3</span>
        <span className="progress-label">
          {t('virtualTerminal.createDetails.billingAddress')}
        </span>
      </li>
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item',
          {
            'current-item': paymentStep === 4,
          }
        )}
      >
        <span className="progress-count">4</span>
        <span className="progress-label">
          {t('virtualTerminal.createDetails.summery')}
        </span>
      </li>
    </ul>
  );
};

import { ProductType, ProgramItem } from 'entities/system';
import moment from 'moment-timezone';

// eslint-disable-next-line @typescript-eslint/ban-types
type ItemFilters = {};

type MiscFilters = {
  productType: ProductType | null;
  name: string;
  date: {
    from: string | null;
    to: string | null;
  };
};

export type Filters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {};

const miscFilters: MiscFilters = {
  productType: null,
  name: '',
  date: {
    from: null,
    to: null,
  },
};

export const defaultFilters: Filters = {
  ...itemFilters,
  ...miscFilters,
};

// eslint-disable-next-line @typescript-eslint/ban-types
type LoadItemsOptions = {} & Filters;

export const listRequestPayload = (options: LoadItemsOptions) => {
  const date = options.date.from ? { filters: options.date } : {};
  return {
    productType: options.productType || undefined,
    name: options.name || undefined,
    ...date,
  };
};

export const normalizeChargeback = (chargeback: any): ProgramItem => ({
  ...chargeback,
});

export type TotalSalesItems = {
  status: string | null;
  approvalDate: string | null;
  totalSalesPerDay: TotalSalesResponse[];
  totalSalesPerMonth: TotalSalesResponse[];
  totalSalesRolling7Days: TotalSalesRollingPerDayResponse[];
  totalSalesRolling30Days: TotalSalesRollingPerDayResponse[];
  totalSalesRolling12Months: TotalSalesRollingPerDayResponse[];
};

export type TotalSalesResponse = {
  date: string | null | undefined;
  calculatedVolume: number;
  expectedVolume: number;
  expectedVolumeNotice: number;
  expectedVolumeBlock: number;
};

export type TotalSalesRollingPerDayResponse = {
  date: string | null | undefined;
  calculatedVolume: number;
  rollingSum: number;
  expectedVolume: number;
  expectedVolumeNotice: number;
  expectedVolumeBlock: number;
};

export const TotalSalesItem: TotalSalesItems = {
  status: null,
  approvalDate: null,
  totalSalesPerDay: [] as TotalSalesResponse[],
  totalSalesPerMonth: [] as TotalSalesResponse[],
  totalSalesRolling7Days: [] as TotalSalesRollingPerDayResponse[],
  totalSalesRolling30Days: [] as TotalSalesRollingPerDayResponse[],
  totalSalesRolling12Months: [] as TotalSalesRollingPerDayResponse[],
};
//
// export type Notifications = {
//   id: number;
//   content: string;
//   relationId: string;
//   timeInfo: string;
//   title: string;
//   type: string;
// };

export const dateInitRange = {
  from: moment()
    .tz('UTC', false)
    .subtract(30, 'day')
    .startOf('day')
    .utc()
    .toDate(),
  to: moment()
    .tz('UTC', false)
    .subtract(1, 'day')
    .startOf('day')
    .utc()
    .toDate(),
};

export const startDate = moment().subtract(30, 'days').format('DD-MM-YYYY');
export const endDate = moment().subtract(1, 'days').format('DD-MM-YYYY');

export enum RangeType {
  SEVEN_DAYS = 'SEVEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
}

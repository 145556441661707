import { CreateParams } from '../../utils';
import React from 'react';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { countryCodeList } from 'api/merchants';
import { useTranslation } from 'react-i18next';
import { Input, SelectController } from 'components/inputs';
import { PaymentCreateSteps } from './PaymentCreateSteps';
import { isStateDisabled, stateList } from 'domain/system/merchants/utils';
import { upPaymentCreateStep } from '../../virtualTerminalSlice';
import { useAppDispatch } from 'hooks/redux';

export const BillingAddressDetails: React.FC<CreateParams> = ({
  register,
  errors,
  control,
  watch,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { country } = watch();

  const nextUpdate = () => {
    dispatch(upPaymentCreateStep());
  };

  return (
    <>
      <form onSubmit={handleSubmit(nextUpdate)}>
        <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:m-4 sm:p-3">
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.addressLine1')}
                />
              }
              className="h-10"
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-back"
              error={errors.addressLine1}
              inputProps={{
                ...register('addressLine1', {
                  required: true,
                  maxLength: {
                    value: 30,
                    message: t('blockedPans.maxLength', {
                      maxLength: 30,
                    }),
                  },
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText={t('virtualTerminal.createDetails.addressLine2')}
                />
              }
              className="h-10"
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-back"
              error={errors.addressLine2}
              inputProps={{
                ...register('addressLine2', {
                  maxLength: {
                    value: 80,
                    message: t('blockedPans.maxLength', {
                      maxLength: 80,
                    }),
                  },
                }),
              }}
            />
          </div>
          <div className="w-full">
            <SelectController
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText={t('virtualTerminal.createDetails.country')}
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              height="2.5rem"
              name="country"
              control={control}
              loadOptions={countryCodeList}
              closeMenuOnSelect
              error={errors.country}
            />
          </div>
          <div className="w-full">
            {country && country?.value === 'US' ? (
              <SelectController
                label={
                  <RequiredFieldComponent
                    isRequired={false}
                    labelText={t('virtualTerminal.createDetails.state')}
                    className="font-medium tracking-wide text-xs text-black"
                  />
                }
                height="2.5rem"
                name="stateUS"
                control={control}
                options={stateList}
                closeMenuOnSelect
                error={errors.stateUS}
              />
            ) : (
              <Input
                label={
                  <RequiredFieldComponent
                    isRequired={!isStateDisabled(country?.value)}
                    labelText={t('virtualTerminal.createDetails.stateProvince')}
                  />
                }
                className="h-10"
                disabled={isStateDisabled(country?.value)}
                labelFontClassName="font-medium tracking-wide text-xs"
                labelColorClassName="text-back"
                error={errors.state}
                inputProps={{
                  ...register('state'),
                }}
              />
            )}
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText={t('virtualTerminal.createDetails.city')}
                />
              }
              className="h-10"
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-back"
              error={errors.city}
              inputProps={{
                ...register('city', {
                  maxLength: {
                    value: 50,
                    message: t('blockedPans.maxLength', {
                      maxLength: 50,
                    }),
                  },
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.postalZipCode')}
                />
              }
              className="h-10"
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-back"
              error={errors.postalZipCode}
              inputProps={{
                ...register('postalZipCode', {
                  required: true,
                  maxLength: {
                    value: 50,
                    message: t('blockedPans.maxLength', {
                      maxLength: 50,
                    }),
                  },
                }),
              }}
            />
          </div>
        </div>
        <PaymentCreateSteps />
      </form>
    </>
  );
};

import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { forgotPassword } from 'domain/account/thunks';
import { emailPattern } from 'validation/patterns';
import { Input } from 'components/inputs/Input';
import { useAppDispatch } from 'hooks/redux';

type ForgotPasswordSchema = {
  email: string;
};

export const FormForgotPassword = ({
  onSubmit = () => {},
}: {
  onSubmit?: (data: ForgotPasswordSchema) => void;
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordSchema>();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleSubmitCallback = async (data: ForgotPasswordSchema) => {
    const { error } = dispatch(
      forgotPassword({
        data: data,
      })
    );

    if (error?.code) {
      setError('email', { type: 'custom', message: 'Something went wrong' });
    } else {
      onSubmit(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className="w-full">
      <div className="mb-8">
        <Input
          label={t('loginForm.email')}
          error={errors.email}
          inputProps={register('email', {
            required: true,
            validate: {
              email: (value) => emailPattern.test(value),
            },
          })}
        />
      </div>
      <LoadingButton
        loading={isSubmitting}
        type="submit"
        variant="contained"
        className="w-full"
      >
        {t('forgotPasswordForm.forgotPasswordSubmit')}
      </LoadingButton>
    </form>
  );
};

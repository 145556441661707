import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellLight, ColumnProps } from 'components/table/Table';
import { ReportItem } from 'entities/reports';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from '../../../account/selectors';
import {
  dateTimeFormatUtil,
  dateWithInitFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { ReportStatusBadge } from '../../components/ReportStatusBadge';
import { useParams } from 'react-router-dom';

export const useReportColumns = (additionalCols: ColumnProps[]) => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const dateFormatVal = (value: string) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);

  const { reportType } = useParams<{ reportType: string }>();

  const withInitValue = (value: string) =>
    dateWithInitFormatUtil(value, 'MMM-YYYY', 'MMMM YYYY', dateZone);

  const dateTimeFormatVal = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'reportName',
        value: (item: ReportItem) => (
          <CellLight>{item.reportName.replaceAll(/(_)/g, ' ')}</CellLight>
        ),
        label: t('reports.acquiring.reportName'),
        width: 200,
        isSorted: true,
        sortField: 'REPORTS_NAME',
      },
      {
        id: 'status',
        value: (item: ReportItem) => (
          <CellLight>
            <ReportStatusBadge status={item.status}>
              {item.status}
            </ReportStatusBadge>
          </CellLight>
        ),
        label: t('reports.acquiring.status'),
        width: 130,
        isSorted: true,
        sortField: 'REPORTS_STATUS',
      },
      ...additionalCols,
      {
        id: 'dateRange',
        value: (item: ReportItem) => (
          <CellLight>
            {item.dateRange &&
              (reportType === 'daily'
                ? dateFormatVal(item.dateRange)
                : withInitValue(item.dateRange))}
          </CellLight>
        ),
        label: t('reports.acquiring.dateRange'),
        width: 130,
        isSorted: true,
        sortField: 'REPORTS_DATE_RANGE',
      },
      {
        id: 'generatedBy',
        value: (item: ReportItem) => <CellLight>{item.generatedBy}</CellLight>,
        label: t('reports.acquiring.generatedBy'),
        width: 130,
        isSorted: true,
        sortField: 'REPORTS_GENERATED_BY',
      },
      {
        id: 'generatedAt',
        value: (item: ReportItem) => (
          <CellLight>
            {item.generatedAt && dateTimeFormatVal(item.generatedAt)}
          </CellLight>
        ),
        label: t('reports.acquiring.generatedAt'),
        width: 200,
        isSorted: true,
        sortField: 'REPORTS_GENERATED_AT',
      },
    ],
    [additionalCols, t]
  );
};

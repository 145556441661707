import LockResetIcon from '@mui/icons-material/LockReset';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { PASSWORD_LENGTH_MAX, PASSWORD_LENGTH_MIN } from 'validation/patterns';
import { PasswordInput } from 'components/inputs/PasswordInput';
import { resetPassword } from 'domain/account/thunks';

import { repeatedValidation, sequenceValidation } from '../components/util';
import { PasswordHintInfo } from '../components/PasswordHintInfo';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type ResetPasswordSchema = {
  newPassword: string;
  repeatedPassword: string;
  email: string;
};

const passwordValidations = {
  minLength: PASSWORD_LENGTH_MIN,
  maxLength: PASSWORD_LENGTH_MAX,
  lowercase: (password: string) => /[a-z]/.test(password),
  uppercase: (password: string) => /[A-Z]/.test(password),
  digits: (password: string) => /[0-9]/.test(password),
  special: (password: string) => /\W|_/g.test(password),
  sequenceCharacters: sequenceValidation,
  repeatedCharacters: repeatedValidation,
};

export const FormResetPassword = ({
  token,
}: {
  onSubmit?: () => void;
  token: string;
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<ResetPasswordSchema>({
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitCallback = async (data: ResetPasswordSchema) => {
    let result: any;
    try {
      if (token) {
        if (!executeRecaptcha) {
          return;
        }

        const tokenKey = await executeRecaptcha('formResetPassword');
        result = await dispatch(
          resetPassword({
            data: { ...data, token: token },
            header: {
              recaptcha: tokenKey,
              'Content-Type': 'application/json',
            },
          })
        );
      } else {
        result = await dispatch(
          resetPassword({
            data: { ...data, token: token },
            header: {
              recaptcha: null,
              'Content-Type': 'application/json',
            },
          })
        );
      }

      if (result.payload) {
        history.push('/login');
      }
    } catch ({ error: { code } }: any) {
      switch (code) {
        default:
          setError('email', {
            type: 'custom',
            message: 'somethingWentWrong',
          });
          break;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className="w-full">
      <div className="mb-5">
        <div className="w-full">
          <PasswordInput
            label={
              <PasswordHintInfo
                minLength={passwordValidations.minLength}
                maxLength={passwordValidations.maxLength}
              />
            }
            error={errors.newPassword}
            inputProps={register('newPassword', {
              required: true,
              minLength: {
                value: passwordValidations.minLength,
                message: t('resetPasswordForm.passwordMinLengthHint', {
                  minLength: passwordValidations.minLength,
                }),
              },
              maxLength: {
                value: passwordValidations.maxLength,
                message: t('resetPasswordForm.passwordMaxLengthHint', {
                  maxLength: passwordValidations.maxLength,
                }),
              },
              validate: {
                passwordUppercaseHint: passwordValidations.uppercase,
                passwordLowercaseHint: passwordValidations.lowercase,
                passwordDigitHint: passwordValidations.digits,
                passwordSpecialHint: passwordValidations.special,
                sequenceCharacters: passwordValidations.sequenceCharacters,
                repeatedCharacters: passwordValidations.repeatedCharacters,
              },
            })}
          />
        </div>
      </div>
      <div className="mb-8">
        <PasswordInput
          label={t('resetPasswordForm.passwordRepeat')}
          error={errors.repeatedPassword}
          inputProps={register('repeatedPassword', {
            required: true,
            validate: {
              passwordConfirmation: (value) =>
                value === getValues().newPassword,
            },
          })}
        />
      </div>
      <LoadingButton
        loading={isSubmitting}
        loadingPosition="start"
        type="submit"
        className="w-full"
        variant="contained"
        startIcon={<LockResetIcon />}
      >
        {t('resetPasswordForm.resetPasswordSubmit')}
      </LoadingButton>
    </form>
  );
};

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from 'components/inputs';

interface FilterReferenceNumberProps {
  register: UseFormReturn['register'];
}

export const FilterReferenceNumber: React.FC<FilterReferenceNumberProps> = ({
  register,
}) => {
  const { t } = useTranslation();

  return (
    <Input
      label={t(
        'transactionsManagement.transactions.list.filters.referenceNumber'
      )}
      labelFontClassName="text-xs font-medium"
      className="h-8 text-xs font-bold"
      inputProps={register('referenceNumber')}
    />
  );
};

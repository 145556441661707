import { ChevronLeft, CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { FormForgotPassword } from './FormForgotPassword';

const BackToLogin = () => {
  const { t } = useTranslation();

  return (
    <Link
      to="/login"
      className="mt-5 text-sm font-semibold text-blue-500 flex items-center"
    >
      <ChevronLeft className="mr-2" fontSize="small" />
      <span className="block h-5">{t('forgotPasswordForm.backToLogin')}</span>
    </Link>
  );
};

export const Container = () => {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');

  return (
    <div className="flex justify-center w-full min-h-screen py-12">
      <div className="flex flex-col items-center lg:justify-center w-full max-w-sm px-3">
        {submitted && (
          <div className="mb-6">
            <CheckCircle className="text-green-500" sx={{ fontSize: 60 }} />
          </div>
        )}
        <h3 className="mb-3 text-2xl font-bold text-gray-900">
          {submitted
            ? t('forgotPasswordForm.submittedTitle')
            : t('forgotPasswordForm.title')}
        </h3>
        {submitted ? (
          <>
            <p className="px-5 mb-8 text-base font-medium text-center text-gray-700">
              {t('forgotPasswordForm.submittedDescription')}
              <br />
              <span className="text-blue-500">{submittedEmail}</span>
            </p>
            <p className="px-5 mb-8 text-base font-medium text-center text-gray-700">
              {t('forgotPasswordForm.submittedNote')}
            </p>
            <BackToLogin />
          </>
        ) : (
          <p className="px-5 mb-8 text-base font-semibold text-center text-gray-700">
            {t('forgotPasswordForm.description')}
          </p>
        )}
        {!submitted && (
          <>
            <FormForgotPassword
              onSubmit={({ email }) => {
                setSubmitted(true);
                setSubmittedEmail(email);
              }}
            />
            <BackToLogin />
          </>
        )}
      </div>
    </div>
  );
};

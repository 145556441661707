import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { programsResource } from './api';
import { RootState } from 'store/store';

import { getPagination, getRoot } from './selectors';
import { listRequestPayload } from './utils';
import { PaginationProps } from 'components/table/TableFooter';
import { LoadItems } from 'entities';

export const loadItems: any = createAsyncThunk(
  'sysPrograms/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { page, rowsPerPage, appliedFilters, sort } = getRoot(
        getState() as RootState
      );

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await programsResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const reloadItems: any = createAsyncThunk(
  'umRolesList/reloadItems',
  async (_: void, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;
      const { page, rowsPerPage }: PaginationProps = getPagination(
        getState() as RootState
      );

      dispatch(
        loadItems({
          offset: 0,
          limit: page * rowsPerPage,
        })
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'sysPrograms/fetchDetails',
  async (data: { id: string }) => {
    try {
      const response = await programsResource.fetchProgram(data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const createPrograms: any = createAsyncThunk(
  'sysPrograms/createPrograms',
  async (data, thunkApi) => {
    try {
      await programsResource.createPrograms(data);

      toast.success('Program created', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      thunkApi.dispatch(reloadItems());

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

import { useEffect } from 'react';
import { SecretKeyManagementList } from './SecretKeyManagementList';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loadItems } from '../thunks';
import { getMainState } from '../selectors';
import { useLocation } from 'react-router-dom';

export const Container = () => {
  const { t } = useTranslation();

  const { page, appliedFilters, rowsPerPage, sort } =
    useAppSelector(getMainState);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: 'Api secret key management',
          route: pathname,
        },
        {
          label: 'Api Secret Key Management',
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(() => {
    dispatch(loadItems());
  }, [dispatch, page, appliedFilters, rowsPerPage, sort]);

  return <SecretKeyManagementList />;
};

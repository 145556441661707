import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMainState } from '../selectors';
import { useHistory, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchDetails,
  fetchListCountryCode,
  updateMerchantToggleStatus as updateMerchant,
} from '../thunks';
import { PageHeader } from 'components/PageHeader';
import { Button, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { RectLoader } from 'components/RectLoader';
import classNames from 'classnames';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MemoryIcon from '@mui/icons-material/Memory';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { ItemView } from 'components/grid/ItemView';
import { MerchantToggleStatusBadge } from '../list/MerchantToggleStatusBadge';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  countryStateList,
  GatewaysValues,
  getMerchantDetailsIconColor,
  MerchantStatuses,
  stateList,
} from '../utils';
import { resetMerchantCreated } from '../merchantsSlice';
import { HintInfoMessage } from 'domain/auth/components/HintInfoMessage';
import { useSelector } from 'react-redux';
import {
  dateTimeFormat,
  dateTimeZone,
  getProfilePermission,
} from 'domain/account/selectors';
import {
  convertToTimeUtil,
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { UserPermissions } from 'enums/Permissions';

export const MerchantPreviewDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    details: merchantDetails,
    isDetailsLoading,
    itemNotFound,
    isMerchantCreated,
  } = useAppSelector(getMainState);
  const history: any = useHistory();
  const { id } = useParams<{ id: string }>();
  const merchantId = Base64.decode(id);
  const permissions: any = useAppSelector(getProfilePermission);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  const timeConverter = (value: string | null) =>
    convertToTimeUtil(value, dateZone);
  const hasUpdateStatusPermission = permissions.includes(
    UserPermissions.MERCHANT_TOGGLE
  );
  const hasUpdateMerchantPermission = permissions.includes(
    UserPermissions.MERCHANT_UPDATE
  );
  const hasUpdateAccountHolderPermission = permissions.includes(
    UserPermissions.ACCOUNT_HOLDERS_UPDATE
  );

  const hasUpdateRapidConnectPermission =
    permissions.includes(UserPermissions.RAPID_CONNECT_MERCHANTS_UPDATE) ||
    permissions.includes(UserPermissions.RAPID_CONNECT_MERCHANTS_CREATE);
  const hasUpdateReportsConfPermission = permissions.includes(
    UserPermissions.REPORT_CONFIGURATION_MODIFY
  );
  const hasUpdateMerchantConfPermission =
    permissions.includes(UserPermissions.MERCHANT_CONFIGURATION_MODIFY) ||
    permissions.includes(UserPermissions.MERCHANT_LIMITS_UPDATE);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [isSetToAudit, setIsSetToAudit] = useState<boolean>(false);

  useEffect(() => {
    if (history.location.state?.isSetToAudit) {
      setIsSetToAudit(history.location.state?.isSetToAudit);
    }
  }, [dispatch, history.location.state?.isSetToAudit]);

  const onComplianceAudit = () => {
    history.push('/compliance-audit', {
      merchant: {
        label: merchantDetails.name,
        value: merchantId,
      },
    });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const stateValue =
    merchantDetails.countryCode === 'US' && merchantDetails.state?.length
      ? stateList.filter((val) => val.value === merchantDetails.state)[0].label
      : countryStateList.includes(merchantDetails.countryCode!) &&
          merchantDetails.state?.length
        ? merchantDetails.state
        : null;

  const rcCreated =
    merchantDetails.processorName === GatewaysValues.RAPID_CONNECT &&
    merchantDetails.merchantStatusDetails.currentStatus ===
      MerchantStatuses.PENDING &&
    !!merchantDetails.rapidConnectMerchant.id;

  const rcNotCreated =
    merchantDetails.processorName === GatewaysValues.RAPID_CONNECT &&
    merchantDetails.merchantStatusDetails.currentStatus ===
      MerchantStatuses.PENDING &&
    !merchantDetails.rapidConnectMerchant.id;

  useEffect(() => {
    dispatch(
      fetchDetails({
        id: merchantId,
      })
    );
    dispatch(fetchListCountryCode());
  }, [dispatch, id, merchantId]);

  const updateMerchantStatus = useCallback(
    (status: string, reason: string) => {
      dispatch(
        updateMerchant({
          merchantId,
          status: status,
          reason: reason,
          fromDateTime: null,
        })
      )
        .unwrap()
        .then(() =>
          dispatch(
            fetchDetails({
              id: merchantId,
            })
          )
        );
    },
    [dispatch, merchantId]
  );

  const goToMerchantList = () => {
    dispatch(resetMerchantCreated());
    history.push('/system/merchants');
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('system.merchantDetails.details')} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          {/*back bar*/}
          <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <div>
              {isMerchantCreated ? (
                <Button
                  onClick={goToMerchantList}
                  startIcon={<KeyboardBackspaceIcon />}
                >
                  Merchants List
                </Button>
              ) : (
                <>
                  {isSetToAudit ? (
                    <div className="flex flex-row gap-4">
                      <div>
                        <Button onClick={onComplianceAudit} variant="outlined">
                          Compliance Audit
                        </Button>
                      </div>
                      <div>
                        <Button onClick={goToMerchantList} variant="outlined">
                          Merchants List
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Button onClick={() => history.goBack()} variant="outlined">
                      Back
                    </Button>
                  )}
                </>
              )}
            </div>
            {!isDetailsLoading && (
              <>
                {merchantDetails.merchantStatusDetails.currentStatus ===
                  MerchantStatuses.PENDING &&
                  hasUpdateStatusPermission && (
                    <div className="flex gap-2">
                      {rcNotCreated && (
                        <div>
                          <HintInfoMessage
                            message={t(
                              'system.merchantDetails.rapidConnectHint'
                            )}
                          />
                        </div>
                      )}
                      <div>
                        <Button
                          onClick={() =>
                            updateMerchantStatus(
                              MerchantStatuses.APPROVED,
                              'Merchant APPROVED'
                            )
                          }
                          disabled={
                            merchantDetails.processorName ===
                              GatewaysValues.RAPID_CONNECT &&
                            !merchantDetails.rapidConnectMerchant.id
                          }
                          variant="contained"
                          color="success"
                        >
                          APPROVE
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() =>
                            updateMerchantStatus(
                              MerchantStatuses.REJECTED,
                              'Merchant REJECTED'
                            )
                          }
                          variant="contained"
                          color="error"
                        >
                          REJECT
                        </Button>
                      </div>
                    </div>
                  )}
                {merchantDetails.merchantStatusDetails.currentStatus ===
                  MerchantStatuses.REJECTED &&
                  hasUpdateStatusPermission && (
                    <div className="flex gap-2">
                      <div>
                        <Button
                          onClick={() =>
                            updateMerchantStatus(
                              MerchantStatuses.PENDING,
                              'Merchant PENDING'
                            )
                          }
                          variant="contained"
                          color="warning"
                        >
                          PENDING
                        </Button>
                      </div>
                    </div>
                  )}
                {(merchantDetails.merchantStatusDetails.currentStatus ===
                  MerchantStatuses.ACTIVE ||
                  merchantDetails.merchantStatusDetails.currentStatus ===
                    MerchantStatuses.SUSPENDED ||
                  merchantDetails.merchantStatusDetails.currentStatus ===
                    MerchantStatuses.BLOCKED ||
                  merchantDetails.merchantStatusDetails.currentStatus ===
                    MerchantStatuses.APPROVED) &&
                  hasUpdateStatusPermission && (
                    <div className="flex gap-2">
                      <div>
                        <Button
                          onClick={() =>
                            history.push(
                              '/system/merchants/update/toggle_status'
                            )
                          }
                          variant="outlined"
                          color="primary"
                        >
                          Toggle Status
                        </Button>
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
          {itemNotFound && (
            <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-col items-center justify-center justify-items-center w-40 h-16 rounded-md ml-3 box-shadow-nero box-bg-nero-300">
                    <div>
                      <StorefrontIcon fontSize="large" sx={{ color: '#fff' }} />
                    </div>
                    <div className="text-xs text-white ml-1">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                  <div>No such merchant</div>
                </div>
              </div>
            </div>
          )}
          {isDetailsLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}
          {!isDetailsLoading && !itemNotFound && (
            <>
              {/*-===Merchant details===-*/}
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <div className="-mt-8 w-full sm:w-auto">
                      <div
                        className={classNames(
                          'flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3',
                          getMerchantDetailsIconColor(
                            merchantDetails.merchantStatusDetails.currentStatus
                          )
                        )}
                      >
                        <div>
                          <StorefrontIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-1">Details</div>
                      </div>
                    </div>
                    {(merchantDetails.processorName === 'NMI' ||
                      merchantDetails.processorName === 'RAPID_CONNECT') &&
                      merchantDetails.merchantStatusDetails.currentStatus ===
                        MerchantStatuses.PENDING &&
                      hasUpdateMerchantPermission && (
                        <div className="w-full sm:w-auto mt-2 sm:-mt-1 flex items-end justify-end justify-items-center">
                          <Button
                            onClick={() =>
                              history.push(
                                '/system/merchants/update/merchant_details'
                              )
                            }
                            variant="outlined"
                            color="warning"
                            startIcon={<EditIcon />}
                            className="w-full"
                          >
                            EDIT
                          </Button>
                        </div>
                      )}
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                    <ItemView labelName="Name" value={merchantDetails.name} />
                    <ItemView
                      labelName="Logo URL"
                      value={merchantDetails.logoUrl}
                    />
                    <ItemView
                      labelName="Externaml Merchant Id"
                      value={merchantDetails.externalMerchantId}
                    />
                    <ItemView
                      labelName="Program Name"
                      value={merchantDetails.programName}
                    />
                    <ItemView
                      labelName="Processor Name"
                      value={merchantDetails.processorName?.replace('_', ' ')}
                    />
                    <ItemView
                      labelName="Status"
                      value={
                        <MerchantToggleStatusBadge
                          status={
                            merchantDetails.merchantStatusDetails.currentStatus
                          }
                        >
                          {merchantDetails.merchantStatusDetails.currentStatus}
                        </MerchantToggleStatusBadge>
                      }
                    />
                    <ItemView
                      labelName="Future Status"
                      value={merchantDetails.merchantStatusDetails.changeStatus}
                    />
                    <ItemView
                      labelName="Status Change Date"
                      value={timeFormat(
                        merchantDetails.merchantStatusDetails.fromDateTime
                      )}
                    />
                    <ItemView
                      labelName="Status Change Reason"
                      value={merchantDetails.merchantStatusDetails.reason}
                    />
                    <ItemView
                      labelName="Country Code"
                      value={merchantDetails.countryCode}
                    />
                    <ItemView labelName="State" value={stateValue} />
                    <ItemView labelName="City" value={merchantDetails.city} />
                    <ItemView
                      labelName="Address 1"
                      value={merchantDetails.address1}
                    />
                    <ItemView
                      labelName="Address 2"
                      value={merchantDetails.address2}
                    />
                    <ItemView
                      labelName="Postal"
                      value={merchantDetails.postal}
                    />
                    <ItemView labelName="Url" value={merchantDetails.url} />
                    <ItemView
                      labelName="Timezone Id"
                      value={merchantDetails.timezoneId}
                    />
                    <ItemView
                      labelName="Account Number"
                      value={merchantDetails.accountNumber}
                    />
                    <ItemView
                      labelName="Routing Number"
                      value={merchantDetails.routingNumber}
                    />
                    <ItemView
                      labelName="Username"
                      value={merchantDetails.username}
                    />
                    <ItemView
                      labelName="Fee Plan"
                      value={merchantDetails.feePlan}
                    />
                    <ItemView
                      labelName="Currency"
                      value={merchantDetails.currency}
                    />
                    <ItemView
                      labelName="Contact First Name"
                      value={merchantDetails.contactFirstName}
                    />
                    <ItemView
                      labelName="Contact Last Name"
                      value={merchantDetails.contactLastName}
                    />
                    <ItemView
                      labelName="Contact Phone"
                      value={merchantDetails.contactPhone}
                    />
                    <ItemView
                      labelName="Contact Email"
                      value={merchantDetails.contactEmail}
                    />
                    <ItemView
                      labelName="Account Type"
                      value={merchantDetails.accountType}
                    />
                    <ItemView
                      labelName="Account Holder Type"
                      value={merchantDetails.accountHolderType}
                    />
                    <ItemView
                      labelName="Merchant Type"
                      value={merchantDetails.merchantType}
                    />
                    <ItemView
                      labelName="Capture Higher Than Authed"
                      value={merchantDetails.captureHigherThanAuthed}
                    />
                    <ItemView
                      labelName="Allow Payment Facilitator Fields"
                      value={merchantDetails.allowPaymentFacilitatorFields}
                    />
                    <ItemView
                      labelName="Created Time"
                      value={timeFormat(merchantDetails.createdTime)}
                    />
                    <ItemView
                      labelName="Updated Time"
                      value={timeFormat(merchantDetails.updatedTime)}
                    />
                  </div>
                </div>
              </div>
              {/*-===Account Holder===-*/}
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <div className="-mt-8 w-full sm:w-auto">
                      <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-biloba-flower-300 box-shadow-biloba-flower">
                        <div>
                          <AccountBalanceIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-1">
                          Account Holder
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-auto mt-2 sm:-mt-1 flex items-end justify-end justify-items-center">
                      {hasUpdateAccountHolderPermission ? (
                        <Button
                          onClick={() =>
                            history.push(
                              '/system/merchants/update/account_holder'
                            )
                          }
                          variant="outlined"
                          color="warning"
                          startIcon={<EditIcon />}
                          className="w-full"
                        >
                          EDIT
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                    <ItemView
                      labelName="First Name"
                      value={merchantDetails.accountHolder.firstName}
                    />
                    <ItemView
                      labelName="Last Name"
                      value={merchantDetails.accountHolder.lastName}
                    />
                    <ItemView
                      labelName="Email"
                      value={merchantDetails.accountHolder.email}
                    />
                    <ItemView
                      labelName="Legal Entity Type"
                      value={merchantDetails.accountHolder.legal_entity_type}
                    />
                    <ItemView
                      labelName="Phone"
                      value={merchantDetails.accountHolder.phone}
                    />
                    <ItemView
                      labelName="Birth Date"
                      value={timeFormat(
                        merchantDetails.accountHolder.birthDate
                      )}
                    />
                    <ItemView
                      labelName="Billing Address Country Code"
                      value={
                        merchantDetails.accountHolder.billingAddressCountryCode
                      }
                    />
                    <ItemView
                      labelName="Billing Address Sub Division Code"
                      value={
                        merchantDetails.accountHolder
                          .billingAddressSubDivisionCode
                      }
                    />
                    <ItemView
                      labelName="Billing Address City"
                      value={merchantDetails.accountHolder.billingAddressCity}
                    />
                    <ItemView
                      labelName="Billing Address Postal Code"
                      value={
                        merchantDetails.accountHolder.billingAddressPostalCode
                      }
                    />
                    <ItemView
                      labelName="Billing Address Address Line 1"
                      value={
                        merchantDetails.accountHolder.billingAddressAddressLine1
                      }
                    />
                  </div>
                </div>
              </div>
              {/*-===Rapid connect===-*/}
              {merchantDetails.processorName === 'RAPID_CONNECT' && (
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="flex flex-wrap justify-between">
                      <div className="-mt-8 w-full sm:w-auto">
                        <div className="flex  flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-roti-400 box-shadow-roti">
                          <div>
                            <MemoryIcon
                              fontSize="large"
                              sx={{ color: '#fff' }}
                            />
                          </div>
                          <div className="text-xs text-white ml-1">
                            Rapid Connect Configuration
                          </div>
                        </div>
                      </div>
                      {hasUpdateRapidConnectPermission ? (
                        <>
                          {rcCreated && (
                            <div className="w-full sm:w-auto mt-2 sm:-mt-1 flex items-end justify-end justify-items-center">
                              <Button
                                onClick={() =>
                                  history.push(
                                    '/system/merchants/update/rapid_connect'
                                  )
                                }
                                variant="outlined"
                                color="warning"
                                startIcon={<EditIcon />}
                                className="w-full"
                              >
                                EDIT
                              </Button>
                            </div>
                          )}
                          {rcNotCreated && (
                            <Button
                              onClick={() =>
                                history.push(
                                  '/system/merchants/update/rapid_connect'
                                )
                              }
                              variant="outlined"
                              color="primary"
                              startIcon={<AddCircleIcon />}
                            >
                              Add Configuration
                            </Button>
                          )}
                        </>
                      ) : null}
                    </div>
                    {!merchantDetails.rapidConnectMerchant.id ? (
                      <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                        <div>No Rapid Connect Info</div>
                      </div>
                    ) : (
                      <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                        <ItemView
                          labelName="Merchant Id"
                          value={
                            merchantDetails.rapidConnectMerchant.merchantId
                          }
                        />
                        <ItemView
                          labelName="Terminal Id"
                          value={
                            merchantDetails.rapidConnectMerchant.terminalId
                          }
                        />
                        <ItemView
                          labelName="Group Id"
                          value={merchantDetails.rapidConnectMerchant.groupId}
                        />
                        <ItemView
                          labelName="Datawire Id"
                          value={
                            merchantDetails.rapidConnectMerchant.datawireId
                          }
                        />
                        <ItemView
                          labelName="Status"
                          value={merchantDetails.rapidConnectMerchant.status}
                        />
                        <ItemView
                          labelName="Primary Service Url"
                          value={
                            merchantDetails.rapidConnectMerchant
                              .primaryServiceUrl
                          }
                        />
                        <ItemView
                          labelName="Secondary Service Url"
                          value={
                            merchantDetails.rapidConnectMerchant
                              .secondaryServiceUrl
                          }
                        />
                        <ItemView
                          labelName="Ecommerce Url"
                          value={
                            merchantDetails.rapidConnectMerchant.ecommerceUrl
                          }
                        />
                        <ItemView
                          labelName="Merchant Category Code"
                          value={
                            merchantDetails.rapidConnectMerchant
                              .merchantCategoryCode
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/*-===Report config===-*/}
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <div className="-mt-8 w-full sm:w-auto">
                      <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-dodger-blue-300 box-shadow-dodger-blue">
                        <div>
                          <InsertChartIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-1">
                          Reports Configuration
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-auto mt-2 sm:-mt-1 flex items-end justify-end justify-items-center">
                      {hasUpdateReportsConfPermission ? (
                        <>
                          {merchantDetails.reportsConfiguration ? (
                            <Button
                              onClick={() =>
                                history.push(
                                  '/system/merchants/update/reports_config'
                                )
                              }
                              variant="outlined"
                              color="warning"
                              startIcon={<EditIcon />}
                              className="w-full"
                            >
                              EDIT
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                history.push(
                                  '/system/merchants/update/reports_config'
                                )
                              }
                              variant="outlined"
                              color="primary"
                              startIcon={<AddCircleIcon />}
                            >
                              Add Configuration
                            </Button>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  {!merchantDetails.reportsConfiguration ? (
                    <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                      <div>No merchant reports configuration</div>
                    </div>
                  ) : (
                    <div className="grid sm:grid-cols-2 xxl:grid-cols-5 items-start justify-center justify-items-start sm:m-4 sm:p-3">
                      <div className="flex flex-col m2d:col-span-2 6xl:col-span-1 mt-2 break-words grow">
                        <div className="text-xs font-normal text-gray-700">
                          Reports
                        </div>
                        {merchantDetails.reportsConfiguration.reports.length ? (
                          merchantDetails.reportsConfiguration.reports.map(
                            (item, i = 0) => (
                              <div key={i} className="text-base font-normal">
                                <RadioButtonCheckedIcon
                                  sx={{ fontSize: 12 }}
                                  className="mr-2"
                                />
                                {item.replaceAll('_', ' ')}
                              </div>
                            )
                          )
                        ) : (
                          <div>No reports</div>
                        )}
                      </div>
                      <div className="flex flex-col mt-2 break-words">
                        <div className="text-xs font-normal text-gray-700">
                          Storage Types
                        </div>
                        {merchantDetails.reportsConfiguration.storageTypes
                          .length ? (
                          merchantDetails.reportsConfiguration.storageTypes.map(
                            (item, i = 0) => (
                              <div key={i} className="text-base font-normal">
                                <RadioButtonCheckedIcon sx={{ fontSize: 12 }} />
                                {item}
                              </div>
                            )
                          )
                        ) : (
                          <div>No storage type</div>
                        )}
                      </div>
                      <ItemView
                        labelName="Folder Name"
                        value={merchantDetails.reportsConfiguration.folderName}
                      />
                      <ItemView
                        labelName="Created At"
                        value={timeFormat(
                          merchantDetails.reportsConfiguration.createdAt
                        )}
                      />
                      <ItemView
                        labelName="Updated At"
                        value={timeFormat(
                          merchantDetails.reportsConfiguration.updatedAt
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/*-===Merchant config===-*/}
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <div className="-mt-8 w-full sm:w-auto">
                      <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-green-smoke-400 box-shadow-green-smoke">
                        <div>
                          <SettingsApplicationsIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-1">
                          Merchant Configuration
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-auto mt-2 sm:-mt-1 flex items-end justify-end justify-items-center">
                      {hasUpdateMerchantConfPermission ? (
                        <>
                          {merchantDetails.merchantConfiguration ? (
                            <Button
                              onClick={() =>
                                history.push(
                                  '/system/merchants/update/merchant_config'
                                )
                              }
                              variant="outlined"
                              color="warning"
                              startIcon={<EditIcon />}
                              className="w-full"
                            >
                              EDIT
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                history.push(
                                  '/system/merchants/update/merchant_config'
                                )
                              }
                              variant="outlined"
                              color="primary"
                              startIcon={<AddCircleIcon />}
                            >
                              Add Configuration
                            </Button>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center justify-items-start my-4 sm:mx-4">
                    <Accordion
                      expanded={expanded === 'general_info'}
                      onChange={handleChange('general_info')}
                      className="w-full"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="general_info"
                        id="general_info"
                      >
                        <Typography>General configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography component="div">
                          {merchantDetails.merchantConfiguration ? (
                            <div className="grid sm:grid-cols-2 l2g:grid-cols-4 items-start justify-center justify-items-start">
                              <ItemView
                                labelName="Client Vertical"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .clientVertical
                                }
                              />
                              <ItemView
                                labelName="Credit Limit"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .creditLimit
                                }
                              />
                              <ItemView
                                labelName="Approval Date"
                                isNotSet={true}
                                value={timeFormat(
                                  merchantDetails.merchantConfiguration
                                    .approvalDate
                                )}
                              />
                              <ItemView
                                labelName="Expiry Date"
                                value={timeFormat(
                                  merchantDetails.merchantConfiguration
                                    .expiryDate
                                )}
                              />
                              <ItemView
                                labelName="Internal Risk Rating"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .internalRiskRating
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Lowest Ticket"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedLowestTicket
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Lowest Ticket Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedLowestTicketCount
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Average Ticket"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedAverageTicket
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Highest Ticket"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedHighestTicket
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Daily Transaction Number"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedDailyTransactionNumber
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Daily Transaction Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedDailyTransactionVolume
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Refund Ratio"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedRefundRatio
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Chargeback Ratio"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedChargebackRatio
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Chargeback Days"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedChargebackDays
                                }
                              />
                              <ItemView
                                labelName="Merchant Advised Delivery Days"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantAdvisedDeliveryDays
                                }
                              />
                              <ItemView
                                labelName="Expected Monthly Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .expectedMonthlyVolume
                                }
                              />
                              <ItemView
                                labelName="Expected Monthly Transactions Number"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .expectedMonthlyTransactionsNumber
                                }
                              />
                              <ItemView
                                labelName="Merchant High Monthly Volume Percent To Flag"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantHighMonthlyVolumePercentToFlag
                                }
                              />
                              <ItemView
                                labelName="Merchant Low Monthly Volume Percent To Flag"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantLowMonthlyVolumePercentToFlag
                                }
                              />
                              <ItemView
                                labelName="Status"
                                isNotSet={true}
                                value={merchantDetails.merchantConfiguration.status.replaceAll(
                                  '_',
                                  ' '
                                )}
                              />
                              <ItemView
                                labelName="High Risk Account"
                                isNotSet={true}
                                value={merchantDetails.merchantConfiguration.highRiskAccount?.toString()}
                              />
                              <ItemView
                                labelName="High Risk Account Description"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .highRiskAccountDescription
                                }
                              />
                              <ItemView
                                labelName="Merchant Daily Transaction Number Limit Percent"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantDailyTransactionNumberLimitPercent
                                }
                              />
                              <ItemView
                                labelName="Merchant Daily Transaction Volume Limit Percent"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .merchantDailyTransactionVolumeLimitPercent
                                }
                              />
                              <ItemView
                                labelName="Daily Ticket Average"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .dailyTicketAverage
                                }
                              />
                              <ItemView
                                labelName="Daily Ticket Volume Limit Percent"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .dailyTicketVolumeLimitPercent
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Refunds Number"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyRefundsNumber
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Refunds Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyRefundsVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Refunds Volume Ratio"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyRefundsVolumeRatio
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Refunds Number Limit Percent"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyRefundsNumberLimitPercent
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Refunds Number Limit Percent"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyChargebacksNumberRatio
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Chargebacks Number"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyChargebacksNumber
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Chargebacks Count Limit Percent"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyChargebacksCountLimitPercent
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Chargebacks Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyChargebacksVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Daily Refunds Number Ratio"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedDailyRefundsNumberRatio
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Card Number Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedCardNumberCount
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Card Number Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedCardNumberVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Bank Account Number Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedBankAccountNumberCount
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Bank Account Number Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedBankAccountNumberVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Ip Address Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedIpAddressCount
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Ip Address Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedIpAddressVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Card Number Utilized Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedCardNumberUtilizedCount
                                }
                              />
                              <ItemView
                                labelName="Advised Repeated Card Number Utilized Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRepeatedCardNumberUtilizedVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Beginning Operating Hour"
                                isNotSet={true}
                                value={timeConverter(
                                  merchantDetails.merchantConfiguration
                                    .advisedBeginningOperatingHour
                                )}
                              />
                              <ItemView
                                labelName="Advised Ending Operating Hour"
                                isNotSet={true}
                                value={timeConverter(
                                  merchantDetails.merchantConfiguration
                                    .advisedEndingOperatingHour
                                )}
                              />
                              <ItemView
                                labelName="Advised Transactions Outside Operating Hours Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedTransactionsOutsideOperatingHoursCount
                                }
                              />
                              <ItemView
                                labelName="Advised Transactions Outside Operating Hours Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedTransactionsOutsideOperatingHoursVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Rejected Checked Transaction Volume"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRejectedCheckedTransactionVolume
                                }
                              />
                              <ItemView
                                labelName="Advised Rejected Checked Transaction Count"
                                isNotSet={true}
                                value={
                                  merchantDetails.merchantConfiguration
                                    .advisedRejectedCheckedTransactionCount
                                }
                              />
                              <ItemView
                                labelName="Created At"
                                value={timeFormat(
                                  merchantDetails.merchantConfiguration
                                    .createdAt
                                )}
                              />
                              <ItemView
                                labelName="Updated At"
                                value={timeFormat(
                                  merchantDetails.merchantConfiguration
                                    .updatedAt
                                )}
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center justify-items-start m-4">
                              <div>No configuration</div>
                            </div>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 'limit_info'}
                      onChange={handleChange('limit_info')}
                      className="w-full"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="limit_info"
                        id="limit_info"
                      >
                        <Typography>Limit configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography component="div">
                          {Object.keys(merchantDetails.merchantLimits)
                            .length !== 0 ? (
                            <>
                              <div>
                                <Divider>SALE</Divider>
                              </div>
                              <div className="mt-3">
                                Annual Limits{' '}
                                {/*<HintInfoMessage message="Rolling 365 days Volume Limit" />*/}
                              </div>
                              <div className="grid sm:grid-cols-2 l2g:grid-cols-4 items-start justify-center justify-items-start">
                                <ItemView
                                  labelName="Annual Volume Limit"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualTransactionVolumeLimit
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Notice"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualTransactionVolumeLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Block"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualTransactionVolumeLimitBlock
                                  }
                                />
                              </div>
                              <div className="mt-3">
                                Monthly Limits{' '}
                                <HintInfoMessage message="Rolling 30 days" />
                              </div>
                              <div className="grid sm:grid-cols-2 l2g:grid-cols-4 items-start justify-center justify-items-start">
                                <ItemView
                                  labelName="Rolling 30 Days Transaction Volume Limit"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling30DaysTransactionVolumeLimit
                                  }
                                />
                                <ItemView
                                  labelName="Rolling 30 Days Transaction Volume Limit Notice"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling30DaysTransactionVolumeLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Rolling 30 Days Transaction Volume Limit Block"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling30DaysTransactionVolumeLimitBlock
                                  }
                                />
                              </div>
                              <div className="mt-3">
                                Weekly Limits{' '}
                                <HintInfoMessage message="Rolling 7 days" />
                              </div>
                              <div className="grid sm:grid-cols-2 l2g:grid-cols-4 items-start justify-center justify-items-start">
                                <ItemView
                                  labelName="Rolling 7 Days Transaction Volume Limit"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling7DaysTransactionVolumeLimit
                                  }
                                />
                                <ItemView
                                  labelName="Rolling 7 Days Transaction Volume Limit Notice"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling7DaysTransactionVolumeLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Rolling 7 Days Transaction Volume Limit Block"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling7DaysTransactionVolumeLimitBlock
                                  }
                                />
                              </div>
                              <div className="mt-3">Daily Limits</div>
                              <div className="grid sm:grid-cols-2 l2g:grid-cols-4 items-start justify-center justify-items-start">
                                <ItemView
                                  labelName="Rolling 24h Transaction Volume Limit"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling24hTransactionVolumeLimit
                                  }
                                />
                                <ItemView
                                  labelName="Rolling 24h Transaction Volume Limit Notice"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling24hTransactionVolumeLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Rolling 24h Transaction Volume Limit Block"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rolling24hTransactionVolumeLimitBlock
                                  }
                                />
                              </div>
                              <div className="mt-5">
                                <Divider>REFUND</Divider>
                              </div>
                              <div className="mt-3">
                                Annual Limits{' '}
                                {/*<HintInfoMessage message="Annual Volume" />*/}
                              </div>
                              <div className="grid sm:grid-cols-2 l2g:grid-cols-6 items-start justify-center justify-items-start">
                                <ItemView
                                  labelName="Annual Volume Limit %"
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualRefundVolumePercentLimit
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualRefundVolumeLimit
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Notice %"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualRefundPercentLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Notice"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualRefundLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Block %"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualRefundPercentLimitBlock
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Block"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualRefundLimitBlock
                                  }
                                />
                              </div>
                              <div className="mt-5">
                                <Divider>CHARGEBACKS</Divider>
                              </div>
                              <div className="mt-3">
                                Annual Limits{' '}
                                {/*<HintInfoMessage message="Annual Volume" />*/}
                              </div>
                              <div className="grid sm:grid-cols-2 l2g:grid-cols-6 items-start justify-center justify-items-start">
                                <ItemView
                                  labelName="Annual Volume Limit %"
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualChargebackVolumePercentLimit
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualChargebackVolumeLimit
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Notice %"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualChargebackPercentLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Notice"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualChargebackLimitNotice
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Block %"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualChargebackPercentLimitBlock
                                  }
                                />
                                <ItemView
                                  labelName="Annual Volume Limit Block"
                                  isNotSet={true}
                                  value={
                                    merchantDetails.merchantLimits
                                      .rollingAnnualChargebackLimitBlock
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            <div className="flex flex-col items-center justify-center justify-items-start m-4">
                              <div>No configuration</div>
                            </div>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import React from 'react';

type SaveButtonProps = {
  form?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const SaveButton: React.FC<SaveButtonProps> = ({
  form,
  loading = false,
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      loadingPosition="start"
      className="w-full max-w-[220px]"
      variant="contained"
      color="success"
      startIcon={<BookmarkAddedIcon />}
      onClick={handleClick}
      type={form ? 'submit' : 'button'}
      form={form}
    >
      <span className="text-xs sm:text-sm">{t('buttons.save')}</span>
    </LoadingButton>
  );
};

import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface LabelProps {
  name?: string;
  labelColorClassName?: string;
  label?: any;
  error?: any;
  isBottomError?: boolean;
  labelFontClassName?: string;
  children?: ReactNode;
}

const Label: React.FC<LabelProps> = ({
  children,
  label,
  name,
  labelFontClassName = 'text-sm font-bold',
  labelColorClassName = 'text-gray-900',
  error,
  isBottomError = false,
}) => {
  const { t } = useTranslation();

  return (
    <label
      htmlFor={name}
      className={classNames(
        'relative block',
        labelColorClassName,
        labelFontClassName
      )}
    >
      <span className="tracking-wide">{label}</span>
      {!isBottomError && error && (
        <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
          {error?.message || t(`validations.${error?.type}`)}
        </span>
      )}

      {children}

      {isBottomError && error && (
        <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
          {error?.message || t(`validations.${error?.type}`)}
        </span>
      )}
    </label>
  );
};

export default Label;

import { CreateParams, paymentTypeItems } from '../../utils';
import React, { useEffect } from 'react';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import {
  currencyCodeList,
  merchantList,
  merchantProgramsList,
} from 'api/merchants';
import { useTranslation } from 'react-i18next';
import { Input, SelectController } from 'components/inputs';
import { PaymentCreateSteps } from './PaymentCreateSteps';
import { upPaymentCreateStep } from '../../virtualTerminalSlice';
import { useAppDispatch } from 'hooks/redux';
import { MerchantStatuses } from 'domain/system/merchants/utils';
import { DatePickerDropDown } from 'components/DatePickerDropDown';
import moment from 'moment';
import { SelectProgramController } from 'components/inputs/SelectProgramController';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import { countDecimalPlaces } from '../../../../transaction-management/authorizations/utils';

export const TransactionsDetails: React.FC<CreateParams> = ({
  register,
  errors,
  control,
  handleSubmit,
  watch,
  setValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const { merchant } = watch();

  const nextUpdate = () => {
    dispatch(upPaymentCreateStep());
  };

  useEffect(() => {
    setValue('program', null);
  }, [merchant, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(nextUpdate)}>
        <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:m-4 sm:p-3">
          <div className="w-full">
            <SelectController
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.type')}
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              height="2.5rem"
              name="type"
              control={control}
              required
              options={paymentTypeItems}
              closeMenuOnSelect
              error={errors.type}
            />
          </div>
          <div className="w-full">
            <SelectController
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.merchant')}
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              height="2.5rem"
              name="merchant"
              control={control}
              required
              loadOptions={merchantList}
              params={{
                status: MerchantStatuses.ACTIVE,
              }}
              closeMenuOnSelect
              error={errors.merchant}
            />
          </div>
          <div className="w-full">
            <SelectProgramController
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.program')}
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              isClearable={false}
              height="2.5rem"
              name="program"
              control={control}
              loadOptions={merchant?.value ? merchantProgramsList : null}
              params={{
                merchantId: merchant?.value,
              }}
              required
              closeMenuOnSelect
              error={errors.program}
              isDisabled={!merchant?.value}
            />
          </div>
          <div className="w-full">
            <DatePickerDropDown
              title={'Select Date'}
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.transactionDate')}
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              popoverWidth={270}
              disabled
              footer={<></>}
              inputHeightClassName="h-10"
              generationDate={moment().tz(dateZone).format(dateFormat)}
              handleDateChange={() => {}}
              isMonthYearPicker={false}
            />
          </div>
          <div className="w-full">
            <SelectController
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.currency')}
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              height="2.5rem"
              name="currency"
              control={control}
              loadOptions={currencyCodeList}
              error={errors.currency}
              closeMenuOnSelect
              isDisabled
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  labelText={t('virtualTerminal.createDetails.amount')}
                />
              }
              isBottomError
              className="h-10"
              type="number"
              min={0.0}
              max={99999.99}
              step={0.01}
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-back"
              error={errors.amount}
              inputProps={{
                ...register('amount', {
                  required: true,
                  validate: {
                    maxRefundAmountDecimals: (value: any) =>
                      countDecimalPlaces(value) <= 2,
                  },
                }),
              }}
            />
          </div>
        </div>
        <PaymentCreateSteps />
      </form>
    </>
  );
};

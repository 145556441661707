import api from 'api';

const transactionList = async (data: any) => {
  return api.post('/transactions/list', data);
};

const merchantTransactionList = async (data: any) => {
  return api.post('/merchant-portal/transactions/list', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/transactions/get', data);
};

const fetchMerchantDetails = async (data: { id: string }) => {
  return api.post('/merchant-portal/transactions/get', data);
};

export const transactionsResource = {
  transactionList,
  merchantTransactionList,
  fetchMerchantDetails,
  fetchDetails,
};

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Link, NavLink, Route, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { IconCaretDown } from 'components/icons/IconCaretDown';
import { RectLoader } from 'components/RectLoader';
import { mappedData, userHasPermission } from './utils';
import { IconPrysymLogo } from 'components/icons/IconLogo';
import { IconLogoutClose } from 'components/icons/IconLogoutClose';
import { useSelector } from 'react-redux';
import {
  getHasNotification,
  isAdminSelector,
  isDashboardActiveSelector,
} from '../../domain/account/selectors';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch } from '../../hooks/redux';
import { setFirstMenuItem } from '../../domain/account/accountSlice';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Badge } from '@mui/material';

type MenuItem = {
  label: string;
  icon: ReactNode;
  route: string;
  subitems?: MenuSubItem[];
};

type MenuSubItem = {
  label: string;
  route: string;
};

const SidebarSubitem = ({ item }: { item: MenuSubItem }) => (
  <li className="text-black">
    <NavLink
      to={item.route}
      activeClassName="item-color-blue"
      className={classNames(
        { 'pointer-events-none': item.route === '/404' },
        'block w-full py-1 pl-6 text-xs text-left transition-colors duration-300 focus:outline-none hover:bg-gray-100'
      )}
    >
      <span className="font-normal text-sm">{item.label}</span>
    </NavLink>
  </li>
);

const SidebarItem = ({
  item,
  active,
  isOpen,
  openMenu,
}: {
  item: MenuItem;
  active: any;
  isOpen: boolean;
  openMenu: () => void;
}) => {
  const [open, setOpen] = useState(active);
  const history = useHistory();
  const hasNotifications = useSelector(getHasNotification);

  useEffect(() => setOpen(active), [active]);

  const subItemsCollapse = useSpring({
    config: { duration: 150 },
    opacity: open ? 1 : 0,
    height: open ? 'auto' : 0,
    marginTop: '5px',
    display: active && isOpen ? '' : 'none',
  });

  const toggleOpen = useCallback(() => {
    if (item.route === '/settings') {
      history.push('/settings');
    } else if (item.route === '/notifications') {
      history.push('/notifications');
    } else if (item.route === '/compliance-audit') {
      history.push('/compliance-audit');
    } else if (item.route === '/virtual-terminal') {
      history.push('/virtual-terminal');
    } else if (item.route === '/api-secret-key-management') {
      history.push('/api-secret-key-management');
    } else if (item.subitems?.length) {
      history.push(item.subitems[0].route);
    } else {
      history.push('/');
    }
    setOpen(!open);
  }, [open, history, item.subitems]);

  return (
    <li>
      <button
        onClick={toggleOpen}
        className="relative flex justify-between w-full px-5 py-3 transition-colors duration-300 hover:bg-gray-100 focus:outline-none items-center"
      >
        {active && (
          <div className="absolute left-0 w-full h-full item-background-color-blue text-black active-item" />
        )}
        <div className="flex z-60 align-bottom items-end text-white flex-wrap arial-font">
          <span
            tabIndex={0}
            role="button"
            aria-hidden="true"
            onClick={openMenu}
            className={classNames('mr-3', {
              'text-white': active,
              'text-black': !active,
            })}
          >
            {item.label === 'Notifications' && hasNotifications ? (
              <Badge variant="dot" color="error">
                <NotificationsNoneOutlinedIcon fontSize="medium" />
              </Badge>
            ) : (
              item.icon
            )}
          </span>
          {isOpen && (
            <span
              className={classNames('text-sm', {
                'text-white': active,
                'text-black': !active,
              })}
            >
              {item.label}
            </span>
          )}
        </div>
        {isOpen && item.subitems && (
          <div className="z-20">
            <IconCaretDown
              className={classNames('transition-transform duration-300', {
                'transform rotate-180': open,
                'text-white': active,
                'text-black': !active,
              })}
            />
          </div>
        )}
      </button>
      {item.subitems && (
        <animated.ul
          style={subItemsCollapse}
          className={classNames(!open && 'pointer-events-none')}
        >
          {item.subitems.map((initItem, index) => (
            <SidebarSubitem item={initItem} key={index} />
          ))}
        </animated.ul>
      )}
    </li>
  );
};

interface SidebarProps {
  profile: any;
  sidebarItems: MenuItem[];
  loading: boolean;
  isOpen: boolean;
  children: ReactNode;
  openModal: () => void;
  setShowContent: (bool: boolean) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  sidebarItems: items,
  loading,
  children,
  isOpen,
  openModal,
  setShowContent,
}) => {
  const [sidebarItems, setSidebarItems] = useState<MenuItem[]>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isDashboardActive = useSelector(isDashboardActiveSelector);
  const isAdminActive = useSelector(isAdminSelector);

  useEffect(() => {
    if (items?.length) {
      setSidebarItems(mappedData(items, isDashboardActive));
    }
  }, [isAdminActive, isDashboardActive, items]);

  useEffect(() => {
    if (sidebarItems?.length) {
      const hasPermission = userHasPermission(
        sidebarItems,
        history.location.pathname
      );

      if (!hasPermission && sidebarItems?.[0].subitems?.length) {
        history.push(sidebarItems[0].subitems[0].route);
      }

      if (sidebarItems?.[0].subitems?.length) {
        dispatch(setFirstMenuItem(sidebarItems[0].subitems[0].route));
      } else if (sidebarItems?.[0].route) {
        dispatch(setFirstMenuItem(sidebarItems[0].route));
      }

      setTimeout(() => setShowContent(true));
    }
  }, [sidebarItems, history, setShowContent]);

  const isExactPath = (item: MenuItem) => item.route === '/';

  return (
    <div
      className="fixed z-50 h-full"
      style={{
        width: isOpen ? '18.5rem' : '95px',
      }}
    >
      <div className="absolute bg-white h-5 z-60 logo-position">{children}</div>
      <div
        className="flex flex-col z-50 h-full pt-4 bg-white border-r"
        style={{
          width: isOpen ? '16.5rem' : '4rem',
          transition: 'all 0.4s ease',
        }}
      >
        <div className="flex justify-around items-center mb-1">
          <div className="flex flex-col justify-center items-center h-full">
            {isOpen ? <IconPrysymLogo /> : <IconLogoutClose />}
          </div>
        </div>
        <div className="overflow-y-auto pt-3 px-3 custom-scrollbar">
          <div className="px-2">
            <ul className="-mx-5">
              {loading || !sidebarItems ? (
                <div className="pt-px">
                  {[...Array(7)].map((item, key) => (
                    <div className="px-5" key={key}>
                      <RectLoader
                        width={250}
                        height={16}
                        className="w-full mt-4"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                sidebarItems.map((item, index) => (
                  <Route
                    key={index}
                    path={item.route}
                    exact={isExactPath(item)}
                    // eslint-disable-next-line react/no-children-prop
                    children={({ match }) => (
                      <SidebarItem
                        item={item}
                        active={match}
                        openMenu={openModal}
                        isOpen={isOpen}
                      />
                    )}
                  />
                ))
              )}
            </ul>
          </div>
          <hr className="mt-5" />
          <div className="my-5">
            {/*{isAdminActive && (*/}
            {/*  <Link to="/notifications">*/}
            {/*    <div className="flex flex-row px-2 py-2 items-end transition-colors duration-300 hover:bg-gray-100 focus:outline-none">*/}
            {/*      <div className="">*/}
            {/*        <Badge variant="dot" color="primary">*/}
            {/*          <NotificationsNoneOutlinedIcon fontSize="medium" />*/}
            {/*        </Badge>*/}
            {/*      </div>*/}
            {/*      {isOpen && (*/}
            {/*        <div className="text-base text-black px-2">*/}
            {/*          Notifications*/}
            {/*        </div>*/}
            {/*      )}*/}
            {/*    </div>*/}
            {/*  </Link>*/}
            {/*)}*/}
            <Link to="/change-password">
              <div className="flex flex-row px-2 py-2 items-end transition-colors duration-300 hover:bg-gray-100 focus:outline-none">
                <div>
                  <LockResetIcon fontSize="medium" />
                </div>
                {isOpen && (
                  <div className="text-base text-black px-2">
                    Change password
                  </div>
                )}
              </div>
            </Link>
            <Link to="/logout">
              <div className="flex flex-row px-2 py-2 items-end transition-colors duration-300 hover:bg-gray-100 focus:outline-none">
                <div>
                  <LogoutIcon fontSize="medium" />
                </div>
                {isOpen && (
                  <div className="text-sm text-black px-2">Log Out</div>
                )}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

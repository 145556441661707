import { useMemo } from 'react';
import moment from 'moment';

import { CellLight, ColumnProps } from 'components/table/Table';
import { UnmatchedTransactionItem } from '../utils';

export const useUnmatchedTransactionsColumns = () => {
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'cardNumber',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.cardNumber}</CellLight>
        ),
        label: 'Card number',
      },
      {
        id: 'transactionAmount',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.transactionAmount}</CellLight>
        ),
        label: 'Transaction Amount',
      },
      {
        id: 'transactionDate',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>
            {item.transactionDate
              ? moment.utc(item.transactionDate).format('D MMM YYYY hh:mm:ss a')
              : null}
          </CellLight>
        ),
        label: 'Transaction Date',
      },
      {
        id: 'messageType',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.messageType}</CellLight>
        ),
        label: 'Message Type',
      },
      {
        id: 'matchType',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.matchType}</CellLight>
        ),
        label: 'Match Type',
      },
      {
        id: 'merchantName',
        value: (item: UnmatchedTransactionItem) => (
          <CellLight>{item.merchantName}</CellLight>
        ),
        label: 'Merchant Name',
      },
    ],
    []
  );
};

import { Doughnut } from 'react-chartjs-2';
import { Link } from '@mui/material';
import { loadTransactionTypeInfoItems } from '../thunks';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  formatterData,
  transactionByPlugins,
  TransactionByTypeDataChart as transactionByTypeChart,
} from '../utils';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getCardInfo,
  getItemsLoadingComputed,
  getTransactionTypeLoadingComputed,
} from '../selectors';
import { RectLoader } from 'components/RectLoader';
import { PeriodType } from '../type';

export const SettlementsTypeChartComponent = () => {
  const cardInfoItem = useAppSelector(getCardInfo);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const transactionTypeLoading = useAppSelector(
    getTransactionTypeLoadingComputed
  );
  const dispatch = useAppDispatch();

  const [activePeriod, setActivePeriod] = useState<PeriodType>(
    PeriodType.LAST_WEEK
  );

  const transactionByTypes = useMemo(() => {
    if (!cardInfoItem.transactionByType.length) {
      transactionByTypeChart.map((it) => (it.numberOfTransactions = 0));
    } else {
      cardInfoItem.transactionByType.map(
        (it) =>
          (transactionByTypeChart.find(
            (item) => item.transactionType === it.transactionType
          )!.numberOfTransactions = it.numberOfTransactions)
      );
    }

    return transactionByTypeChart;
  }, [cardInfoItem.transactionByType]);

  const transactionByTypeData = {
    labels: transactionByTypes.map((it) => it.transactionType),
    datasets: [
      {
        label: '# of Votes',
        data: transactionByTypes.map((it) => it.numberOfTransactions),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(89,0,255,0.41)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgb(47,0,138)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const transactionByTypeOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: '#1976d2',
        formatter: formatterData,
      },
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        color: '#000000',
        font: {
          weight: 'normal' as const,
          size: 14,
        },
        text: 'Settlements by type',
      },
    },
  };

  const activeLinkPeriod = (period: PeriodType) =>
    activePeriod === period ? 'bold' : 'normal';

  const updateChartByPeriod = (period: PeriodType) => {
    dispatch(
      loadTransactionTypeInfoItems({
        dashboardChartPeriod: period,
      })
    );
    setActivePeriod(period);
  };

  return (
    <div className="col-span-8 xxl:col-span-4 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      {itemsLoading || transactionTypeLoading ? (
        <div className="flex items-center justify-center h-full">
          <RectLoader width={150} height={28} />
        </div>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <div className="grow justify-between justify-items-center h-full">
            <Doughnut
              data={transactionByTypeData}
              options={transactionByTypeOptions}
              plugins={[ChartDataLabels as any, transactionByPlugins]}
              height={'350px'}
              width={'100%'}
            />
          </div>
          <hr className="style-hr" />
          <div className="flex flex-wrap items-center justify-between justify-items-center mx-10 mb-1">
            <div>
              <Link
                underline={'none'}
                fontWeight={activeLinkPeriod(PeriodType.LAST_WEEK)}
                className="text-sm cursor-pointer"
                onClick={() => updateChartByPeriod(PeriodType.LAST_WEEK)}
              >
                {'Last Week'}
              </Link>
            </div>
            <div>
              <Link
                underline={'none'}
                fontWeight={activeLinkPeriod(PeriodType.LAST_MONTH)}
                className="text-sm cursor-pointer"
                onClick={() => updateChartByPeriod(PeriodType.LAST_MONTH)}
              >
                {'Last 30 days'}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

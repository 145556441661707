import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  RangeType,
  TotalSalesItem,
  TotalSalesItems,
  TotalSalesResponse,
} from './utils';

import {
  loadCardInfoItems,
  loadTotalSalesPerDayItems,
  loadTotalSalesPerMonthItems,
  loadTotalSalesRollingPerDayItems,
  loadTotalSalesRollingPerMonthItems,
} from './thunks';

export interface ComplianceAuditState {
  totalSalesItems: TotalSalesItems;
  itemsLoading: boolean;
  totalSalesPerDayLoading: boolean;
  totalSalesPerMonthLoading: boolean;
  totalSalesRollingPer7DayLoading: boolean;
  totalSalesRollingPer30DayLoading: boolean;
  totalSalesRollingPerMonthLoading: boolean;
  transactionTypeLoading: boolean;
  authorizationStatusLoading: boolean;
  authorizationTypeLoading: boolean;
}

export const storeName = 'complianceAudit';

const initialState: ComplianceAuditState = {
  totalSalesItems: TotalSalesItem,
  itemsLoading: false,
  totalSalesPerDayLoading: false,
  totalSalesPerMonthLoading: false,
  totalSalesRollingPer7DayLoading: false,
  totalSalesRollingPer30DayLoading: false,
  totalSalesRollingPerMonthLoading: false,
  transactionTypeLoading: false,
  authorizationStatusLoading: false,
  authorizationTypeLoading: false,
};

const complianceAuditSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    resetAllItems(state) {
      state.totalSalesItems = initialState.totalSalesItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadCardInfoItems.fulfilled,
      (state, action: PayloadAction<TotalSalesItems>) => {
        state.totalSalesItems = action.payload;
        state.itemsLoading = false;
      }
    );
    builder.addCase(loadCardInfoItems.pending, (state) => {
      state.itemsLoading = true;
    });
    builder.addCase(loadCardInfoItems.rejected, (state) => {
      state.itemsLoading = false;
    });
    builder.addCase(
      loadTotalSalesPerDayItems.fulfilled,
      (state, action: PayloadAction<TotalSalesResponse[]>) => {
        state.totalSalesItems.totalSalesPerDay = action.payload;
        state.totalSalesPerDayLoading = false;
      }
    );
    builder.addCase(loadTotalSalesPerDayItems.pending, (state) => {
      state.totalSalesPerDayLoading = true;
    });
    builder.addCase(loadTotalSalesPerDayItems.rejected, (state) => {
      state.totalSalesPerDayLoading = false;
    });
    builder.addCase(
      loadTotalSalesPerMonthItems.fulfilled,
      (state, action: PayloadAction<TotalSalesResponse[]>) => {
        state.totalSalesItems.totalSalesPerMonth = action.payload;
        state.totalSalesPerMonthLoading = false;
      }
    );
    builder.addCase(loadTotalSalesPerMonthItems.pending, (state) => {
      state.totalSalesPerMonthLoading = true;
    });
    builder.addCase(loadTotalSalesPerMonthItems.rejected, (state) => {
      state.totalSalesPerMonthLoading = false;
    });
    builder.addCase(
      loadTotalSalesRollingPerDayItems.fulfilled,
      (state, action) => {
        const rangeType = action.meta.arg.rangeType;
        if (rangeType === RangeType.SEVEN_DAYS) {
          state.totalSalesItems.totalSalesRolling7Days = action.payload;
          state.totalSalesRollingPer7DayLoading = false;
        }

        if (rangeType === RangeType.THIRTY_DAYS) {
          state.totalSalesItems.totalSalesRolling30Days = action.payload;
          state.totalSalesRollingPer30DayLoading = false;
        }
      }
    );
    builder.addCase(
      loadTotalSalesRollingPerDayItems.pending,
      (state, action) => {
        const rangeType = action.meta.arg.rangeType;
        if (rangeType === RangeType.SEVEN_DAYS) {
          state.totalSalesRollingPer7DayLoading = true;
        }

        if (rangeType === RangeType.THIRTY_DAYS) {
          state.totalSalesRollingPer30DayLoading = true;
        }
      }
    );
    builder.addCase(
      loadTotalSalesRollingPerDayItems.rejected,
      (state, action) => {
        const rangeType = action.meta.arg.rangeType;
        if (rangeType === RangeType.SEVEN_DAYS) {
          state.totalSalesRollingPer7DayLoading = false;
        }

        if (rangeType === RangeType.THIRTY_DAYS) {
          state.totalSalesRollingPer30DayLoading = false;
        }
      }
    );
    builder.addCase(
      loadTotalSalesRollingPerMonthItems.fulfilled,
      (state, action) => {
        state.totalSalesItems.totalSalesRolling12Months = action.payload;
        state.totalSalesRollingPerMonthLoading = false;
      }
    );
    builder.addCase(loadTotalSalesRollingPerMonthItems.pending, (state) => {
      state.totalSalesRollingPerMonthLoading = true;
    });
    builder.addCase(loadTotalSalesRollingPerMonthItems.rejected, (state) => {
      state.totalSalesRollingPerMonthLoading = false;
    });
  },
});

export const { resetAllItems } = complianceAuditSlice.actions;
export default complianceAuditSlice.reducer;

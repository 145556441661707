import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { Notifications } from './context';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import {
  NotificationColsDetails,
  NotificationSeverity,
} from '../components/utilComponent';
import { NotificationType } from 'domain/dashboard/utils';
import classNames from 'classnames';
import { useAppDispatch } from 'hooks/redux';
import { setCurrentNotificationId } from '../notificationSlice';
import { ActionButton } from '../components/ActionButton';

export const useColumns = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'severity',
        value: (item: Notifications) => (
          <CellLight>{NotificationSeverity[item.severity].icon}</CellLight>
        ),
        label: 'Severity',
        width: 80,
        isSorted: true,
        sortField: 'NOTIFICATIONS_SEVERITY',
      },
      {
        id: 'type',
        value: (item: Notifications) => (
          <CellLight>
            <span className={NotificationColsDetails[item.type].color}>
              {NotificationColsDetails[item.type].icon}{' '}
              <span className="ml-1">{item.type}</span>
            </span>
          </CellLight>
        ),
        label: 'Type',
        width: 120,
        isSorted: true,
        sortField: 'NOTIFICATIONS_TYPE',
      },
      {
        id: 'group',
        value: (item: Notifications) => <CellLight>{item.category}</CellLight>,
        label: 'Group',
        width: 150,
        isSorted: true,
        sortField: 'NOTIFICATIONS_CATEGORY',
      },
      {
        id: 'message',
        value: (item: Notifications) => <CellLight>{item.content}</CellLight>,
        label: 'Message',
        width: 300,
        isSorted: false,
        sortField: 'NOTIFICATIONS_CONTENT',
      },
      {
        id: 'status',
        value: (item: Notifications) => (
          <CellLight>
            <span
              className={classNames(
                'inline-flex items-center rounded-md px-2 py-1 border-2 text-xs font-medium ring-1 ring-inset ring-yellow-600/20',
                item.status === 'ACTIVE'
                  ? 'border-orange-50 text-yellow-800'
                  : 'border-green-100 text-green-800'
              )}
            >
              {item.status}
            </span>
          </CellLight>
        ),
        label: 'Status',
        width: 100,
        isSorted: true,
        sortField: 'NOTIFICATIONS_STATUS',
      },
      {
        id: 'created_on',
        value: (item: Notifications) => (
          <CellLight>{timeFormat(item.createdOn)}</CellLight>
        ),
        label: 'Date/time',
        width: 200,
        isSorted: true,
        sortField: 'NOTIFICATIONS_CREATED_AT',
      },
      {
        id: 'action',
        value: (item: Notifications) => (
          <CellLight>
            <ActionButton item={item} />
            {item.status === 'ACTIVE' && (
              <span
                role="button"
                aria-hidden="true"
                onClick={() => dispatch(setCurrentNotificationId(item.id))}
                className={classNames(
                  'ml-2 hover:bg-green-200',
                  NotificationColsDetails[NotificationType.SUCCESS].color
                )}
              >
                <CheckBoxOutlinedIcon />
                <span className="ml-1">Resolve</span>
              </span>
            )}
          </CellLight>
        ),
        label: 'Action',
        width: 230,
        isSorted: false,
      },
    ],
    [t]
  );
};

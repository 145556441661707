import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/redux';
import { useHistory, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';
import { useEffect } from 'react';
import { fetchDetails } from '../thunks';
import { PageHeader } from 'components/PageHeader';
import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getDetails, getLoadingDetails, getNotFound } from '../selectors';
import { RectLoader } from 'components/RectLoader';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ItemView } from 'components/grid/ItemView';

export const ChargebacksDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const chargebackId = Base64.decode(id);

  const item = useSelector(getDetails);
  const notFound = useSelector(getNotFound);
  const isDetailLoading = useSelector(getLoadingDetails);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const dateFormatUtil = (value: string) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);

  useEffect(() => {
    dispatch(fetchDetails({ id: chargebackId }));
  }, [dispatch, id, chargebackId]);

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader
          title={t('disputeResolution.chargebacks.breadcrumbDetails')}
        />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          {/*back bar*/}
          <div className="flex flex-wrap flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <div>
              <Button
                onClick={() => history.goBack()}
                startIcon={<KeyboardBackspaceIcon />}
              >
                Back
              </Button>
            </div>
          </div>
          {notFound && (
            <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8">
                  <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-shadow-nero box-bg-nero-300">
                    <div>
                      <MoneyOffCsredIcon
                        fontSize="large"
                        sx={{ color: '#fff' }}
                      />
                    </div>
                    <div className="text-xs text-white">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start my-4 sm:mx-4 sm:p-3">
                  <div>No details found</div>
                </div>
              </div>
            </div>
          )}
          {isDetailLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}
          {!isDetailLoading && !notFound && (
            <>
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8">
                    <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-mantis-300 box-mantis-green">
                      <div className="border rounded-lg">
                        <AttachMoneyIcon
                          fontSize="large"
                          sx={{ color: '#fff' }}
                        />
                      </div>
                      <div className="text-xs text-white ml-2">Details</div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                    <ItemView
                      labelName="Acquirer Reference Number"
                      value={item.acquirerReferenceNumber}
                    />
                    <ItemView
                      labelName="Adjustment Amount"
                      value={item.adjustmentAmount}
                    />
                    <ItemView
                      labelName="Adjustment Date"
                      value={dateFormatUtil(item.adjustmentDate)}
                    />
                    <ItemView
                      labelName="Airline Ticket Number"
                      value={item.airlineTicketNumber}
                    />
                    <ItemView
                      labelName="Authorization Code"
                      value={item.authorizationCode}
                    />
                    <ItemView
                      labelName="Batch Date"
                      value={dateFormatUtil(item.batchDate)}
                    />
                    <ItemView labelName="Card Number" value={item.cardNumber} />
                    <ItemView labelName="Card Type" value={item.cardType} />
                    <ItemView
                      labelName="Cb Response Received"
                      value={item.cbResponseReceived}
                    />
                    <ItemView
                      labelName="Chargeback Currency Code"
                      value={item.chargebackCurrencyCode}
                    />
                    <ItemView
                      labelName="Chargeback Cycle"
                      value={item.chargebackCycle}
                    />
                    <ItemView
                      labelName="Current Status"
                      value={item.currentStatus}
                    />
                    <ItemView
                      labelName="Custom Tracking"
                      value={item.customTracking}
                    />
                    <ItemView
                      labelName="Debit Network Description"
                      value={item.debitNetworkDescription}
                    />
                    <ItemView
                      labelName="Debit Network Id"
                      value={item.debitNetworkId}
                    />
                    <ItemView
                      labelName="Dispute Amount"
                      value={item.disputeAmount}
                    />
                    <ItemView
                      labelName="Dispute Type"
                      value={item.disputeType}
                    />
                    <ItemView
                      labelName="Due Date"
                      value={dateFormatUtil(item.dueDate)}
                    />
                    <ItemView
                      labelName="Exp Date"
                      value={dateFormatUtil(item.expDate)}
                    />
                    <ItemView labelName="External Id" value={item.externalId} />
                    <ItemView labelName="Filler" value={item.filler} />
                    <ItemView
                      labelName="First Chargeback Date"
                      value={dateFormatUtil(item.firstChargebackDate)}
                    />
                    <ItemView
                      labelName="Hierarchy Id"
                      value={item.hierarchyId}
                    />
                    <ItemView
                      labelName="Hierarchy Level No"
                      value={item.hierarchyLevelNo}
                    />
                    <ItemView
                      labelName="Ids Case Number"
                      value={item.idsCaseNumber}
                    />
                    <ItemView
                      labelName="Invoice Number"
                      value={item.invoiceNumber}
                    />
                    <ItemView labelName="Loan Number" value={item.loanNumber} />
                    <ItemView
                      labelName="Location DBA Name"
                      value={item.locationDBAName}
                    />
                    <ItemView labelName="Location Id" value={item.locationId} />
                    <ItemView
                      labelName="Mobile Indicator"
                      value={item.mobileIndicator}
                    />
                    <ItemView
                      labelName="Processed Transaction Amount"
                      value={item.processedTransactionAmount}
                    />
                    <ItemView
                      labelName="Processed Transaction Currency Code"
                      value={item.processedTransactionCurrencyCode}
                    />
                    <ItemView labelName="Reason Code" value={item.reasonCode} />
                    <ItemView
                      labelName="Reason Code Description"
                      value={item.reasonCodeDescription}
                    />
                    <ItemView
                      labelName="Receive Date"
                      value={dateFormatUtil(item.receiveDate)}
                    />
                    <ItemView labelName="Record Type" value={item.recordType} />
                    <ItemView
                      labelName="Request Date"
                      value={dateFormatUtil(item.requestDate)}
                    />
                    <ItemView
                      labelName="Request Type"
                      value={item.requestType}
                    />
                    <ItemView
                      labelName="Responded On Date"
                      value={dateFormatUtil(item.respondedOnDate)}
                    />
                    <ItemView
                      labelName="Second Chargeback Date"
                      value={dateFormatUtil(item.secondChargebackDate)}
                    />
                    <ItemView labelName="Sic Code" value={item.sicCode} />
                    <ItemView
                      labelName="Soft Descriptor"
                      value={item.softDescriptor}
                    />
                    <ItemView
                      labelName="Special Reference 1"
                      value={item.specialReference1}
                    />
                    <ItemView
                      labelName="Special Reference 2"
                      value={item.specialReference2}
                    />
                    <ItemView
                      labelName="Status Date"
                      value={dateFormatUtil(item.statusDate)}
                    />
                    <ItemView
                      labelName="Status Description"
                      value={item.statusDescription}
                    />
                    <ItemView
                      labelName="Store Number"
                      value={item.storeNumber}
                    />
                    <ItemView
                      labelName="Sub Merchant Id"
                      value={item.subMerchantId}
                    />
                    <ItemView
                      labelName="Submitted Transaction Amount"
                      value={item.submittedTransactionAmount}
                    />
                    <ItemView
                      labelName="Submitted Transaction Currency Code"
                      value={item.submittedTransactionCurrencyCode}
                    />
                    <ItemView
                      labelName="Transaction Date"
                      value={dateFormatUtil(item.transactionDate)}
                    />
                    <ItemView
                      labelName="Transaction POS Entry Mode"
                      value={item.transactionPOSEntryMode}
                    />
                    <ItemView
                      labelName="Transaction Service Code"
                      value={item.transactionServiceCode}
                    />
                    <ItemView
                      labelName="User Data 2"
                      value={dateFormatUtil(item.userData2)}
                    />
                    <ItemView
                      labelName="Work Order Number"
                      value={item.workOrderNumber}
                    />
                    <ItemView labelName="Work Type" value={item.workType} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

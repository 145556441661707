export const SuccessVerify = () => (
  <img
    style={{
      width: '25px',
      height: '25px',
    }}
    src={require('../img/verified.png')}
    alt={''}
  />
);

import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import translationEN from './locales/en/translation.json';

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
});

export const i18n = i18next;

export const t = (key: string, alternative = '' as string) => {
  return i18n.exists(key) ? i18n.t(key) : alternative;
};

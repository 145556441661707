import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { HistoryBackButton } from 'components/buttons/HistoryBackButton';
import { CancelButton } from 'components/buttons/CancelButton';
import { ItemAttributes } from 'components/itemAttributeType';
import { Input, SelectController } from 'components/inputs';
import { SaveButton } from 'components/buttons/SaveButton';
import { PageHeader } from 'components/PageHeader';
import { useAppDispatch } from 'hooks/redux';
import { merchantList } from 'api/merchants';
import { Card } from 'components/Card';
import { roleList } from 'api/roles';

import { useSchema } from '../details/utils';
import { MERCHANT_ROLE } from '../utils';
import { createUser } from '../thunks';

export const UserCreate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isMerchant, setIsMerchant] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<any>({
    resolver: yupResolver(useSchema()),
    defaultValues: {},
  });

  const handleBack = () => {
    history.push('user-list');
  };

  const onSubmit = async (data: any) => {
    const result = await dispatch(
      createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        roleId: data.role.value,
        merchants:
          data.merchants?.map((merchant: any) => merchant.value) || undefined,
      })
    );

    if (!result.error) {
      handleBack();
    }
  };

  const handleRoleChange = (role: ItemAttributes | null) => {
    if (role?.label === MERCHANT_ROLE) {
      setIsMerchant(true);
    } else {
      setIsMerchant(false);
      setValue('merchants', []);
    }
  };

  return (
    <div className="bg-gray-200">
      <PageHeader title={t('userManagement.createUser')} />

      <div className="pt-3 px-4 sm:px-7 pb-6 grid grid-rows gap-4">
        <Card>
          <HistoryBackButton />
        </Card>

        <Card
          title={t('userManagement.detailsUser')}
          icon={<ManageAccountsIcon fontSize="large" sx={{ color: '#fff' }} />}
        >
          <form
            id="create-user-form"
            onSubmit={handleSubmit(onSubmit)}
            className="w-full mt-5 grid grid-cols-3 gap-4 my-4 sm:m-4 sm:p-3"
          >
            <Input
              label={
                <RequiredFieldComponent
                  labelText={t('userManagement.userDetails.firstName')}
                />
              }
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-gray-700"
              error={errors.firstName}
              inputProps={register('firstName', {
                required: true,
              })}
            />
            <Input
              label={
                <RequiredFieldComponent
                  labelText={t('userManagement.userDetails.lastName')}
                />
              }
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-gray-700"
              error={errors.lastName}
              inputProps={register('lastName', { required: true })}
            />
            <Input
              label={
                <RequiredFieldComponent
                  labelText={t('userManagement.userDetails.email')}
                />
              }
              labelFontClassName="font-medium tracking-wide text-xs"
              labelColorClassName="text-gray-700"
              error={errors.email}
              inputProps={register('email', { required: true })}
            />
            <SelectController
              label={
                <RequiredFieldComponent
                  labelText={t('userManagement.userDetails.role')}
                  className="font-medium tracking-wide text-xs text-gray-700"
                />
              }
              name="role"
              control={control}
              loadOptions={roleList}
              closeMenuOnSelect
              onSelect={handleRoleChange}
              error={errors.role}
            />
            {isMerchant && (
              <SelectController
                label={
                  <RequiredFieldComponent
                    labelText={t('userManagement.userDetails.merchant')}
                    className="font-medium tracking-wide text-xs text-gray-700"
                  />
                }
                placeholder={t('common.all')}
                name="merchants"
                control={control}
                loadOptions={merchantList}
                isMulti={true}
              />
            )}
          </form>
        </Card>

        <Card className="flex items-center justify-center">
          <div className="grid grid-cols-2 gap-4">
            <SaveButton
              form="create-user-form"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
            <CancelButton onClick={handleBack} />
          </div>
        </Card>
      </div>
    </div>
  );
};

import { MouseEvent, ReactNode, useState } from 'react';
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout, Settings } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fullNameSelector } from '../domain/account/selectors';

export const PageHeader = ({ title }: { title: string; right?: ReactNode }) => {
  const fullName = useSelector(fullNameSelector);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    history.push('/settings');
    setAnchorEl(null);
  };

  const handleLogout = () => {
    history.push('/logout');
    setAnchorEl(null);
  };

  const stringToColor = () => {
    let hash = 0;
    let i: number;

    /* eslint-disable no-bitwise */
    for (i = 0; i < fullName.length; i += 1) {
      hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = () => ({
    sx: {
      bgcolor: stringToColor(),
    },
    children: `${fullName.split(' ')[0][0]}${fullName.split(' ')[1][0]}`,
  });

  return (
    <div className="flex items-center justify-between w-full h-16 bg-white border-b">
      <div className="pl-8 pr-2 sm:pr-8">
        <p className="mb-0 sm:text-lg font-bold leading-none text-black">
          {title}
        </p>
      </div>
      <div className="pr-16 pl-2 sm:pl-4 z-30">
        <Avatar
          id="basic-button"
          variant="rounded"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className="box-shadow-grey z-30 cursor-pointer"
          {...stringAvatar()}
        />
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleSettings}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';

import { RectLoader } from 'components/RectLoader';
import { IconEmpty } from 'components/icons/IconEmpty';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { ColumnComponent } from 'components/table/ColumnComponent';
import { ItemSort } from '../../../../entities';
import Collapse from '@mui/material/Collapse';
import { Notifications } from '../list/context';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import Modal from '@mui/joy/Modal';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ModalClose } from '@mui/joy';
import { TextArea } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  getCurrentNotificationIdComputed,
  getSolveLoadingComputed,
} from '../selectors';
import { useAppDispatch } from 'hooks/redux';
import { resetCurrentNotificationId } from '../notificationSlice';
import { resolveNotification } from '../thunks';
import { useHistory } from 'react-router-dom';
import { NotificationCategoryEnum } from '../utils';

export type ColumnProps = {
  value: (item: any) => ReactNode;
  headerContent?: () => ReactNode;
  columnFilter?: (item: any) => ReactNode;
  isSorted?: boolean;
  sortField?: string;
  label?: string;
  id: string;
  minWidth?: number;
  width?: number;
  tooltip?: string;
};

const NotificationValue = ({
  fieldName,
  notificationValue,
  className,
}: {
  fieldName: string;
  notificationValue?: any;
  className?: any;
}) => {
  return (
    <>
      <div className={classNames('flex flex-col w-full', className)}>
        <div className="text-xs font-normal text-gray-700">{fieldName}</div>
        {notificationValue ? (
          <div className="text-base font-normal">{notificationValue}</div>
        ) : (
          <div className="text-base font-normal">No Information</div>
        )}
      </div>
    </>
  );
};

const NotificationCheck = ({ item }: { item: Notifications }) => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: any) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);
  const calcTime = (value1: any, value2: any) => {
    return timeFormat(value1) + ' - ' + timeFormat(value2);
  };
  return (
    <>
      {item &&
        item.category === NotificationCategoryEnum.MERCHANT_VOLUME_LIMITS && (
          <>
            <NotificationValue
              fieldName="Merchant Name"
              notificationValue={item.details.merchantName}
            />
            <NotificationValue
              fieldName="Limit Range"
              notificationValue={item.details.limitRange}
            />
            <NotificationValue
              fieldName="Limit Volume"
              notificationValue={item.details.limitVolume}
            />
            <NotificationValue
              fieldName="Calculated Period"
              className="col-span-2"
              notificationValue={calcTime(
                item.details?.calculationPeriod?.from,
                item.details?.calculationPeriod?.to
              )}
            />
            <NotificationValue
              fieldName="Calculated Amount"
              notificationValue={item.details.calculatedAmount}
            />
            <NotificationValue
              fieldName="Transaction Type"
              notificationValue={item.details.transactionType}
            />
            <NotificationValue
              fieldName="Action"
              notificationValue={item.details.action}
            />
            <NotificationValue
              fieldName="Comments"
              className="col-span-2"
              notificationValue={item.details.comments}
            />
          </>
        )}
      {(item?.category === NotificationCategoryEnum.DFM_FILE_MISSING ||
        item?.category === NotificationCategoryEnum.SERVICE_MONITORING) && (
        <>
          <NotificationValue
            fieldName="Comments"
            notificationValue={item.details.comments}
          />
        </>
      )}
    </>
  );
};

export const CellBase = ({ children }: { children: ReactNode }) => (
  <span className="block overflow-x-auto text-xs font-medium leading-normal text-gray-900 break-words whitespace-pre-line">
    {children}
  </span>
);
export const CellLight = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={classNames('text-gray-700', className)}>{children}</div>;

export const Table = ({
  items,
  itemExpand,
  itemExpandContent,
  columns,
  loading = false,
  onRowClick,
  setSort,
  onHover = true,
}: {
  items: Notifications[];
  itemExpand?: any;
  itemExpandContent?: ReactNode;
  columns: ColumnProps[];
  loading?: boolean;
  onRowClick?: (row: any) => any;
  setSort?: (sort: ItemSort) => void;
  currentSort?: ItemSort | null;
  onHover?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const solveLoading = useSelector(getSolveLoadingComputed);
  const currentNotificationId = useSelector(getCurrentNotificationIdComputed);

  const [open, setOpen] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      comment: '',
    },
  });

  useEffect(() => {
    if (currentNotificationId) {
      setModalOpen(true);
    }
  }, [currentNotificationId]);

  const rowClickHandle = useCallback(
    (index: any) => {
      setOpen(open === index ? -1 : index);
    },
    [open]
  );

  useEffect(() => {
    if (history.location.state?.notifyId) {
      setOpen(history.location.state.notifyId);
    }
  }, [history.location.state]);

  const handleClickClose = () => {
    dispatch(resetCurrentNotificationId());
    setModalOpen(false);
    setValue('comment', '');
  };

  const save = (data: any) => {
    dispatch(
      resolveNotification({
        id: currentNotificationId,
        comment: data.comment,
      })
    )
      .unwrap()
      .then(() => {
        location.reload();
      });
  };
  const { comment } = watch();

  const isSaveDisabled = useMemo(() => {
    return comment.trim().length === 0;
  }, [comment]);

  return (
    <>
      <table className="relative w-full table-auto">
        <thead className="w-full z-0">
          <tr className="text-left">
            {columns.map((item, index) => (
              <ColumnComponent key={index} item={item} setSort={setSort} />
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading && !items.length && (
            <tr>
              <td colSpan={20} className="h-64">
                <div className="flex flex-col items-center justify-center w-full h-full pt-20">
                  <div className="mb-5 text-center">
                    <IconEmpty />
                  </div>
                  <span className="flex flex-col items-center text-xs font-medium text-gray-700">
                    <div>{t('kyc.table.noResults')}</div>
                    <div>{t('kyc.table.tryAgain')}</div>
                  </span>
                </div>
              </td>
            </tr>
          )}
          {(loading ? [...Array(10)] : items).map((item, index) => (
            <Fragment key={index}>
              <tr
                className={classNames('transition-colors duration-300', {
                  'hover:bg-gray-100': onHover,
                  'bg-gray-100': isEqual(item, itemExpand),
                  'cursor-pointer': onRowClick,
                })}
                onClick={() => rowClickHandle(item.id)}
              >
                {columns.map((column, columnIndex) => (
                  <td
                    key={`${index}-${columnIndex}`}
                    className={classNames('p-0 relative', {
                      'border-b': !isEqual(index, open),
                    })}
                  >
                    <div
                      className="py-4 pl-4"
                      style={{
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {isEqual(item, itemExpand) &&
                        columnIndex === 0 &&
                        !loading && (
                          <div className="absolute top-0 left-0 w-1 h-full bg-blue-500" />
                        )}

                      {loading ? (
                        <RectLoader
                          className={'w-full'}
                          width={100}
                          height={20}
                        />
                      ) : (
                        <CellBase>{column.value(item)}</CellBase>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td
                  colSpan={columns.length}
                  className={classNames('p-0 relative', {
                    'border-b': isEqual(item?.id ? item.id : -2, open),
                  })}
                >
                  <Collapse in={open === (item?.id ? item.id : -2)}>
                    <div className="grid w-full bg-gray-100 pt-3">
                      <div className="grid grid-cols-7 justify-stretch items-center gap-4 w-full ml-5 mb-3">
                        <NotificationCheck item={item} />
                      </div>
                    </div>
                  </Collapse>
                </td>
              </tr>

              {isEqual(item, itemExpand) && (
                <tr className="bg-gray-100">
                  <td
                    colSpan={columns.length}
                    className="relative p-0 border-b"
                  >
                    <div className="absolute top-0 left-0 w-1 h-full bg-blue-500" />
                    <div className="border-l-4 border-blue-500">
                      {itemExpandContent}
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalOpen}
        onClose={() => handleClickClose()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <ModalClose onClick={() => handleClickClose()} />
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <form onSubmit={handleSubmit(save)}>
            <DialogContent>
              <div className="w-[15rem] m2d:w-[30rem] xxl:w-[35rem]">
                <TextArea
                  label={
                    <RequiredFieldComponent labelText="Please provide the resolution before marking it as Resolved." />
                  }
                  className="h-23"
                  error={errors.comment}
                  labelFontClassName="font-medium tracking-wide text-xs"
                  inputProps={register('comment', {
                    required: true,
                  })}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                type="submit"
                disabled={isSaveDisabled}
                color="primary"
                variant="contained"
                onClick={() => isSubmitting}
                loading={solveLoading}
              >
                Save
              </LoadingButton>
              <Button color="primary" onClick={() => handleClickClose()}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default Table;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RowsPerPage } from 'entities/common';
import { defaultFilters, Filters, mappedData } from './utils';
import { loadItems } from './thunks';
import {
  ItemSort,
  UnmatchedTransactionItem,
} from 'entities/transaction-management';

export const storeName = 'tmUnmatchedTransaction';

export interface TransactionSliceProps {
  items: UnmatchedTransactionItem[];
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  filters: Filters;
  sort: ItemSort | null;
  appliedFilters: Filters;
}

const initialState: TransactionSliceProps = {
  items: [] as UnmatchedTransactionItem[],
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
};

const unmatchedTransactionSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
      state.totalNumber = initialState.totalNumber;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.sort = initialState.sort;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: UnmatchedTransactionItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.totalNumber = action.payload.paging.totalCount;
        state.items = mappedData(data);
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setSort,
  setApplyFilters,
  resetAllFilters,
  setFilters,
} = unmatchedTransactionSlice.actions;

export default unmatchedTransactionSlice.reducer;

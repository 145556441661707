import classNames from 'classnames';
import { useAppSelector } from 'hooks/redux';
import { getMerchantCreateStepStatus } from '../selectors';

export const StepWizardComponent = () => {
  const merchantCreateStepNumber = useAppSelector(getMerchantCreateStepStatus);

  return (
    <ul className="step-wizard-list w-full">
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item ',
          {
            'current-item': merchantCreateStepNumber === 1,
          }
        )}
      >
        <span className="progress-count">1</span>
        <span className="progress-label">Merchant Info</span>
      </li>
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item',
          {
            'current-item': merchantCreateStepNumber === 2,
          }
        )}
      >
        <span className="progress-count">2</span>
        <span className="progress-label">Merchant Method</span>
      </li>
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item',
          {
            'current-item': merchantCreateStepNumber === 3,
          }
        )}
      >
        <span className="progress-count">3</span>
        <span className="progress-label">Account Holder</span>
      </li>
      <li
        className={classNames(
          'step-wizard-item xl:step-wizard-item xxl:step-wizard-item xxxl:step-wizard-item',
          {
            'current-item': merchantCreateStepNumber === 4,
          }
        )}
      >
        <span className="progress-count">4</span>
        <span className="progress-label">Summary</span>
      </li>
    </ul>
  );
};

import api from 'api';

export type Role = {
  id: string;
  name: string;
};

export const list = (options: any) => {
  return api.post('/dropdown/roles', options);
};

export const roleList = async (options = {} as any) => {
  try {
    const { data } = await list(options);

    return data;
  } catch (e) {
    return [];
  }
};

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UnmatchedTransactions } from './unmatched-transactions/UnmatchedTransactions';
import { AuthorizationManagement } from './AuthorizationManagement';
import { TransactionManagementRout } from './TransactionManagementRout';

export const TransactionManagement = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/authorizations`}>
        <AuthorizationManagement />
      </Route>
      <Route path={`${match.path}/settlements`}>
        <TransactionManagementRout />
      </Route>
      <Route path={`${match.path}/unmatched-transactions`}>
        <UnmatchedTransactions />
      </Route>
    </Switch>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import { storeName } from './unmatchedTransactionSlice';

export const getRoot = (state: RootState) => state[storeName];

export const getUnmatchedTransactionState = createSelector(
  [getRoot],
  (state) => state
);

export const getItems = createSelector([getRoot], ({ items }) => items);
export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);
export const getFilters = createSelector([getRoot], ({ filters }) => filters);
export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { userStatusItems } from 'domain/user-management/users/utils';
import { ItemAttributes } from 'components/itemAttributeType';
import { UserStatus } from 'domain/user-management/status';
import { RectLoader } from 'components/RectLoader';
import { useAppSelector } from 'hooks/redux';
import {
  MerchantStatuses,
  merchantStatusItems,
} from 'domain/system/merchants/utils';

import { getCardInfo, getItemsLoadingComputed } from '../selectors';

export const InfoCard = () => {
  const history = useHistory();

  const cardInfoItem = useAppSelector(getCardInfo);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const cardItems = [
    {
      icon: <StorefrontIcon fontSize="large" sx={{ color: '#fff' }} />,
      cardMessage: 'Active Merchants',
      value: cardInfoItem.merchantNumber,
      colorClass: 'box-bg-roti-400 box-shadow-dark',
      onClick: () => {
        history.push('/system/merchants', {
          status: merchantStatusItems.filter(
            (status: ItemAttributes) => status.value === MerchantStatuses.ACTIVE
          ),
        });
      },
    },
    {
      icon: <AccountBoxOutlinedIcon fontSize="large" sx={{ color: '#fff' }} />,
      cardMessage: 'Active Users',
      value: cardInfoItem.usersNumber,
      colorClass: 'box-bg-green-300 box-shadow-dark',
      onClick: () => {
        history.push('/user-management/user-list', {
          status: userStatusItems.filter(
            (status: ItemAttributes) => status.value === UserStatus.ACTIVE
          ),
        });
      },
    },
    {
      icon: (
        <AccountBalanceWalletIcon fontSize="large" sx={{ color: '#fff' }} />
      ),
      cardMessage: 'Monthly Authorizations Number',
      value: cardInfoItem.monthlyAuthorizationNumber,
      colorClass: 'box-bg-hippie-blue-300 box-shadow-dark',
      onClick: () => {
        history.push('/transaction-management/authorizations');
      },
    },
    {
      icon: <ReceiptLongIcon fontSize="large" sx={{ color: '#fff' }} />,
      cardMessage: 'Monthly Settlements number',
      value: cardInfoItem.monthlySettlementNumber,
      colorClass: 'box-bg-california-300 box-shadow-dark',
      onClick: () => {
        history.push('/transaction-management/settlements');
      },
    },
  ];

  return (
    <>
      {cardItems.map((cardItem) => (
        <div
          aria-hidden="true"
          key={cardItem.cardMessage}
          className="flex col-span-8 sm:col-span-4 xxl:col-span-3 items-center justify-between justify-items-center h-24 bg-white border rounded-md box-shadow-dark cursor-pointer"
          onClick={cardItem.onClick}
        >
          <div
            className={classNames(
              'flex items-center justify-center justify-items-center w-16 h-16 rounded-md ml-3',
              cardItem.colorClass
            )}
          >
            {cardItem.icon}
          </div>
          <div className="flex flex-col mr-2 items-end">
            <div className="text-sm text-black">{cardItem.cardMessage}</div>
            <div className="text-2xl text-black">
              {itemsLoading ? (
                <RectLoader width={150} height={18} />
              ) : (
                cardItem.value
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

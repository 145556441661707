import { createAsyncThunk } from '@reduxjs/toolkit';

import { listRequestPayload, mappedAuthDetails } from './utils';
import { transactionsResource } from './api';
import { RootState } from 'store/store';

import { getTransactionState } from './selectors';
import { dateTimeZone, isMerchantSelector } from '../../account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';

export const loadItems: any = createAsyncThunk(
  'tmTransaction/loadItems',
  async (_: void, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { appliedFilters, page, rowsPerPage, sort } = getTransactionState(
        getState() as RootState
      );

      const isMerchantRole = isMerchantSelector(getState() as RootState);
      const dateZone = dateTimeZone(getState() as RootState);
      const dateFormatVal = (value: any) =>
        dateTimeFormatUtil(value, 'YYYY-MM-DD', dateZone);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...listRequestPayload(appliedFilters, dateFormatVal),
      };

      return !isMerchantRole
        ? await transactionsResource.transactionList(params)
        : await transactionsResource.merchantTransactionList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchTransactionDetails: any = createAsyncThunk(
  'tmTransaction/fetchTransactionDetails',
  async (data: { id: string }, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootState);

      const response = !isMerchantRole
        ? await transactionsResource.fetchDetails(data)
        : await transactionsResource.fetchMerchantDetails(data);
      return mappedAuthDetails(response);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

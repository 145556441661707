import api from 'api';

const getRecordInfoItem = async (data: { merchantId: string }) => {
  return api.post('/compliance-audit/total-sales-summary', data);
};

const getTotalSalesPerDayItem = async (data: {
  merchantId: string;
  dateRange: any;
}) => {
  return api.post('/compliance-audit/total-sales-per-day', data);
};

const getTotalSalesPerMonthItem = async (data: {
  merchantId: string;
  dateRange: any;
}) => {
  return api.post('/compliance-audit/total-sales-per-month', data);
};

const getTotalSalesRollingPerDayItem = async (data: {
  merchantId: string;
  date: any;
}) => {
  return api.post('/compliance-audit/total-sales-rolling-per-day', data);
};

const getTotalSalesRollingPerMonthItem = async (data: {
  merchantId: string;
  date: any;
}) => {
  return api.post('/compliance-audit/total-sales-rolling-per-month', data);
};

export const complianceAuditResource = {
  getRecordInfoItem,
  getTotalSalesPerDayItem,
  getTotalSalesPerMonthItem,
  getTotalSalesRollingPerDayItem,
  getTotalSalesRollingPerMonthItem,
};

import { createSelector } from '@reduxjs/toolkit';
import { getSidebar } from 'domain/account/selectors';

import { RootState } from 'store/store';

import { storeName } from './rolesSlice';
import { mappedUiTabs } from './utils';

export const getRoot = (state: RootState) => state[storeName];

export const getMainState = createSelector([getRoot], (state) => state);

export const getItems = createSelector([getRoot], ({ items }) => items);
export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getDetails = createSelector(
  [getRoot, getSidebar],
  ({ details }) => details
);

export const getRoleSidebar = createSelector([getSidebar], ({ sidebar }) =>
  mappedUiTabs(sidebar)
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);
export const getisDetailsLoading = createSelector(
  [getRoot],
  ({ isDetailsLoading }) => isDetailsLoading
);

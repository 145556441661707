import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addFilterToState, removeFilterFromState } from 'utils/filters';
import { Role, RoleDetails } from 'entities/user-management';
import { RowsPerPage } from 'entities/common';

import { createRole, fetchDetails, loadItems, updateRole } from './thunks';
import { defaultFilters, details, Filters, getItemFilters } from './utils';
import { ItemSort } from '../../../entities';

export interface MerchantsState {
  items: Role[];
  itemsLoading: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  details: RoleDetails;
  isDetailsLoading: boolean;
  itemNotFound: boolean;
  filters: Filters;
  sort: ItemSort | null;
  appliedFilters: Filters;
}

export const storeName = 'umRolesList';

const initialState: MerchantsState = {
  items: [] as Role[],
  itemsLoading: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  itemNotFound: false,
  details: details,
  isDetailsLoading: false,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
};

const merchantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.page = 1;
      state.items = [];
    },
    setApplyFilters(state) {
      state.appliedFilters = Object.assign({}, state.filters);
    },
    clearDetails(state) {
      state.details = initialState.details;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.totalNumber = initialState.totalNumber;
      state.page = initialState.page;
      state.appliedFilters = initialState.appliedFilters;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
    addPermision(state, action) {
      state.details.permissions = [
        ...state.details.permissions,
        action.payload,
      ];
    },
    removePermision(state, action) {
      state.details.permissions = state.details.permissions.filter(
        (item) => item.value !== action.payload.value
      );
    },
    resetPermision(state) {
      state.details.permissions = [];
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
    },
    addRoleFilter(state, action) {
      state.filters = {
        ...state.filters,
        ...addFilterToState({
          filters: getItemFilters(state.filters),
          filterKey: 'rolesId',
          payload: action.payload,
        }),
      };
    },
    removeRoleFilter(state, action) {
      state.filters = {
        ...state.filters,
        ...removeFilterFromState({
          filters: getItemFilters(state.filters),
          filterKey: 'rolesId',
          payload: action.payload,
        }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.details = action.payload;
    });
    builder.addCase(fetchDetails.pending, (state) => {
      state.itemNotFound = false;
      state.isDetailsLoading = true;
    });
    builder.addCase(fetchDetails.rejected, (state) => {
      state.itemNotFound = true;
      state.isDetailsLoading = false;
    });

    builder.addCase(updateRole.fulfilled, (state) => {
      state.isDetailsLoading = false;
    });
    builder.addCase(updateRole.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(updateRole.rejected, (state) => {
      state.isDetailsLoading = false;
    });

    builder.addCase(createRole.fulfilled, (state) => {
      state.isDetailsLoading = false;
    });
    builder.addCase(createRole.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(createRole.rejected, (state) => {
      state.isDetailsLoading = false;
    });

    builder.addCase(
      loadItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action.payload.data;

        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  clearDetails,
  setPage,
  setSort,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  resetAllItems,
} = merchantsSlice.actions;

export default merchantsSlice.reducer;

import { DateRange } from 'types/dateRange';
import { ItemAttributes } from '../../components/itemAttributeType';

export type ReportItem = {
  id: string;
  merchants: MerchantsItem[];
  reportName: string;
  generatedBy?: string;
  generatedAt?: string;
  dateRange: string;
  status: string;
};

export enum SubReportTypes {
  'COMPLETE_REPORT' = 'COMPLETE_REPORT',
  'ALL_SUB_REPORTS_TYPE' = 'ALL_SUB_REPORTS_TYPE',
  'TOTAL_AMOUNT_VALUE' = 'TOTAL_AMOUNT_VALUE',
  'TOTAL_COUNT_TRANSACTIONS' = 'TOTAL_COUNT_TRANSACTIONS',
  'TOTAL_AMOUNT_NET_VALUE' = 'TOTAL_AMOUNT_NET_VALUE',
  'TOTAL_COUNT_NET_TRANSACTIONS' = 'TOTAL_COUNT_NET_TRANSACTIONS',
  'REFUND_AMOUNT_VALUE' = 'REFUND_AMOUNT_VALUE',
  'REFUND_COUNT_TRANSACTIONS' = 'REFUND_COUNT_TRANSACTIONS',
  'REFUND_PERCENT_OF_TOTAL' = 'REFUND_PERCENT_OF_TOTAL',
  'REFUND_THREE_MONTH_ROLLING_RATIO' = 'REFUND_THREE_MONTH_ROLLING_RATIO',
  'CHARGEBACK_AMOUNT_VALUE' = 'CHARGEBACK_AMOUNT_VALUE',
  'CHARGEBACK_COUNT_TRANSACTIONS' = 'CHARGEBACK_COUNT_TRANSACTIONS',
  'CHARGEBACK_PERCENT_OF_TOTAL' = 'CHARGEBACK_PERCENT_OF_TOTAL',
  'CHARGEBACK_THREE_MONTH_ROLLING_RATIO' = 'CHARGEBACK_THREE_MONTH_ROLLING_RATIO',
  'HIGH_MONTHLY_AND_LOW_MONTHLY' = 'HIGH_MONTHLY_AND_LOW_MONTHLY',
}

export enum ReportActions {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export type ReportsFilterProp = {
  programManager?: string | null;
  merchants: ItemAttributes[];
  status: ItemAttributes[];
  date: DateRange;
};

export type ReportDetails = {
  id: string;
  title: string;
  type: string;
  merchants: MerchantsItem[];
  subReports?: SubReportItems[];
  dateRange: string;
  generatedBy?: string;
  generatedAt?: string;
  reviewedBy?: string;
  reviewedAt?: string;
  status: string;
  reason: string;
  cause: string;
};

export type SubReportItems = {
  type: string;
};

export type MerchantsItem = {
  merchantName?: string;
  merchantId?: string;
};

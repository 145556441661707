import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReportView } from './report-view-component/component/ReportView';
import { ReportPreview } from './report-view-component/component/preview/ReportPreview';
import { useEffect } from 'react';
import { resetAllItems } from './report-view-component/reportSlice';
import { useAppDispatch } from 'hooks/redux';

export const ReportsViewRout = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetAllItems());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={`${match.path}/:id`}>
        <ReportPreview />
      </Route>
      <Route path={`${match.path}`}>
        <ReportView />
      </Route>
    </Switch>
  );
};

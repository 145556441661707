import { HistoryProps } from 'entities/user-management/user-list';
import { ItemAttributes } from 'components/itemAttributeType';
import { UserLoggingDetails } from 'entities/user-management';

import { UserStatus } from '../status';

export const ADMIN_ROLE = 'ADMIN';
export const MERCHANT_ROLE = 'MERCHANT';
export const PENDING_STATUS = 'PENDING';
export const ACTIVE_STATUS = 'ACTIVE';
export const LOCKED_STATUS = 'LOCKED';
export const UNLOCK_USER_REASON = 'Unlock User Reason';
export const USER_NOTIFICATION_REASON = 'Change User Notification';

export enum LoggingParams {
  USER_SIG_IN = 'user_sig_in',
  USER_SIG_OUT = 'user_sig_out',
  USER_INVALID_PASSWORD = 'user_invalid_password',
  USER_INVALID_OTP = 'user_invalid_otp',
}

export enum LoggingStatus {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_USER = 'SIGN_OUT_USER',
  SIGN_OUT_SYSTEM = 'SIGN_OUT_SYSTEM',
  MAXIMUM_LOGIN_ATTEMPTS = 'MAXIMUM_LOGIN_ATTEMPTS',
  INVALID_PASSWORD_ENTRIES = 'INVALID_PASSWORD_ENTRIES',
  INVALID_OTP_ENTRIES = 'INVALID_OTP_ENTRIES',
}

export const userStatusItems: ItemAttributes[] = [
  { label: 'ACTIVE', value: UserStatus.ACTIVE },
  { label: 'DISABLED', value: UserStatus.DISABLED },
  { label: 'PENDING', value: UserStatus.PENDING },
  { label: 'LOCKED', value: UserStatus.LOCKED },
];

export type FiltersParams = {
  rolesId: ItemAttributes[];
  merchants: ItemAttributes[];
  status: ItemAttributes[];
  userName: string | null;
  userEmail: string | null;
};

export const details = {
  id: '',
  name: '',
  permissions: [],
  createdTime: '',
};

export const defaultFilters: FiltersParams = {
  rolesId: [],
  merchants: [],
  status: [],
  userName: null,
  userEmail: null,
};

type LoadItemsOptions = Record<string, unknown> & FiltersParams;

export const normalizeLoggingChargeback = (
  chargeback: any
): UserLoggingDetails => ({
  ...chargeback,
});

export const listRequestPayload = (options: LoadItemsOptions) => ({
  status: options.status?.map(({ value }) => value) || undefined,
  search: {
    'user-name': options.userName || undefined,
    'user-email': options.userEmail || undefined,
  },
  rolesId: options.rolesId?.map(({ value }) => value) || undefined,
});

export type Merchant = {
  id: string;
  name: string;
};

export const userDetailsPreview = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  reason: '',
  role: { id: '', name: '' },
  status: UserStatus.ACTIVE,
  merchants: [],
  history: [],
  createdTime: '',
  updatedTime: '',
  lastActivityTime: '',
  disabledTime: '',
  signInAt: [],
  logoutAt: [],
  invalidPasswordAttempts: [],
  invalidOTPEntries: [],
  notificationType: [],
};

export interface LoggingDetails {
  loggingTime: string;
  loggType: string;
}

interface UserDetailsProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserDetailsHelper;
  status: UserStatus;
  reason: string;
  createdTime: string;
  updatedTime: string;
  lastActivityTime: string;
  disabledAt: string;
  history: HistoryProps[];
  signInAt: string[];
  logoutAt: string[];
  invalidPasswordAttempts: string[];
  invalidOTPEntries: string[];
  merchants: UserDetailsHelper[];
  notificationType: string[];
}

export interface UserDetailsHelper {
  id: string;
  name: string;
}

export const mappedUserDetails = ({ data }: { data: UserDetailsProps }) => {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    role: data.role,
    history: data.history,
    status: data.status,
    reason: data.reason,
    createdTime: data.createdTime,
    updatedTime: data.updatedTime,
    lastActivityTime: data.lastActivityTime,
    disabledTime: data.disabledAt,
    signInAt: data.signInAt,
    logoutAt: data.logoutAt,
    invalidPasswordAttempts: data.invalidPasswordAttempts,
    invalidOTPEntries: data.invalidOTPEntries,
    merchants: data.merchants,
    notificationType: data.notificationType,
  };
};

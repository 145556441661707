import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'domain/account/selectors';

export const PrivateRoute = (props: RouteProps) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};

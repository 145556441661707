import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { User } from './context';
import { UserStatusBadge } from './UserStatusBadge';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from '../../../account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';

export const useColumns = () => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'name',
        value: (item: User) => (
          <CellLight>
            {item.firstName} {item.lastName}
          </CellLight>
        ),
        label: 'User Name',
        width: 150,
        isSorted: true,
        sortField: 'USERS_NAME',
      },
      {
        id: 'email',
        value: (item: User) => <CellLight>{item.email}</CellLight>,
        label: 'Email',
        width: 250,
        isSorted: true,
        sortField: 'USERS_EMAIL',
      },
      {
        id: 'role',
        value: (item: User) => <CellLight>{item.role}</CellLight>,
        label: 'Role',
        width: 150,
        isSorted: true,
        sortField: 'ROLE_NAME',
      },
      {
        id: 'status',
        value: (item: User) => (
          <CellLight>
            <UserStatusBadge status={item.status} />
          </CellLight>
        ),
        label: 'Status',
        width: 150,
        isSorted: true,
        sortField: 'USERS_STATUS',
      },
      {
        id: 'lastLogIn',
        value: (item: User) => (
          <CellLight>{item.lastLogIn && timeFormat(item.lastLogIn)}</CellLight>
        ),
        label: 'Last Login',
        width: 210,
        isSorted: true,
        sortField: 'LAST_ACTIVITY_TIME',
      },
      {
        id: 'lastPasswordChange',
        value: (item: User) => (
          <CellLight>
            {item.lastPasswordChange && timeFormat(item.lastPasswordChange)}
          </CellLight>
        ),
        label: 'Last Password Change',
        width: 210,
        isSorted: true,
        sortField: 'USER_PASSWORD_CHANGE',
      },
      {
        id: 'passwordExpirationDate',
        value: (item: User) => (
          <CellLight>
            {item.passwordExpirationDate &&
              timeFormat(item.passwordExpirationDate)}
          </CellLight>
        ),
        label: 'Password Expiration Date',
        width: 210,
        isSorted: true,
        sortField: 'USER_PASSWORD_CHANGE',
      },
    ],
    [t]
  );
};

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';

import { menuItemsMap, menuSubItemsMap } from 'layouts/app/utils';
import { FilterSelect } from 'components/filters/FilterSelect';

const formatGroupLabel = ({ label }: any) => {
  return (
    <div>
      <span>{menuItemsMap[label].label}</span>
    </div>
  );
};

interface SelectUiTabsProps {
  items: any;
  defaultItems?: any;
  control: any;
  error: any;
}

const SelectUiTabs: React.FC<SelectUiTabsProps> = ({
  items,
  defaultItems,
  control,
  error,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const missingOptions = defaultItems.filter(
      (defaultItem) => !menuSubItemsMap[defaultItem.value]
    );

    if (missingOptions.length) {
      missingOptions.forEach((option) => {
        toast.warn(`${option.value} is no longer available in Tab field`, {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          bodyClassName: 'break-word break-all',
        });
      });
    }
  }, [defaultItems]);

  return (
    <div>
      <label
        className="relative block text-gray-700 font-medium tracking-wide text-xs"
        htmlFor="select-controller-tabs"
      >
        {t('userManagement.roleDetails.tabs')}
        {error && (
          <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
            {t('validations.required')}
          </span>
        )}
      </label>
      <FilterSelect
        key={defaultItems?.length}
        labelClassName="font-medium tracking-wide text-xs text-gray-700"
        name="tabs"
        control={control}
        options={items}
        error={error}
        formatGroupLabel={formatGroupLabel}
        getOptionLabel={(option) => {
          return menuSubItemsMap[option.value]
            ? menuSubItemsMap[option.value].label
            : menuSubItemsMap.TAB_VALUE_DOES_NOT_EXIST.label;
        }}
      />
    </div>
  );
};

export default SelectUiTabs;

import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { MerchantToggleStatusBadge } from './MerchantToggleStatusBadge';

export type MerchantItem = {
  id: string;
  name: string;
  status: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  companyName: string;
  processor: string;
  programName: string;
};

export const useColumns = () => {
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'name',
        label: 'Merchant',
        value: (item: MerchantItem) => <CellLight>{item.name}</CellLight>,
        isSorted: true,
        sortField: 'MERCHANT_NAME',
      },
      {
        id: 'isEnabled',
        label: 'Status',
        value: (item: MerchantItem) => (
          <CellLight>
            <MerchantToggleStatusBadge status={item.status}>
              {item.status}
            </MerchantToggleStatusBadge>
          </CellLight>
        ),
        isSorted: false,
        sortField: 'MERCHANT_STATUS',
      },
      {
        id: 'processor',
        label: 'Processor',
        value: (item: MerchantItem) => (
          <CellLight>{item.processor.replace('_', ' ')}</CellLight>
        ),
        isSorted: true,
        sortField: 'MERCHANT_PROCESSOR',
      },
      {
        id: 'accountHolder',
        value: (item: MerchantItem) => (
          <CellLight>
            {item.accountHolderFirstName} {item.accountHolderLastName}
          </CellLight>
        ),
        label: 'Account Holder',
        width: 150,
        isSorted: true,
        sortField: 'MERCHANT_ACCOUNT_HOLDER',
      },
      {
        id: 'companyName',
        label: 'Company',
        value: (item: MerchantItem) => (
          <CellLight>{item.companyName}</CellLight>
        ),
        isSorted: true,
        sortField: 'MERCHANT_COMPANY',
      },
      {
        id: 'programName',
        label: 'Program',
        value: (item: MerchantItem) => (
          <CellLight>{item.programName}</CellLight>
        ),
        isSorted: true,
        sortField: 'PROGRAM_NAME',
      },
    ],
    []
  );
};

import { ItemAttributes } from 'components/itemAttributeType';
import {
  countDecimalPlaces,
  filterValues,
} from '../../transaction-management/authorizations/utils';
import { DateRange } from '../../../types/dateRange';
import { TransactionType } from '../../transaction-management/authorizations/authorization';
import moment from 'moment-timezone';
import { dateTimeSecondsFormat } from '../../../components/dateTimeFormatUtil';

export type TerminalFiltersProps = {
  cardLastFour: string;
  authorizationCode: string;
  stan: string;
  id: string;
  nameOnCard: string;
  merchantExternalReference: string;
  transactionDate: DateRange;
  settledDate: DateRange;
  amount: number | null;
  merchants: ItemAttributes[];
  transactionTypes: ItemAttributes[];
  cardTypes: ItemAttributes[];
  status: ItemAttributes[];
  submittedBy: ItemAttributes[];
  submittedByClient: ItemAttributes[];
};

export const defaultFilters: TerminalFiltersProps = {
  cardLastFour: '',
  authorizationCode: '',
  stan: '',
  id: '',
  nameOnCard: '',
  merchantExternalReference: '',
  transactionDate: {
    from: null,
    to: null,
  },
  settledDate: {
    from: null,
    to: null,
  },
  amount: null,
  merchants: [],
  transactionTypes: [],
  cardTypes: [],
  status: [],
  submittedBy: [],
  submittedByClient: [],
};

export const listRequestPayload = (
  options: TerminalFiltersProps,
  dateZone: any
) => ({
  filters: {
    transactionDate:
      options.transactionDate.from && options.transactionDate.to
        ? {
            from: moment(options.transactionDate.from)
              .tz(dateZone)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format(dateTimeSecondsFormat),
            to: moment(options.transactionDate.to)
              .tz(dateZone)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0,
              })
              .format(dateTimeSecondsFormat),
          }
        : null,
    settledDate:
      options.settledDate.from && options.settledDate.to
        ? {
            from: moment(options.settledDate.from)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format(dateTimeSecondsFormat),
            to: moment(options.settledDate.to)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0,
              })
              .format(dateTimeSecondsFormat),
          }
        : null,
    cardLast4: options.cardLastFour || null,
    id: options.id || null,
    authorizationCode: options.authorizationCode || null,
    amount: options.amount || null,
    usersId:
      options.submittedBy.length > 0 ? filterValues(options.submittedBy) : null,
    clientsId:
      options.submittedByClient.length > 0
        ? filterValues(options.submittedByClient)
        : null,
    merchantIds:
      options.merchants.length > 0 ? filterValues(options.merchants) : null,
    status: options.status.length > 0 ? filterValues(options.status) : null,
    transactionType:
      options.transactionTypes.length > 0
        ? filterValues(options.transactionTypes)
        : null,
  },
});

export interface CreateParams {
  register: any;
  control?: any;
  errors: any;
  watch?: any;
  handleSubmit?: any;
  setError?: any;
  setValue?: any;
}

export interface SummeryCreateParams {
  watch?: any;
  handleSubmit?: any;
}

enum PaymentType {
  SALES = 'Sales',
  PURCHASE = 'PURCHASE',
  AUTHORIZATION = 'Authorization',
  AUTHORIZATION_VALUE = 'AUTHORIZATION',
}

export const paymentTypeItems: ItemAttributes[] = [
  {
    label: PaymentType.SALES,
    value: PaymentType.PURCHASE,
  },
  {
    label: PaymentType.AUTHORIZATION,
    value: PaymentType.AUTHORIZATION_VALUE,
  },
];

export const virtualTerminalTransactionTypes = (t: any) =>
  [
    TransactionType.AUTHORIZATION, //todo check with ba
    TransactionType.CAPTURE, // todo check with ba
    TransactionType.PURCHASE, // todo check with ba
    TransactionType.REFUND, // todo check with ba
    TransactionType.REVERSAL,
    TransactionType.VERIFICATION,
    TransactionType.VOID,
    TransactionType.ACH,
  ].map((item: string) => ({
    value: item,
    label: t(`transactionsManagement.authorizations.transactionType.${item}`),
  }));

const isCountDecimalPaces = (amount: number | null) => {
  return amount ? countDecimalPlaces(amount) <= 2 : false;
};

export const isFilterSelected = (filters: TerminalFiltersProps) =>
  [
    filters.id.length ||
      filters?.cardLastFour.length ||
      (filters.amount !== null &&
        isCountDecimalPaces(filters.amount) &&
        filters.amount >= 0) ||
      filters.merchants.length ||
      filters.transactionTypes.length ||
      filters.status.length ||
      filters.submittedBy.length ||
      filters.submittedByClient.length ||
      (filters.settledDate.from && filters.settledDate.to) ||
      (filters.transactionDate.from && filters.transactionDate.to),
  ].some((value) => !!value);

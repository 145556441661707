import Label from './Label';
import { getTextAreaProps, TextAreaProps } from './utils';
import React from 'react';

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  disabled = false,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  hasError,
  placeholder = ' ',
  className,
  maxLength,
  onKeyPress,
  onChange,
  inputProps,
}) => {
  const props = {
    ...getTextAreaProps({
      error,
      hasError,
      className,
      placeholder,
      name,
      disabled,
    }),
  };

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <div className="relative">
        <textarea
          style={{ resize: 'none' }}
          maxLength={maxLength}
          onChange={onChange}
          onKeyPress={onKeyPress}
          disabled={disabled}
          {...props}
          {...inputProps}
        />

        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

import { Control, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { ItemAttributes } from 'components/itemAttributeType';
import { useAppDispatch } from 'hooks/redux';
import {
  SelectorInput,
  SelectorOption,
  stylesStyles,
} from 'components/inputs/SelectHelper';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface FilterSelectProps {
  name: string;
  control: Control<any>;
  label?: any;
  placeholder?: string;
  loadOptions?: any;
  options?: ItemAttributes[];
  isMulti?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  labelClassName?: string;
  components?: any;
  formatGroupLabel?: any;
  getOptionLabel?: any;
  error?: any;
  isDisabled?: any;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  name,
  loadOptions,
  control,
  label = '',
  placeholder = ' ',
  options = [],
  isMulti = true,
  isClearable = true,
  closeMenuOnSelect = false,
  labelClassName = 'text-xs font-medium',
  components = {},
  formatGroupLabel,
  getOptionLabel,
  error,
  isDisabled = false,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [list, setList] = useState<ItemAttributes[]>(options);
  useEffect(() => {
    if (loadOptions) {
      const fetchOptions = async () => {
        setIsLoading(true);
        const data = await loadOptions();
        setList(data);
        setIsLoading(false);
      };

      fetchOptions();
    }
  }, [dispatch, loadOptions]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div>
          {label && (
            <label
              className={classNames(
                'relative block tracking-wide',
                labelClassName
              )}
              htmlFor={`select-controller-${name}`}
            >
              {label}
              {error && (
                <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
                  {error?.message || t(`validations.${error?.type}`)}
                </span>
              )}
            </label>
          )}
          <Select
            inputId={`select-controller-${name}`}
            isDisabled={isDisabled}
            placeholder={
              <span className="text-gray-600 cursor-pointer text-xs font-medium">
                {placeholder}
              </span>
            }
            onChange={(option: ItemAttributes) => onChange(option)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            openMenuOnFocus={false}
            components={{
              Option: SelectorOption,
              Input: SelectorInput,
              ...components,
            }}
            styles={stylesStyles({
              error: error,
              control: { height: 32, marginTop: 4 },
              singleValue: {
                marginTop: 3,
                fontWeight: 'bold',
                fontSize: '0.75rem',
              },
            })}
            getOptionLabel={getOptionLabel}
            formatGroupLabel={formatGroupLabel}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={false}
            isClearable={isClearable}
            isLoading={isLoading}
            isMulti={isMulti}
            options={list}
            value={value}
          />
        </div>
      )}
    />
  );
};

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import LayoutList from 'layouts/list/LayoutList';

import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
} from '../serviceMonitorSlice';
import { Filters } from './filters/Filters';
import { useColumns } from './useColumns';
import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { FiltersParams, ServicesItem } from '../utils';

export const ServiceMonitorList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const items: ServicesItem[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);

  const columns: ColumnProps[] = useColumns();

  const tableRef = useRef<HTMLDivElement>(null);
  const filters: FiltersParams = useAppSelector(getFilters);

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [itemsLoadingComputed]);

  const isFilterSelected = [filters.day?.from].some((value) => !!value);

  return (
    <LayoutList title={t('serviceMonitor.breadcrumb')}>
      <TableCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        items={items}
        totalNumber={totalNumber}
        pagination={pagination}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        filters={<Filters />}
        filterApply={
          isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
        }
        onHover={false}
      />
    </LayoutList>
  );
};

import { t } from 'i18n';
import { ColumnProps } from '../../components/table';
import { ReportActions } from '../../entities/reports';

export const downloadReports = (response: any) => {
  function download(urls) {
    const url = urls.pop();

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (urls.length == 0) {
      //eslint-disable-next-line @typescript-eslint/no-use-before-define
      clearInterval(interval);
    }
  }

  const interval = setInterval(download, 1000, response.data);
};

export const errorMessage = (code: string) =>
  t(`reports.errorCodes.${code}`, code);

export const DAILY = 'daily';
export const MONTHLY = 'monthly';

export const ReportTypes = {
  MERCHANT_MONTHLY_CLIENT: {
    typeName: 'MERCHANT_TRANSACTION_REPORT',
    name: 'Merchant Monthly Client',
    link: '/reports/monthly/merchant-reports',
  },
  MONTHLY_INTERNAL_MERCHANT_PORTFOLIO: {
    typeName: 'INTERNAL_PORTFOLIO_REPORT',
    name: 'Monthly Internal Merchant Portfolio',
    link: '/reports/monthly/internal-merchant-reports',
  },
  MONTHLY_EXCESSIVE_ACTIVITY: {
    typeName: 'MONTHLY_EXCESSIVE_ACTIVITY_REPORTING',
    name: 'Monthly Excessive Activity',
    link: '/reports/monthly/monthly-excessive-activity',
  },
  PORTFOLIO_TRANSACTION_SUMMARY: {
    typeName: 'PORTFOLIO_TRANSACTION_SUMMARY',
    name: 'Portfolio Transaction Summary',
    link: '/reports/monthly/portfolio-transaction-summary',
  },
  GENERAL_ACCOUNT_DATA: {
    typeName: 'GENERAL_ACCOUNT_DATA_REPORT',
    name: 'General Account Data',
    link: '/reports/daily/general-account-data-report',
  },
  DAILY_EXCEPTION_EXCESSIVE_ACTIVITY: {
    typeName: 'DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY',
    name: 'Daily Exception Excessive Activity',
    link: '/reports/daily/daily-exception-report-excessive-activity',
  },
  DAILY_EXCEPTION_EXCESSIVE_ACTIVITY_LIVE: {
    typeName: 'DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY_LIVE',
    name: 'Daily Exception Excessive Activity Live',
    link: '/reports/daily/daily-exception-report-excessive-activity-live',
  },
  DAILY_TRANSACTIONS: {
    typeName: 'DAILY_TRANSACTIONS_REPORT',
    name: 'Daily Transactions',
    link: '/reports/daily/daily-transactions',
  },
  DAILY_TRANSACTION_SUMMARY: {
    typeName: 'DAILY_TRANSACTION_SUMMARY',
    name: 'Daily Transaction Summery',
    link: '/reports/daily/daily-transaction-summary',
  },
  DAILY_EXCESSIVE_ACTIVITY: {
    typeName: 'DAILY_EXCESSIVE_ACTIVITY_REPORTING',
    name: 'Daily Excessive Activity',
    link: '/reports/daily/daily-excessive-activity',
  },
  DAILY_EXCEPTION_REFUND: {
    typeName: 'DAILY_EXCEPTION_REPORT_REFUND_EXCEPTIONS',
    name: 'Daily Exception Refund',
    link: '/reports/daily/daily-exception-refund',
  },
  DAILY_EXCEPTION_BATCH: {
    typeName: 'DAILY_REPORT_BATCH_EXCEPTIONS',
    name: 'Daily Exception Batch',
    link: '/reports/daily/daily-exception-batch',
  },
  DAILY_EXCEPTION_TICKET: {
    typeName: 'DAILY_REPORT_TICKET_EXCEPTIONS',
    name: 'Daily Exception Ticket',
    link: '/reports/daily/daily-exception-ticket',
  },
  DUPLICATE_TRANSACTION: {
    typeName: 'DAILY_DUPLICATE_TRANSACTION',
    name: 'Daily Duplicate Transaction',
    link: '/reports/daily/daily-duplicate',
  },
};

export const monthYearFormat = 'MMMM YYYY';
export const generationMonthYearFormat = 'yyyy-MM';

export type ReportData = {
  reportType: string;
  reportName: string;
  reportCols: ColumnProps[];
  hasMerchant?: boolean;
  hasMultipleMerchant?: boolean;
  isYearDate?: boolean;
  filterMaxDate?: Date;
  generateMaxDate?: Date;
  hasMultipleDownloads?: boolean;
};

export const getReportsDetailsIconColor = (status: string) => {
  switch (status) {
    case ReportActions.ACCEPTED:
      return 'box-bg-green-300 box-shadow-green';
    case ReportActions.REJECTED:
      return 'box-bg-red-300 box-shadow-red';
    case ReportActions.PENDING:
      return 'box-bg-orange-300 box-shadow-orange';
    case ReportActions.PROCESSED:
      return 'box-bg-yellow-green-300 box-shadow-hippie-blue';
    case ReportActions.FAILED:
      return 'box-bg-brown-300 box-shadow-brown';
    default:
      return 'box-bg-grey-300 box-shadow-grey';
  }
};

export const FAILED_STATUS = 'FAILED';

import api from 'api';

const signOutPost = async (data: any) => {
  return api.post('/auth/sign-out', data);
};

const signInPost = async (data: any, headers: any) => {
  return api.post('/auth/sign-in', data, {
    headers,
  });
};

const authorizationVerify = async (data: any) => {
  return api.post('/auth/verify', data);
};

const resetPassword = async (data: any, headers: any) => {
  return api.post('/account/set-password', data, {
    headers,
  });
};

const changePasswordTokenUrl = async (data: any, headers: any) => {
  return api.post('/account/change-password/token', data, {
    headers,
  });
};
const changePassword = async (data: any) => {
  return api.post('/account/change-password', data);
};

const profile = async (data: any) => {
  return api.post('/account/get', data);
};

const menuItems = async (data: any) => {
  return api.post('/account/ui-tab/list', data);
};

const forgotPassword = async (data: any) => {
  return api.post('/account/restore-password', data);
};

const checkResetPasswordToken = async (data: any) => {
  return api.post('/account/verify-confirmation-code', data);
};

export const authResource = {
  checkResetPasswordToken,
  forgotPassword,
  resetPassword,
  menuItems,
  login: signInPost,
  logout: signOutPost,
  profile,
  changePasswordTokenUrl,
  changePassword,
  authorizationVerify,
};

import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface ItemViewProps {
  labelName: string;
  value?: any;
  isNotSet?: boolean;
  isUnknown?: boolean;
}

export const ItemView: React.FC<ItemViewProps> = ({
  labelName,
  value,
  isNotSet = false,
  isUnknown = false,
}) => {
  return (
    <>
      {(value || value === 0) && (
        <div className="flex flex-col mt-2 break-words">
          <div className="text-sm font-normal text-gray-800">{labelName}:</div>
          <div className="text-base font-normal">{value}</div>
        </div>
      )}
      {(value === undefined || value === null) && isNotSet && (
        <div className="flex flex-col mt-2 break-words">
          <div className="text-sm font-normal text-gray-800">{labelName}:</div>
          <div className="text-base font-normal">
            {isUnknown ? 'Unknown' : 'Not Set'}
          </div>
        </div>
      )}
    </>
  );
};

interface ItemCardViewProps {
  labelName: string;
  value?: any;
  onClick: any;
  isNotSet?: boolean;
  isUnknown?: boolean;
  isHiddenNumber?: boolean;
}

export const ItemCardView: React.FC<ItemCardViewProps> = ({
  labelName,
  value,
  isNotSet = false,
  isUnknown = false,
  isHiddenNumber = false,
  onClick,
}) => {
  return (
    <>
      {(value || value === 0) && (
        <div className="flex flex-col mt-2 break-words">
          <div className="text-xs font-normal text-gray-700">{labelName}</div>
          <div className="flex">
            <div className="text-base font-normal">{value}</div>
            <div className="ml-2">
              {isHiddenNumber ? (
                <VisibilityOffIcon
                  color="action"
                  className="hover:border-ruby-500"
                  fontSize="small"
                  onClick={onClick}
                  sx={{ '&:hover': { color: 'blue', cursor: 'pointer' } }}
                />
              ) : (
                <VisibilityIcon
                  color="action"
                  fontSize="small"
                  onClick={onClick}
                  sx={{ '&:hover': { color: 'blue', cursor: 'pointer' } }}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {(value === undefined || value === null) && isNotSet && (
        <div className="flex flex-col mt-2 break-words">
          <div className="text-xs font-normal text-gray-700">{labelName}</div>
          <div className="text-base font-normal">
            {isUnknown ? 'Unknown' : 'Not Set'}
          </div>
        </div>
      )}
    </>
  );
};

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container as MerchantsList } from './merchants/list/Container';
import { CreateMerchantNew } from './merchants/create-merchant/CreateMerchantNew';
import { MerchantPreviewDetails } from './merchants/preview/MerchantPreviewDetails';
import { EditMerchantDetails } from './merchants/edit-merchant/EditMerchantDetails';
import { useEffect } from 'react';
import { resetAllItems } from './merchants/merchantsSlice';
import { useAppDispatch } from 'hooks/redux';

export const MerchantRoleManagement = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <CreateMerchantNew />
      </Route>
      <Route exact={true} path={`${match.path}/details/:id`}>
        <MerchantPreviewDetails />
      </Route>
      <Route path={`${match.path}/update/:component`}>
        <EditMerchantDetails />
      </Route>
      <Route path={`${match.path}`}>
        <MerchantsList />
      </Route>
    </Switch>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { settingsResource } from './api';

export const setUserDateTimeDetails: any = createAsyncThunk(
  `${'settings'}/setUserDateTimeDetails`,
  async (data: any) => {
    try {
      await settingsResource.setUserDateTimeDetails(data);

      toast.success('User settings updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return data;
    } catch (e) {
      toast.error('User settings could not be updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return Promise.reject(e);
    }
  }
);

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Chargebacks } from './chargebacks/Chargebacks';
import { ChargebacksDetails } from './chargebacks/details/ChargebacksDetails';

export const DisputeResolution = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact={true} path={`${match.path}/chargebacks/details/:id`}>
        <ChargebacksDetails />
      </Route>
      <Route path={`${match.path}/chargebacks`}>
        <Chargebacks />
      </Route>
    </Switch>
  );
};

import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReportItem, ReportsFilterProp } from 'entities/reports';
import LayoutList from 'layouts/list/LayoutList';
import { PaginationProps, TableCard } from 'components/table';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getReportState,
  getTotalNumber,
} from '../selectors';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../reportSlice';
import { loadItems } from '../thunks';
import { ItemSort } from 'entities';
import { useSelectedReport } from './selectedReport';
import { ReportGenerate } from './generate/ReportGenerate';
import { ReportFilter } from './filter/ReportFilter';
import { Base64 } from 'js-base64';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';

export const ReportView = () => {
  const routeMatch = useRouteMatch();

  const dispatch = useAppDispatch();
  const { page, rowsPerPage, reportFilterApply } =
    useAppSelector(getReportState);
  const totalNumber = useAppSelector(getTotalNumber);
  const filters: ReportsFilterProp = useAppSelector(getFilters);
  const history = useHistory();

  const selectedReport = useSelectedReport(routeMatch.url);

  useEffect(() => {
    dispatch(loadItems({ reportTypeName: selectedReport?.reportType }));
  }, [
    dispatch,
    page,
    rowsPerPage,
    selectedReport?.reportType,
    reportFilterApply,
  ]);

  const isFilterSelected = [
    filters.status.length ||
      filters.merchants.length ||
      (filters.date.from && filters.date.to),
  ].some((value) => !!value);

  const tableRef = useRef<HTMLDivElement>(null);
  const items: ReportItem[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const itemsLoadingComputed = useAppSelector(getItemsLoadingComputed);
  const permissions: any = useAppSelector(getProfilePermission);
  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isOnRowClick = permissions.includes(UserPermissions.REPORTS_GET);
  const canGenerate = permissions.includes(UserPermissions.REPORTS_CREATE);

  const handleRowClick = (report: any) => {
    history.push(`${routeMatch.url}/${Base64.encodeURI(report.id)}`);
  };

  return (
    <LayoutList title={selectedReport!.reportName}>
      <>
        <TableCard
          tableRef={tableRef}
          totalNumber={totalNumber}
          loading={itemsLoadingComputed}
          columns={selectedReport!.reportCols}
          items={items}
          setSort={setCurrentSort}
          onRowClick={isOnRowClick ? handleRowClick : undefined}
          onHover={isOnRowClick}
          pagination={pagination}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          filters={
            <ReportFilter
              hasMerchant={selectedReport!.hasMerchant}
              isYearDate={selectedReport!.isYearDate}
              maxDate={selectedReport!.filterMaxDate}
            />
          }
          filterApply={
            isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
          }
          generate={
            canGenerate ? (
              <ReportGenerate
                reportGenerationType={selectedReport!.reportType}
                hasMerchant={selectedReport!.hasMerchant}
                hasMultipleMerchant={selectedReport!.hasMultipleMerchant}
                isYearDate={selectedReport!.isYearDate}
                maxDate={selectedReport!.generateMaxDate}
              />
            ) : null
          }
        />
      </>
    </LayoutList>
  );
};

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { HistoryBackButton } from 'components/buttons/HistoryBackButton';
import { PageHeader } from 'components/PageHeader';
import { useAppDispatch } from 'hooks/redux';
import { Card } from 'components/Card';

import { RoleForm } from '../compoents/RoleForm';
import { fetchDetails } from '../thunks';

export const RoleUpdate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params: { id: string } = useParams();

  useEffect(() => {
    dispatch(fetchDetails({ roleId: params.id }));
  }, [dispatch, params.id]);

  return (
    <div className="bg-gray-200">
      <PageHeader title={t('userManagement.roleDetails.update')} />

      <div className="pt-3 px-4 sm:px-7 pb-6 grid grid-rows gap-4">
        <Card>
          <HistoryBackButton />
        </Card>

        <RoleForm />
      </div>
    </div>
  );
};

import api from 'api';

export const list = (options?: any) => {
  return api.post('/dropdown/processors', options);
};

export const processorList = async (options = {} as any) => {
  try {
    const { data } = await list(options);

    return data;
  } catch (e) {
    return [];
  }
};

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import RoleCreate from './roles/create/RoleCreate';
import { RoleUpdate } from './roles/update/RoleUpdate';
import { Container as RoleListContainer } from './roles/list/Container';
import { useEffect } from 'react';
import { resetAllItems } from './roles/rolesSlice';

export const RoleRoutManagement = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <RoleCreate />
      </Route>
      <Route path={`${match.path}/update/:id`}>
        <RoleUpdate />
      </Route>
      <Route path={`${match.path}`}>
        <RoleListContainer />
      </Route>
    </Switch>
  );
};

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useSelector } from 'react-redux';
import {
  dateTimeZone,
  getProfilePermission,
  isMerchantSelector,
} from 'domain/account/selectors';
import { dateMonthFormatUtil } from 'components/dateTimeFormatUtil';
import { getAppliedFilters, getFilters } from '../../selectors';
import React, { useRef } from 'react';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../reportSlice';
import { FilterSelect } from 'components/filters/FilterSelect';
import { defaultFilters, reportStatusItems } from '../../../components/utils';
import { merchantList, merchantPortalList } from 'api/merchants';
import { UserPermissions } from 'enums/Permissions';
import { ReportsFilterProp } from 'entities/reports';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { useFilterEffect } from 'utils/useFilterEffect';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';

type ReportProps = {
  hasMerchant?: boolean;
  isYearDate?: boolean;
  maxDate?: Date;
  toggleDrawer?: () => void;
};

export const ReportFilter: React.FC<ReportProps> = ({
  hasMerchant = false,
  isYearDate = false,
  maxDate = new Date(),
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMerchantRole = useSelector(isMerchantSelector);
  const permissions: any = useAppSelector(getProfilePermission);
  const reportFilterApply: ReportsFilterProp =
    useAppSelector(getAppliedFilters);
  const dateZone = useSelector(dateTimeZone);

  const {
    getValues,
    handleSubmit,
    control,
    reset: resetFilters,
    watch,
  } = useForm<any>({ defaultValues: reportFilterApply });

  const hasMerchantViewPermission = permissions.includes(
    UserPermissions.MERCHANT_LIST
  );

  const allValues = watch();
  const previousValues = useRef<ReportsFilterProp>(reportFilterApply);

  useFilterEffect(previousValues, allValues, setFilters);

  const filters = useSelector(getFilters);

  const reportHasFilter = Boolean(
    filters.merchants.length ||
      filters.status.length ||
      (filters.date?.from && filters?.date.to)
  );

  const handleApplyFilters = () => {
    const values = getValues();
    dispatch(setApplyFilters(values));
    if (toggleDrawer) toggleDrawer();
  };

  const reset = () => {
    dispatch(resetAllFilters());
    resetFilters(defaultFilters);
  };

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (isYearDate) {
      if (start) {
        setDateFilter(callback, {
          from: start,
          to: null,
        });
      }
      if (start && end) {
        setDateFilter(callback, {
          from: start,
          to: moment(end).tz(dateZone).endOf('month').toDate(),
        });
      }
      return;
    }
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const dateMonthFormatVal = (value: any) =>
    dateMonthFormatUtil(value, dateZone);

  const showRangeValue = (value: any) => {
    return value?.to && value?.from
      ? `${dateMonthFormatVal(value.from)} : ${dateMonthFormatVal(value.to)}`
      : '';
  };

  const filterComponentsList = {
    0: (
      <FilterSelect
        label="Select Status"
        name="status"
        options={reportStatusItems}
        control={control}
        isClearable={false}
      />
    ),
    1:
      hasMerchant && (isMerchantRole || hasMerchantViewPermission) ? (
        <FilterSelect
          label="Select Merchant"
          name="merchants"
          loadOptions={!isMerchantRole ? merchantList : merchantPortalList}
          control={control}
          isClearable={false}
        />
      ) : null,
    2: isYearDate ? (
      <DatePickerControl
        control={control}
        label={t('reports.acquiring.selectDateRange')}
        showValueChange={showRangeValue}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.MONTH_START_END_RANGE_ONE_MONTH}
        popoverWidth={280}
        name="date"
        maxDate={maxDate}
        title="Month Range"
      />
    ) : (
      <DatePickerControl
        control={control}
        label={t('reports.acquiring.selectDateRange')}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={280}
        footer={<></>}
        name="date"
        maxDate={maxDate}
        title="Date Range"
      />
    ),
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={reset}
      isDisabledClear={!reportHasFilter}
      isDisabledApply={!reportHasFilter}
    />
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { userResource } from './api';
import { RootState } from 'store/store';
import { getRoot } from './selectors';
import { LoadItems } from 'entities';
import { listRequestPayload } from './utils';

export const loadItems: any = createAsyncThunk(
  'umUserList/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { page, rowsPerPage, appliedFilters, sort } = getRoot(
        getState() as RootState
      );

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await userResource.secretKeyList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

import { useEffect } from 'react';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/redux';

import { useLocation } from 'react-router-dom';
import { ComplianceAuditIndexComponent } from './components/ComplianceAuditIndexComponent';
import { resetAllItems } from './complianceAuditSlice';

export const Container = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('programs.breadcrumb'),
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return <ComplianceAuditIndexComponent />;
};
